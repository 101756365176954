/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { FxButton } from '../../Action/FxButton';
import { RegisterComponent } from '../../../libs/saga/dataSaga';

/**
 * Component used to display password updated details  
 */
export const CustomerChangePassword: React.FC<any> = React.memo((props: any) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch()

    //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => () => {
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const passwordInfo: any = {};
    /**
     * Getting data
     */
    if (context && context.data && context.data.body && context.data.body.lastUpdatedOn) {
      passwordInfo.last_updated_on = context.data.body.lastPasswordReset;
    }
    const history = useHistory()
    
    return (
      <Grid id="customer-profile-password-grid" item xs={12}>
        <FxCard id="customer-profile-password-card" className="fx-theme-passport">
          <FxCardHeader id={'customer-profile-password-card-header'} >
            <Grid item container justifyContent="space-between" xs={12} alignItems="center">
              <Typography id="customer-profile-password-card-title" variant="h4" >Password</Typography>
              <FxButton id="customer-profile-password-card-change-button" title="Edit" className="fx-button fx-button-passport-border fx-svg-theme" variant="contained" startIcon={<EditIcon />} onClick={() => history.push('/changepassword')} >
                Change
                </FxButton>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="customer-profile-password-card-body" className="fx-info-card fx-margin-top-reducer">
            <Grid container direction="row" key="password-updated" >
              <Grid item xs={12}>
                <Divider />
              </Grid>
                <Grid item xs={12} className="fx-passowrd-lastChanged-card"><Typography  >
                Last Changed <FxDateTimeView className="fx-passowrd-lastChanged-card-value" id="customer-profile-password-card-last-updated-on-textview" value={passwordInfo.last_updated_on} format="MM/DD/YYYY"></FxDateTimeView>
              </Typography>
              </Grid>
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    )
  })