/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Component: CustomerViewDetails
 * Usage: For Customer details view 
 */
import React from "react"; // we need this to make JSX compile
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { Grid } from "@mui/material";
import { ViewCustomerDetails } from "./ViewCustomerDetails";
import { TransactionVolume } from "../Dashboard/TransactionVolume";
import moment from "moment";
import { useParams } from "react-router-dom";
import { checkPermission } from "../../../Utils/CommonBaseClass";

export const CustomerViewDetails: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  interface RouteParams {
    id: string
}
const params = useParams<RouteParams>();
const id = params.id;
  const COLORS = ["#40596B", "#C8CFD5", "#40596B", "#F58220", "#93A1AC"];
  const labelText = { text: 'Transactions', value: 0, labelColor: '#103046' }
  /**
   * Transaction summary source
   */
  const transactionSummarySrc = {
    baseUrl: false,
    url: "/transactionSummary",
    method: "POST",
    data: {
        "pageNumber": 1,
        "pageSize": 25,
        "sortOptions": {
          "sortBy": "createdOn",
          "sortOrder": "desc"
        },
        "criteria": {
            "filters": [
              {
                "operator": "eq",
                "key": "customerId",
                "values": [
                  id
                ]
            },
                {
                    "operator": "gte",
                    "key": "statusDate",
                    "values": [
                        moment().format('MM/DD/YYYY')
                    ]
                },
                {
                    "operator": "lte",
                    "key": "statusDate",
                    "values": [
                        moment().format('MM/DD/YYYY')
                    ]
                }
            ]
        }
      }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <ViewCustomerDetails id="partner-customer-view-details" data={context?.data?.body} />
        </Grid>
        <Grid item xs={12} md={4}>
        {checkPermission({ entity: "Partner Portal", name: "Customer", operation: "Transaction_Summary" }) && <TransactionVolume id="transaction-volume" source={transactionSummarySrc} colors={COLORS} label={labelText} customerId={id}/>}
        </Grid>
      </Grid>
    </>
  );
});
