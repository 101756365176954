/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../Action/FxButton';
import issuance_countries from '../../../libs/utils/country/country.json';
import { renderCityNameError } from '../../Utils/CommonBaseClass';
import defaultcurrencies from '../../Utils/currencies.json';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxExternalAccountNumberEdit } from '../../Input/FxExternalAccountNumber/FxExternalAccountNumberEdit';
import { FxSkeltonList } from '../Cards/FxSkelton';
import usa_state from '../../Utils/usa_states.json';
import { renderStateError, setZipValidationOptions, setCityNameValidation } from '../../Utils/CommonBaseClass';
import FxMaterialMultipleSelect from '../../Input/FxSelect/FXMaterialMultipleSelect';
import { FxSwiftCode } from '../../Input/FxSwiftCode/FxSwiftCode';
import { useMediaQuery } from 'react-responsive';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

const httpClient = HttpClient.getClient();

interface InputErrType {
    type: string;
    message: string;
}


Logger.debug("CreateInternationalExternalAccount.tsx", "create International external account new initializing");
/**
 * Component: CreateInternationalExternalAccount
 * Usage: create International external account
 */
// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}
export const CreateInternationalExternalAccount: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        let internationalExternalAccountId: any = '';
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });

        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control, watch } = useForm();
        const params = useParams<any>();
        const createType = params.type;
        const [holderAddress, setHolderAddress] = React.useState<any>();
        if (context?.data?.body?.id) {
            internationalExternalAccountId = context.data.body.id;
        }
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available
        const [typeSelected, setMethod] = React.useState(context?.data?.body?.card ? 'CARD' : 'BANK_ACCOUNT');
        const accountNumber = context?.data?.body?.accountNumber ? '**' + context.data.body.accountNumber : '';
        const [isloading, setIsloading] = React.useState(false);
        const [isDataLoaded, setIsDataLoaded] = React.useState(false);
        const [submitButton, setSubmitButton] = React.useState(isSmallScreen ? 'Submit' : 'Add International External Account');
        const [isEdit, setIsEdit] = React.useState(!!props?.source);
        const [isActive, setIsActive] = React.useState(false);
        const [processApi, setProcessApi] = React.useState(false);
        const [routingCodeDetails, setRoutingCodeDetails] = React.useState<any>();
        const currencyFixedOptions = [{value:'USD', label:'USD'}];

        /**
         * Method useeffect functions calling before component loading
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if (!isEdit) {
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Add International External Account' + accountNumber,backButton:'/internationalexternalaccounts' } }});
                setValue('type', 'BANK_ACCOUNT');
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        const history = useHistory();

        const accountTypeData = [
            { label: 'Checking', value: 'CHECKING' },
            { label: 'Savings', value: 'SAVINGS' }
        ];
        const accountPurposeData = [
            { label: 'Consumer', value: 'CONSUMER' },
            { label: 'Corporate', value: 'CORPORATE' }
        ];



        if (!isEdit && !processApi) {
            setProcessApi(true)
        }
        /**
         * Method to render account number error
         * @param err 
         */
        const renderAccountNumberError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };

        const renderNameError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.NAME_INVALID_LENGTH;
            }
            return err.message;
        };
        // account number validation constant
        const setAccountNumberValidation = {
            required: (typeSelected === 'BANK_ACCOUNT' && !isActive),
            maxLength: 40
        }

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push('/internationalexternalaccount/view/' + context.data.body.id)
            } else if (createType === 'my-account-ach') {
                history.push('/schedule/collect/add/my-account-ach')
            } else if (createType === 'send-ach') {
                history.push('/schedule/send/add/ach')
            } else if (createType === 'send-wire') {
                history.push('/schedule/send/add/wire')
            } else {
                history.push('/internationalexternalaccounts')
            }
        };
        /**
         * Method to handle changes on event
         * @param event : event type
         */
        const handleChange = (event: any) => {
            if (event.target.name === 'type') {
                setMethod(event.target.value);
                clearErrors();
            }
        }

        /**
         * Method to check if data available in context for edit and set default values
         *  */
        if (context?.data?.body && !isDataLoaded) {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Edit International External Account' + accountNumber,backButton:'/internationalexternalaccount/view/' + context?.data?.body?.id } }});
            const address = context.data.body.holderAddress;
            setHolderAddress(address);
            setValue('type', context.data.body ? 'BANK_ACCOUNT' : 'CARD')
            setValue('swiftCode', context.data.body?.swiftCode)
            // To add default currencies in required format
            setValue('internationalRoutingCode', context.data.body?.internationalRoutingCode)
            setMethod(context.data.body ? 'BANK_ACCOUNT' : 'CARD')
            setSubmitButton('Save');
            setIsDataLoaded(true); // set as true if edit data available
            setIsEdit(true);
            if (context.data.body.status === 'ACTIVE') {
                setIsActive(true);
            }
        }

        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            setIsloading(true)
            let req: any = {};
            let status: any;
            if (typeSelected === 'BANK_ACCOUNT') {
                req = {
                    holderType: data.holderType,
                    holderName: data.holderName,
                    swiftCode: data.swiftCode,
                    type:data.accountType,
                    accountNumber: data.internationalAccountNumber,
                    purpose: data.internationalPurpose,
                    acceptedCurrency: data?.acceptedCurrency?.map((ele:any)=>ele.value).filter((currency: any) => currency !== "Select All") || currencyFixedOptions.map((ele:any)=>ele.value),
                    ...(routingCodeDetails?.internationalCodeLabel && data.internationalRoutingCode && {internationalRoutingCode: data.internationalRoutingCode}),
                    "holderAddress": {
                      state: data.country !== "US" ? data?.nonUnitedState : data?.unitedState,
                      country: data.country,
                      city: data.city,
                      zip: data.zip,
                      addressLine1: data.addressLine1,
                      addressLine2: data.addressLine2,
                    }

                }
                if (isActive) {
                    delete req.internationalAccountNumber;
                }
            }

            if (data.externalId !== '') {
                req['externalId'] = data.externalId
            }

            if(!isEdit && req?.holderAddress?.state === ''){
                 delete req.holderAddress.state;
            } else if(isEdit && req?.holderAddress?.state === ''){
                req.holderAddress.state = null;
            }

            if (!isEdit && req?.holderAddress?.zip === '') {
                delete req.holderAddress.zip;
            } else if(isEdit && req?.holderAddress?.zip === ''){
                req.holderAddress.zip = null;
            }

            if (isEdit && !req?.internationalRoutingCode && !routingCodeDetails.isShow) {
                req['internationalRoutingCode'] = null;
            }

            let successMessage = 'International External Account Created Successfully!';
            if (context?.data?.body?.id) {
                status = await updateInternationalExternalAccount(req);
                successMessage = 'International External Account Updated Successfully!';
            } else {
                status = await CreateInternationalExternalAccount(req);
                if (status?.headers?.url) {
                    internationalExternalAccountId = (status.headers.url).split('/').pop();
                }
            }
            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                setTimeout(() => {
                    history.push('/internationalexternalaccount/view/' + internationalExternalAccountId)
                }, 1000)
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setIsloading(false);
            if (status.status) {
                dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'ea-upload', 'files': [] } });
                if(!isEdit) {
                    const ea_url = '/thanks/manage/internationalexternalaccount/' + internationalExternalAccountId;
                    setTimeout(() => {
                        history.push(ea_url);
                    }, 1000);
                }
                else {
                    history.push('/internationalexternalaccount/view/'+internationalExternalAccountId);
                }
            }
        }


        /**
         * Method to call api  for create international externalAccount
         * @param paylaoddata : request payload
         */
        async function CreateInternationalExternalAccount(paylaoddata: any) {
            try {
             
                const url =  '/internationalExternalAccount'
                 
                const data: any = await httpClient.post(getCustomerUrl(url,false), paylaoddata).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateInternationalExternalAccount.tsx", "error", err);
                return err;
            }
        }
        /**
         * Method to call api  for update international externalAccount
         * @param paylaoddata : request payload
         */
        async function updateInternationalExternalAccount(paylaoddata: any) {
            try {
                const url = '/internationalExternalAccount/id/'

                const data: any = await httpClient.post(getCustomerUrl(url + context?.data?.body?.id,false), paylaoddata).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateInternationalExternalAccount.tsx", "error", err);
                return false;
            }
        }

         /**
         * Method to handle error
         * @param err
         * @returns
         */
         const renderZipError = (err: InputErrType): string => {
            return err.message;
        };

        return (
            <Grid container id="create-international-external-account-main-grid" xs={12} className='fx-form-grid'>
                <Grid id="create-international-external-account-first-grid" item xs={12} container justifyContent="center">
                    <Grid id="create-international-external-account-sub-grid" item xs={12} sm={isSmallScreen ? 8 : 6} >
                            <div className="fx-form-edit-profile flex column">
                                {!isDataLoaded && isEdit && <FxSkeltonList height="15rem" />}
                                {(isDataLoaded || !isEdit) && <form id="create-international-external-account-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-international-external-account-form-card" className="fx-theme-passport">
                                        <FxCardBody id="create-international-external-account-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                            <Grid item xs={12} sm={12}>
                                                <FxLabelView id="international-external-account-basic-info-header" className="" >BASIC INFORMATION</FxLabelView>
                                            </Grid>
                                                {typeSelected === 'BANK_ACCOUNT' && <>
                                                <Grid item xs={12} sm={6}>
                                                    <FxMaterialSelect register={{ ...register("country") }} className={errors.country ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} control={control} onChange={handleChange} id="create-international-external-account-form-card-country" name="country" data={issuance_countries} value={context?.data?.body?.holderAddress?.country ? context.data.body.holderAddress.country : ""} label="Country*" readOnly={isActive ? true : false} setValue={setValue} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} className="fx-contact-user-card">
                                                <FxMaterialMultipleSelect name='acceptedCurrency'
                                                    selectAllLabel={'Select All' }
                                                    control={control} 
                                                    isEdit={props.isEdit ? true : false} 
                                                    id="iea-contact-form-acceptedcurrency"
                                                    data={defaultcurrencies} 
                                                    rules={{ required: currencyFixedOptions ? false : true }}
                                                     fixedOptions={currencyFixedOptions}
                                                     limitTags={3}
                                                     readOnly={props.isEdit ? true : false}
                                                     label='Accepted Currency *'
                                                     canSearch= {false}
                                                     register={{ ...register("acceptedCurrency") }}
                                                     setValue={setValue} />
                                                   </Grid>
                                                    {individualCustomer && <>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxMaterialSelect register={{ ...register("holderType") }} className={errors.accountPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: (typeSelected === 'BANK_ACCOUNT' || !isActive) ? true : false }} value={context?.data?.body?.holderType ? context.data.body.holderType : 'CONSUMER'} control={control} onChange={handleChange} id="create-international-external-account-form-card-account-purpose" name="holderType" data={accountPurposeData} label="Holder Type*" readOnly={isEdit ? true : false} setValue={setValue} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("holderName") }} className={errors.holderName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-international-external-account-form-card-holdername-textbox" label="Holder Name*" name="holderName" variant="outlined" defaultValue={context?.data?.body?.holderName ? context.data.body.holderName : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.holderName && renderNameError(errors.holderName)}
                                                        </div>
                                                    </Grid></>}
                                                    {businessCustomer && <>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxMaterialSelect register={{ ...register("holderType") }} className={errors.accountPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: (typeSelected === 'BANK_ACCOUNT' || !isActive) ? true : false }} value={context?.data?.body?.holderType ? context.data.body.holderType : 'CORPORATE'} control={control} onChange={handleChange} id="create-international-external-account-form-card-account-purpose" name="holderType" data={accountPurposeData} label="Holder Type*" readOnly={isEdit ? true : false} setValue={setValue} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("holderName") }} className={errors.holderName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-international-external-account-form-card-holdername-textbox" label="Holder Name*" name="holderName" variant="outlined" defaultValue={context?.data?.body?.holderName ? context.data.body.holderName : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.holderName && renderNameError(errors.holderName)}
                                                        </div>
                                                    </Grid>

                                                    </>}

                                                <Grid item container xs={12} sm={12}>
                                                    <fieldset className="fx-add-user-address-container">
                                                        <legend>Address Details</legend>
                                                        <Grid item container xs={12} sm={12} spacing={2}>
                                                            <Grid item xs={12} sm={12}>
                                                                <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'payee-address-add-addressline1-textbox'} label="Address Line 1" name={'addressLine1'} inputProps={{ maxLength: 30 }} defaultValue={holderAddress?.addressLine1 ? holderAddress.addressLine1 : ''} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'payee-address-add-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} inputProps={{ maxLength: 30 }} defaultValue={holderAddress?.addressLine2 ? holderAddress.addressLine2 : ''} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(true)} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-add-city-textbox" name="city" label="City*" defaultValue={holderAddress?.city} isEditable={true} />
                                                                <div className={'error-message'}>
                                                                    {errors.city && renderCityNameError(errors.city)}
                                                                </div>
                                                            </Grid>
                                                            {watch('country') === "US" ? <Grid item xs={12} sm={4}>
                                                                    <FxMaterialSelect register={{ ...register("unitedState") }} rules={{ required: false }} className={errors.unitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-iea-add-state-textbox" name="unitedState" data={usa_state} label="State" value={holderAddress?.state || ""}
                                                                        setValue={setValue} readOnly={props.isEdit ? true : false} />
                                                                </Grid> : <Grid item xs={12} sm={4}>
                                                                    <FxTextEdit register={{ ...register("nonUnitedState") }} control={control} rules={{ required: false , maxLength: 3}} className={errors.nonUnitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-iea-add-city-textbox" name="nonUnitedState" label="State" defaultValue={holderAddress?.state || ""} isEditable={props.isEdit ? false : true} />
                                                                    <div className={'error-message'}>{errors.nonUnitedState && renderStateError(errors.nonUnitedState)}</div>
                                                                </Grid>}
                                                            <Grid item xs={12} sm={4}>
                                                                <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(false,/^[a-zA-Z0-9]{1,9}$/)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-add-zip-textbox" name="zip" label="Zip" defaultValue={holderAddress?.zip} isEditable={true} />
                                                                <div className={'error-message'}>
                                                                    {errors.zip  && renderZipError(errors.zip)}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </fieldset>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FxLabelView id="international-external-account-account-header" className="" >BANK ACCOUNT INFORMATION</FxLabelView>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("accountType") }} className={errors.accountType ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: (typeSelected === 'BANK_ACCOUNT' || !isActive) ? true : false }} control={control} onChange={handleChange} id="create-international-external-account-form-card-account-type" name="accountType" data={accountTypeData} value={context?.data?.body?.type ? context.data.body.type : 'CHECKING'} label="Account Type*" readOnly={isEdit ? true : false} setValue={setValue} />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                <FxSwiftCode register={{ ...register("swiftCode") }} setValue={setValue} className={errors.swiftCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-onetime-internationalwire-add-swift-number-textbox" label="Swift Code*" name="swiftCode" variant="outlined"
                                                    defaultValue={context?.data?.body?.swiftCode || ""}
                                                    isEditable={props.isEdit ? false : true} onSwiftCodeChange={(data: any) => {
                                                        setRoutingCodeDetails(data);
                                                        props.isEdit && setValue('swiftCode', context?.data?.body?.swiftCode)
                                                    }} />
                                                    </Grid>
                                                    {routingCodeDetails?.internationalCodeCharacterLength !== 0 && routingCodeDetails?.internationalCodeLabel && <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("internationalRoutingCode") }} className={errors.internationalRoutingCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control}
                                                            rules={{ required: false }} id="iea-contact-add-routingcode-number-textbox"
                                                            label={routingCodeDetails?.internationalCodeLabel || "International Routing Code"}
                                                            name="internationalRoutingCode" variant="outlined" isEditable={props.isEdit ? false : true}
                                                            defaultValue={context?.data?.body?.internationalRoutingCode || ''}
                                                        />
                                                    </Grid>}
                                                    <Grid item xs={12} sm={6}>
                                                        <FxExternalAccountNumberEdit register={{ ...register("accountNumber") }} className={errors.accountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} error={errors?.accountNumber} control={control} rules={setAccountNumberValidation} id="create-international-external-account-form-card-account-number" label="International Bank Account Number *" name="internationalAccountNumber" variant="outlined" defaultValue={context?.data?.body?.accountNumberLast4 ? '*****' + context.data.body.accountNumberLast4 : ''} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                                        <div className={'error-message'}>
                                                            {errors.accountNumber && renderAccountNumberError(errors.accountNumber)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                            <FxTextEdit register={{ ...register("internationalPurpose") }} className={errors.internationalPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-international-external-account-form-card-account-purpose" label="Purpose" name="internationalPurpose" variant="outlined" isEditable={props.isEdit ? false : true}
                                                            defaultValue={context?.data?.body?.purpose ? context.data.body.purpose : ''}/>
                                                        </Grid>
                                                </>}
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </FxCardBody>
                                    <FxCardFooter id="create-international-external-account-form-card-footer" className="fx-footer">
                                        <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                            <FxButton variant="contained"
                                                className="fx-button fx-button-cancel"
                                                id="ea-modal"
                                                onClick={handleClose}>
                                                Cancel
                                                </FxButton>
                                            <span className="fx-padding-right-16" />
                                            <FxButton
                                                disableRipple={false}
                                                className="fx-button fx-button-theme"
                                                id={"create-international-external-account-form-card-" + submitButton + "-button"}
                                                type="submit"
                                            >
                                                {isloading ? (
                                                    <CircularProgress
                                                        size={20}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                ) : (
                                                        submitButton
                                                    )}
                                            </FxButton>
                                        </Grid>
                                    </FxCardFooter>
                                </FxCard>
                            </form>}
                            </div>
                    </Grid>
                </Grid>
               
            </Grid>
        )
    });