/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  Grid,
  IconButton,
  Modal,
} from '@mui/material';

import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';


interface GenericModalProps {
  open: boolean;
  onClose: (event?:any, item?:any) => void;
  onOpen?: () => void;
  id:string;
  className?: string;
  title?: string;
  hideClose?: boolean;
  disableEscapeKeyDown?: boolean;
  componentProps?: Record<string, any>;
  component: FC<any>,
}

export type FxModalGenericProps<Metadata = any> = {
  onClose: (event?:any, item?:any) => void;
  metadata?: Metadata;
}
const FxModalGeneric: React.FC<GenericModalProps> = ({
  open,
  onClose,
  onOpen,
  id,
  className,
  componentProps,
  title,
  component,
  hideClose,
  disableEscapeKeyDown
}: GenericModalProps) => {


  return (
    <Modal
      id={id}
      open={open}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={onClose}
      disableEnforceFocus={true}
      disableAutoFocus={false}
      className={clsx('fx-modal', className)}
    >
      <div className='modal-content'>
        <div className='modal-body'>
          <Grid className='fx-padding-32'>
           <Grid container alignItems={'center'} justifyContent={title ? 'space-between' : 'flex-end'}>
             {title && <Grid>
               <Typography
                 variant="h4"
                 className="fx-modal-generic-title"
               >
                 {title}
               </Typography>
             </Grid>}
             {!hideClose && <Grid className="fx-modal-close-icon">
               <IconButton
                 id='modal-close-button'
                 size='large'
                 onClick={onClose}
               >
                 <CloseIcon />
               </IconButton>
             </Grid>}
           </Grid>
            <Grid className='fx-margin-top-10'>
              {React.createElement(component, {id:`${id}#comp` ,onClose,metadata:componentProps})}
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

export default FxModalGeneric;
