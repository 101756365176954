/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { ReactComponent as DownArrow } from "../../../../assets/svg/down-black-triangle-arrow.svg";
import { ReactComponent as UpArrow } from "../../../../assets/svg/up-black-triangle-arrow.svg";
import { useFieldArray } from "react-hook-form";
import { FxLink } from "../../../Action/FxLink";
import FxRadioEdit from "../../../Input/FxRadio/FxRadioEdit";
import { FxPartnerAssociationSectionCard } from "./FxPartnerAssociationSectionCard";
import usa_state from '../../../Utils/usa_states.json';

/**
 * Component: Funding Rules Create Screen >> Association Section Wrapper component
 * Usage: To render the Association section of the create screen
 */
export const FxPartnerAssociationSection: React.FC<any> = React.memo((props) => {
  const [partnerExpandedAssociation, setPartnerExpandedAssociation] = React.useState(
    props?.data?.length > 0 ? true : false
  );
  const [accordingIcon, setAccordingIcon] = React.useState(<DownArrow />);
  const [allCustomers, setAllCustomers] = React.useState("toAllCustomers");

  const customerConditions = [
    { label: "Apply to all Customers", value: "toAllCustomers" },
    { label: "Custom", value: "PartnerCustomAssociation", disabled:props?.ownership === 'INHERITED'}
  ];

  /**
   * create a Form Field Array
   */
  const { fields, append, remove, update } = useFieldArray({
    control: props.control,
    name: "funding-rule-partner-association",
    shouldUnregister: true,
  });

  /**
   * Method to add Field
   */
  function handlePartnerAppend() {
    append({});
  }

  //By default association card should open
  useEffect(()=>{
    handlePartnerAssociationSectionOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  //On load while clone card should open by defualt if no data
  useEffect(() => {
    if (props?.isEdit && props?.data?.length === 0) {
      handlePartnerAssociationSectionOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Method handles the opening of the association Section Accordian
   */
  const handlePartnerAssociationSectionOpen = () => {
    if (partnerExpandedAssociation === true && fields.length >= 1) {
      setPartnerExpandedAssociation(false);
      setAccordingIcon(<DownArrow />);
    } else {
      if (fields.length === 0) {
        handlePartnerAppend();
      }
      setPartnerExpandedAssociation(true);
      setAccordingIcon(<UpArrow />);
    }
  };

  /**
   * Method to handle the accordian close event when 1 item is left
   * @param index
   * @param length
   */
  const handlePartnerAccordianClose = (index: any, length: any) => {
    remove(index);
    setPartnerExpandedAssociation(false);
    setAccordingIcon(<DownArrow />);
  };

  /**
   * Method to handle deletion of the association accordian rows
   * @param index
   * @param length
   */
  const removingPartnerAssociation = (index: any, length: any) => {
    remove(index);
  };

    /**
   * useEffect to load the api data into the useFieldArray Object
   */
    useEffect(() => {
      if (props?.data?.length > 0) {
        setAllCustomers('PartnerCustomAssociation');
        fields.length = props?.data?.length;
  
        for (let i = 0; i < props?.data?.length; i++) {
          update(i, props?.data[i]);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data]);

  
  /**
   * On change either the custom options will be shown or hidden and delete newly added custom options
   * @param ev selected option
   */
  const handleAssociationChange = (ev: any) => {
    if (ev?.target?.value === 'toAllCustomers') {
      setAllCustomers('toAllCustomers');
    } else {
      setPartnerExpandedAssociation(true);
      if(props?.isEdit){
        remove();
        removeNewAssociation();
      } else {
        remove();
      }
      !props?.data?.length && handlePartnerAppend();
      setAllCustomers('PartnerCustomAssociation')
    }
  }

  /**
   * Method to remove new added associations
   */
  const removeNewAssociation = () => {
    for (let i = 0; i < props?.data?.length; i++) {
      update(i, props?.data[i]);
    }
  }

  return (
    <Grid container paddingTop={"2rem"}>
      <Grid item xs={12} sm={12}>
        <fieldset className="fx-container-create-schdule-summery fx-splitfunding">
          <Grid container xs={12}>
            <Accordion className="fx-criteria" expanded={partnerExpandedAssociation}>
              <AccordionSummary>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    justifyContent={"flex-start"}
                    className="fx-container-funding-rules-criteria-summery"
                  >
                    <Typography className="fx-summary-title" variant="h3">
                      {fields.length > 0 && partnerExpandedAssociation === false && allCustomers !=='toAllCustomers'
                        ? `Association (${fields.length})`
                        : `Association`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"flex-end"}
                    textAlign={"right"}
                  >
                    <FxLink onClick={handlePartnerAssociationSectionOpen}>
                      <Typography
                        className="fx-add-button-theme-text"
                        variant="h3"
                      >
                        {fields.length === 0 && partnerExpandedAssociation === false
                          ? "+ Add"
                          : accordingIcon}
                      </Typography>
                    </FxLink>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12}>
                  <FxRadioEdit register={{ ...props?.register("applicableCustomers") }} control={props?.control} className={'radio-edit'} data={customerConditions} id="rule-merchant-partner-form-card-radio" name="applicableCustomers"
                    defaultValue={allCustomers} setValue={props?.setValue} onChange={handleAssociationChange} />
                </Grid>
                {allCustomers === 'PartnerCustomAssociation' && props?.ownership !== 'INHERITED' && <Grid
                  item
                  xs={12}
                  sm={12}
                  width={"100%"}
                  marginTop={'2rem'}
                  className={
                    "fx-approval-policy-condition fx-create-approval-policy"
                  }
                  id={"add-partner-association-funding-rule"}
                >
                  <fieldset className="fx-container-create-schdule-summery fx-condition-summary funding-rule-criteria-section fx-splitfunding-condition">
                    {fields.map((field: any, index: any) => {
                      return (
                        <FxPartnerAssociationSectionCard
                          id={`funding-rule-partner-association-row-` + index}
                          control={props.control}
                          index={index}
                          value={field}
                          data={props?.data}
                          setValue={props.setValue}
                          register={props.register}
                          errors={props.errors}
                          handlePartnerAppend={handlePartnerAppend}
                          handlePartnerRemove={() => {
                            fields.length === 1
                              ? handlePartnerAccordianClose(index, fields.length)
                              : removingPartnerAssociation(index, fields.length);
                          }}
                          isAddButtonDisabled={
                            fields.length !== index + 1 || fields.length === 2
                          }
                          fieldLength={fields.length}
                          getValues={props.getValues}
                          update={update}
                          watch={props.watch}
                          usastate={[...[{value:'Select All', label: 'Select All'}],...usa_state]}
                        />
                      );
                    })}
                  </fieldset>
                </Grid>}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </fieldset>
      </Grid>
    </Grid>
  );
});
