/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import { Logger } from "../../../../../libs/utils/logger";
import FxCard from "../../../../Container/FxCard";
import FxCardBody from "../../../../Container/FxCardBody";
import FxCardFooter from "../../../../Container/FxCardFooter";
import FxCardHeader from '../../../../Container/FxCardHeader';
import { FxConsentText } from '../../../../Data/FxConsentText';
import { maskAccountNumber } from "../../../../Utils/CommonBaseClass";
import { getKey } from "../../../../../libs/utils/storageManager";
import UIFeatureImpl from "../../../../../libs/services/uiFeatures";

Logger.debug("QuickTransferContainer.tsx", "QuickTransferContainer initializing");
/**
 * This component used to render the Quick Transfer Container
 */
interface QuickTransferContainerProps {
  id: string;
  consentText?: string;
  buttons: ReactNode;
  headerText?: string;
  showWithdrawalNote: boolean;
  destinationAccountData: any;
}
export const QuickTransferContainer: React.FC<QuickTransferContainerProps> = React.memo((props) => {

  const { id, children, consentText, buttons, headerText, showWithdrawalNote, destinationAccountData } = props;
  const uiFeat = UIFeatureImpl.getInstance();

  return (
    <Grid id={`${id}-quick-transfer-container-main-grid`} className={'fx-quick-transfer-card'} item xs={12}>
      <FxCard
        id={`${id}-quick-transfer-card`}
        className="fx-theme-passport"
      >
        <FxCardHeader>
          <Grid container item xs={12} className='fx-quick-transfer-header'>
            <Typography variant="h2" className='fx-quick-transfer-header-text'>
              {headerText || 'Quick Transfer'}
            </Typography>
          </Grid>
        </FxCardHeader>
        <Grid item xs={12} className={'fx-card-divider'}/>
        <FxCardBody
          id={`${id}-quick-transfer-card-body`}
          className="fx-info-card"
        >
          <Grid spacing={2} container>
            <Grid item container xs={12}>
              {children}
            </Grid>
            {!!consentText && <Grid item container xs={12}>
              <FxConsentText>
                {consentText}
              </FxConsentText>
            </Grid>}
            {getKey('cashBuilderPlusInvestmentMode') === 'THIRD_PARTY' && showWithdrawalNote && <Grid item container xs={12}>
              <FxConsentText>
              NOTE: Expect a days delay before money reflects in your Passport {destinationAccountData?.nickName ? destinationAccountData?.nickName : destinationAccountData?.title ? destinationAccountData?.title : uiFeat.getBusinessCustomerFeature().available ? getKey('CustomerName') : getKey('CustomerIndividualFullName')} account ({maskAccountNumber(destinationAccountData?.accountNumber, false)})
              </FxConsentText>
            </Grid>}
          </Grid>
        </FxCardBody>
        <FxCardFooter id={`${id}-quick-transfer-card-footer`}>
          <Grid container xs={12} justifyContent="flex-end">
            {buttons}
          </Grid>
        </FxCardFooter>
      </FxCard>
    </Grid>
  );
});
