/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid, Typography, IconButton, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as SelectIcon } from '../../../assets/svg/unselect-icon.svg';
import { FxDateEdit } from '../../Input';
import { clean } from '../../Utils/CommonBaseClass';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { getKey } from '../../../libs/utils/storageManager';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxCardNumberEdit } from '../../Input/FxCardNumber/FxCardNumberEdit';
const httpClient = HttpClient.getClient();

Logger.debug("ActivateDebitCard.tsx", "Activate Debit Card initializing")

/**
 * This component handles the Activate the Debit Card
 */
export const ActivateDebitCard: React.FC<any> = React.memo((props) => {
        const { register , handleSubmit, control, setValue,formState: { errors }, setError, clearErrors } = useForm();

        /**
         * Async function handles the form submission
         */
        async function onSubmit(data: any) {
            let status: any;

            const selectedMonth = (new Date(data.expiryDate).getMonth()+1);
            const formattedMonth = selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth;
            clearErrors("cardNumber");
            if(data['cardNumber']){
              const cardObj:any = JSON.parse(data['cardNumber'])
              if(!cardObj.success){
                  setError("cardNumber", {
                      type: "manual",
                      message: cardObj.message,
                  });
                  return;
              }
              data['cardNumber'] = cardObj?.value;
              data['brand'] = cardObj?.type;
          }
            const request = {
                "expiryMonth": (formattedMonth).toString(),
                "expiryYear": (new Date(data.expiryDate).getFullYear()).toString(),
                "cardNumberLast4": data.cardNumber.slice(-4),
                "cvv" : data.cvv
            };
            
            status = await activateDebitCard(clean(request));
            status = processAPIResponse(status)
            
            if(status.status){
              props.setDescription('Your Request to activate card is placed successfully.');
              props.setSuccess(true);
              props.close();
            } else {
              //api  failed
              FxSnackBar.show({
                text: status.message,
              });
          }
        }

        /**
         * Method to call api for Activating Debit Card
         * @param payloadData : request payload
         */
        async function activateDebitCard(payloadData: any) {
            try {
                const url =  '/account/id/' + getKey('selectedAccount')?.id + '/debitCard/id/' + props.debitCardId + '/activate';
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData)
                return data;
            } catch (err) {
                Logger.error("ActivateDebitCard.tsx", "error", err);
                return err;
            }
        }

        const setCardNumberValidation = {
          required: true
      }

        /**
         * Validations for Expiry Date field
         */
        const ExpiryDateValidation = {
            required: true,
            minDate: (new Date())
        };

        /**
         * Validations for CVV field
         */
        const cvvValidations = {
          required: true,
        }

        return (
            <>
            <div className="modal-content">
            <div className="modal-body">
              {
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FxCard>
                    <FxCardHeader
                      id="activate-debit-card-header"
                      className="fx-card-header"
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs>
                          <Typography id="title-activate-debit-card-modal"></Typography>
                        </Grid>
                        <Grid className="fx-modal-close-icon">
                          <IconButton
                            onClick={props.close}
                            id="activate-debit-card-modal-close-icon"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </FxCardHeader>
                    <FxCardBody
                      id="activate-debit-card-modal-body"
                      className="fx-info-card"
                    >
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        className="fx-modal-form flex column"
                        paddingLeft={"1rem"}
                      >
                        <Grid item container justifyContent="center" xs={12}>
                          <IconButton
                            id="activate-debit-card-select-icon"
                            className="fx-modal-delete-icon"
                            size="large"
                            disabled={true}
                          >
                            <SelectIcon />
                          </IconButton>
                        </Grid>
                        <Grid
                          container
                          justifyContent="center"
                          item
                          xs={12}
                          className="fx-delete-title"
                        >
                          <Typography id="debit-card-actions-popup-modal-title-text" variant="h3">
                            Activate Card
                          </Typography>
                        </Grid>
                        <Grid container xs={12} sm={12}>
                          <FxCardNumberEdit register={{ ...register("cardNumber") }}
                            className={errors.cardNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                            control={control} setValue={setValue} setError={setError}
                            value={''}
                            clearErrors={clearErrors}
                            rules={setCardNumberValidation} id="activate-debit-card-card-number-textbox"
                            label="Card Number*" name="cardNumber" variant="outlined" maxLength={19} isEditable={true} />
                        </Grid>
                        <Grid container xs={12} sm={6}>
                          <FxTextEdit
                            register={{ ...register("cvv") }}
                            passwordIcon={true}
                            className={errors.cvv ? "border-error-input fx-input-edit" : "fx-input-edit"}
                            control={control}
                            rules={cvvValidations}
                            inputProps={{ maxLength: 3 }}
                            id="cvv"
                            label="CVV*"
                            name="cvv"
                            variant="outlined"
                            onWheel={(e:any)=>e.target.blur()}
                            valuePattern={/^[^0-9]+$/}
                          />
                        </Grid>
                        <Grid container xs={12} sm={6}>
                          <FxDateEdit
                            register={{ ...register("expiryDate") }}
                            className={"fx-input-edit"}
                            control={control}
                            rules={ExpiryDateValidation}
                            id="activate-card-expiry-textbox"
                            label="Expiry Date*"
                            name="expiryDate"
                            type="number"
                            variant="outlined"
                            defaultValue={""}
                            setValue={setValue}
                            month={true}
                            disablePast={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </FxCardBody>
                    <FxCardFooter
                      id="activate-debit-card-modal-Footer"
                      className="fx-footer"
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        className="fx-modal-footer"
                      >
                        <Button
                          variant="contained"
                          className="fx-button fx-button-cancel"
                          id="cancel-button"
                          onClick={props.close}
                        >
                          Cancel
                        </Button>
                        <span className="fx-padding-right-16" />
                        <FxButton
                          disableRipple={false}
                          className="fx-button fx-button-theme"
                          id={"activate-debit-card-button"}
                          type="submit"
                        >
                            SUBMIT
                        </FxButton>
                      </Grid>
                    </FxCardFooter>
                  </FxCard>
                </form>
              }
            </div>
          </div>
            </>
          
        );
    });
