/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from 'react';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ReactComponent as OneTimeIcon } from '../../../assets/svg/one_time.svg';
import { ReactComponent as ManageExternalAccountIcon } from '../../../assets/svg/manage-ea.svg';
import { ReactComponent as ManageContactIcon } from '../../../assets/svg/contact-icon.svg';
import { IScheduleMenuItem } from '../../../types/common.interfaces';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { ScheduleTabView } from './ScheduleTabView';

Logger.debug("ScheduleMenu.tsx", "Schedule Menu Card initializing");

const allLinks = {
    contact: [
        '/transaction/send/contact/add/ach',
        '/transaction/send/edit/contact/ach',
        '/transaction/send/contact/add/wire',
        '/transaction/send/edit/contact/wire',
        '/transaction/send/contact/add/check',
        '/transaction/send/edit/contact/check',
        '/transaction/send/contact/add/book',
        '/transaction/send/edit/contact/book',
        '/transaction/send/contact/add/virtualcard',
        '/transaction/send/edit/contact/virtualcard',
        '/recurring/contact/edit',
        '/transaction/send/contact/add/internationalWire',
        '/transaction/send/edit/contact/internationalWire',
    ],
    one_time: [
        '/transaction/send/onetime/add/ach',
        '/transaction/send/edit/onetime/ach',
        '/transaction/send/onetime/add/wire',
        '/transaction/send/edit/onetime/wire',
        '/transaction/send/onetime/add/check',
        '/transaction/send/edit/onetime/check',
        '/transaction/send/onetime/add/book',
        '/transaction/send/onetime/add/card',
        '/transaction/send/edit/onetime/book',
        '/transaction/send/onetime/add/virtualcard',
        '/transaction/send/edit/onetime/virtualcard',
        '/transaction/send/onetime/add/internationalWire',
        '/transaction/send/edit/onetime/internationalWire',
    ],
    my_account: [
        '/transaction/send/add/ach',
        '/transaction/send/edit/account/ach',
        '/transaction/send/add/wire',
        '/transaction/send/edit/account/wire',
        '/transaction/send/add/check',
        '/transaction/send/edit/account/check',
        '/transaction/send/transfer',
        '/transaction/send/edit/account/book',
        '/transaction/send/add/internationalWire',
        '/transaction/send/edit/account/internationalWire',
        '/recurring/account/edit',
    ],
}

const permissionData = {
    send_ach: {
        entity: "Accounts",
        name: "Ach Send",
        operation: "CREATE"
    },
    send_internationalWire: {
        entity: "Accounts",
        name: "International Wire Send",
        operation: "CREATE"
    },
    send_wire: {
        entity: "Accounts",
        name: "Wire Send",
        operation: "CREATE"
    },
    send_check: {
        entity: "Accounts",
        name: "Check Send",
        operation: "CREATE"
    },
    send_book: {
        entity: "Accounts",
        name: "Book Send",
        operation: "CREATE"
    },
    send_card: {
        entity: "Accounts",
        name: "Card Send",
        operation: "CREATE"
    },
    send_virtual_card: {
        entity: "Accounts",
        name: "Virtual Card Send",
        operation: "CREATE"
    },
    send_ach_edit: {
        entity: "Accounts",
        name: "Ach Send",
        operation: "EDIT"
    },
    send_internationalWire_edit: {
        entity: "Accounts",
        name: "International Wire Send",
        operation: "EDIT",
    },
    send_wire_edit: {
        entity: "Accounts",
        name: "Wire Send",
        operation: "EDIT"
    },
    send_check_edit: {
        entity: "Accounts",
        name: "Check Send",
        operation: "EDIT"
    },
    send_book_edit: {
        entity: "Accounts",
        name: "Book Send",
        operation: "EDIT"
    },
    recurring_ach: {
        entity: "Recurring Transaction",
        name: "Ach Send",
        operation: "CREATE"
    },
    recurring_internationalWire: {
        entity: "Recurring Transaction",
        name: "International Wire Send",
        operation: "CREATE"
    },
    recurring_internationalWire_edit: {
        entity: "Recurring Transaction",
        name: "International Wire Send",
        operation: "EDIT",
    },
    recurring_wire: {
        entity: "Recurring Transaction",
        name: "Wire Send",
        operation: "CREATE"
    },
    recurring_check: {
        entity: "Recurring Transaction",
        name: "Check Send",
        operation: "CREATE"
    },
    recurring_book: {
        entity: "Recurring Transaction",
        name: "Book Send",
        operation: "CREATE"
    },
    recurring_virtual_card: {
        entity: "Recurring Transaction",
        name: "Virtual Card Send",
        operation: "CREATE"
    },
    recurring_ach_edit: {
        entity: "Recurring Transaction",
        name: "Ach Send",
        operation: "EDIT"
    },
    recurring_wire_edit: {
        entity: "Recurring Transaction",
        name: "Wire Send",
        operation: "EDIT"
    },
    recurring_check_edit: {
        entity: "Recurring Transaction",
        name: "Check Send",
        operation: "EDIT"
    },
    recurring_book_edit: {
        entity: "Recurring Transaction",
        name: "Book Send",
        operation: "EDIT"
    },
    contact_permission: {
        entity: "Other Entities",
        name: "Contact",
        operation: "VIEW"
    }
}

export const ScheduleMenuCard: React.FC<any> = React.memo(
    (props) => {
        const location = useLocation();
        ({ props } = RegisterComponent(props));

        const scheduleMenuItems: IScheduleMenuItem[] = [];
        let locationPath = location.pathname;
        if(locationPath.includes('edit/')){
            const locationPathArr = locationPath.split('/');
            locationPathArr.pop();
            locationPath = locationPathArr.join('/');
        }

        if ((checkPermission(permissionData.contact_permission)) && ((!props?.edit && (checkPermission(permissionData.send_ach) || checkPermission(permissionData.send_wire) || checkPermission(permissionData.send_check) || checkPermission(permissionData.send_book) || checkPermission(permissionData.send_virtual_card) || checkPermission(permissionData.send_internationalWire) || checkPermission(permissionData.recurring_ach) || checkPermission(permissionData.recurring_wire) || checkPermission(permissionData.recurring_check) || checkPermission(permissionData.recurring_book) || checkPermission(permissionData.recurring_virtual_card) || checkPermission(permissionData.recurring_internationalWire) )) || (props?.edit && (checkPermission(permissionData.send_ach_edit) || checkPermission(permissionData.send_wire_edit) || checkPermission(permissionData.send_check_edit) || checkPermission(permissionData.send_book_edit)  || checkPermission(permissionData.send_internationalWire_edit)|| checkPermission(permissionData.recurring_ach_edit) || checkPermission(permissionData.recurring_wire_edit) || checkPermission(permissionData.recurring_check_edit) || checkPermission(permissionData.recurring_book_edit) || checkPermission(permissionData.recurring_internationalWire_edit))))) {
            scheduleMenuItems.push({
                title: "Contact",
                icon: <ManageContactIcon />,
                link: (checkPermission(permissionData.send_ach) || checkPermission(permissionData.recurring_ach)) ? "/transaction/send/contact/add/ach" : (checkPermission(permissionData.send_wire) || checkPermission(permissionData.recurring_wire)) ? "/transaction/send/contact/add/wire" : (checkPermission(permissionData.send_check) || checkPermission(permissionData.recurring_check)) ? "/transaction/send/contact/add/check" : (checkPermission(permissionData.send_book) || checkPermission(permissionData.recurring_book)) ? "/transaction/send/contact/add/book" : (checkPermission(permissionData.send_virtual_card) || checkPermission(permissionData.recurring_virtual_card)) ? "/transaction/send/contact/add/virtualcard" : (checkPermission(permissionData.send_internationalWire) || checkPermission(permissionData.recurring_internationalWire)) ? "/transaction/send/contact/add/internationalWire" :'',
                name: "contact",
                narration:"Send funds to your linked contacts from your Passport Account.",
                selected: !!allLinks.contact.includes(locationPath),
            },)
        }
        if (((!props?.edit && (checkPermission(permissionData.send_ach)|| checkPermission(permissionData.send_wire) || checkPermission(permissionData.send_book) || checkPermission(permissionData.send_virtual_card) || checkPermission(permissionData.send_internationalWire) )) || (props?.edit && (checkPermission(permissionData.send_ach_edit) || checkPermission(permissionData.send_wire_edit) || checkPermission(permissionData.send_book_edit) || checkPermission(permissionData.send_internationalWire_edit) || checkPermission(permissionData.send_card))))) {
            scheduleMenuItems.push({
                title: "One Time",
                icon: <OneTimeIcon />,
                link: (checkPermission(permissionData.send_ach)) ? "/transaction/send/onetime/add/ach" : (checkPermission(permissionData.send_book)) ? "/transaction/send/onetime/add/book" : (checkPermission(permissionData.send_virtual_card)) ? "/transaction/send/onetime/add/virtualcard":(checkPermission(permissionData.send_internationalWire)) ? "/transaction/send/onetime/add/internationalWire": (checkPermission(permissionData.send_wire)) ? "/transaction/send/onetime/add/wire" :(checkPermission(permissionData.send_card)) ? "/transaction/send/onetime/add/card" :'',
                name: "one_time",
                narration:"Send funds to your payees without saving the account information.",
                selected: !!allLinks.one_time.includes(locationPath),
            })
        }
        if ((!props?.edit && (checkPermission(permissionData.send_ach) || checkPermission(permissionData.send_wire) || checkPermission(permissionData.send_check) || checkPermission(permissionData.send_book) || checkPermission(permissionData.send_internationalWire) || checkPermission(permissionData.recurring_ach) || checkPermission(permissionData.recurring_wire) || checkPermission(permissionData.recurring_check) || checkPermission(permissionData.recurring_book) || checkPermission(permissionData.recurring_internationalWire))) || (props?.edit && (checkPermission(permissionData.send_ach_edit) || checkPermission(permissionData.send_wire_edit) || checkPermission(permissionData.send_check_edit) || checkPermission(permissionData.send_book_edit) || checkPermission(permissionData.send_internationalWire_edit) || checkPermission(permissionData.recurring_ach_edit) || checkPermission(permissionData.recurring_wire_edit) || checkPermission(permissionData.recurring_check_edit) || checkPermission(permissionData.recurring_book_edit) || checkPermission(permissionData.recurring_internationalWire_edit)))) {
            scheduleMenuItems.push({
                title: "My Account",
                icon: <ManageExternalAccountIcon />,
                link: (checkPermission(permissionData.send_ach) || checkPermission(permissionData.recurring_ach)) ? "/transaction/send/add/ach" : (checkPermission(permissionData.send_wire) || checkPermission(permissionData.recurring_wire)) ? "/transaction/send/add/wire" : (checkPermission(permissionData.send_check) || checkPermission(permissionData.recurring_check)) ? "/transaction/send/add/check" : (checkPermission(permissionData.send_book) || checkPermission(permissionData.recurring_book)) ? '/transaction/send/transfer' : (checkPermission(permissionData.send_internationalWire) || checkPermission(permissionData.recurring_internationalWire)) ? '/transaction/send/add/internationalWire' : '',
                name: "my_account",
                narration:"Send funds to your own account from your passport account.",
                selected: !!allLinks.my_account.includes(locationPath),
            })
        }

        /**
         * to removve one time option for recurring
         */
        if(props?.id === 'edit-recurring-schedule-menu'){
            scheduleMenuItems.splice(1,1)
        }

        return (
            <ScheduleTabView
                isEdit={props?.edit}
                menuItems={scheduleMenuItems}
            />
        );
    })
