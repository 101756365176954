/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * This component handles the customer selection 
 */
import { Grid } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { FxPopOver } from "../../../Data/FxPopOver";
import { ReactComponent as TriangleDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../../../assets/svg/triangle-up-icon.svg';
import { setVerificationStatus } from '../../../Utils/CommonBaseClass';
import { getDefaultCustomer, toTitleCase, truncateChars, updateComponentData, updateComponentParams } from "../../../Utils/CommonBaseClass";
import { useIsMobileScreen } from "../../../../libs/utils/utils";
import { FxLink } from "../../../Action/FxLink";
import { useHistory } from "react-router";
import { ReactComponent as MenuDotIcon } from '../../../../assets/svg/menu-nine-dots.svg';
import { ReactComponent as CheckedCustomerIcon } from '../../../../assets/svg/checked-customer.svg';
import { useDispatch } from "react-redux";
import { getKey, setKey } from "../../../../libs/utils/storageManager";
import { setLocalStorage } from "../../../../libs/utils/cacheHandler";
import HttpClient from "../../../../libs/utils/httpClient";
import { Logger } from "../../../../libs/utils/logger";
import { checkIsPartner } from '../../../Utils/ThemeCheckerPartner';
import { applyCustomFeatures } from "../../../../libs/services/uiFeatures";

const httpClient = HttpClient.getClient();
export const SwitchCustomerMenu: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const { customerList, skipSkeleton, selectedCustomer, showSwitch, drawerExpand, welcomeExpanded, anchorEl, customerConfigurations } = props;
    const isSmallScreen = useIsMobileScreen();
    const history = useHistory();
    const dispatch = useDispatch()
    

    useEffect(() => {
        if(customerList?.length > 0){
            updateComponentParams(dispatch, props.id, { selectedCustomer: selectedCustomer? selectedCustomer: getDefaultCustomer(customerList, getKey('merchantMID')), showSwitch: true, welcomeExpanded: false, anchorEl: null })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[customerList])
    /**
     * handle click away from popover
     */
    const handleClose = (event: any, item: any) => {
        updateComponentParams(dispatch, props.id, { anchorEl: null });
    };
    const open = Boolean(anchorEl);

    /**
     * handle click on menu
     */
    const handleClick = (event: any) => {
        event.stopPropagation();
        const config: any = { showSwitch: !showSwitch, anchorEl: event.currentTarget };
        if(drawerExpand){
            config['welcomeExpanded'] = true;
        }
        updateComponentParams(dispatch, props.id, config)
    };
   
    /**
     * Usememo hook memoizes the options for customer list dropdown
     */
    const menuOptions = useMemo(() => {
        if(customerList?.length > 0){
            const baseOptions: any = [];
                customerList?.forEach((item: any) => {
                    const obj = { id: item?.id, label: item.type? `${toTitleCase(item.type)} (${item.id})`: '', subLabel: item.name? `${truncateChars(item.name, 20)}`: '', iconSufix: selectedCustomer?.id === item.id ? <CheckedCustomerIcon /> : <></>, name: item.name, avatarClass:'fx-switch-customer-menu-avatar',title: item.name }
                    baseOptions.push(obj);
            })
        return [...baseOptions];
    }
    },[customerList,selectedCustomer])
     /**
     * function to get customer data from API
     * @returns data
     */
     function getCustomerDetails(customerId: any) {
        return httpClient.get('customer/id/' + customerId);
    }
    const listRequest =
    {
        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        criteria: {
            filters: []
        }
    };
     /**
     * function to get account list from API
     * @returns data
     */
     function getUserAccount(customerId: any) {
        return httpClient.post('customer/id/' + customerId + '/account/list', listRequest);
    }
     /**
     * function to get customer configuration 
     * @returns data
     */
     function getCustomerConfiguration(customerId: any) {
        return httpClient.get('customer/id/' + customerId + '/configuration');
    }
     /**
     * Function to get customer configuraion value
     * @param data :merchant data
     * @param settingName :setting name
     * @returns
     */
     function getCustomerConfigurationValue(data:any, settingName: string) {
        const value = data?.resources?.find((x:any)=> x.name === settingName)
        return value?.value;
    }
     /**
     * Function to check account status
     * @param accountList :account info
     */
     function getKYCDetails(accountList: any) {
        if (accountList && accountList.totalCount === 0) {
            setKey("customerKYC", 'continue');
            return 'continue';
        }
        else if (accountList && accountList.totalCount === 1 && accountList?.resources[0]?.status === 'INACTIVE') {
            setKey("customerKYC", 'stepper');
            return 'stepper';
        }
        else {
            setKey("customerKYC", 'skip');
            return 'skip';
        }
    }
    /**
     * Useeffect hook redirects after configuring the parameters
     */
    useEffect(() => {
        applyCustomFeatures(customerConfigurations)
        if (getKey("permissionDenied") === 'True') {
            history.push("user/permission/denied");
        }
        else if (getKey("showTerms") === 'YES') {
            history.push("/auth/terms/" + getKey('id'));
        }
        else if(getKey("showTerms") === 'NO' && skipSkeleton) {
            history.push({
                pathname: '/switch-customer',
                state: { accountList: props.accountList }
              });
        }
        updateComponentParams(dispatch, props.id, { 'skipSkeleton': false })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, skipSkeleton, customerConfigurations])
    /**
     * Method fetches an object from array based on id
     * @param arr : data array
     * @param id : id of the object
     * @returns 
     */
    function getObjectById(arr: any, id: any) {
        return arr.find((item: any) => item.id === id);
    }
    const settingKeyForCustomer = (customerData: any, customerId: any) => {
        setKey("userType", String(customerData?.subType));
        setKey("customerId", customerId);
        setLocalStorage("permission", customerData?.permissions)
        setKey("programManagerId",customerData?.programManagerId)
    }
    const settingKeyForCustomerConfigurations = (customerConfigurations: any) => {
        const enableInvestmentAccount = getCustomerConfigurationValue(customerConfigurations, 'ENABLE_CASH_BUILDER_ACCOUNT');                    
        setKey('enableInvestmentAccount', enableInvestmentAccount === '1' || enableInvestmentAccount?.toLowerCase() === 'true' ? 'true':'false');
        const enableCashBuilderAccount = getCustomerConfigurationValue(customerConfigurations, 'ENABLE_CASH_BUILDER_PLUS_ACCOUNT');                    
        setKey('enableCashBuilderAccount', enableCashBuilderAccount === '1' || enableCashBuilderAccount?.toLowerCase() === 'true' ? 'true':'false');
        const collectAchControl = getCustomerConfigurationValue(customerConfigurations, 'ALLOW_ACH_COLLECT');   
        setKey('collectAchControl', collectAchControl);
        const enableFundingRule = getCustomerConfigurationValue(customerConfigurations, checkIsPartner()? "SHOW_FUNDING_RULE_PARTNER_PORTAL" :"SHOW_FUNDING_RULE_CUSTOMER_PORTAL");  
        setKey('enableFundingRule', enableFundingRule === '1' ? 'true' : 'false');

        if (customerConfigurations) {
            const transactionAprovalValue = getCustomerConfigurationValue(customerConfigurations, 'TRANSACTION_APPROVAL');
            setKey('isTransactionApprovalEnabled', transactionAprovalValue === '1' || transactionAprovalValue?.toLowerCase() === 'true' ? 'true':'false');
        }
    }
    const initializeComponentParameters = (customerList: any,customerId: any) => {
        dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'customer-basic-info' } });
        updateComponentParams(dispatch, props.id, { selectedCustomer: getObjectById(customerList,customerId) })
        updateComponentParams(dispatch, props.id, { 'skipSkeleton': false })
        updateComponentParams(dispatch, "customer-basic-info", { 'accountList': [], hasInvestmentAccount: false  })
        updateComponentParams(dispatch, 'customer-home-passport', { showOverlay: false, showSkeleton: true})
        updateComponentData(dispatch, 'switch-customer-data', { customerKYC: undefined, selectedAccount: undefined })
    }
    const settingKeyForBusinessCustomer = (userTypeData: any) => {
        setKey('mxmFastFunding',true);
        setKey('doingBusinessAs', userTypeData?.business?.doingBusinessAs)
        setKey("CustomerName", userTypeData?.business?.legalName);
        setKey("businessCategory", userTypeData?.business?.businessCategory);
        setVerificationStatus(userTypeData?.business?.verification);
    }
    /**
     * Method handles the customer changing functionality
     * @param value : customer details
     * @returns 
     */
    const handleSwitchCustomer = async (value: any) => {
        try{
            dispatch({ type: "DATA_COMPONENT_INIT", payload: { "id": 'account-list-sub-menu' } })
            const customerData = customerList?.find((item: any) => item.id === value?.id);
            setKey("authUserId", customerData?.authorizedUser?.id);
            const customerId = customerData?.id;
            // return if the selected customer is again selected
            if(selectedCustomer?.id === customerId){
                return;
            }
            
            // setting the keys based on customer selection
            settingKeyForCustomer(customerData,customerId)
            
            
            let userTypeData: any;
            let accountList: any;
            let customerConfigurations: any;
            // initializing the component parameters related to customer selection
            initializeComponentParameters(customerList,customerId)
            const customer = customerList?.find((item: any) => item.id === customerId);
            setKey('showBatch', customer?.merchant?.length > 0)
            // calling the customer related apis
            const result = await Promise.allSettled([getCustomerDetails(customerId), getUserAccount(customerId), getCustomerConfiguration(customerId)]).then(function (data: any) {
                 if (data?.[0]?.status === "fulfilled") {
                    userTypeData = data?.[0]?.value?.data
                }
                if (data?.[1]?.status === "fulfilled") {
                    accountList = data?.[1]?.value?.data
                }
                if (data?.[2]?.status === "fulfilled") {
                    customerConfigurations = data?.[2]?.value?.data
                }
                // setting the keys based on customer configuration values
                settingKeyForCustomerConfigurations(customerConfigurations)
                
                let accStatus: any;
                let hasInvestmentAccount: any;
                let hasCashBuilderAccount: any;
                if(accountList){
                    updateComponentParams(dispatch, props.id, { accountList: accountList})
                }
                // if customer have multiple accounts
                if (accountList && accountList.totalCount > 1) {
                    setKey("verificationStatus", 'true');
                    setKey("accountStatus", 'true');
                    accStatus = 'true';
                    if(accountList?.resources.find((x:any)=>x.type === 'CASH_BUILDER')){
                        hasInvestmentAccount = true;
                    }
                    if(accountList?.resources.find((x:any)=>x.type === 'CASH_BUILDER_PLUS')){
                        hasCashBuilderAccount = true;
                    }
                }
                // if customer have an account and the account is active
                else if (accountList && accountList.totalCount === 1 && accountList?.resources[0]?.status === 'ACTIVE') {
                    setKey("accountStatus", 'true');
                    accStatus = 'true';
                }
                else {
                    setKey("accountStatus", 'false');
                    setKey("selectedAccount",undefined)
                    accStatus = 'false';
                }

                if (userTypeData?.type === 'BUSINESS') {
                    setKey("CustomerName", userTypeData?.business?.legalName);
                    setKey("CustomerIndividualFullName", undefined);
                }
                else if (userTypeData?.type === 'INDIVIDUAL') {
                    setKey("CustomerIndividualFullName", userTypeData?.individual?.fullName);
                    setKey("CustomerName", undefined);
                }
                else if (userTypeData?.type === 'JOINT_TENANCY') {
                  const authUserId = getKey("authUserId");
                  const customer = userTypeData.owners?.find((item: any) => item.id === authUserId);
                  setKey("CustomerIndividualFullName", customer?.fullName);
                  setKey("CustomerName", undefined);
                }
                
                updateComponentParams(dispatch, "customer-basic-info", { 'accountList': accountList, hasInvestmentAccount: hasInvestmentAccount, hasCashBuilderAccount: hasCashBuilderAccount  })
                setKey("customerInitialAccount",accStatus === 'false'? undefined: accountList?.resources?.[0] );
                // setting keys based on customer details
                if (userTypeData?.type) {
                    setKey("userType", userTypeData?.type);
                }
                if (userTypeData?.type === 'BUSINESS') {
                    settingKeyForBusinessCustomer(userTypeData)
                }
                setKey("isUserDetailsAvailable", 'TRUE');
                const kycDetails = getKYCDetails(accountList);
                updateComponentData(dispatch, 'switch-customer-data', { accountStatus: accStatus, customerKYC: kycDetails })
                updateComponentParams(dispatch, props.id, { 'skipSkeleton': true, 'customerConfigurations': customerConfigurations })
            }).catch(error => {
                return { ...error };
            })
            return result
        }
        catch(e){
            Logger.error("SwitchCustomerMenu.tsx", "error", e);
        }
    }
    return (<>
        {customerList?.length > 1 && !drawerExpand && <>
            <Grid item xs={12} className='fx-sidenav-collapse-close-popover' onClick={handleClick}>
                <Grid item xs={12} container justifyContent='end'>
                    <FxLink id="menu-left-portal-switch"><MenuDotIcon style={{ width: '3rem', height: '3rem' }} id="avtar-collapse" className="fx-sidenav-collapse-close-popover-switch-customer fx-sidenav-collapse-close-popover-welcome-avatar" /></FxLink>
                </Grid>
            </Grid>
        </>}
        {customerList?.length > 1 && drawerExpand  && <Grid item className={`fx-sidenav-bottommenu-item`} onClick={handleClick}>
            <FxLink id="menu-left-portal-switch" className={`fx-sidenav-bottommenu-main `} >
                <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={!isSmallScreen ? 2 : 1} justifyContent='center' alignItems='center'><MenuDotIcon style={{ marginLeft: '1rem' }} id="avtar-collapse" className={`${welcomeExpanded ? 'fx-sidenav-expanded-avatar-theme' : 'fx-sidenav-expanded-avatar-not-theme'} fx-sidenav-bottommenu-left`} /></Grid>
                    <Grid className='fx-sidenav-bottommenu-middle' item xs={!isSmallScreen ? 7 : 11}><div className="fx-menu-passport-bottommenu-smalltext">Switch Customer</div><div>{selectedCustomer?.name? truncateChars(selectedCustomer.name, 20): ''}</div></Grid>
                    <Grid item justifyContent='center' alignItems='center' display='flex' xs={!isSmallScreen ? 2 : 1} className='fx-sidenav-bottommenu-main-right'>{!welcomeExpanded ? <TriangleUp /> : <TriangleDown />}</Grid>
                </Grid>
            </FxLink>
        </Grid>}
        <Grid item container xs={12} className="fx-switch-customer-menu">
            <FxPopOver
                id={'welcome-list-menu'}
                open={open}
                refElement={anchorEl}
                onClose={handleClose}
                className={`${!drawerExpand ? 'fx-sidenav-customer-switch-menu-collapse' : 'fx-sidenav-customer-switch-menu-expanded-popup'}`}
                anchorOrigin={{
                    vertical: drawerExpand ? 'top' : 'bottom',
                    horizontal: drawerExpand ? 'center' : 'right',
                }}
                transformOrigin={{
                    vertical: drawerExpand ? 'bottom' : 'bottom',
                    horizontal: drawerExpand ? 'center' : 'left',
                }}
                data={menuOptions}
                onClick={handleSwitchCustomer}
            >
            </FxPopOver>
        </Grid>
    </>)
})