/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useMemo } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { FxButtonGroup } from '../../Action/FxButtonGroup';
import alertPerferencesConfig from './alertPerferencesConfig.json';
import { AlertPreferencesList } from './AlertPreferencesList';
import HttpClient from '../../../libs/utils/httpClient';
import { getKey } from '../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { Logger } from '../../../libs/utils/logger';
import FxSnackBar from '../../Utils/fx-snack-bar';
import _ from "lodash";
import { removeUnderScoreConvertTitleCase, updateComponentParams } from '../../Utils/CommonBaseClass';
import FxNoGridDataImage from '../../Data/FxNoGridDataImage';


/**
 * Component: Manage Alert List
 * Usage: To display list of alert list
 
*/
export const ManageAlertList: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const dispatch = useDispatch();
  const httpClient = HttpClient.getClient()
  
  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => {
    updateComponentParams(dispatch, props?.id, {type:'security', isLoading:false, alertPerferences:[], alertPerferencesType:[] });
    return () => {
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props?.id } }); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  /**
    * get alert perferences from api resposne.
  */
  const perferencesList = useMemo(() => context?.data?.body?.alertPreference || [], [context?.data?.body]);

  /**
    * get alert perferences from api resposne.
  */
    const type = useMemo(() => props?.type, [props?.type])  

  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => {
   if(type && perferencesList.length > 0){
      extractAlertPerferences(type, perferencesList);    
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, perferencesList]);

  /**
   * To extract alert preferences type
   * @param type : Alert perferences route type (Category)
   * @param data : API response list
  */
  const extractAlertPerferences = (type: any, data:any) => {
    const perferencesList: any = [];

    const selectedCategory = alertPerferencesConfig.category.find(x=>x.name.toLowerCase() === type);
    if(selectedCategory){
      selectedCategory.subCategory.forEach(element => {
        const items = data.filter((x:any)=>x.category === element.key).map((y:any)=>{          
          const item = {
            ...y,
            key:y.name,
            name:removeUnderScoreConvertTitleCase(y.name)
          };

          return item;                   
        })

        const subCategory = {
          name: element.name,         
          items: items
        };
        perferencesList.push(subCategory);
       
      });  
    }  

    updateComponentParams(dispatch, props?.id, { alertPerferences:perferencesList});   
  };

  /**
   * To extract alert preferences list
   * @param type : Alert perferences route type (Category)
   * @param data : API response list
  */
  const extractAlertPerferencesType = (type: any, data:any) => {
    const typeOfData: any = []

    if(data?.length > 0){
      alertPerferencesConfig.category.forEach((item: any) => {
        // Extract all sub category keys
        const subCategoryKeys = item.subCategory.map((x:any) => x.key);
        if (data.some((x: any) => subCategoryKeys.includes(x.category))) {
          const obj = { 
            id: item.name, 
            value: item.name,
            label: item.name,
            selected: type === item.name.toLowerCase()
          }
  
          typeOfData.push(obj);
        }
      })
  
      const selectedType = typeOfData.find((x:any)=>x.value.toLowerCase() === type);
      if(!selectedType){
        updateComponentParams(dispatch, props?.id, { type:typeOfData[0]?.value?.toLowerCase()});
      }
    }    
    
    return typeOfData;
  }   

  /**
    * get alert perferences type (tabs options).
  */
  const alertPerferencesType = useMemo(() => {
    return extractAlertPerferencesType(type, perferencesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, perferencesList]);

  /**
  * get alert perferences list.
  */
  const alertPerferences = useMemo(() => {
    return props.alertPerferences ? _.cloneDeep(props.alertPerferences) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.alertPerferences]);

   /**
   * To convert string to boolen
   * @param value : The value.
  */
  const convertToBoolean =(value:string)=>{
    return value?.toLowerCase() === 'true' ? true:false;
  } 
  
  /**
   * Submit button handler
   * @param data : Form data
   * @param callback : Callback funtion.
  */
  const onSubmitAlertPerferences = async (data:any, callback:any)=>{    
    updateComponentParams(dispatch, props?.id, { isLoading:true})   

    const payload = extractSavePerferncesPayload(data);    
    const status = await saveAlertPerferences(payload); 
  
    updateComponentParams(dispatch, props?.id, { isLoading:false})   

    if (status.status) {
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props?.id } });   
      updateComponentParams(dispatch, props?.id, {type:type});
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: 'Alert Perferences Updated Successfully!',
      });

      if(callback){
        callback()
      }
    }
    else {
      //api  failed
      FxSnackBar.show({
          text: status.message,
      });
    }
  }

  /**
   * To extract change data from form data.
   * @param data : Form data
  */
  const extractSavePerferncesPayload=(data:any)=>{
    const _perferencesList:any = perferencesList;

    const payload:any = {
      alertPreference: []
    };    
    
    for (const element in data) {  

      const item = _perferencesList.find((x:any)=>x.name === element);
      if(item) {
        const {deliveryChannel, customTrigger} = data[element]; 
      
        const changedDeliveryChannel:any={
          email: convertToBoolean(deliveryChannel?.email[0]),
          sms: convertToBoolean(deliveryChannel?.sms[0]),
          inApp: convertToBoolean(deliveryChannel?.inApp[0]),
        }; 

        const _item = {
          category:item.category,
          name:item.name,
          deliveryChannel:changedDeliveryChannel,
          customTrigger:customTrigger
        }

        payload.alertPreference.push(_item);   
      }
      
    }
    
    return payload;
  }  

  /**
   * On handle change type.
   * @param item : Selected item
  */
  const handleTypeChange = (item: any) => {   
    updateComponentParams(dispatch, props?.id, { type:item.value.toLowerCase()});
  }

  /**
    * Method to call api to save alert perferences
  * */
  async function saveAlertPerferences(payload: any) {
      try {
          const data: any = await httpClient.post(getCustomerUrl(`authorizedUser/id/${getKey('authUserId')}/preference`,false), payload)
          return data;
      } catch (err) {
          Logger.error("ManageAlertList.tsx", "error", err);
          return err;
      }
  }

  /**
    * On cancel button click handler.
  * */
  const onCancelAlertPreferences = ()=> {  
    extractAlertPerferences(type.toLowerCase(), perferencesList) 
  }

   /**
    * get custom trigger value from cloned object.
    * * @param item : Selected item
  * */
   const getCustomTriggedValue = (item:any)=> {  
      const currentPreference = perferencesList.find((x:any)=>x.name === item.key);
      return {...currentPreference.customTrigger};
  }

  //check api call completed or not
  const contextState = context?.data_state;
  const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

  return (
    <Grid id="manager-alert-grid" className='fx-alert-perferences' container>
      {dataLoadStatus ?      
       <Grid container rowSpacing={2} >
        {alertPerferencesType?.length > 0 ? 
          <>
          <Grid item xs={12} className="fx-flex-grow fx-ea-type-button-mobile">
            <Grid className='fx-tab-border'>
              <FxButtonGroup id="security-card-button-group" options={alertPerferencesType} onClick={handleTypeChange} />
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <AlertPreferencesList
              id={`${props?.id}-alert-list`}
              type={type}
              data={alertPerferences}
              onSubmitAlertPerferences={onSubmitAlertPerferences}    
              onCancelAlertPreferences={onCancelAlertPreferences}      
              getCustomTriggedValue={getCustomTriggedValue}  
            />
          </Grid>
        </>:
         <Grid item xs={12} sm={12} textAlign={'center'}>
         <FxNoGridDataImage />
          <Typography className='fx-no-data'> No data to show </Typography>
        </Grid>
        }
        </Grid>
        : <FxSkeltonList height="25rem" />}
    </Grid>
  )
})


