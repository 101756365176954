/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect,useMemo }  from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Logger } from '../../../libs/utils/logger';
import { checkPermission, stateValueFormatter } from '../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { getKey, setKey } from '../../../libs/utils/storageManager';
import { FxInfoCard } from '../Cards/FxInfoCard';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxAccessDenied } from '../Index/Passport/FxAccessDenied';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * Component used to display company details
 */
Logger.debug("CompanyInfoCard.tsx", "Company Info initializing")

export const CompanyInfoCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const jointTenancyCustomer = uiFeat.getJointTenancyFeature().available

        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        let customerInfo: any = {
            individual: {}
        };
        /**
         * Get Company details from API
         */
        if (context && context.data && context.data.body) {
            customerInfo = { ...context.data.body };
            customerInfo.addressFull = {};
            const customerType = customerInfo.type.toLowerCase();
            setKey("customerType", customerType);
            customerInfo.userName = customerInfo?.portalAccess?.username;

            // To Set the customer details for join tenancy customer
            if (jointTenancyCustomer) {
                const authUserId = getKey("authUserId");
                const ownerDetails = customerInfo.owners.find((x:any)=>x.id === authUserId);
                customerInfo.userName = ownerDetails?.portalAccess?.username;

                if (ownerDetails?.mailingAddress && ownerDetails?.mailingAddress.length > 0) {
                    ownerDetails['mailingAddress'].forEach((item: any) => {
                        if (item.isPrimary === true) {
                            customerInfo.addressFull = item.addressLine1 + ', ' + (item.addressLine2 ? item.addressLine2 + ', ' : '') + item.city + ', ' + item.state + ', ' + item.zip;
                        }
                    })
                }
                customerInfo.email = ownerDetails['email'] ? ownerDetails['email'] : '';
                customerInfo.createdInfo = ownerDetails['createdBy']['username'] + '\nOn ' + moment(ownerDetails['createdOn']).format('MM/DD/YYYY');
                customerInfo.updatedInfo = ownerDetails['lastUpdatedBy']['username'] + '\nOn ' + moment(ownerDetails['lastUpdatedOn']).format('MM/DD/YYYY');

                customerInfo.fullName = ownerDetails.fullName ? ownerDetails.fullName : '';
                //customerInfo.signatoryName = customerInfo.individual.fullName ? customerInfo.individual.fullName : '';
                customerInfo.last4ssn = ownerDetails.last4ssn ? '****' + ownerDetails.last4ssn : '';
                customerInfo.dob = ownerDetails.dob ? moment(ownerDetails.dob).format('MM/DD/YYYY') : '';
                customerInfo.mobilePhone = ownerDetails.mobilePhone ? ownerDetails.mobilePhone : '';
                customerInfo.homePhone = ownerDetails.homePhone ? ownerDetails.homePhone : '';
                customerInfo.workPhone = ownerDetails.workPhone ? ownerDetails.workPhone : '';
                customerInfo.isPaperless = (customerInfo.isPaperless === true) ? 'Yes' : 'No';
            }
            else{// To Set the customer details for business and individual customer
                customerInfo.email = customerInfo[customerType]?.['email'] ? customerInfo[customerType]['email'] : '';
                customerInfo.createdInfo = customerInfo['createdBy']['username'] + '\nOn ' + moment(customerInfo['createdOn']).format('MM/DD/YYYY');
                customerInfo.updatedInfo = customerInfo['lastUpdatedBy']['username'] + '\nOn ' + moment(customerInfo['lastUpdatedOn']).format('MM/DD/YYYY');
                if (customerInfo.type === 'INDIVIDUAL') {
                    if (customerInfo?.individual?.mailingAddress && customerInfo?.individual?.mailingAddress.length > 0) {
                        customerInfo['individual']['mailingAddress'].forEach((item: any) => {
                            if (item.isPrimary === true) {
                                customerInfo.addressFull = item.addressLine1 + ', ' + (item.addressLine2 ? item.addressLine2 + ', ' : '') + item.city + ', ' + item.state + ', ' + item.zip;
                            }
                        })
                    }
                    customerInfo.fullName = customerInfo.individual.fullName ? customerInfo.individual.fullName : '';
                    customerInfo.signatoryName = customerInfo.individual.fullName ? customerInfo.individual.fullName : '';
                    customerInfo.last4ssn = customerInfo.individual.last4ssn ? '****' + customerInfo.individual.last4ssn : '';
                    customerInfo.dob = customerInfo.individual.dob ? moment(customerInfo.individual.dob).format('MM/DD/YYYY') : '';
                    customerInfo.mobilePhone = customerInfo.individual.mobilePhone ? customerInfo.individual.mobilePhone : '';
                    customerInfo.homePhone = customerInfo.individual.homePhone ? customerInfo.individual.homePhone : '';
                    customerInfo.workPhone = customerInfo.individual.workPhone ? customerInfo.individual.workPhone : '';
                    customerInfo.isPaperless = (customerInfo.isPaperless === true) ? 'Yes' : 'No';
                    customerInfo.website = customerInfo.individual.website ? customerInfo.individual.website : '';
                } else if (customerInfo.type === 'BUSINESS') {
                  customerInfo.authAddressFull = {};
                    customerInfo.fullName = customerInfo.business.legalName ? customerInfo.business.legalName : '';
                    customerInfo.email = customerInfo.business.email ? customerInfo.business.email : '';
                    customerInfo.doingBusinessAs = customerInfo.business.doingBusinessAs ? customerInfo.business.doingBusinessAs : '';
                    customerInfo.businessCategory = customerInfo.business.businessCategory ? customerInfo.business.businessCategory : '';
                    customerInfo.stateOfIncorporation = customerInfo.business.stateOfIncorporation ? stateValueFormatter(customerInfo.business.stateOfIncorporation) : '';
                    customerInfo.isPaperless = (customerInfo.isPaperless === true) ? 'Yes' : 'No';
                    customerInfo.dateOfIncorporation = customerInfo.business.dateOfIncorporation ? moment(customerInfo.business.dateOfIncorporation).format('MM/DD/YYYY') : '';
                    customerInfo.ein = customerInfo.business.ein ? customerInfo.business.ein : '';
                    customerInfo.last4ssn = customerInfo.business.last4ssn ? '****' + customerInfo.business.last4ssn : '';
                    customerInfo.phone = customerInfo.business.phone ? customerInfo.business.phone : '';
                    customerInfo.authorizedSignatory = {};
                    if (customerInfo?.business?.beneficialOwner) {
                        customerInfo['business']['beneficialOwner'].forEach((item: any) => {
                            if (item.actAsAuthorizedSignatory === true) {
                                item?.mailingAddress?.forEach((elem:any)=>{
                                    if(elem.isPrimary === true){
                                        customerInfo.authAddressFull = elem.addressLine1 + ', ' + (elem.addressLine2 ? elem.addressLine2 + ', ' : '') + elem.city + ', ' + elem.state + ', ' + elem.zip;
                                    }
                                })
                                customerInfo.signatoryName = item.fullName ? item.fullName : '';
                                customerInfo.dob = item.dob ? moment(item.dob).format('MM/DD/YYYY') : '';
                                customerInfo.authmobilePhone = item.mobilePhone ? item.mobilePhone : '';
                                customerInfo.secondaryIdentificationType = item.secondaryIdentification?.type ? item.secondaryIdentification.type : '';
                                customerInfo.countryOfIssuance = item.secondaryIdentification?.type === 'DRIVER_LICENSE'? item.secondaryIdentification?.stateOfIssuance : item.secondaryIdentification?.countryOfIssuance? item.secondaryIdentification.countryOfIssuance :' '
                                customerInfo.secondaryIdentificationId = item.secondaryIdentification?.id ? item.secondaryIdentification.id : '';
                                customerInfo.isUSCitizen = (item.isUSCitizen === true) ? 'Yes' : 'No';
                                customerInfo.authorizedSignatory.name = item.fullName ? item.fullName : '';
                                customerInfo.authorizedSignatory.last4ssn = item.last4ssn ? '****' + item.last4ssn : '';
                                customerInfo.authorizedSignatory.authAddressFull = customerInfo?.authAddressFull ? customerInfo.authAddressFull : '';
                                customerInfo.authorizedSignatory.mobilePhone = item.mobilePhone ? item.mobilePhone : '';
                                customerInfo.authhomePhone = item.homePhone ? item.homePhone : '';
                                customerInfo.authworkPhone = item.workPhone ? item.workPhone : '';
                                customerInfo.authorizedSignatoryEmail = item.email ? item.email : '';
                            }
                        })
                    }
                    customerInfo.website = customerInfo.business.website ? customerInfo.business.website : '';    
                }
            }           
        }
        const basic_info_left_section = [
            {
                label: 'Name',
                value: 'fullName'
            },
            {
                label: 'EIN',
                value: 'ein'
            },
            {
                label: 'Doing Business As',
                value: 'doingBusinessAs'
            },
            {
                label: 'Business Category',
                value: 'businessCategory'
            },
            {
                label: 'State of Incorporation',
                value: 'stateOfIncorporation'
            },
            {
                label: 'Website',
                value: 'website'
            }
        ]
        const basic_info_left_section_ind = [
            {
                label: 'Name',
                value: 'fullName'
            },
            {
                label: 'Username',
                value: 'userName'  
            },
            {
                label: 'Date of Birth',
                value: 'dob'
            },
            {
                label: 'Email',
                value: 'email'
            },
            {
                label: 'Is Paperless',
                value: 'isPaperless'
            },
            {
                label: 'Primary Address',
                value: 'addressFull'
            }
        ]
        const basic_info_right_section = [
            {
                label: 'SSN',
                value: 'last4ssn'
            },
            {
                label: 'Mobile',
                value: 'phone'
            },
            {
                label: 'Email',
                value: 'email'
            },
            {
                label: 'Is Paperless',
                value: 'isPaperless'
            },
            {
                label: 'Date of Incorporation',
                value: 'dateOfIncorporation',
                type: "fri://fieldtype/date",
                format: "MMM DD, yyyy",
            }
        ]
        const basic_info_right_section_ind = [
            {
                label: 'SSN',
                value: 'last4ssn'
            },
            {
                label: 'Mobile Phone',
                value: 'mobilePhone'
            },
            {
                label: 'Home Phone',
                value: 'homePhone'
            },
            {
                label: 'Work Phone',
                value: 'workPhone'
            }
        ]
        const audit_fields_left_section = [
            {
                label: 'Created',
                value: 'createdInfo'
            }
        ]
        const audit_fields_right_section = [
            {
                label: 'Last Updated',
                value: 'updatedInfo'
            }
        ]
        const title: any = businessCustomer ? 'Company Information' : 'Customer Information';
    
          const permission = useMemo(() => {
            const flag = checkPermission({
                entity: "Business Customer",
                name: "Business Customer",
                operation: 'View'
            })
            return flag
        }, [])

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid id="company-profile-company-information-grid" item xs={12}>
                {permission ?
                    <FxCard id="company-profile-company-information-card" className=" fx-theme-passport">
                        {dataLoadStatus ?
                            <>
                    <FxCardHeader id={'company-profile-company-information-card-header'}>
                        <Grid id="company-profile-company-information-card-header-grid" item container justifyContent="space-between" xs={12} alignItems='center' >
                            <Typography id="customer-profile-user-card-title" variant="h4" >
                               {title}
                            </Typography>
                            {/* {customerType !== 'INDIVIDUAL' && <FxButton id="company-profile-company-information-card-edit-button" permissions={editPermission} title="Edit" className="fx-button fx-button-passport-border fx-svg-theme" variant="contained" startIcon={<EditIcon />} onClick={() => history.push('/profile/edit/authorizedsignatory')} >
                                Edit
                            </FxButton>} */}
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="company-profile-company-information-card-body" className="fx-info-card fx-margin-top-reducer" >
                        <Grid container item xs={12} direction="row" >
                             <Grid item xs={12}>
                                {context?.data?.body && <FxInfoCard id="company-profile-company-information-card-basic-one-section-info-card" title="BASIC INFORMATION" leftcolumn={businessCustomer ? basic_info_left_section : basic_info_left_section_ind} rightcolumn={businessCustomer ? basic_info_right_section : basic_info_right_section_ind}  data={customerInfo} ></FxInfoCard>}
                            </Grid>
                             <Grid item xs={12}>
                                {context?.data?.body && <FxInfoCard id="company-profile-company-information-card-audit-one-section-info-card" title="AUDIT FIELDS" leftcolumn={audit_fields_left_section} rightcolumn={audit_fields_right_section} data={customerInfo} ></FxInfoCard>}
                            </Grid>
                        </Grid>
                    </FxCardBody>
                            </>
                        : <FxSkeltonList height="25rem" />}
                    </FxCard>
                    :
                    <FxAccessDenied id={'company-Cards-details-access-denied'} title={'You are not authorized to access this card'} />
                }
            </Grid >
        )
    })
