/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useLocation, useParams } from "react-router-dom";
import { CreateFundingRule } from "../CreateFundingRule";
import React from "react";
/**
 * Component: Clone Funding Rule
 * Usage: To Clone a previously existing Funding Rule
 */
export const CloneFundingRuleAction = () => {
  interface RouteParams {
    id: string;
  }
  const params = useParams<RouteParams>();
  const location: any = useLocation();
  /**
   * get ID from  url
   */
  const id = params.id;
  const ruleId = location?.state?.ruleId?.id;
  /**
   * funding rule details API
   */
  const user_source = {
    url: "fundingRule/id/" + ruleId,
    method: "GET",
  };
  const rand = Math.floor(Math.random() * 100) + 1;
  return (
    <>
      <CreateFundingRule id={rand + "clone-funding-rule" + id} source={user_source} />
    </>
  );
};
