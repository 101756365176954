/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { ReactComponent as DigitalFirstInfoIcon } from '../../../assets/svg/digital-info-icon.svg';   

Logger.debug("DigitalFirstInfoModal.tsx", "Digital First Info Modal initializing")
/**
 * This component shows a modal for digital first card information
 */
export const DigitalFirstInfoModal: React.FC<any> = () => {
    return (
        <Grid>
            <FxCard className='fx-digital-first-card-modal'>
                <FxCardHeader id="digital-first-card-modal-header" className="fx-card-header fx-modal-header">
                    <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                        <Grid item container xs={12} justifyContent="center">
                            <DigitalFirstInfoIcon />
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="digital-first-card-modal-body" className="fx-info-card">
                    <Grid container item xs={12} className="fx-modal-form flex column" >
                        <Typography id="digital-first-card-modal-subtext" variant='h5' textAlign={"center"} className='fx-digital-first-card-modal-text'>
                            The digital debit card can be used for transactions while we ship the physical card. Use the 'Enable Physical card' option to activate the physical card once it is received.
                        </Typography>
                    </Grid>
                </FxCardBody>
            </FxCard>
        </Grid>
    );
};
