/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { FxPopOver } from "../../../Data/FxPopOver";
import { ReactComponent as TriangleDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../../../assets/svg/triangle-up-icon.svg';
import {  fetchDataFromState, getRedirectInfo, updateComponentData, updateComponentParams } from "../../../Utils/CommonBaseClass";
import { useIsMobileScreen } from "../../../../libs/utils/utils";
import { FxLink } from "../../../Action/FxLink";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as MenuDotIcon } from '../../../../assets/svg/menu-nine-dots.svg';
import { ReactComponent as CheckedPoralIcon } from '../../../../assets/svg/checked-portal.svg';
import { useDispatch, useSelector } from "react-redux";
import { FxWelcomeListMenu } from "./WelcomeMenuList";
import { getLocalStorage } from "../../../../libs/utils/cacheHandler";

/**
 * This component handles the welcome selection in Switch Portal Menu
 */
export const SwitchPortalMenu: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const isSmallScreen = useIsMobileScreen();
    const location = useLocation();
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [welcomeExpanded, setWelcomeExpanded] = useState(false);
    const history = useHistory();
    useEffect(()=>{    
        updateComponentData(dispatch, 'portal-selected', { selectedPortal: selectedPortalState?.data?.selectedPortal?selectedPortalState?.data?.selectedPortal: location.pathname==='/leads-home'?'Leads':'Banking' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch,location.pathname])

    /**
     * Retrieve logged use permissions and check whether there exists a role entity other than Partner and Partner Portal
     * if exist then banking portal switching menu will available.
     */
    useEffect(()=>{
        const loggedUserPermissionArray=(getLocalStorage('permission')) ||[];
        const entitiesArray = JSON.parse(loggedUserPermissionArray)?.map((item:any) => item.entity);
        const bankingPortal = entitiesArray.some((entity: string) => entity !== "Partner" && entity !== "Partner Portal");
        updateComponentParams(dispatch, props?.id, { isBankingPortal: bankingPortal });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const accountList = useSelector((state: any) => state.data['customer-basic-info']?.params?.accountList || null);

    /**
     * Method to handle redirect of banking portal
     * @param option :selected option
     */
    const getMenuSwitch = (option: any) => {
        if (option?.id === 'ACCOUNT') {
            history.push(getRedirectInfo(accountList, dispatch, '/dashboard'))
        }
    }

    
    /**
     * handle click away from popover
     */
    const handleClose = (event: any, item: any) => {
       if(item && item?.id){
        updateComponentParams(dispatch, "layout-passport", { 'selectedPage': item?.id })
        item?.id && updateComponentData(dispatch, 'portal-selected', { selectedPortal: item?.label?.replace('Portal', '').trim() })
       }
       getMenuSwitch(item)
        event.stopPropagation();
        setAnchorEl(null);
        setWelcomeExpanded(false);
        item !== 'backdropClick' && props?.onMenuSelect && props?.onMenuSelect(event);
    };
    const open = Boolean(anchorEl);
    const selectedPortalState = useSelector((state: any) =>
        fetchDataFromState(state, 'portal-selected')
    );
    /**
     * handle click on menu
     */
    const handleClick = (event: any) => {
        event.stopPropagation();
        props?.drawerExpand && setWelcomeExpanded(true);
        setAnchorEl(event.currentTarget);

    };
    /**
     * Options for welcome menu
     */
    const menuOptions = useMemo(() => {
        const baseOptions = [
            { id: 'ACCOUNT', label: 'Banking Portal', url: '/dashboard', iconSufix: selectedPortalState?.data?.selectedPortal === 'Banking' ? <CheckedPoralIcon /> : <></> },
            { id: 'LEADS', label: 'Lead Portal', url: '/leads-home', iconSufix: selectedPortalState?.data?.selectedPortal === 'Lead' || selectedPortalState?.data?.selectedPortal === 'Leads' ? <CheckedPoralIcon /> : <></> },
        ];
        return [...baseOptions];
    },[selectedPortalState?.data?.selectedPortal])

    const isLeadsPortal = selectedPortalState?.data?.selectedPortal === 'Leads' || location.pathname === '/leads-home';


    return (<>
        {!props.drawerExpand && <>
        {isLeadsPortal &&  <FxWelcomeListMenu drawerExpand={props.drawerExpand} userName={props.userName} selectedPortal={'LEADS'}></FxWelcomeListMenu>}
            <Grid item xs={12} className='fx-sidenav-collapse-close-popover' onClick={handleClick}>
                {props?.isBankingPortal && <Grid item xs={12} container justifyContent='end'>
                    <FxLink id="menu-left-portal-switch"><MenuDotIcon style={{ width: '3rem', height: '3rem' }} id="avtar-collapse" className="fx-sidenav-collapse-close-popover-welcome fx-sidenav-collapse-close-popover-welcome-avatar" /></FxLink>
                </Grid>}
            </Grid>
        </>}

        {props?.drawerExpand && <>
        {isLeadsPortal &&  <FxWelcomeListMenu drawerExpand={props.drawerExpand} userName={props.userName} selectedPortal={'LEADS'}></FxWelcomeListMenu>}
        {props?.isBankingPortal && <Grid item className={`fx-sidenav-bottommenu-item ${welcomeExpanded ? 'fx-sidenav-expanded-theme-sel' : 'fx-sidenav-expanded-theme-not-sel'}`} onClick={handleClick}>
            <FxLink id="menu-left-portal-switch" className={`fx-sidenav-bottommenu-main `} >
                <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={!isSmallScreen ? 2 : 1} justifyContent='center' alignItems='center'><MenuDotIcon style={{ marginLeft: '1rem' }} id="avtar-collapse" className={`${welcomeExpanded ? 'fx-sidenav-expanded-avatar-theme' : 'fx-sidenav-expanded-avatar-not-theme'} fx-sidenav-bottommenu-left`} /></Grid>
                    <Grid className='fx-sidenav-bottommenu-middle' item xs={!isSmallScreen ? 7 : 11}><div className="fx-menu-passport-bottommenu-smalltext">Switch Portal</div><div>{selectedPortalState?.data?.selectedPortal}</div></Grid>
                    <Grid item justifyContent='center' alignItems='center' display='flex' xs={!isSmallScreen ? 2 : 1} className='fx-sidenav-bottommenu-main-right'>{!welcomeExpanded ? <TriangleUp /> : <TriangleDown />}</Grid>
                </Grid>
            </FxLink>
        </Grid>}
        </>}
        <Grid item container xs={12}>
            <FxPopOver
                id={'welcome-list-menu'}
                open={open}
                refElement={anchorEl}
                onClose={handleClose}
                className={`${!props.drawerExpand ? 'fx-sidenav-collapse-portal-switch-menu' : 'fx-sidenav-expanded-popup'}`}
                anchorOrigin={{
                    vertical: props.drawerExpand ? 'top' : 'bottom',
                    horizontal: props.drawerExpand ? 'center' : 'right',
                }}
                transformOrigin={{
                    vertical: props.drawerExpand ? 'bottom' : 'bottom',
                    horizontal: props.drawerExpand ? 'center' : 'left',
                }}
                data={menuOptions}
            >
            </FxPopOver>
        </Grid>
    </>)
})


