/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Chip, Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import HttpClient from "../../../libs/utils/httpClient";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import {
    clean,
    getEAOrAddressDetailsFromContact,
    renderError,
    setPayeeInformation,
    transformRecurringRequestGlobal,
    fetchLabelFromId,
    convertToTitleCaseAndAppendWithS,
    convertEndData,
    convertToOnDayFormat,
    updateComponentParams,
    deleteUnwantedRequest,
    checkPermission,
    isMethodSupported,
    truncateChars,
    createCommonVerifyProps
} from "../../Utils/CommonBaseClass";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import {
    createSendMyAccount,
    dataSourceTransformation,
    getEAList,
    accountLabelTransformation,
    getDestinationValue,
    getLocationData
} from "./ScheduleGlobalFunctions";
import { FxSuccess } from "../../Data/FxSuccess";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { FxSwitch } from "../../Action/FxSwitch";
import { FxRecurring } from "./Recurring/FxRecurring";
import { FxDateEdit } from "../../Input/FxDate/FxDateEdit";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { FxDynamicField } from "./FxDynamicField";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";
const httpClient = HttpClient.getClient();

Logger.debug(
    "CreateSendContactCHECK.tsx",
    "create send Contact Check initializing"
);
/**
 * This component handles the send creation to linked contacts through check method
 */
export const CreateSendContactCHECK: React.FC<any> = React.memo((props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
    const individual = uiFeat.getIndividualCustomerFeature().available;
    /**
     * Function to be called when context data body changes
     */
    useEffect(() => {
        if (context?.data?.body) {
            setFormValues();
        }
        if(context?.data?.body?.processingDetail?.location){
            getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
        }
    }, [context?.data?.body]);
    
    const history = useHistory();
    
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        resetField,
        control,
        clearErrors,
        setError,
        watch,
    } = useForm();
    let sendMyAccountId: any = "";
    const [contacEA, setContacEA] = useState<any>([]);
    const [eaLoad, setEALoad] = useState(false);
    const [destination, setDestination] = useState<any>();
    const [contactName, setContactName] = useState<any>();
    const [externalEAId, setExternalEAId] = useState();
    const [isDestination, setIsDestination] = useState(true);
    const [repeatStatus, setRepeatStatus] = useState(false);
    const [remittanceStatus, setRemittanceStatus] = useState(false);
    const [allowDuplicate, setAllowDuplicate] = useState(false);
    const [recurringFormData, setRecurringFormData] = useState<any>();
    const [fileUploadData, setFileUploadData] = useState<any>();
    const [isPpiContact, setIsPpiContact] = useState(false);
    const [selectedPPI, setSelectedPPI] = useState("");
    const [sendEnabled, setSendEnabled] = useState(true);
    const params = useParams<any>();
    const urlContactId = params?.contactId;
    const scheduleId = params?.id;
    const [location, setLocation] = useState<any>();
    const acc_src = {
        url: "account/list",
        method: "POST",
        data: {
            pageSize: 25,
            pageNumber: 1,
            sortOptions: {
                sortOrder: "DESC",
                sortBy: "createdOn",
            },
            criteria: {
                filters: [
                    {
                        key: "status",
                        operator: "nin",
                        values: ["INACTIVE"],
                    },
                ],
            },
        },
    };
    const destination_src = {
        url: "contact/list",
        method: "POST",
        data: {
            pageNumber: 1,
            pageSize: 5,
            criteria: {
                filters: [
                    {
                        key: "allowedMethod",
                        operator: "eq",
                        values: ["CHECK"],
                    },
                ],
            },
        },
    };

    const method_Tab = useMemo(() => {
        const tabs = [
            {
                title: "ACH",
                link: "/transaction/send/contact/add/ach",
                disabled: !!props.isEdit,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "CREATE"
                },
                {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "CREATE"
                }]
            },
            {
                title: "WIRE",
                link: "/transaction/send/contact/add/wire",
                disabled: !!props.isEdit,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Wire Send",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "CREATE"
                }]
            },
            {
                title: "INTERNATIONAL WIRE",
                link: "/transaction/send/contact/add/internationalWire",
                disabled: !!props.isEdit,
                multiplePermission: true,
                permission: [{
                  entity: "Accounts",
                  name: "International Wire Send",
                  operation: "CREATE"
                }, {
                  entity: "Recurring Transaction",
                  name: "International Wire Send",
                  operation: "CREATE"
                }]
            },
          ...(!individual ? [{
                className: "fx-link-sel",
                title: "CHECK",
                link: "/transaction/send/contact/add/check",
                disabled: !!props.isEdit,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "CREATE"
                }]
            }] : []),
            {
                title: "BOOK",
                link: "/transaction/send/contact/add/book",
                disabled: !!props.isEdit,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "CREATE"
                }]
            }
        ];
        // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
        if(getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer){
            tabs.push({
            title: "VIRTUAL CARD",
            link: "/transaction/send/contact/add/virtualcard",
            disabled: !!props.isEdit,
            multiplePermission: true,
            permission: [{
                entity: "Accounts",
                name: "Virtual Card Send",
                operation: "CREATE"
            }, {
                entity: "Recurring Transaction",
                name: "Virtual Card Send",
                operation: "CREATE"
            }]
            });
        }
        return tabs;
    }, [props.isEdit]);

    const deliveryModeData: any = [
        { label: "Two Day", value: "TWO_DAY" },
        { label: "Overnight", value: "OVERNIGHT" },
        { label: "Standard", value: "STANDARD" },
    ];

    const [isloading, setIsloading] = useState(false);
    const [checkRemitenceField, setCheckRemitenceField] = useState<any>([]);
    const [checkRemitenceData, setCheckRemitenceData] = useState<any>();
    const [allowCheckRemitence, setAllowCheckRemitence] = useState(false);

    /**
     * Function to be called before loading the component
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
                id: "create-send-contact-check-form-card-file-id-file-upload",
                files: [],
            },
        });
        setValue("type", "REGULAR");
        if (urlContactId) {
            setExternalAccount(urlContactId);
        }
        const templateId = getKey("accountinfotemplate")?.id;
        if (templateId) {
            templateSettings(templateId);
        }
        // checking whether edit or not.
        if (params.id) {
            const src = {
                url: "/transaction/id/" + params.id,
                method: "GET"
            }
            dispatch({ type: "DATA_API_PARAM_CHANGED", payload: { id: 'edit-send-contact-check', source: src } });
            updateComponentParams(dispatch, 'edit-send-contact-check', { 'isEdit': true })
        }
        else{
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Send Money',backButton:'/dashboard' } }});
        }
        const verifyProps = {
            open: false
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }, []);

    /**
    * setting form values for edit form
    */
    const setFormValues = () => {
        setValue('amount', context?.data?.body?.amount)
        setValue('purpose', context?.data?.body?.purpose)
        setValue('memo', context?.data?.body?.processingDetail?.memo)
        setValue('deliveryMode', context?.data?.body?.processingDetail?.deliveryMode)
        getDestinationData();
        setValue('contactAddress', context?.data?.body?.destination?.contact?.mailingAddress?.id)
        setExternalAccount(context?.data?.body?.destination?.contact?.id)
        if (context?.data?.body?.processingDetail?.remittanceInfo) {
            setRemittanceStatus(false);
            setCheckRemitenceField(context?.data?.body?.processingDetail?.remittanceInfo)
            setTimeout(() => {
                setRemittanceStatus(true);
            }, 1)
        }
    }

    /**
     * Method handles the api call for getting contact name from the contact api and set the value
     */
    async function getDestinationData() {
        const destinationValue: any = await getDestinationValue(context?.data?.body?.destination);
        setValue('destination', destinationValue)
    }

    /**
     * Method checks whether check remittance should be allowed or not
     */
    async function templateSettings(id: any) {
        const req: any = {
            limit: "3",
            criteria: {
                filters: [
                    {
                        key: "settingName",
                        operator: "in",
                        values: ["CHECK_PROCESSING_INCLUDE_REMITTANCE_INFORMATION"],
                    },
                ],
            },
        };
        try {
            /**
             * calling api for checking whether settings for adding check remittance are enabled at admin and program manager level
             */
            const response = await httpClient.post("programManager/getProgramManagerSetting", req);
            if (response.error) {
                FxSnackBar.show({
                    text: 'Please try again',
                });
            } else if (response?.data?.resources?.[0]?.defaultValue === "true" && (
                !response?.data?.resources?.[0]?.settingValue ||
                response?.data?.resources?.[0]?.settingValue === "TRUE"
            )) {
                setAllowCheckRemitence(true);
                templateInfo(id);
            }
        } catch (err) {
            Logger.error("CreateSendContactCHECK.tsx", "error", err);  
         }
    }

    /**
     * Method set the check remittance field data
     */
    async function templateInfo(id: any) {
        try {
            const response = await httpClient.get("template/checkRemittance/id/" + id);
            if (response.error) {
                FxSnackBar.show({
                    text: 'Please try again',
                });
            } else {
                const remData: any = {};
                [...response?.data?.headers].forEach((item: any) => {
                    remData[item.name] = "";
                });
                setCheckRemitenceField([remData]);
            }
        } catch (err) {
            Logger.error("CreateSendContactCHECK.tsx", "error", err);  
         }
    }

    /**
     * Method set the check remittance field data
     */
    function fieldData(event: any) {
        setCheckRemitenceData(event.dynamicField);
    }

    const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];

    /**
     * Method to set file upload data
     * @param event :event object
     */
    function passdata(event: any) {
        try {
            setFileUploadData(event);
            if (event.length > 0 && errors["fileError"]) {
                clearErrors("fileError");
            }
        } catch (e) { 
            Logger.error("CreateSendContactCHECK.tsx", "error", e);  
        }
    }

    /**
     * Method to remove file upload data
     * @param id : file id
     */
    const removefile = (id: any) => {
        const newval = fileUploadData.filter((item: any) => item.id !== id);
        dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
                id: "create-send-contact-check-form-card-file-id-file-upload",
                files: newval,
            },
        });
        setFileUploadData(newval);
    };

    const handleChange = (event: any) => {
        if (event?.value) {
            setExternalAccount(event?.value);
            setIsDestination(true);
        } else {
            setContacEA([]);
            setEALoad(false);
            setIsDestination(true);
            setIsPpiContact(false);
        }
        setSendEnabled(true);
        FxSnackBar.closeForce();
    };

    /**
     * // getting the destination data
     * @param contactId
     */
    function setExternalAccount(contactId: any) {
        setContacEA([]);
        setIsDestination(false);
        getEAList(contactId).then((data: any) => {
            setContactName(data?.name);
            const ea = getEAOrAddressDetailsFromContact(
                data,
                "ADDRESS",
                "",
                false,
                true
            );
            if (ea?.length) {
                setDestination(setPayeeInformation(data));
                setIsDestination(true);
                setExternalEAId(ea[0].value);
            }
            if (data?.ppi) {
              setEALoad(false);
              setIsPpiContact(true);
              setSelectedPPI(data.ppi);
              if(isMethodSupported("check")) {
                setSendEnabled(true);
              } else {
                setSendEnabled(false);
                FxSnackBar.show({
                    text: 'Selected payment method is not supported for sending money to contact with PPI. Available methods: ' + getKey('ppiSupportedMethods'),
                });
              }
            } else {
              setContacEA(ea);
              setIsPpiContact(false);
              setEALoad(true);
              setSelectedPPI("");
              setSendEnabled(true);
            }
        });
    }

    /**
     * Function handles transformation of destination data
     * @param data : destination data array
     * @returns : array
     */
    const destinationTransformer = useCallback((data: any) => data?.map((item: any) => ({
        label: item.name + " (ID: " + item.id + ")",
        value: item.id,
    })) || [], []);

    const destinationFilterOptions: any = [
        { label: "Contact Name", value: "name", operator: "like" },
    ];

    const handleClose = () => {
        if (context?.data?.body) {
            history.push("/transactions/send/" + context.data.body.id);
        } else {
            history.push("/dashboard");
        }
    };

    /**
     * Async function handles the create send form submission
     * @param data : form request
     */
    async function onSubmit(data: any) {
        let req: any = {};
        let request: any = {};
        let recurringRequest: any = {};
        if (urlContactId) {
            data.destination = urlContactId;
        }
        if (!data.destination) {
            setError("destination", {
                type: "manual",
                message: "Destination is required",
            });
            return;
        }
        if (repeatStatus) {
            recurringRequest = transformRecurringRequestGlobal(
                data,
                recurringFormData
            );
            req = {
                name: data?.name,
                transactionDetail: recurringRequest["transactionDetail"],
                action: [],
            };
            req["transactionDetail"]["startDate"] = data?.startDate;
            request = {
                transaction: {
                    source: {
                        account: {
                            id: data.source,
                        },
                    },
                    method: "CHECK",
                    type: "REGULAR",
                    amount: data.amount,
                    purpose: data.purpose,
                    allowDuplicate: allowDuplicate,
                },
            };
            if (data.destination) {
                request["transaction"]["destination"] = {
                    contact: {
                        id: data.destination,
                        mailingAddress: {
                            id: data.contactAddress,
                        },
                    },
                };
            }
            request["transaction"]["processingDetail"] = {};
            if (data["deliveryMode"] || data["checkNumber"]) {
                if (data["deliveryMode"] && data["memo"]) {
                    request["transaction"]["processingDetail"]["deliveryMode"] =
                        data.deliveryMode;
                    request["transaction"]["processingDetail"]["memo"] = data.memo;
                }
                if (data["deliveryMode"] && !data["memo"]) {
                    request["transaction"]["processingDetail"]["deliveryMode"] =
                        data.deliveryMode;
                }
                if (data["checkNumber"]) {
                    request["transaction"]["processingDetail"]["checkNumber"] =
                        data.checkNumber;
                    request["transaction"]["processingDetail"]["memo"] = data.memo;
                }

                if (data.receivingPayee) {
                    request["transaction"]["processingDetail"]["recipient"] = {};
                    request["transaction"]["processingDetail"]["recipient"]["payee"] = {};
                    request["transaction"]["processingDetail"]["recipient"]["payee"][
                        "id"
                    ] = data.receivingPayee;
                }
            }
            if (checkRemitenceData && remittanceStatus) {
                request["transaction"]["processingDetail"]["remittanceInfo"] =
                    checkRemitenceData;
            }
            if(data['location']){
                request['transaction']['processingDetail']['location'] = {
                    'id' : data['location']
                }
            }

            req["action"].push(request);
            if (fileUploadData?.length) {
                req["linkedDocument"] = fileUploadData.map((item: any) => ({
                  purpose: "Authorization",
                  document: { id: item.id }
                }));
            }
        } else {
            req = {
                source: {
                    account: {
                        id: data.source,
                    },
                },
                method: "CHECK",
                type: "REGULAR",
                amount: data.amount,
                purpose: data.purpose,
                allowDuplicate: allowDuplicate,
            };
            req["destination"] = {
                contact: {
                    id: data.destination,
                    mailingAddress: {
                        id: data.contactAddress,
                    },
                },
            };
            req["processingDetail"] = {};
            if (data["deliveryMode"] || data["checkNumber"]) {
                if (data["deliveryMode"] && data["memo"]) {
                    req["processingDetail"]["deliveryMode"] = data.deliveryMode;
                    req["processingDetail"]["memo"] = data.memo;
                }
                if (data["deliveryMode"] && !data["memo"]) {
                    req["processingDetail"]["deliveryMode"] = data.deliveryMode;
                }
                if (data["checkNumber"]) {
                    req["processingDetail"]["checkNumber"] = data.checkNumber;
                    req["processingDetail"]["memo"] = data.memo;
                }

                if (data.receivingPayee) {
                    req["processingDetail"]["recipient"] = {};
                    req["processingDetail"]["recipient"]["payee"] = {};
                    req["processingDetail"]["recipient"]["payee"]["id"] =
                        data.receivingPayee;
                }
            }
            if (checkRemitenceData && remittanceStatus) {
                req["processingDetail"]["remittanceInfo"] = checkRemitenceData;
            }
            if(data['location']){
                req['processingDetail']['location'] = {
                    'id' : data['location']
                }
            }
        }
        if (props.isEdit) {
            req['id'] = context?.data?.body?.id;
            const unwantedRequestArray = ['source', 'destination', 'method', 'type'];
            deleteUnwantedRequest(req, unwantedRequestArray)
        }
        if (isPpiContact) {
            delete req?.processingDetail //in case of ppi we are deleting ea node
            if (req?.destination?.contact?.mailingAddress) {
              delete req["destination"]['contact']['mailingAddress'];
            } else if (req?.action && req['action'][0] && req['action'][0] && req['action'][0]['transaction']?.destination?.contact?.mailingAddress) {
                delete req['action'][0]['transaction'].destination.contact.mailingAddress;
            }

          }
        req = clean(req);

        setIsloading(true);
        let status: any;
        status = await createSendMyAccount(req);
        if (status?.headers?.url) {
            sendMyAccountId = status.headers.url.split("/").pop();
        }

        status = processAPIResponse(status);
        setIsloading(false);
        if (status.status) {
                const successMessage = props.isEdit? 'Send Updated Successfully!': repeatStatus? 'Recurring Created Successfully!': 'Send Created Successfully';
                const redirectUrl = props.isEdit? `/transactions/send/${context?.data?.body?.id}`: repeatStatus? '/thanks/transactions/send-contact-check-recurring/': '/thanks/transactions/send-contact-check/';
                const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                if(status.responseData?.sessionId){
                const verifyProps = {
                    ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, 'send'),
                    successMessage : successMessage,
                    redirectUrl: redirectUrl,
                    redirectUrlFromHeader:params?.id?false: true,
                    postSuccessEvent:handlePostSuccessEvent

                }
                updateComponentParams(dispatch, props.id, { verifyProps })
            }
            else{
            if (props.isEdit) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Send Updated Successfully!',
                });
                history.push('/transactions/send/' + context?.data?.body?.id);
            }
            else {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: repeatStatus ? 'Recurring Created Successfully!' : 'Send Created Successfully!',
                });
                if (repeatStatus) {
                    dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-contact-check-form-card-file-id-file-upload', 'files': [] } });
                    history.push('/thanks/transactions/send-contact-check-recurring/' + sendMyAccountId);
                } else {
                    history.push('/thanks/transactions/send-contact-check/' + sendMyAccountId)
                }
            }
        }
        }
        else {
            //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
           
        }
    }   

    /**
    * The main summary data
    */
    const amount = watch('amount');
    const destinationId = watch('destination');  

    const destinationLabel = useMemo(() => {
        return destinationId && contactName ? contactName :''
    }, [destinationId, contactName]);

    const contactAddress = watch("contactAddress");
    const viaToLable = useMemo(() => {
        const ppi = {
            'To PPI': {
                id: 'ppi',
                value: selectedPPI,
            }
        }

        const address = {
            'To Address': {
                id: 'contactAddress',
                value: contactAddress
                ? fetchLabelFromId(contacEA, contactAddress)
                : fetchLabelFromId(contacEA, externalEAId),
            }
        }

        if(isPpiContact){
           return ppi;         
        }else{
           return address       
        }       
    }, [isPpiContact, selectedPPI, contactAddress, contacEA, externalEAId]);
    
    const summaryData: Record<string, TransactionSummaryFieldType | string> = {
        'Send Amount': {
            id: 'amount',
            ValueComponent: FxSummaryCurrency,
            value: amount && !isNaN(amount) ? amount : '',
        },
        'From': {
            id: 'from',
            value: accountLabelTransformation(getKey('selectedAccount'))
        },
        'To': {
            id: 'to',
            value: destinationLabel,
        },
        ...(businessCustomer
          ? { Purpose: { id: "purpose", value: watch("purpose") } }
          : { Memo: { id: "purpose", value: watch("purpose") } }),
        'Via': {
            id: 'method',
            value: 'CHECK',
        },
        ...viaToLable,
        'Delivery Mode':{
            id: 'deliveryMode',
            value: watch("deliveryMode"),
        }
    };

    if(businessCustomer) {
        summaryData['Memo'] = {
            id: 'achCompanyDescription',
            value: watch('memo'),
        }
    }

    /**
     * Recurring related summary data
     */
    const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
        if(!repeatStatus || !recurringFormData) {
            return {}
        }
        const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
        const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
            ? convertToTitleCaseAndAppendWithS(
                recurringFormData?.repeatEveryFormData?.repeatEverySelect
            )
          : '';
        const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
            'Repeat every': {
                id: 'repeat-every',
                value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
            },
        }
        if(onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
            result['On'] = {
                id: 'repeat-on',
                value: convertToOnDayFormat(recurringFormData),
            };
        }
        const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
        const ends = recurringFormData?.endFormData
            ? convertEndData(recurringFormData?.endFormData)
            : '';
        if (ends || endOn) {

        }
        result['Ends'] = endOn ? {
            id: 'repeat-ends',
            value: ends,
            ValueComponent: ({ value }) => (
                <>
                    On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
                </>
            )
        } : {
            id: 'repeat-ends',
            value: ends,
        };
        return result;
    }, [recurringFormData, repeatStatus]);

    const topSectionDefaultValues = {
        amount: context?.data?.body?.amount,
        source: context?.data?.body?.source?.id,
        purpose: context?.data?.body?.purpose
      }

      const tabClickFunction = () => {
        try{
          const data:any = {
            schedule: 'send',
            type: 'contact',
            formData: { amount: watch('amount'), purpose: watch('purpose')}
          }
          dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
        }
        catch(e){
            Logger.error("CreateSendContactCHECK.tsx", "error", e);  
        }
      }
        /**
                 * Function to handle the closing of the modal.
                 */
            
        const handleCloseModal = () => {
            const verifyProps = {...props.verifyProps}
            verifyProps['open'] = false;
            updateComponentParams(dispatch, props.id, { verifyProps });
        };
         /** 
            *This function is called upon successful completion of a post operation.
            *It dispatches an action to update component parameters related to file uploads.
            */
            const handlePostSuccessEvent=()=>{
                dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-contact-check-form-card-file-id-file-upload', 'files': [] } });
            }
    return (
      <>
        <ScheduleLayout
            id='create-send-contact-check-card'
            type='send'
            loading={isloading}
            submitTitle={repeatStatus ? 'Create' : props.isEdit ? "Save Changes" : "Send Money"}
            summaryData={{ ...summaryData, ...summaryRepeatData }}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={handleClose}
            saveEnabled={sendEnabled}
            verifyProps={props?.verifyProps}
            handleCloseModal={handleCloseModal} >

            {urlContactId && <FxSuccess id="sucess-external-account" header={"Contact added successfully"} description={"CONTACT ID - " + urlContactId} /> }

            <ScheduleTopSection id="create-send-contact-check-schedule-top-section" method='CHECK' schedule="send" type="contact" register={register} control={control} errors={errors}
                setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-contact-check-form-card-amount-textbox"
                sourceId="create-send-contact-check-form-card-source" purposeId="create-send-contact-check-form-card-purpose-textbox"/>
            {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
                <FxLocationSelectComponent
                    label='Location'
                    register={{ ...register("location") }}
                    control={control}
                    rules={{ required: false }}
                    id="create-send-contact-check-form-location"
                    name="location"
                    className="fx-input-edit"
                    setError={setError}
                    clearError={clearErrors}
                    setValue={setValue}
                    resetField={resetField}
                    isEditable={!scheduleId}
                    value={location ? {
                        label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                        value: location?.id,
                    }: null}
                />
            </Grid>}

            {!props.isEdit && 
                <Grid item xs={12} sm={12}>
                    {isDestination && 
                        <FxAutoComplete register={{ ...register("destination") }} control={control} rules={{ required: !urlContactId ? true : false }} 
                            id="create-send-contact-ach-form-card-destination" name="destination" source={destination_src} searchBy={'name'} 
                            className={errors.destination ? "border-error-input fx-input-edit" : "fx-input-edit"} dataTransformer={destinationTransformer} setError={setError} 
                            clearError={clearErrors} setValue={setValue} resetField={resetField} value={destination} filterOptions={destinationFilterOptions} placeholder={'Search Contact...'}
                            isEditable={true} setParam={handleChange} />}
                </Grid>
            }

            {props.isEdit && 
                <Grid item xs={12} sm={12} >
                    <FxTextEdit register={{ ...register("destination") }} className={errors.destination ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} 
                        id="create-send-contact-check-form-card-destination-textbox" label="" name="destination" variant="outlined" isEditable={false} />
                </Grid>
            }   

            <Grid item>
                <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-contact-check-form-card-tab" tabClick={tabClickFunction} />
            </Grid>    

            {isPpiContact && 
                <Grid item xs={12} sm={12}>
                    <FxTextEdit control={control} rules={{ required: false }} id="create-send-contact-check-form-card-ppi-textbox" label="Payment ID" name="passportPaymentId"
                    variant="outlined" value={truncateChars(selectedPPI, 60)} isEditable={false} className={"fx-input-edit fx-input-edit-disabled"} />
                </Grid>
            }

            {eaLoad && 
                <Grid item xs={12} sm={12}>
                    <FxMaterialSelect register={{ ...register("contactAddress") }} id="create-send-contact-ach-form-card-address" name="contactAddress" data={contacEA} control={control}
                        label='Contact Address' rules={{ required: true }} value={externalEAId ? externalEAId : ""} clearError={clearErrors} readOnly={params?.id ? true : false}
                        className={errors.contactAddress ? "border-error-input fx-input-edit" : "fx-input-edit"} setValue={setValue} />
                </Grid>
            }

            {!isPpiContact &&
                <Grid item xs={12} sm={12}>
                    <FxMaterialSelect register={{ ...register("deliveryMode") }} control={control} rules={{ required: true }} id="create-send-contact-check-form-card-delivery-mode"
                        name="deliveryMode" data={deliveryModeData} value={context?.data?.body?.processingDetail?.deliveryMode ? context.data.body["processingDetail"]["deliveryMode"] : "STANDARD"}
                        label="Delivery Mode" setValue={setValue} />
                </Grid>
            }

            {businessCustomer && <Grid item xs={12} sm={12}>
                <FxTextEdit register={{ ...register("memo") }} className={errors.memo ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control}
                    rules={{ required: false }} id="create-send-contact-check-form-card-memo-textbox" label="Memo" name="memo" variant="outlined"
                    defaultValue={context?.data?.body?.memo ? context.data.body["memo"] : ""}  />                                
            </Grid>}

            <Grid item xs={12} sm={12}>
                <FxSwitch id="create-send-contact-check-form-card-allow-duplicate" value={allowDuplicate} onClick={setAllowDuplicate}></FxSwitch>
                {" "}
                <FxLabel value="Allow Duplicate"></FxLabel>                                
            </Grid>

            {allowCheckRemitence &&
                <Grid item xs={12} sm={12}>
                    <FxSwitch id="create-send-contact-check-form-card-remittance-switch" value={remittanceStatus} onClick={setRemittanceStatus}></FxSwitch>
                    {" "}
                    <FxLabel value="Include Remittance Information"></FxLabel>
                </Grid>
            }

            {remittanceStatus && checkRemitenceField && 
                <Grid item xs={12} sm={12}>
                    <Grid item>
                        <FxDynamicField id={"remitence-field"} fieldData={fieldData} data={checkRemitenceField} limit={15} buttonName="Add More"></FxDynamicField>
                    </Grid>
                </Grid>
            }

            {(!props.isEdit  && checkPermission({entity: "Recurring Transaction",name: "Check Send",operation: "Create" })) && 
                <Grid item xs={12} sm={12}>
                    <FxSwitch id="create-send-contact-check-form-card-repeat-switch" value={repeatStatus} onClick={setRepeatStatus}></FxSwitch>{" "}
                    <FxLabel value="Repeat"></FxLabel>
                </Grid>
            }

            {repeatStatus && 
                <>
                    <Grid item xs={12} sm={12}>
                        <FxDateEdit register={{ ...register("startDate") }} className={errors.startDate ? "border-error-input fx-input-edit" : "fx-input-edit"}
                            control={control} id="create-send-contact-check-form-card-startDate" name="startDate" type="date" variant="outlined" label={"Start Date *"}
                            setValue={setValue} disablePast={true} resetField={resetField} rules={{ required: true }} />                                
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FxTextEdit register={{ ...register("name") }} className={errors.name ? "border-error-input fx-input-edit" : "fx-input-edit"}
                            control={control} rules={{ required: true }} id="create-send-account-ach-form-card-name-textbox" label="Name *" name="name" variant="outlined" />                                
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FxRecurring id="create-send-contact-check-form-card-fx-recurring" updateTrigger={setRecurringFormData} startDate={watch("startDate")} register={register}
                            control={control} errors={errors} setValue={setValue} clearErrors={clearErrors}></FxRecurring>
                        <Grid item container xs={12}>
                            <Grid item xs={12} sm={12}>
                                <Typography className="filter-popover-label">LINKED DOCUMENTS</Typography>                            
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                &nbsp;
                            </Grid> 
                            <Grid item container xs={12} spacing={1} alignItems="center">
                                <Grid item xs={12} sm={8}>
                                    <FxMaterialSelect register={{ ...register("docType") }} id="create-send-contact-check-form-card-doc-type" control={control} rules={{ required: false }}
                                        name="docType" data={docTypeData} value={"CONTRACT"} readOnly={true} label="Select a document type" setValue={setValue} />                            
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller name={"fileId"} control={control} rules={{ required: false }}
                                        render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => 
                                                <FxFileUpload register={{ ...register("fileId") }} name="fileId" passData={passdata} id="create-send-contact-check-form-card-file-id-file-upload"
                                                    onChange={(e: any) => { onChange(e);}} value={fileUploadData ? fileUploadData : value}
                                                    acceptedFiles={[".pdf",".docx",".jpg",".txt",".png",".jpeg",".xls",".wav",".xlsx",".tiff",".doc",".rtf",".bmp",".efx",".csv",".kswps",".wps"]}
                                                    formData={{ type: "CONTRACT" }} setValue={setValue} maxFileSize={10485760} />
                                        }
                                    />                            
                                </Grid>
                            </Grid> 
                            <Grid item xs={12} sm={8}>
                                <FxLabelView className="fx-label-small" id="create-send-contact-check-form-card-file-types-supported">
                                    (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc, .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                                </FxLabelView>                            
                            </Grid>   
                            <Grid item xs={12} sm={12}>
                                <div className={"error-message"}>
                                    {errors.fileError && renderError(errors.fileError)}
                                </div>                            
                            </Grid>   
                            {fileUploadData && 
                                <Grid item container>
                                    {fileUploadData.map((key: any) => {
                                        return (
                                            <Chip icon={<InsertDriveFileTwoToneIcon />} label={key["name"]} onDelete={() => removefile(key["id"])} color="primary"
                                                variant="outlined" deleteIcon={<DeleteIcon />} />
                                        );
                                    })}
                                </Grid>
                            }                
                        </Grid>    
                    </Grid>
                </>
            }
        </ScheduleLayout>
      </>
    );
});
