/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import { DataGrid } from '../../../Data/DataGrid';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import { ReactComponent as AddIcon } from '../../../../assets/svg/add-new-icon.svg';
import { FxPrimaryFilter } from '../../../Data/FxPrimaryFilter';
import { FxDateTimeView } from '../../../Input/FxDate/FxDateView';
import FxTextView from '../../../Input/FxText/FxTextView';
import { Logger } from '../../../../libs/utils/logger';

/**
 * This component handles the Viewing, Adding & Editing Roles 
 */
export const Roles: React.FC<any> = React.memo(() => {

    /**
     * function to get source info
     * @returns :source 
     */
    function sourceInfo() {
        const filters: any = [];
        const src = {
            url: "/role/list",
            method: "POST",
            data: { "pageNumber": 1, "pageSize": 25, "criteria": { "filters": [] } }
        }
        try {
            src.data.criteria = { filters }
        }
        catch (e) {
            Logger.error("Roles.tsx", "error", e); 
         }
        return src;
    }
    const dispatch = useDispatch();
    const history = useHistory();

    /**
    * useEffect to load title while loading page
    */
    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Roles' } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const column: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "ID",
                "enable": true,
                "fieldName": "id",
                "type": "fri://fieldtype/default",
                "default": true,
            },
            {
                "label": "Role Name",
                "enable": true,
                "fieldName": "nameWithDesc",
                "type": "fri://fieldtype/custom",
                "default": true
            },
            {
                "label": "Last Updated On",
                "enable": true,
                "fieldName": "lastUpdatedOn",
                "type": "fri://fieldtype/date",
                "default": true
            },
            {
                "label": "Last Updated By",
                "enable": true,
                "fieldName": "lastUpdatedBy",
                "type": "fri://fieldtype/default",
                "default": true
            }
        ]
    }

    const datagridProps: any = {
        id: "roleCard-role-list",
        column: column,
        pagination: true,
        pageNumber: 1,
        pageSize: 25,
    };
    const primaryFilterData: any = [
        { label: 'Role Name', value: 'name', operator: 'like' }

    ];

    /**
     * Transform the list data
     * @param data 
     * @returns 
     */
    function transformRoleList(data: any) {
        try {
            const newData: any = [];
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                if (item?.parentRole?.id) {
                    newData[i]['parentRole'] = getParentRole(data, item.parentRole.id)
                }
                newData[i]['lastUpdatedBy'] = item?.lastUpdatedBy?.username;

                newData[i]['nameWithDesc'] = <FxTextView id={'name'} value={`${item['name']}`} toolTipValue={`${item['description']}`} tooltip={true}></FxTextView>


                newData[i].lastUpdatedInfo = <Grid container xs={12}><FxDateTimeView tooltip={true} value={item['lastUpdatedOn']} format={"MMM DD, yyyy"}></FxDateTimeView>&nbsp; By &nbsp;<FxTextView id={'createdBy'} value={`${item['createdBy']['username']}`} tooltip={true}></FxTextView></Grid>

            });
            return newData;
        }
        catch (e) {
            Logger.error("Roles.tsx", "error", e); 
        }

    }
    /**
     * method to redirect to roles create screen
     */
    const handleClick = () => {
        history.push('/role')
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-role-form-card-role-type' } });
    }

    /** 
     * Get parent role
     */
    function getParentRole(data: any, id: any) {
        let parentRole: any = ''
        // eslint-disable-next-line eqeqeq
        const role = data.find((element: any) => element.id == id)
        if (role) {
            parentRole = role.name;
        }
        return parentRole
    }
    /**
     * Method to redirect to the roles details page
     * @param event :event object
     * @param data : external account data
     */
    function redirectDetailsPage(event: any, data: any) {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'role-permissions' } });
        history.push(`/role/${data.id}`);
    }
    return (

        <Grid id="roles-grid" item xs={12}>
            <Grid container className="fx-top-header" id="roles-grid" item justifyContent="space-between" alignItems="center" xs={12}>                    {/* <ViewProfile /> */}
            </Grid>
            <Grid container xs={12} >
                <FxCard id="roles-card" className="fx-theme-passport">
                    <FxCardHeader>
                        <Grid id="role-list-card-header-first-grid" item container justifyContent="space-between" xs={12} >
                            <Grid item>
                                  <FxPrimaryFilter id="role-list-primary-filter" name="externals-primary-filter" permissions={{ entity: "Role", name: "Role", operation: "View" }} data={primaryFilterData} dataGridProps={datagridProps} defaultSelection="name" />
                            </Grid>
                            <Grid item>
                                  <FxButton id="edit-role-button" variant="contained" className="fx-button fx-margin-left-10 fx-button-theme fx-svg-theme" permissions={{ entity: "Role", name: "Role", operation: "Create" }} onClick={handleClick} startIcon={<AddIcon />}>Add Role</FxButton>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="roles-card-body" className="fx-card-body-no-border-no-shadow">
                        <Grid id="roles-card-body-grid" item xs={12}>
                            <DataGrid id="roleCard-role-list" rowClick={redirectDetailsPage} tableClass="fx-table-passport" dataTransformer={transformRoleList} permissions={{ entity: "Role", name: "Role", operation: "View" }} source={sourceInfo()} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                        </Grid>
                    </FxCardBody>
                    <FxCardFooter id="roles-card-footer">
                    </FxCardFooter>
                </FxCard>
            </Grid>
        </Grid>
    )
})

