/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { useDispatch } from "react-redux";
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxRoutingCodeLabel } from '../../../Input/FxSwiftCode/FxRoutingCodeLabel';
import { FxInfoCard } from '../../Cards/FxInfoCard';
import { sourceDataTransformer } from '../../Schedules/ScheduleGlobalFunctions';
import { convertToPhoneDisplayFormat, maskAccountNumber } from '../../../Utils/CommonBaseClass';

/**
 * This component handles the Send Destination Info
 */
export const SendDestinationInfo: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }, []);

        const scheduleId = props?.scheduleInfo?.id;
        const scheduleInfo = props?.scheduleInfo;
        const scheduleDestInfoCopy = {...props?.scheduleInfo?.destination?.externalAccount}
        let destinationInfo = props?.destinationInfo;
        let cardInfo: any = {};

        if (scheduleInfo?.method === 'VIRTUAL_CARD' && scheduleInfo.destination?.email) {
            destinationInfo = destinationInfo ? { ...destinationInfo, email: scheduleInfo.destination?.email } : { email: scheduleInfo.destination?.email }
        }



        if (scheduleInfo?.method === 'WIRE' && scheduleInfo.destination?.externalAccount?.holderPhone) {
            scheduleDestInfoCopy['holderPhone'] = convertToPhoneDisplayFormat(scheduleInfo['destination']['externalAccount']['holderPhone'])
        }

        const swiftCodeDetails = useMemo(() => {
            if (destinationInfo?.internationalExternalAccount?.swiftCode) {
                return destinationInfo.internationalExternalAccount.swiftCode;
            } else if (scheduleInfo?.destination?.internationalExternalAccount?.swiftCode) {
                return scheduleInfo.destination.internationalExternalAccount.swiftCode;
            } else if (destinationInfo?.swiftCode) {
                return destinationInfo.swiftCode;
            }  else {
                return '';
            }
        }, [scheduleInfo, destinationInfo]);

        const destination_info_check_section = [
            {
                label: 'Address',
                value: 'address',
                type: 'fri://fieldtype/address'
            }
        ]

        /**
         * Left Info Section for the Send External Check Usecase
         */
        const destination_info_check_left_section = [
            {
                label: 'Payee Name',
                value: 'payeeName',
                type: 'fri://fieldtype/default'
            }
        ]

        const destination_info_wire_section = [
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'routingNumber'
            },
            {
                label: 'Wire Routing Number',
                value: 'wireRoutingNumber'
            },
            {
                label: 'Account Type',
                value: 'type'
            },
            {
                label: 'Bank Name',
                value: 'bankInfo.name'
            },
            {
                label: 'Bank Address',
                value: 'bankInfo.address',
            },


            
            
        ];
        const destination_info_wire_section_two = [
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Holder Type',
                value: 'holderType'
            },
            {
                label: 'Holder Phone',
                value: 'holderPhone'
            },
            {
                label: 'Holder Address',
                value: 'holderAddress',
                type: 'fri://fieldtype/address'
            },
            
        ]


        //Left Side Columns for the Tax Wire
        const destination_info_tax_wire_left_section = [
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
        ];

        //Right Side Columns for the Tax Wire
        const destination_info_tax_wire_right_section = [
            {
                label: 'Routing Number',
                value: 'routingNumber'
            },
            {
                label: 'Wire Routing Number',
                value: 'wireRoutingNumber'
            }
        ];

        const destination_info_card_section = [
            {
                label: 'Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Wire Routing Number',
                value: 'wireRoutingNumber'
            },
            {
                label: 'Account Type',
                value: 'type'
            }
        ]
        const destination_info_external_account_section = [
            {
                label: 'Bank Name',
                value: 'bankInfo.name'
            },
            {
                label: 'Holder Type',
                value: 'holderType'
            },
            {
                label: 'Address',
                value: 'bankInfo.address'
            }
        ]
        const contact_section_left = [
            {
                label: 'Contact',
                value: 'name'
            },
            {
                label: 'Holder Name',
                value: 'externalAccount.holderName'
            },
            {
                label: 'Account Type',
                value: 'externalAccount.type'
            },
            {
                label: 'Holder Type',
                value: 'externalAccount.holderType'
            }
        ];
        const contact_section_right = [
            {
                label: 'Account Number',
                value: 'externalAccount.accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'externalAccount.routingNumber'
            },
            {
                label: 'Bank Name',
                value: 'externalAccount.bankInfo.name'
            }
        ]
        const contact_section_wire_left = [
            {
                label: 'Contact',
                value: 'name'
            },
            {
                label: 'Holder Name',
                value: 'externalAccount.holderName'
            },
            {
                label: 'Account Type',
                value: 'externalAccount.type'
            },
            {
                label: 'Holder Type',
                value: 'externalAccount.holderType'
            }
        ];
        const contact_section_wire_right = [
            {
                label: 'Account Number',
                value: 'externalAccount.accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing Number',
                value: 'externalAccount.routingNumber'
            },
            {
                label: 'Wire Routing Number',
                value: 'externalAccount.wireRoutingNumber'
            },
            {
                label: 'Bank Name',
                value: 'externalAccount.bankInfo.name'
            }
        ]
        const contact_section_check_left = [
            {
                label: 'Contact',
                value: 'name'
            },
            {
                label: 'Primary Address',
                value: 'mailingAddress',
                type: 'fri://fieldtype/address'
            }
        ]
        const contact_section_check_right = [
            {
                label: 'Shipped To',
                value: 'mailingAddress.name'
            },
            {
                label: 'Phone',
                value: 'mailingAddress.phone',
            }
        ]
        const contact_section_book_left = [
            {
                label: 'Contact',
                value: 'name'
            }
        ]
        const ppi_destination_section_left = [
            {
                label: 'PPI ID',
                value: 'ppi'
            }
        ]
        const source_account_section = [
            {
                label: 'Account ID',
                value: 'id'
            }
        ];
        const source_account_section_two = [
            {
                label: 'Account',
                value: 'accountInfo'
            }
        ];

        const contact_section_virtual_card_left = [
            {
                label: 'Contact',
                value: 'name'
            }
        ]

        const section_virtual_card_left = [
            {
                label: 'Email',
                value: 'email'
            }
        ]
        const destination_info_international_external_account_section_left = [
            {
                label: 'International Bank Account Number',
                value: 'accountNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Account Type',
                value: 'type'
            },
            {
                label: 'Swift Code',
                value: 'swiftCode'
            },
            {
                label: (swiftCodeDetails && <FxRoutingCodeLabel defaultValue={swiftCodeDetails} />) || '',
                value: 'internationalRoutingCode'
            }
        ]

        const destination_info_international_external_account_section_right = [
          {
            label: "Holder Name",
            value: "holderName",
          },
          {
            label: "Holder Type",
            value: "holderType",
          },
          {
            label: "Holder Address",
            value: "holderAddress",
            type: "fri://fieldtype/address",
          },
        ];

        /**
         * left column data for TRANSACTION DETAILS for Destination Details for card method and REFUND Type
         */
        const destination_external_card_left_section = [
          {
            label: "Auth Code",
            value: "authCode",
          },
          {
            label: "Card Brand",
            value: "brand",
          },
        ];

        /**
         * right column data for TRANSACTION DETAILS for Destination Details for card method and REFUND Type
         */
        const destination_external_card_right_section = [
          {
            label: "Card Number",
            value: "cardNumberLast4",
            type: "fri://fieldtype/accountnumber",
          },
        ];

        // Coulmns for Card refund
        const card_left_section_label = [
            {
                label: 'Contact Name',
                value: 'contactName'
            },
            {
                label: 'Card Number',
                value: 'cardNumber'
            }
        ];
        // Left Columns for Destination Information
        const card_left_terminal_section = [
            {
                label: 'Card Number',
                value: 'cardNumberLast4',
                type: "fri://fieldtype/accountnumber"
            }
        ];

        // Right Columns for Destination Information
        const card_right_terminal_section = [
            {
                label: 'Brand',
                value: 'brand'
            }
        ];
        
        const card_right_section_label = [
            {
                label: 'Card Holder Name',
                value: 'cardHolderName'
            },
            {
                label: 'Brand',
                value: 'cardBrand'
            }
        ];

        /**
         * Left column data for TRANSACTION DETAILS for Destination Details for BOOK method and Destination as Card
         */
        const book_card_left_section = [
            {
                label: 'Card External ID',
                value: 'externalId'
            }
        ]

        /**
         * Right column data for TRANSACTION DETAILS for Destination Details for BOOK method and Destination as Card
         */
        const book_card_right_section = [
            {
                label: 'Card ID',
                value: 'id'
            }
        ]



        /**
       * Method to transfrom data from api
       * @param data :data
       * @returns
       */
        function transformData(data: any) {
            const newData: any = {}
            newData['payeeName'] = scheduleInfo?.destination?.payeeName;
            newData['address'] = {
                'addressLine1': data.addressLine1,
                'addressLine2': data.addressLine2 ? data.addressLine2 : '',
                'city': data.city,
                'state': data.state,
                'zip': data.zip
            }
            return newData
        }

        // To get the Card info in case of refund
        if(scheduleInfo?.destination?.contact?.card && scheduleInfo?.method === 'CARD' && scheduleInfo?.type === 'REFUND') {
            cardInfo = {};
            const cardId = scheduleInfo?.destination?.contact?.card?.id;
            const selectedCard = destinationInfo?.card?.find((cardDetail: any) => cardDetail.id === cardId);
            if(selectedCard) {
                cardInfo['cardBrand'] = selectedCard?.brand;
                cardInfo['cardHolderName'] = selectedCard?.holderName;
                cardInfo['cardNumber'] = maskAccountNumber(selectedCard.cardNumberLast4, false);
                cardInfo['contactName'] = destinationInfo?.name || '';
            }

        }

        return (
            <>
                {scheduleInfo?.type === 'EXTERNAL' && (destinationInfo || scheduleInfo?.destination?.payeeName) && (scheduleInfo?.destination?.payeeName || destinationInfo?.addressLine1) && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-check-destination-information-check-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" rightcolumn={destination_info_check_section} leftcolumn={destination_info_check_left_section} dataTransformer={transformData} data={destinationInfo ?? scheduleInfo}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.type !== 'EXTERNAL' && destinationInfo && destinationInfo?.addressLine1 && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-check-destination-information-check-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_check_section} dataTransformer={transformData} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {destinationInfo?.resourceName === 'account' && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-check-destination-information-book-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={source_account_section} rightcolumn={source_account_section_two} dataTransformer={sourceDataTransformer} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {destinationInfo?.resourceName === 'contact' && scheduleInfo?.method === 'ACH' && !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={contact_section_left} rightcolumn={contact_section_right} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {destinationInfo?.resourceName === 'contact' && scheduleInfo?.method === 'WIRE' && !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-contact-destination-information-wire-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={contact_section_wire_left} rightcolumn={contact_section_wire_right} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {destinationInfo?.resourceName === 'contact' && scheduleInfo?.method === 'CHECK' && !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={contact_section_check_left} rightcolumn={contact_section_check_right} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {destinationInfo?.resourceName === 'contact' && scheduleInfo?.method === 'BOOK' && !destinationInfo?.ppi && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={contact_section_book_left} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.method === 'BOOK' && scheduleInfo?.destination?.card && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-card-destination-information-book-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={book_card_left_section} rightcolumn={book_card_right_section} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {destinationInfo?.resourceName === 'contact' && scheduleInfo?.method === 'VIRTUAL_CARD' && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={contact_section_virtual_card_left} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.destination?.externalAccount?.holderName && !scheduleInfo?.isTaxPayment && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-card-destination-information-wire-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_wire_section} rightcolumn={destination_info_wire_section_two} data={scheduleDestInfoCopy}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.destination?.externalAccount?.holderName && scheduleInfo?.isTaxPayment && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-card-destination-information-wire-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_tax_wire_left_section} rightcolumn={destination_info_tax_wire_right_section} data={scheduleDestInfoCopy}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.destination?.internationalExternalAccount?.holderName && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-card-destination-information-iea-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_international_external_account_section_left} rightcolumn={destination_info_international_external_account_section_right} data={scheduleInfo.destination.internationalExternalAccount}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.destination?.contact?.internationalExternalAccount && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-card-destination-information-iea-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_international_external_account_section_left} rightcolumn={destination_info_international_external_account_section_right} data={destinationInfo?.internationalExternalAccount}
                ></FxInfoCard></Grid>}
                {(scheduleInfo?.destination?.ppi || scheduleInfo?.destination?.contact?.ppi) && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-wallet-destination-information-ach-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={ppi_destination_section_left} data={scheduleInfo.destination.contact ? scheduleInfo.destination.contact : scheduleInfo.destination}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.destination?.externalAccount?.url && <FxInfoCard id={"send-details-card-destination-information-refund-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_card_section} rightcolumn={destination_info_external_account_section} data={destinationInfo}
                ></FxInfoCard>}
                {scheduleInfo?.destination?.internationalExternalAccount?.url && <FxInfoCard id={"send-details-card-destination-information-iea-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_info_international_external_account_section_left} rightcolumn={destination_info_international_external_account_section_right} data={destinationInfo}
                ></FxInfoCard>}
                {scheduleInfo?.method === 'VIRTUAL_CARD' && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-contact-destination-information-ach-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={section_virtual_card_left} data={destinationInfo}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.method === 'CARD' && scheduleInfo?.type === 'REFUND' && scheduleInfo?.processingDetail?.terminal?.id && scheduleInfo?.destination?.card && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-refund-card-destination-information-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={card_left_terminal_section} rightcolumn={card_right_terminal_section} data={scheduleInfo?.destination?.card}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.method === 'CARD' && scheduleInfo?.type === 'REFUND' && !scheduleInfo?.destination?.contact?.card && !scheduleInfo?.processingDetail?.terminal?.id && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-refund-card-destination-information-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={destination_external_card_left_section} rightcolumn={destination_external_card_right_section} data={scheduleInfo?.destination?.card}
                ></FxInfoCard></Grid>}
                {scheduleInfo?.method === 'CARD' && scheduleInfo?.type === 'REFUND' && scheduleInfo?.destination?.contact?.card && <Grid item xs={12} direction="column"> <FxInfoCard id={"send-details-refund-card-destination-information-" + scheduleId + "-section-info-card-component"} title="DESTINATION INFORMATION" leftcolumn={card_left_section_label} rightcolumn={card_right_section_label} data={cardInfo}
                ></FxInfoCard></Grid>}
            </>
        )
    })