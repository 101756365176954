/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Component:LeadPreFilledInviteInfoView
 * This component handles the  LeadPreFilledInviteInfoView card compoent
 */
import React from "react"; // we need this to make JSX compile
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { Grid, Typography } from "@mui/material";
import { FxInfoCard } from "../../Cards/FxInfoCard";
import FxCard from "../../../Container/FxCard";
import FxCardBody from "../../../Container/FxCardBody";
import FxCardHeader from "../../../Container/FxCardHeader";
import { addressFormatter, stateValueFormatter, truncateChars } from "../../../Utils/CommonBaseClass";
import moment from "moment";

type leadPreFilledInviteInfo = {
  id: string;
  data:any
}
export const LeadPreFilledInviteInfoView: React.FC<leadPreFilledInviteInfo> = React.memo((props) => {
  ({ props } = RegisterComponent(props)); 
  let prefilledData: any = {};
let authorizedSignatory: any = {};
let auditData:any={};
let business:any={};
const individual:any={};
if (props?.data?.business) {
   business = props?.data?.business;
  const formattedAddress=business?.mailingAddress? addressFormatter('', business?.mailingAddress?.[0]):'';
  const trucatedAddress=business?.mailingAddress?truncateChars(formattedAddress, 40):'';
  authorizedSignatory = (business?.beneficialOwner || []).find((item: any) => item.actAsAuthorizedSignatory === true) || {};

  prefilledData = {
    ...business,
    name: business?.legalName || '',
    address: business?.mailingAddress ? trucatedAddress : '',
    dateOfIncorp: business?.dateOfIncorporation ? moment(business.dateOfIncorporation).format('MM/DD/YYYY') : '',
    StateOfIncorporation:business?.stateOfIncorporation? stateValueFormatter(business?.stateOfIncorporation):'',
    customerType:props?.data?.customerType,
    email:business.email?truncateChars(business?.email,25):'',
    isPaperless:props?.data?.isPaperless?'True':'False',
    mobile: authorizedSignatory?.mobilePhone || ''
  };

  const created:any = props?.data['createdBy']['username'] + '\nOn ' + moment(props?.data['createdOn']).format('MM/DD/YYYY');
  const updated:any = props?.data['lastUpdatedBy']['username'] + '\nOn ' + moment(props?.data['lastUpdatedOn']).format('MM/DD/YYYY');
  auditData={
    lastUpdatedOn:updated,
    createdOn:created,
  }
  authorizedSignatory = {
    name: !!authorizedSignatory?.actAsAuthorizedSignatory && `${authorizedSignatory?.firstName ||''} ${authorizedSignatory?.lastName ||''}`,
    dob: authorizedSignatory?.dob ? moment(authorizedSignatory?.dob).format('MM/DD/YYYY') : '',
    primaryAddress: authorizedSignatory?.mailingAddress && addressFormatter('', authorizedSignatory?.mailingAddress?.find((address: any) => address.isPrimary === true)),
    secondaryIdentificationType: authorizedSignatory?.secondaryIdentification?.type || '',
    countryOfIssuance: authorizedSignatory?.countryOfIssuance || '',
    ssn: authorizedSignatory?.last4ssn || '',
    mobile: authorizedSignatory?.mobilePhone || '',
    homePhone: authorizedSignatory?.homePhone || '',
    workPhone: authorizedSignatory?.workPhone || '',
    email: authorizedSignatory?.email || '',
    secondaryIdentificationId: authorizedSignatory?.secondaryIdentification?.id || '',
    countryOfIssurance:authorizedSignatory?.secondaryIdentification?.countryOfIssuance,
    usCitizen: authorizedSignatory?.isUSCitizen ? 'Yes' : 'No',
  };
} else {
  const individual = props?.data?.individual;
  //const trucatedAddress=individual?.mailingAddress?truncateChars(formattedAddress, 25):'';

  prefilledData = {
    ...individual,
    name: individual?.firstName ? `${individual.firstName} ${individual.lastName}` : '',
    mobile: individual?.mobilePhone || '',
    homePhone: individual?.homePhone || '',
    WorkPhone: individual?.WorkPhone || '',
    dob:individual?.dob?individual?.dob:'',
    ssn:individual?.last4ssn?'**' +individual?.last4ssn:'',
    //address:individual?.mailingAddress? trucatedAddress:'',
    isPaperless:props?.data?.isPaperless?'True':'False',
    email:individual?.email? truncateChars(individual?.email,25):'',
    primaryAddress:individual?.mailingAddress && individual?.mailingAddress.findIndex((item:any)=>item.isPrimary)!==-1 ? addressFormatter('', individual?.mailingAddress?.find((address: any) => address.isPrimary === true)):''
  };

 const created:any = props?.data['createdBy']['username'] + '\nOn ' + moment(props?.data['createdOn']).format('MM/DD/YYYY');
 const updated:any = props?.data['lastUpdatedBy']['username'] + '\nOn ' + moment(props?.data['lastUpdatedOn']).format('MM/DD/YYYY');
  auditData={
    lastUpdatedOn:updated,
    createdOn:created,
  }
}

  const business_info_card_left = [
  
   
    {
      label: "Doing Business As",
      value: "doingBusinessAs",
    },
    {
      label: "Business Category",
      value: "businessCategory",
    },
    {
      label: "EIN",
      value: "ein",
    },
    {
      label: "State Of Incorporation",
      value: "StateOfIncorporation",
    },
    {
      label: "Website",
      value: "website",
    },
   
  ];

  const business_info_card_right = [
    {
      label: "Mobile",
      value: "mobile",
    },
    {
      label: "Email",
      value:<Grid title={business?.email }>{truncateChars(business?.email, 25)}</Grid>,
      type: 'fri://fieldtype/custom'
    },
    {
      label: "Is Paperless",
      value: "isPaperless",
    },
    {
      label: "Date Of Incorpartion",
      value: "dateOfIncorp",
    },
    {
      label: "Mailing Address",
      value: "address",
    },

  ];
  const authorized_signatory_info_card_left = [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "DOB",
      value: "dob",
    },
    {
      label: "Primary Address",
      value: "primaryAddress",
    },
    {
      label: "Secondary Identification Type ",
      value: "secondaryIdentificationType",
    },
    {
      label: "Country Of Issuance",
      value: "countryOfIssurance",
    },
  ];
  const audit_info_card_left = [
    {
      label: "Created",
      value: "createdOn",
    },
  ];
  const audit_info_card_right = [ 
    {
      label: "Last Updated",
      value: "lastUpdatedOn",
    },
  ];
 

  const authorized_signatory_info_card_right = [
    {
      label: "SSN",
      value: "ssn",
    },
    {
      label: "Mobile",
      value: "mobile",
    },
    {
      label: "Home Phone",
      value: "homePhone",
    },
    {
      label: "Work Phone",
      value: "workPhone",
    },
    {
      label: "Email",
      value: <Grid title={authorizedSignatory?.email}>{truncateChars(authorizedSignatory?.email, 25)}</Grid>,
      type: 'fri://fieldtype/custom'
    },
    {
      label: "Secondary Identification Id",
      value: "secondaryIdentificationId",
    },
    {
      label: "Is US Citizen",
      value: "usCitizen",
    },
  ];
   /**
   * configuring left card
   */
   const view_individual_section_left = [
    {
      label: "Name ",
      value: "name",
    },
    {
      label: "Date Of Birth",
      value: "dob",
      type: "fri://fieldtype/date",
      format: "MMM DD, yyyy",
    },
    {
      label: "Email",
      value:<Grid title={individual?.email }>{truncateChars(individual?.email, 25)}</Grid>,
      type: 'fri://fieldtype/custom'
    },
    {
      label: "Is Paperless",
      value: "isPaperless",
    },
    {
      label: "Primary Address",
      value: "primaryAddress",
    },
  ];

  /**
   * configuring right cards
   */
  const view_individual_section_right = [
    {
      label: "SSN",
      value: "ssn",
    },
    {
      label: "Mobile Phone",
      value: "mobilePhone",
    },
    {
      label: "Home Phone",
      value: "homePhone",
    },
    {
      label: "Work Phone",
      value: "workPhone",
    },
  ];

  return (
    <>
      <Grid
        id="lead-pre-filled-invite-info-Grid "
        className="fx-margin-bottom-16"
        item
        xs={12}
      >
        <FxCard id="lead-pre-filled-invite-info-card" className="fx-theme-passport">
          <>
            <FxCardHeader id={"lead-pre-filled-invite-info-card#card-header"}>
              <Grid
                id="lead-pre-filled-invite-info-Grid2"
                item
                container
                justifyContent="space-between"
                xs={12}
                alignItems={"center"}
              >
                <Grid id="lead-pre-filled-invite-info-Grid3">
                  <Typography id="title-lead-pre-filled-invite-info" variant="h3">
                    {props?.data?.business?props?.data?.business?.legalName:props?.data?.individual?.firstName }
                  </Typography>
                  <div
                    id="div-grow-title-lead-pre-filled-invite-info-list"
                    className="fx-flex-grow"
                  />
                </Grid>
              </Grid>
            </FxCardHeader>
            <FxCardBody
              id="lead-pre-filled-invite-info-card#card-body"
              className="fx-info-card fx-margin-top-reducer"
            >
              <Grid container item xs={12} direction="row">
                <>
                  <Grid item xs={12} direction="column">
                    <FxInfoCard
                      id={"lead-pre-filled-invite-info-left-fxbody-infocard"}
                      title={props?.data?.business?"BUSINESS INFO":"INDIVIDUAL INFO"}
                      leftcolumn={props?.data?.business?business_info_card_left:view_individual_section_left}
                      rightcolumn={props?.data?.business?business_info_card_right:view_individual_section_right}
                      data={prefilledData}
                    />
                  </Grid>
                </>
              </Grid>

             {props?.data?.business?.beneficialOwner && <Grid container item xs={12} direction="row">
                <>
                  <Grid item xs={12} direction="column">
                    <FxInfoCard
                      id={"lead-pre-filled-invite-info-right-fxbody-infocard"}
                      title="AUTHORIZED SIGNATORY INFORMATION"
                      leftcolumn={authorized_signatory_info_card_left}
                      rightcolumn={authorized_signatory_info_card_right}
                      data={authorizedSignatory}
                    />
                  </Grid>
             
                </>
              </Grid>
}

              <Grid container item xs={12} direction="row">
                <>
                  <Grid item xs={12} direction="column">
                    <FxInfoCard
                      id={"lead-pre-filled-invite-info-right-fxbody-infocard"}
                      title="AUDIT DETAILS"
                      leftcolumn={audit_info_card_left}
                      rightcolumn={audit_info_card_right}
                      data={auditData}
                    />
                  </Grid>
             
                </>
              </Grid>
            </FxCardBody>
          </>
        </FxCard>
      </Grid>
    </>
  );
});
