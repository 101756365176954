/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import FxCard from "../../../Container/FxCard";
import FxCardBody from "../../../Container/FxCardBody";
import clsx from "clsx";
import { checkPermission, maskAccountNumber, updateComponentParams } from "../../../Utils/CommonBaseClass";
import { FxCurrencyView } from "../../../Input/FxCurrency/FxCurrencyView";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as WhiteLightningIcon } from "../../../../assets/svg/lightning-icon 2.svg";
import { ReactComponent as DarkInfoIcon } from '../../../../assets/svg/darkgrey-info-icon.svg';
import { useHistory } from "react-router";
import { FxInfoTooltip } from '../../../Utils/FxInfoTooltip';
import FxModalGeneric from "../../../Utils/FxModalGeneric";
import { AvailFunds } from "../../Ledgers/AvailFundsCard";
import { getKey } from "../../../../libs/utils/storageManager";
import HttpClient from '../../../../libs/utils/httpClient';
import { Logger } from "../../../../libs/utils/logger";
import { useDispatch } from "react-redux";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import UIFeatureImpl from "../../../../libs/services/uiFeatures";
const httpClient = HttpClient.getClient();

export const LedgerSummaryActionCard: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props))
  const history = useHistory();
  const [isOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const uiFeat = UIFeatureImpl.getInstance();

  /**
   * Method handles the redirection from the 'View Details' button to the Faster Funds Details Screen
   */
  const redirectToFasterFundDetails = () => {
    history.push("/fasterfunds/view");
  };

  /**
   * Method handles the closing of the Avail Funds Modal
   */
  const handleCloseModal = ()=>{
    setModalOpen(false);
  }

  /**
   * Method handles the opening of the Avail Funds Modal
   */
  const handleAvailFund=()=>{
    setModalOpen(true);
  }


    /**
    * useEffect to load title while loading the header section
    */
  useEffect(() => {
    getAllMerchants();

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

/**
 * method to get all merchants link with customer
 */
  async function getAllMerchants() {
    const merchantIds:any =[];

    try{
      const merchants:any = await httpClient.get('customer/id/' + getKey("customerId") + '/merchant');
      merchants?.data?.map((item:any)=>{
        return (merchantIds.push(item.id))
    });

    // Calling the getMerchantSetting function to call the configuration/list api and get the setting value
   getMerchantSetting(merchantIds);


  } catch (err) {
    Logger.error("LedgerSummaryActionCard.tsx", "error", err);
  }
   
   
}

  /**
   * Method to get merchant settings
   * @returns setting
   */
  async function getMerchantSetting(merchantIds: any) {
    try {
      const quick_settle_settings_request = {
        "pageNumber": 1,
        "pageSize": 1,
        "sortOptions": {
          "sortBy": "lastUpdatedOn",
          "sortOrder": "desc"
        },
        "criteria": {
          "filters": [{
            "operator": "eq",
            "key": "category",
            "values": [
              "QUICK_SETTLE"
            ]
          }, {
            "operator": "in",
            "key": "entityName",
            "values": [
              "MERCHANT"]
          }, {
            "operator": "eq",
            "key": "hierarchyLevel",
            "values": [
              "ENTITY"]
          },
          {
            "operator": "in",
            "key": "hierarchyLevelEntityId",
            "values": merchantIds
          }]
        }
      }
      const settings = await httpClient.post('programManager/configuration/list', quick_settle_settings_request);
      if(merchantIds.length > 0 && settings?.data?.resources[0]?.value !== undefined)
        updateComponentParams(dispatch, props?.id, { 'fasterFunding': (settings?.data?.resources[0]?.value === "0") ? false : true });
    } catch (err) {
      Logger.error("LedgerSummaryActionCard.tsx", "error", err);
    }
  }


  

  return (
    <>
      <Grid id="ledger-summary-action-card-grid" xs={12} container item>
        <FxCard className={clsx("fx-theme-passport fx-account-summary-card")}>
          <FxCardBody>
            <Grid container className={"fx-account-info-header-title"}>
                  <Grid item className="fx-account-activity-header">
                    <Typography variant="h5" > {props?.accountData?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+' : props?.accountData?.type === 'CASH_BUILDER' ? 'Cash Builder' : 'Passport Checking'} {maskAccountNumber(props?.accountData?.accountNumber,false)}</Typography>
                  </Grid>
                </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              className="fx-account-activity-summary-header"
            >
              <Grid>
                <Grid container >
                  <Grid>
                    <Typography className="fx-ledger-summary-balance-text">AVAILABLE BALANCE</Typography>
                  </Grid>
                </Grid>
                  <Grid item className="fx-account-summary-currency">
                  <FxCurrencyView
                    className="balance-amount"
                    id="home-account-info-card-currencyview"
                    value={props?.accountData?.availableBalance?.amount?props?.accountData?.availableBalance?.amount:"0.00"}
                    prefix={"$"}
                  />
                </Grid>
              </Grid>
              {getKey("selectedAccount")?.type !== 'DEPOSIT' && uiFeat.getFasterFunding().available && props?.fasterFunding && <Grid item className="fx-faster-funds-section">
                <Grid item container alignItems={'center'} xs={'auto'} className={'fx-available-faster-funds'}>
                  <Typography variant="h6" className={'fx-available-faster-funds-title'}>
                    Faster Funds Available: ${props?.data?.fasterFundsAvailable && props?.data?.fasterFundsAvailable >= 0 ? props?.data?.fasterFundsAvailable : "0.00"}
                  </Typography>
                    <FxInfoTooltip arrow placement={'bottom'}
                                   className={'fx-available-faster-funds-info-tooltip'}
                                   title={'Your available Faster Funds is a calculation based on several factors assessed by Priority and may change over time'}>
                        <DarkInfoIcon className="fx-dark-grey-icon fx-hover-cursor-pointer" />
                    </FxInfoTooltip>
                </Grid>
                <Grid className="fx-ledger-summary-action-card-buttons">
                  {getKey("isProxyLogin") && <Grid>
                    <FxButton
                      id={'fx-ledger-detail-view-button'}
                      className="fx-account-insight-ac-statement-btn"
                      onClick={redirectToFasterFundDetails}
                    >
                      VIEW DETAILS
                    </FxButton>
                  </Grid>}
                  {checkPermission({entity: "Accounts", name: "Faster Funds", operation: 'Avail'}) && <Grid>
                    <FxButton id={'fx-ledger-detail-avail-button'} className="fx-button fx-button-theme fx-thunder-icon-svg" onClick={handleAvailFund}>
                      <WhiteLightningIcon /> ACCESS FUNDS
                    </FxButton>
                    <FxModalGeneric
                      id="avail-funds-modal"
                      open={isOpen}
                      onClose={handleCloseModal}
                      componentProps={props.data}
                      className={'fx-modal-small2medium'}
                      component={AvailFunds}
                    >
                    </FxModalGeneric>
                  </Grid>}
                </Grid>
              </Grid>}
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </>
  );
});
