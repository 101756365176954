/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import HttpClient from '../../../../libs/utils/httpClient';
import { getCustomerUrl, useIsMobileScreen } from '../../../../libs/utils/utils';
import { FxLink } from '../../../Action/FxLink';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import FxNoGridDataImage from '../../../Data/FxNoGridDataImage';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import { FxSkeltonList } from '../../Cards/FxSkelton';
import { FxAccessDenied } from './FxAccessDenied';
import { checkPermission } from '../../../Utils/CommonBaseClass';
import { ReactComponent as ArrowPermsDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as ArrowPermsUp } from '../../../../assets/svg/triangle-up-icon.svg';
import { Logger } from '../../../../libs/utils/logger';

/**
 * This component handles the Daily Transactions Summary
 */
const httpClient = HttpClient.getClient();

interface IDailyTransactionSummary {
    id: string;
    selectedAccountId: number
}

interface ITransactionInfo {
    amount: string;
    count: number;
}

interface IResponseData {
    card: {
        batch: ITransactionInfo;
        refund: ITransactionInfo;
        sale: ITransactionInfo;
        fee: ITransactionInfo;
    };
    ach: {
        credit: ITransactionInfo;
        debit: ITransactionInfo;
        fee: ITransactionInfo;
    };
    check: {
        credit: ITransactionInfo;
        debit: ITransactionInfo;
        fee: ITransactionInfo;
    };
    book: {
        debit: ITransactionInfo;
        fee: ITransactionInfo
    }
    debitCard: {
        authHold: ITransactionInfo;
        charge: ITransactionInfo;
        fee: ITransactionInfo;
    };
    wire: {
        debit: ITransactionInfo;
        fee: ITransactionInfo;
    };
    internationalWire: {
        debit: ITransactionInfo;
        fee: ITransactionInfo;
    }
}

const titleByKey: Record<keyof IResponseData, string> = {
    card: 'card',
    ach: 'ach',
    check: 'check',
    book: 'book',
    debitCard: 'debit card',
    wire: 'wire',
    internationalWire: 'intl wire'
}

const titleByNestedKeys: Record<string, string> = {
    sale: 'sales',
    refund: 'refunds',
    instantFunded: 'instant funding transfer fees',
    credit: 'credits',
    fee: 'fees',
    charge: 'charge',
    authHold: 'auth hold',
    debit: 'debits',
    fasterFunded: 'fees (faster funding accessed)'
}

const daySelectOptions = [
    { label: 'TODAY', value: 'today' },
    { label: 'YESTERDAY', value: 'yesterday' }
];

const yesterday = moment().subtract(1, 'days');

const dates = {
    today: [moment().format('MMM DD, YYYY HH:mmA'), moment().format('MM/DD/YYYY')],
    yesterday: [yesterday.format('MMM DD, YYYY'), yesterday.format('MM/DD/YYYY')]
}

const summaryFields:Array<keyof IResponseData> = ['card', 'debitCard', 'ach', 'check', 'book', 'wire', 'internationalWire']
export const DailyTransactionSummary: React.FC<IDailyTransactionSummary> = React.memo(({id, selectedAccountId}) => {
    const { register, setValue, control, watch } = useForm();
    const [data, setData] = useState<IResponseData>({} as IResponseData);
    const [isLoading, setIsLoading] = useState(false);
    const [summaryOpen, setSummaryOpen] = useState(false);
    const isSmallScreen = useIsMobileScreen();



    /**
     * useEffect hook. Executed on component mount and when either 'day' or 'selectedAccountId' are updated.
     */
    useEffect(() => {
        /**
         * Async function to fetch the day report. Uses the selected date and account ID.
         */
        async function getDailyReport() {
            const day = dates[watch('day') as keyof typeof dates] || dates.today;
            const reqBody = {
                reportDate: day[1]
            }
            setIsLoading(true);
            try {
                const res = await httpClient.post(getCustomerUrl(`account/id/${selectedAccountId}/dayreport`, false), reqBody);
                const data: IResponseData = res.data;
                setData(data);
            } catch (e) {
                Logger.error("DailyTransactionSummary.tsx", "error", e);
            } finally {
                setIsLoading(false);
            }
        }
        getDailyReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('day'), selectedAccountId]);

   /** 
    * to set permission
   */
   const permission = useMemo(() => {
    return checkPermission({
        entity: "Accounts",
        name: "Ledger",
        operation: "view"
        });
    }, []);

    //Method to handle open/close accordion
    const handleAccordionChange = () =>{
        setSummaryOpen(!summaryOpen);
    }

    return (
        <Grid className={'fx-daily-transaction-summary'} id={id} item xs={12}>
            <FxCard id={'daily-transaction-summary-card'} className="fx-theme-passport fx-quick-transfer-header">
            <Accordion expanded={summaryOpen} onChange={() => handleAccordionChange()}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Grid item xs={permission? isSmallScreen ? 12:8 : 12} sm={12}>
                    <Grid direction='row' container justifyContent="space-between" alignItems={'baseline'}>
                      <Grid item xs={9}>
                        <Typography className="fx-quick-transfer-header-text">
                        Daily Account Summary
                        </Typography>
                      </Grid>
                      {<Grid item className='fx-dashboard-toggle-icon'>
                        {!summaryOpen ? <ArrowPermsUp /> : <ArrowPermsDown />}
                      </Grid>}
                    </Grid>
                  </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {permission? <>
                    <Grid item sm={12} className='fx-quick-transfer-header-summary'>
                    <Grid direction='row' container justifyContent="space-between">
                      <Grid item xs={8}>
                      <Typography className="fx-daily-transaction-summary-date fx-header-text">As of {dates[watch("day") as keyof typeof dates]?.[0] || dates.today[0]}</Typography>
                      </Grid>
                        {permission && <Grid item xs={4} className={'fx-daily-transaction-summary-day-select'}>
                        <FxMaterialSelect register={{ ...register('day') }}
                                          id={'daily-summary-day-select'}
                                          name="day"
                                          data={daySelectOptions}
                                          value={'today'}
                                          control={control}
                                          setValue={setValue} />
                       </Grid>}
                    </Grid>
                  </Grid>
                <FxCardBody id={'daily-transaction-summary-card-body'} className="fx-daily-transaction-summary-card-body">
                    {!isLoading ? (Object.entries(data).length ? summaryFields.map((key)=> {
                        const value = data[key];
                        if (!value) return <></>;
                        const title = key === 'card' ? `${titleByKey[key]} (${(value as IResponseData['card']).batch.count} batches)` : titleByKey[key];
                           return <Grid className='fx-daily-transaction-summary-list-item'>
                                <Typography className='fx-daily-transaction-summary-list-headerText fx-header-text'>
                                    {title}
                                </Typography>
                                <Grid xs={12} container item className={'fx-daily-transaction-summary-list-item-content'}>
                                    {(Object.entries(value) as Array<[string, ITransactionInfo]>).map(([nestedKey, nestedValue])=>(
                                        titleByNestedKeys[nestedKey] &&
                                        <Grid item xs={4}>
                                            <Typography>
                                                <span className={'fx-text-ellipsis'}>
                                                {nestedKey === 'fee' && key === 'card' ? titleByNestedKeys.fasterFunded : (titleByNestedKeys[nestedKey] || '')}
                                                </span>
                                                &nbsp;{`(${nestedValue.count})`}
                                            </Typography>
                                            <FxCurrencyView value={nestedValue.amount || 0} prefix={"$"} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>})
                        :
                        <Grid item className='fx-no-data'>
                            <FxNoGridDataImage />
                            <Typography>No data to show</Typography>
                        </Grid>)
                        :

                        <FxSkeltonList height="25rem" />
                    }
                </FxCardBody>
                <FxCardFooter id="daily-transaction-summary-card-footer" className="fx-daily-transaction-summary-card-footer">
                    <Typography>* Daily account summary only contains originations from your Passport Account. To view the transaction activity for returns, disputes, ext credits/debits navigate to <FxLink redirect="/accountledgers">account activity</FxLink>.</Typography>
                </FxCardFooter>
                </>
                :  <FxAccessDenied id={'recent-ledger-list-access-denied'} title={'You are not authorized to access this card'} />}
            </AccordionDetails>
            </Accordion>
            </FxCard>
        </Grid>
    );
});
