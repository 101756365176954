/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Logger } from "../../../libs/utils/logger";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { FxButtonGroup } from "../../Action/FxButtonGroup";
import { useHistory } from "react-router";
import { FxFundingEnableCard } from "./FxFundingEnableCard";

Logger.debug(
  "FundingRuleQueue.tsx",
  "Funding Rule Queue Listing initializing"
);

/**
 * Component: FundingRuleQueue
 * Usage: Listing of the Active Funding Rule Queue
 */
export const FundingRuleQueue: React.FC<any> = React.memo((props) => {
  const [listingType, setListingType] = useState("active");
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * API for Active Funding Rules listing
  */
  const src = {
    url: "/fundingRule/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 25,
      sortOptions: {
        "sortBy": "lastUpdatedOn",
        "sortOrder": "desc"
      },
      criteria: {
        filters: [
          {
            operator: "eq",
            key: "status",
            values: ['ACTIVE'],
          }
        ],
      },
    },
  };

  //type of Funding Rules Listing Options
  const typeData = [
    { id: 'active', value: 'ACTIVE', label: 'ACTIVE', selected: true, path: '/fundingrules/active' },
    { id: 'inactive', value: 'INACTIVE', label: 'INACTIVE',  selected: false, path: '/fundingrules/inactive' } 
   ];

  /**
   * useEffect to load title while loading page
   */
  useEffect(() => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: { id: "page-title-details", data: { title: "Funding Rules" } },
    });
    // eslint-disable-next-line array-callback-return
  }, [dispatch]);

  /**
  * Method handles the onchange of the Funding Rules Queue List type
  */
  const handleTypeChange = (event: any, updatedFromModal = false) => {
    if (listingType !== event.id) {
      setListingType(event.id);
      history.push(event.path);
    }
  };

  const merchant_request = {
    url: "merchant/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      criteria: {
        filters: [],
      },
    },
  };

  return (
    <Grid id="active-funding-rule-queue-grid" item xs={12}>
      <Grid className="fx-tab-border">
        <FxButtonGroup
          id="active-funding-rule-queue-list-card-button-group"
          options={typeData}
          onClick={(event: any) => handleTypeChange(event)}
        />
      </Grid>

      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <FxFundingEnableCard id="Funding-rule-enable-card" src={src} listingType={listingType} source={merchant_request}/>
    </Grid>
  );
});
