/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useMemo, useState } from 'react'; // we need this to make JSX compile
import { Grid, Divider, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import { ISource } from '../../../types/common.interfaces';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { AUTH_STRINGS } from '../../../constants/strings';
import { IFileInfo } from '../../Data/FxAgreementLinkCard';
import { FxAgreementLinkCard } from '../../Data/FxAgreementLinkCard';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import FxRadioEdit from '../../Input/FxRadio/FxRadioEdit';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { addressFormatter } from '../../Utils/CommonBaseClass';
import { getKey } from '../../../libs/utils/storageManager';
import FxSnackBar from '../../Utils/fx-snack-bar';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
const httpClient = HttpClient.getClient();

interface InputErrType {
    type: string;
    message: string;
}

Logger.debug("RequestDebitCard.tsx", "add debit card initializing")

/**
 * Component used to request debit card for the customer
 */
export const RequestDebitCard: React.FC<any> = React.memo((props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
        const history = useHistory();
        const dispatch = useDispatch()
        const [expressDelivery, setExpressDelivery] = useState(false);
        const [customerDetails, setCustomerDetails] = useState<any>('');
        const [cardHolderData, setCardHolderData] = useState<any>('');
        const [addressId, setAddressId] = useState<any>('');
        const [isLoading, setIsLoading] = useState(false);
        const [fileInfo, setFileInfo] = useState<IFileInfo[]>();
        const [btnDisabled, setBtnDisabled] = useState(true);
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available

        //rest the component
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'request-debitCard-card-holder' } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'request-debitCard-card-type' } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Request Debit card',backButton:'/dashboard' } }});
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const cardDetails: any = useMemo(() => {
            const data: any = { ...cardHolderData }
            if (data.last4ssn) {
                data['actAsAuthorizedSignatory'] = data.actAsAuthorizedSignatory === true ? 'Yes' : 'No';
                data['last4ssn'] = data.last4ssn && '**' + data.last4ssn
                data['phone'] = data?.homePhone ? data.homePhone : (data?.workPhone ? data.workPhone : (data?.mobilePhone ? data.mobilePhone : ''))
                data['shippingAddress'] = data.mailingAddress && addressFormatter("", data.mailingAddress[0])
                setAddressId(data.mailingAddress && data.mailingAddress[0]?.id)
            }
            return data
        }, [cardHolderData]);

        const customer_src: ISource = {
            url: "/",
            method: "GET"
        }

        const card_type_src: ISource={
            url: "account/id/" + getKey("selectedAccount")?.id +"/debitCard/program/list",
            method: "POST",
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "sortOptions": {
                    "sortOrder": "DESC",
                    "sortBy": "createdOn"
                },
                "criteria": {
                    "filters": [ ]
                }
            }
        }

        const expressDeliveryData: any = [
            { label: 'Yes', value: 'YES' },
            { label: 'No', value: 'NO' }
        ];

        const card_holder_section_left = [
            {
                label: 'Card Holder Name',
                value: 'fullName'
            },
            {
                label: 'SSN',
                value: 'last4ssn'
            },
            {
                label: 'Act as Authorized Signatory',
                value: 'actAsAuthorizedSignatory',
            }
        ]

        const card_holder_section_individual_left = [
            {
                label: 'Card Holder Name',
                value: 'fullName'
            },
            {
                label: 'SSN',
                value: 'last4ssn'
            }
        ]

        const card_holder_section_right = [
            {
                label: 'Email',
                value: 'email'
            },
            {
                label: 'Mobile Phone',
                value: 'phone'
            },
            {
                label: 'Shipping Address',
                value: 'shippingAddress'
            }
        ]


        // debit terms & condition options
        const debitCardTerms = [
            { label: 'I Agree to the Passport Debit Card Terms and Conditions', value: 'yes' }
        ];

        /**
         * Method used to call request debit card API
         * @param paylaoddata :form data
         * @returns
         */
        async function requestDebitCard(paylaoddata: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('account/id/' + getKey("selectedAccount")?.id + '/debitCard',false), paylaoddata).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("RequestDebitCard.tsx", "error", err);
                return false;
            }
        }

        /**
         * Method used to format request to add mailing address
         * @param data :form data
         */
        async function onSubmit(data: any) {
          const linkedDocuments = fileInfo?.map((file: IFileInfo) => {
            return {
              purpose: 'DEBIT_CARD_AGREEMENT',
              document: {
                type: file?.fileType,
                name: 'Terms&Condition.pdf',
                base64encodedContent: file?.base64
              }
            }
          })
            const request: any = {
                type: 'DEBIT',
                shippingDetail: {
                    "address": {
                        "id": addressId
                    },
                    "expressDelivery": expressDelivery
                },
                linkedDocument: linkedDocuments,
                cardProgram:{
                    id:data?.cardType
                }
            }
            if (businessCustomer) {
                request['cardholder'] = {
                    "type": "BENEFICIAL_OWNER",
                    "id": data.cardHolder
                }
            }

            setIsLoading(true);
            let status: any = await requestDebitCard(request);
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                //api success
                setTimeout(() => {
                    history.push("/thanks/manage/debit-card");
                }, 400)
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        /**
         * Function to transform Api data
         */
        function customerDataTransformation(data: any) {
            setCustomerDetails(data?.business?.beneficialOwner)
            try {
                const optionData = data?.business?.beneficialOwner?.map(function (option: any) {
                    return { value: option.id, label: option.fullName + ' (' + option.id + ')' };
                })
                return optionData
            }
            catch (e) { 
                Logger.error('RequestDebitCard.tsx','error',e);
            }
        }

         /**
         * Function to transform card type Api data
         */
        function cardTypeTransformation(data:any){
            try {
                const optionData = data?.map(function (option: any) {
                    return { value: option.id, label: option.cardName};
                })
                return optionData
            }
            catch (e) { 
                Logger.error('RequestDebitCard.tsx','error',e);
            }
        }

        function individualCustomerDataTransformer(data: any) {
            const customerData = data.individual
            const newData: any = {}
            newData['fullName'] = customerData.fullName
            newData['last4ssn'] = customerData.last4ssn && '**' + customerData.last4ssn
            newData['email'] = customerData.email
            newData['phone'] = customerData?.homePhone ? customerData.homePhone : (customerData?.workPhone ? customerData.workPhone : (customerData?.mobilePhone ? customerData.mobilePhone : ''))
            newData['shippingAddress'] = customerData.mailingAddress && addressFormatter("", customerData.mailingAddress[0])
            setAddressId(customerData.mailingAddress && customerData.mailingAddress[0]?.id)
            return newData
        }

        //handle change radio
        const handleChangeRadio = (event: any) => {
            setExpressDelivery(event.target.value === 'YES');
        }
        /**
         * Method to get onchange value
         * @param event :event
         */
        const handleChangeSelection = (event: any) => {
            // eslint-disable-next-line array-callback-return
            customerDetails !== undefined && customerDetails.map((item: any) => {
                if (item.id === event?.target?.value) {
                    setCardHolderData(item)
                }
            })
        }
        /**
         * Method to handle form change events
         * @param event :event object
         */
           const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target.checked === true) {
                setBtnDisabled(false)
            }
            else {
                setBtnDisabled(true)
            }
        }

        // Error render function
        const renderError = (err: InputErrType): string => {
            if (err.type === 'required') {
                return AUTH_STRINGS.ERRORS.AGREE_PLEASE_ENTER;
            }
            return err.message;
        };

        return (
            <Grid container xs={12} sm={8} alignItems="flex-start" justifyContent="space-between" className="fx-container fx-form-grid"  >
                <Grid id="request-debit-card-info-Grid" container item xs={12}>
                    <Grid container spacing={1} >
                        <FxCard id="request-debit-card-info-card" className="fx-theme-passport">
                            <div className="fx-form-edit-profile flex column">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FxCardHeader id={'request-debit-card-info-card#card-header'}>

                                    </FxCardHeader>
                                    <FxCardBody id="request-debit-card-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                                        <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                            {businessCustomer && <Grid item xs={12}>
                                                <FxMaterialSelect register={{ ...register("cardHolder") }} control={control} rules={{ required: true }} className={errors.cardHolder ? "border-error-input fx-input-edit" : "fx-input-edit"} id="request-debitCard-card-holder" dataTransformation={customerDataTransformation} onChange={handleChangeSelection} source={customer_src} name="cardHolder" label={"Select Card Holder *"} setValue={setValue} noResources={true} />
                                            </Grid>}
                                            <Grid item xs={12}>
                                                <FxMaterialSelect register={{ ...register("cardType") }} control={control} rules={{ required: true }} className={errors.cardType ? "border-error-input fx-input-edit" : "fx-input-edit"} id="request-debitCard-card-type" dataTransformation={cardTypeTransformation} source={card_type_src} name="cardType" label={"Type *"} setValue={setValue}/>
                                            </Grid>
                                            <Grid item container xs={12} justifyContent="space-between" >
                                                <FxRadioEdit register={{ ...register("expressDelivery") }} control={control} className={"fx-input-edit"} data={expressDeliveryData} id="expressDelivery" name="expressDelivery" label="EXPRESS DELIVERY (ADDITIONAL CHARGES WILL BE APPLICABLE)" row="vertical" defaultValue={'NO'} setValue={setValue} onChange={handleChangeRadio} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            {businessCustomer && <Grid item xs={12}>
                                                <FxInfoCard id={"card-holder-information-info-card"} title='CARD HOLDER DETAILS' leftcolumn={card_holder_section_left} rightcolumn={card_holder_section_right} data={cardDetails} />
                                            </Grid>}
                                            {individualCustomer && <Grid item xs={12}>
                                                <FxInfoCard id={"card-holder-information-info-card"} notiltle={true} leftcolumn={card_holder_section_individual_left} rightcolumn={card_holder_section_right} dataTransformer={individualCustomerDataTransformer} source={customer_src} />
                                            </Grid>}
                                          <Grid item container xs={12} spacing={2}>
                                            <Grid item xs={12}>
                                              <FxAgreementLinkCard
                                                agreementTypes={['DebitCard']}
                                                onFileInfoReady={setFileInfo}
                                              />
                                            </Grid>
                                            <Grid item container xs={12} className="flex column fx-kyc-agree-label">
                                              {!!fileInfo?.length && <Grid item xs={12} className="">
                                                  <FxCheckEdit register={{ ...register("debitCardTerms") }} rules={{ required: "Please click Agree" }} control={control} id="debit-terms-condition" name="debitCardTerms" className="fx-privacy-text" data={debitCardTerms} row="vertical" onChange={(e: any) => { handleChange(e); }} setValue={setValue} />
                                              </Grid>}
                                              <Grid item xs={12} className={'error-message'}>
                                                {errors.debitCardTerms && renderError(errors.debitCardTerms)}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                    </FxCardBody>
                                    <FxCardFooter id="request-debit-Card-Footer" className="fx-footer">
                                        <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                            <Grid item >
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className={btnDisabled ? "fx-button fx-button-disabled" :"fx-button fx-button-theme"}
                                                    id="request-debit-card-submit"
                                                    type="submit"
                                                    disabled={btnDisabled}
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        'REQUEST'
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </Grid>
                                    </FxCardFooter>
                                </form>
                            </div>
                        </FxCard>
                    </Grid>
                </Grid >
            </Grid>
        )
    })
