/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FundingRulesList } from './FundingRulesList';

/**
 * The is to check whether merchant exists for customer
 */
export const FxFundingEnableCard: React.FC<any> = React.memo((props) => {

    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();

    /**
     * useEffect to set merchant
     */
    useMemo(() => {
        if (context && context?.data && context?.data?.body) {
            updateComponentParams(dispatch, props?.id, { 'enableCard': context?.data?.body?.resources?.length > 0 ? true : false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context?.data?.body])


    return (
        <FundingRulesList src={props?.src} listingType={props?.listingType} />
    )
})