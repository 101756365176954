/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add-new-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete-icon.svg";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { renderError, updateComponentParams } from "../../../Utils/CommonBaseClass";
import { FxTextEdit } from "../../../Input";
import { ISource } from "../../../../types/common.interfaces";
import { accountLabelTransformation } from "../../Schedules/ScheduleGlobalFunctions";
import { FxAutoComplete } from "../../../Input/FxAutoComplete/FxAutoComplete";
import UIFeatureImpl from "../../../../libs/services/uiFeatures";
import { Logger } from "../../../../libs/utils/logger";

/**
 * Component: Funding Rules Section - Fields and Buttons
 * Usage: To display Split rules Section of Funding Rules Creation Screen
 */
export const FxFundingRuleCard: React.FC<any> = React.memo((props) => {
  const dispatch = useDispatch();
  const [categoryType, setCategoryType] = useState<any>("");
  const [selectedAccount, setSelectedAccount] = useState<any>("");
  const [percentageError, SetPercentageError]  = useState<any>('');
  const enableCard = useSelector((state: any) => state.data['Funding-rule-enable-card']?.params?.enableCard || false);
  const uiFeat = UIFeatureImpl.getInstance();
  const checkIsPartner=uiFeat.getPartnerFeature().available;  


  useEffect(()=>{
    if(props?.value){
      setCategoryType(props?.value?.category);
      setSelectedAccount(props?.value?.account);
    }
  }, [props?.data, props?.value])

  /**
   * Category dropdown items
   */
   const categoryItems = enableCard ? [
    { value: checkIsPartner ? "partner" : "my_account", label: checkIsPartner ? "Partner" : "My Account", disabled: false },
    { value: checkIsPartner ? "customer" : "my_contact", label: checkIsPartner ? "Customer" : "My Contact", disabled: false },
    { value: "dynamic", label: "Pick from Tag", disabled: !props?.onlyCard }
  ] : [
    { value: checkIsPartner ? "partner" : "my_account", label: checkIsPartner ? "Partner" : "My Account", disabled: false },
    { value: checkIsPartner ? "customer" : "my_contact", label: checkIsPartner ? "Customer" : "My Contact", disabled: false },
    ...checkIsPartner ? [{ value: "dynamic", label: "Pick from Tag", disabled: !props?.onlyCard }]:[]
  ];


  /**
   * Variable Maps To dropdown options
   */
  const variableMappingOptions = [
    { value: "ppi", label: "PPI" },
    { value: "accountId", label: "Account ID" }
  ];

  /**
   * On Change of the Category Dropdown Value change the categoryType state to dynamic if "Map Dynamically From" option is selected
   * @param event
   */
  const setCategory = (event: any) => {
    props.setValue(`funding-rule-split-rule.${props.index}.account`, '');
    if (event.target.value === "dynamic") {
      setCategoryType("dynamic");
    } else {
      setCategoryType(event.target.value);
    }
  };

  /**
   * reset the component
   * */
  useEffect(() => {
    dispatch({
      type: "DATA_COMPONENT_RESET",
      payload: { id: "funding-rule-split-rule-form" },
    });
    updateComponentParams(dispatch, props?.id, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acc_src: ISource = {
    url: "account/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      sortOptions: {
        sortOrder: "DESC",
        sortBy: "createdOn",
      },
      criteria: {
        filters: [
          {
            key: "status",
            operator: "nin",
            values: ["INACTIVE"],
          },
        ],
      },
    },
  };

  const contact_src = {
    url: "contact/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 5,
      criteria: {
        filters: [
          {
            key: "allowedMethod",
            operator: "eq",
            values: ["BOOK"],
          },  
        ],
      },
    },
  };

  const destinationFilterOptions: any = [
    { label: "Name", value: "name", operator: "like" },
  ];

  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  function destinationTransformer(data: any) {
    return (
      data?.map((item: any) => ({
        label: item?.name + " (ID: " + item?.ppi + ")",
        value: item.id,
      })) || []
    );
  }

  const handleChange = (event: any) => { };

  /**
   * Function transforms data for source account
   * @param data : data
   * @returns : transformed data
   */
  function dataDestinationTransformation(data: any) {
    return data
      ?.filter(
        (option: any) =>
          option.status === "ACTIVE" && option.type !== "DEPOSIT"
      )
      .map((item: any) => ({
        value: item.id,
        label: accountLabelTransformation(item),
      }));
  }

  //Validation for percentage field
  const setPercentageValidation = {
    required: false,
    max: {
        value: 100,
        message: 'Percentage should not be more than 100%'
    }
  }
  /**
   * Method to render percentage field error
   * @param err
   */
    const handlePercentageChange = (event: any) =>{
      try{
        const { name, value } = event.target;
        const max = 100;
        if (value <= max){
            props?.clearErrors(name, value)
            SetPercentageError('');
        } else {
          props?.setError(name, {
                type: "manual",
                message: 'Percentage should not be more than 100%'
            });
            SetPercentageError('Percentage should not be more than 100%');
        }
        props?.setValue(name, value)
    }
    catch(e){
      Logger.error('FxFundingRuleCard.tsx','error',e);
    }
    }

  return (
    <Grid
      container
      item
      direction="row"
      className="fx-form-edit-profile"
      spacing={2}
      key={"rule" + props.index}
    >
        {/* Serial Number */}
        <Grid item xs={"auto"} display={"inline-flex"} alignItems={"center"}>
          <Typography className="fx-summary-title" variant="h3">
            {props.index + 1}.
          </Typography>
        </Grid>

        {/* Configure Reciever's Amount */}
        <Grid
          item
          xs={5}
          sm={5}
          justifyContent={"flex-start"}
          className={"fx-approval-policy-condition fx-create-approval-policy"}
          id={"add-split-rule-conditions-reciever-configurations" + props.index}
          key={props.index}
          marginRight={'1rem'}
        >
          {props.index === 0 && <Grid
            container
            item
            direction="row"
            className="fx-form-edit-profile"
            paddingBottom={"1.125rem"}
          >
            <Typography variant="subtitle1" className="fx-new-invite-sub-label">
              Select Receiver's Account
            </Typography>
          </Grid>}
          <Grid
            container
            item
            direction="row"
            className="fx-form-edit-profile"
            marginTop={"1rem"}
            spacing={"1.125rem"}
          >
            <Grid
              container
              direction="row"
              className="fx-form-edit-profile"
              spacing={2}
              paddingLeft={"1.5rem"}
            >
              <Grid item xs={5}>
                <FxMaterialSelect
                  register={{
                    ...props.register(
                      `funding-rule-split-rule.${props.index}.category`
                    ),
                  }}
                  className={
                    props.errors.category
                      ? "border-error-input fx-input-edit"
                      : "fx-input-edit"
                  }
                  id={`funding-rule-split-rule.${props.index}.category`}
                  name={`funding-rule-split-rule.${props.index}.category`}
                  data={categoryItems}
                  placeholder="Select"
                  control={props.control}
                  rules={{ required: true }}
                  label="Transfer To*"
                  setValue={props.setValue}
                  onChange={setCategory}
                  value={categoryType}
                />
              </Grid>

              {(categoryType !== "dynamic") ? (
                (categoryType === "my_contact") ? (
                  <Grid item xs={7}>
                    <FxAutoComplete
                      register={{
                        ...props.register(
                          `funding-rule-split-rule.${props.index}.contact`
                        ),
                      }}
                      control={props.control}
                      rules={{ required: false }}
                      id={`funding-rule-split-rule.${props.index}.contact`}
                      name={`funding-rule-split-rule.${props.index}.contact`}
                      source={contact_src}
                      searchBy={"name"}
                      className={
                        props.errors.contact
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit fx-funding-select-contact"
                      }
                      dataTransformer={destinationTransformer}
                      setError={props.setError}
                      clearError={props.clearErrors}
                      setValue={props.setValue}
                      resetField={props.resetField}
                      value={props?.value?.contact ? {label: props?.value?.contact?.label, value: props?.value?.contact?.value} : null}
                      filterOptions={destinationFilterOptions}
                      placeholder={"Search Contact..."}
                      isEditable={true}
                      setParam={handleChange}
                    />
                  </Grid>
                  ) : (categoryType === 'partner' || categoryType === 'customer' || checkIsPartner) ?
                  (<Grid item xs={7} justifyContent={"flex-start"}>
                    <FxTextEdit
                      register={{
                        ...props.register(
                          `funding-rule-split-rule.${props.index}.tag`
                        ),
                      }}
                      className={
                        props.errors.tag
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit"
                      }
                      id={`funding-rule-split-rule.${props.index}.tag`}
                      name={`funding-rule-split-rule.${props.index}.tag`}
                      control={props.control}
                      rules={{ required: true }}
                      label="Account Tag*"
                      setValue={props.setValue}
                      isEditable={categoryType === 'partner' || categoryType === 'customer'}
                    />
                  </Grid>)
                  : !checkIsPartner ? (
                  <Grid item xs={7}>
                    <FxMaterialSelect
                      control={props.control}
                      rules={{ required: true }}
                      register={{
                        ...props.register(
                          `funding-rule-split-rule.${props.index}.account`
                        ),
                      }}
                      readOnly={categoryType !== 'my_account'}
                      id={`funding-rule-split-rule.${props.index}.account`}
                      name={`funding-rule-split-rule.${props.index}.account`}
                      dataTransformation={dataDestinationTransformation}
                      source={acc_src}
                      label="Select Account*"
                      value={selectedAccount}
                      setValue={props.setValue}
                      onChange={(event)=> setSelectedAccount(event.target.value)}
                    />
                  </Grid>
                ):(<></>)
              ) : (
                <Grid
                  container
                  item
                  xs={7}
                  display={"flex"}
                  spacing={"1rem"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={6} justifyContent={"flex-start"}>
                    <FxTextEdit
                      register={{
                        ...props.register(
                          `funding-rule-split-rule.${props.index}.variableName`
                        ),
                      }}
                      className={
                        props.errors.variableName
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit"
                      }
                      id={`funding-rule-split-rule.${props.index}.variableName`}
                      name={`funding-rule-split-rule.${props.index}.variableName`}
                      control={props.control}
                      rules={{ required: true }}
                      label="Tag Name"
                      setValue={props.setValue}
                    />
                  </Grid>
                  <Grid item xs={6} justifyContent={"flex-end"}>
                    <FxMaterialSelect
                      register={{
                        ...props.register(
                          `funding-rule-split-rule.${props.index}.variableToMap`
                        ),
                      }}
                      className={
                        props.errors.variableToMap
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit"
                      }
                      id={`funding-rule-split-rule.${props.index}.variableToMap`}
                      name={`funding-rule-split-rule.${props.index}.variableToMap`}
                      data={variableMappingOptions}
                      control={props.control}
                      rules={{ required: true }}
                      label="Tag Maps To"
                      setValue={props.setValue}
                      value={props?.value?.variableToMap || ''}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className={`funding-splitrule-criteria-section-split-divider ${props?.index === 0 ? 'funding-splitrule-criteria-section-firstAction': ''}` }></div>
        {/* Configure Payment Split */}
        <Grid
          item
          xs={5}
          sm
          className={"fx-approval-policy-condition fx-create-approval-policy"}
          id={"add-split-rule-conditions-payment-configurations" + props.index}
          justifyContent={"flex-end"}
          key={props.index}
        >
          {props?.index === 0 && <Grid
            container
            item
            direction="row"
            className="fx-form-edit-profile"
            paddingBottom={"1.125rem"}
            xs={12}
          >
            <Typography variant="subtitle1" className="fx-new-invite-sub-label">Define Payment Split</Typography>
          </Grid>}
          <Grid
            container
            item
            direction="row"
            className="fx-form-edit-profile"
            marginTop={"1rem"}
            spacing={3}
            justifyContent={'space-between'}
            paddingLeft={"1.5rem"}
            xs={12}
          >
            <Grid
              container
              direction="row"
              className="fx-form-edit-profile"
              spacing={2}
            >
              <Grid item spacing={1} xs>
                <FxTextEdit
                  register={{
                    ...props.register(
                      `funding-rule-split-rule.${props.index}.percentage`
                    ),
                  }}
                  className={
                    props.errors.percentage
                      ? "border-error-input fx-input-edit clear-number-field-arrow"
                      : "fx-input-edit clear-number-field-arrow"
                  }
                  control={props.control}
                  rules={setPercentageValidation}
                  id={`funding-rule-split-rule.${props.index}.percentage`}
                  label="Percentage"
                  name={`funding-rule-split-rule.${props.index}.percentage`}
                  setValue={props.setValue}
                  variant="outlined"
                  defaultValue={
                    props?.data?.percentage ? props?.data?.percentage : ""
                  }
                  suffix="%"
                  valuePattern={/-|\+|e|E/}
                  type="number"
                  showDecimal={true}
                  onChange={(e: any) => { handlePercentageChange(e) }}
                  onWheel={(e: any) => e.target.blur()}
                />
               <div className={'error-message'}>
                  {props.errors ? percentageError || props?.errors?.['funding-rule-split-rule']?.[props.index]?.['percentage'] ? renderError(props?.errors?.['funding-rule-split-rule']?.[props.index]?.['percentage']) : '': ''}
               </div>
              </Grid>
              <Grid item xs={1} display={"inline-flex"} alignItems={"center"} textAlign={'center'}>
                <Typography width={'100%'}>+</Typography>
              </Grid>
              <Grid item spacing={1} xs>
                <FxTextEdit
                  register={{
                    ...props.register(
                      `funding-rule-split-rule.${props.index}.amount`
                    ),
                  }}
                  className={
                    props.errors.amount
                      ? "border-error-input fx-input-edit clear-number-field-arrow"
                      : "fx-input-edit clear-number-field-arrow"
                  }
                  control={props.control}
                  rules={{ required: false }}
                  id={`funding-rule-split-rule.${props.index}.amount`}
                  label="Amount"
                  name={`funding-rule-split-rule.${props.index}.amount`}
                  setValue={props.setValue}
                  defaultValue={props?.data?.amount ? props?.data?.amount : ""}
                  variant="outlined"
                  valuePattern={/-|\+|e|E/}
                  type="number"
                  prefix="$"
                  showDecimal={true}
                  onWheel={(e: any) => e.target.blur()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Button Group - Add */}
        <Grid
          item
          xs={"auto"}
          container
          justifyContent={"space-between"}
          display={"inline-flex"}
          alignItems={"center"}
          className={props?.index === 0 ? 'funding-splitrule-criteria-section-firstsplit': 'funding-splitrule-criteria-section-non-firstsplit'}
        >
          <Grid item>
            <FxButton
              id={props.index + "-" + props.index + "-delete-condition-btn"}
              title="Delete"
              className={`fx-button fx-approval-button-icon fx-condition-btn ${props.fieldLength === 1 ? "fx-button-disabled" : ""
                }`}
              variant="contained"
              disabled={props.fieldLength === 1}
              onClick={props.handleRemove}
              startIcon={<DeleteIcon />}
            />
          </Grid>
        </Grid>

        {/* Button Group - Remove */}
        <Grid
          item
          xs={"auto"}
          container
          justifyContent={"space-between"}
          display={"inline-flex"}
          alignItems={"center"}
          className={props?.index === 0 ? 'funding-splitrule-criteria-section-firstsplit': 'funding-splitrule-criteria-section-non-firstsplit'}
        >
          <Grid item>
            <FxButton
              id={props.index + "-" + props.index + "-add-condition-btn"}
              title="Add"
              className={`fx-button fx-approval-button-icon fx-condition-btn ${props.isAddButtonDisabled ? "fx-button-disabled" : ""
                }`}
              disabled={props.isAddButtonDisabled}
              variant="contained"
              onClick={props.handleAppend}
              startIcon={<AddIcon />}
            />
          </Grid>
        </Grid>
    </Grid>
  );
});
