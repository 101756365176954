/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import { FxInfoTooltip } from '../../../Utils/FxInfoTooltip';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import FxCardBody from '../../../Container/FxCardBody';
import { checkPermission, truncateChars } from '../../../Utils/CommonBaseClass';
import { FxPaymentModeStatus } from '../../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { ReactComponent as MdArrowForward } from "../../../../assets/svg/view-all-arrow-icon.svg";
import { FxStatus } from '../../../Input/FxStatus/FxStatus';
import { FxAccessDenied } from './FxAccessDenied';
import { getKey } from '../../../../libs/utils/storageManager';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import FxNoGridDataImage from '../../../Data/FxNoGridDataImage';
import ShowFasterFundingAsPerTheme from '../../../Utils/ThemeChecker';
import { useIsMobileOrTabletScreen } from '../../../../libs/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionAmountJSX, getTransactionsArrowJSX } from '../../../../libs/utils/TransactionService';

export const HomeTransactionActivity: React.FC<any> = React.memo((props) => {

    const history = useHistory();
    let context: any;
    ({ context, props } = RegisterComponent(props));
    let cur_date: any = '';
    const isSmallScreen = useIsMobileOrTabletScreen()
    const dispatch = useDispatch()

    const permissionArray: any = [
        {
            entity: "Accounts",
            name: "Card Collect",
            operation: "View"
        },
        {
            entity: "Accounts",
            name: "Check Collect",
            operation: "View"
        },
        {
            entity: "Accounts",
            name: "Ach Collect",
            operation: "View"
        },
        {
            entity: "Accounts",
            name: "Wire Send",
            operation: "View"
        },
        {
            entity: "Accounts",
            name: "Book Send",
            operation: "View"
        },
        {
            entity: "Accounts",
            name: "Check Send",
            operation: "View"
        },
        {
            entity: "Accounts",
            name: "Ach Send",
            operation: "View"
        },
        {
            entity: "Accounts",
            name: "Virtual Card Send",
            operation: "View"
        }
    ]

    /**
     * useEffect to set default address 
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });      
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    /**
    * to set permission
    */
    const permission = useMemo(() => {
        for (const item of permissionArray) {
            if (checkPermission(item)) {
                return true;
            }
        }
        return false;
        // eslint-disable-next-line
    }, []);

    const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);
    const selectedAccount = accountListInfo?.params?.selectedOption;

    /**
    * Method handles the redirection to details page
    * @param event : event parameter
    * @param data : row data
    */
    function redirectDetailsPage(data: any) {
        if(selectedAccount?.type !== 'DEPOSIT'){
            if (data?.source?.account?.id) {
                history.push(`/transactions/send/${data.id}`);
            }
            else if (data?.destination?.account?.id) {
                history.push(`/transactions/collect/${data.id}`);
            }
        }
    }

    
    return (
        <>
            {permission ? <FxCardBody id="recent-transaction-activity-list-Home-Card-Body" className="fx-home-ledger-list">
                {context?.data?.body?.resources.map((item: any, i: number) => {
                    let title: any;
                    if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
                        cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                        title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                    }
                    const isExternalOrRefund = (item.type === 'EXTERNAL' || item.type === 'REFUND') && item.method === 'CARD';
                    return (
                        <Grid id={"recent-transaction-activity-list-Home-Grid"} item xs={12}>
                            {title ? <Grid container xs={12} direction="row">
                                <Grid item xs className='fx-home-ledger-list-headerText'> <Typography variant='h6'>{title}</Typography></Grid>
                            </Grid> : (
                                <Grid item xs={12} sm={12}>
                                    <Divider />
                                </Grid>
                            )}
                            <Grid container direction="row" className={selectedAccount?.type === 'DEPOSIT'? "fx-home-ledger-list-column": "fx-home-ledger-list-column fx-home-ledger-list-column-pointer-event"} onClick={()=>redirectDetailsPage(item)}>
                                <Grid item className="fx-home-ledger-list-column-icon">
                                    {getTransactionsArrowJSX(item, getKey("selectedAccount")?.id)}
                                </Grid>
                                <Grid item container xs className="fx-home-ledger-list-column-content">
                                    <Grid item container xs={12} alignItems='center'>
                                        <Grid item container xs={8}>
                                            <Grid item>
                                                <Typography variant='h4'>{isExternalOrRefund ? truncateChars((item.source?.account?.id ? 'REFUND - Ref: ' : item.destination?.account?.id ? 'SALE - Ref: ' : '') + item?.purpose, (isSmallScreen ? 25: 45)) : truncateChars(item?.purpose, (isSmallScreen ? 25: 45))}</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid className='fx-home-ledger-list-column-status-mode'>&nbsp;<FxPaymentModeStatus tooltip={true} id={'transaction-method'} value={item?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>
                                                {item?.processingDetail?.quickSettle && item?.status !== 'VOIDED' && <ShowFasterFundingAsPerTheme/>} 
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {!isSmallScreen && <Grid item container xs={2}>
                                            <FxInfoTooltip title={item?.statusReason} arrow placement={'bottom-start'} className={'fx-status-tooltip'}>
                                                <span><FxStatus id="transaction-status" value={item?.status} fill={true} className="fx-status"></FxStatus></span>
                                            </FxInfoTooltip>
                                        </Grid> }                                           
                                        <Grid item container xs={isSmallScreen? 4: 2} justifyContent='end'>
                                           {/* <Grid className='fx-home-ledger-list-column-status-mode'> &nbsp;<FxStatus id="transaction-status" value={item?.status} fill={true} className="fx-status"></FxStatus></Grid> */}

                                            {getTransactionAmountJSX(item, getKey("selectedAccount")?.id)}
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs alignItems='center'>
                                        <Grid item>
                                            <Grid><Typography variant='h5'>Txn ID: {item?.id}</Typography></Grid>
                                        </Grid>
                                        {
                                            (item?.processingDetail?.batch?.id) && <Grid item>
                                            <Grid><Typography variant='h5'>Batch ID: {item?.processingDetail.batch.id}</Typography></Grid>
                                        </Grid>
                                        }
                                        {isSmallScreen && <Grid item className="fx-grid-list-status">
                                            <FxStatus id="transaction-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={12} className="fx-home-ledger-list-column-padding"></Grid>
                            </Grid>
                        </Grid>
                    )
                })
                }
                {(!getKey("selectedAccount")?.id || (context?.data?.body?.totalCount === 0)) && <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} textAlign={'center'}>
                        <FxNoGridDataImage />
                        <Typography className='fx-no-data'> No data to show </Typography>
                    </Grid>
                </Grid>}
            </FxCardBody> : <FxAccessDenied id={'transaction-activity-list-access-denied'} title={'You are not authorized to access this card'} />}
            {(permission && context?.data?.body?.totalCount > 0) && <FxCardFooter id="transaction-activity-list-Home-Card-Footer" className="fx-footer fx-footer-middle">
                <Grid container xs={12} justifyContent="center" alignItems="flex-start">
                    <FxButton variant="contained" className="fx-button fx-button-view-all" id="home-transaction-activity-list-view-all-button" onClick={() => history.push('/transactions')}> View All <MdArrowForward className="fx-footer-icon" /></FxButton>
                </Grid>
            </FxCardFooter>}
        </>
    )
})