/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { getCustomerUrl } from "../../../libs/utils/utils";
import { IAccountOptions } from '../../../types/common.interfaces';
import { addressFormatter, maskAccountNumber } from "../../Utils/CommonBaseClass";
import { LogoAccount } from '../../Utils/Logo';
const httpClient = HttpClient.getClient();

/**
 * Async Function handles api call for create and update send
 * @param paylaoddata : form request
 * @returns : response object
 */
export async function createSend(paylaoddata: any) {
    try {
        let url = '/transaction'
        if (paylaoddata.id && paylaoddata.id !== undefined) {
            url = '/transaction/id/' + paylaoddata.id;
        }
        const data: any = await httpClient.post(getCustomerUrl(url,false), paylaoddata).then(response => {
            return response
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        return err;
    }
}
/**
 * Async Function handles api call for create and update transaction
 * @param paylaoddata : form request
 * @returns : response object
 */
export  function createTransactionUrl(paylaoddata: any) {
    
        let url = '/transaction'
        if (paylaoddata.id && paylaoddata.id !== undefined) {
            url = '/transaction/id/' + paylaoddata.id;
        }
        if(paylaoddata.action !== undefined){
            url='/transaction/recurring'
        }
      return getCustomerUrl(url);

}

/**
 * Async Function handles api call for create and update send
 * @param paylaoddata : form request
 * @returns : response object
 */
export async function createSendMyAccount(paylaoddata: any) {
    try {
        let url = '/transaction'
        if (paylaoddata.id && paylaoddata.id !== undefined) {
            url = '/transaction/id/' + paylaoddata.id;
        }
        if(paylaoddata.action !== undefined){
            url='/transaction/recurring'
        }
        const data: any = await httpClient.post(getCustomerUrl(url,false), paylaoddata).then(response => {
            return response
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        Logger.error("CreateSendAccountCHECK.tsx", "error", err);
        return err;
    }

}
/**
 * Async Function handles api call for create and update recurring
 * @param paylaoddata : form request
 * @returns : response object
 */
export async function updateRecurring(paylaoddata: any) {
    try {
        let url = '/transaction/recurring'
        if (paylaoddata.action && paylaoddata.id) {
            url = '/transaction/recurring/id/' + paylaoddata.id
        }
        const data: any = await httpClient.post(getCustomerUrl(url,false), paylaoddata).then(response => {
            return response
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        Logger.error("EditRecurring.tsx", "error", err);
        return err;
    }

}

/**
 * Async Function handles api call for create and update collect
 * @param paylaoddata : form request
 * @returns : response object
 */
export async function createUpdateCollect(payload: any) {
    let url = '/transaction';
    if (payload.action) {
        url='/transaction/recurring';
    }
    if (payload.id) {
        url = '/transaction/id/' + payload.id;
    }
    try {
        const data: any = await httpClient.post(getCustomerUrl(url,false), payload).then(response => {
            return response;
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        return err;
    }
}

export async function getTransactionStatus(transactionId: string) {
    try {
        const data = await httpClient.get(getCustomerUrl(`/transaction/id/${transactionId}`, false));
        return data;
    } catch (err) {
        Logger.error("getTransactionStatus.tsx", "error", err);
        return err;
    }
}

/**
 * Function transforms data for source account
 * @param data : data
 * @param hasPrefix : boolean
 * @returns : transformed data
 */
export function dataSourceTransformation(data: any, hasPrefix = false) {
    return data?.filter((option: any) => option.status === 'ACTIVE' || option.status === 'DEBIT_BLOCKED')
        .map((item:any) => ({ value: item.id, label: accountLabelTransformation(item),  prefix: hasPrefix ? <LogoAccount /> : '' }));
}

/**
 * Function transforms data for the settlement account of merchant
 * @param data : data
 * @param hasPrefix : boolean
 * @returns : transformed data
 */
export function merchantSettlementAccountListDataTransformation(data: any) {
    return data?.filter((option: any) => option.status === 'ACTIVE' && option.type !== 'CASH_BUILDER_PLUS' && option.type !== 'CASH_BUILDER')
        .map((item:any) => ({ value: item.id, label: accountLabelTransformation(item) }));
}

/**
 * Function transforms data for source account
 * @param data : data
 * @returns : transformed data
 */
export function dataSourceTransformationTreasure(data: any) {
    return data?.filter((option: any) => option.status === 'ACTIVE' && !(option.type === 'CASH_BUILDER_PLUS'))
        .map((item:any) => ({ value: item.id, label: accountLabelTransformation(item) }));
}

/**
 * Function transforms data for source account
 * @param data : data
 * @returns : transformed data
 */
export function dataSourceTransformationInvestment(data: any) {
    return data?.filter((option: any) => option.status === 'ACTIVE'|| option.purpose === 'DEPOSIT')
        .map((item:any) => ({ value: item.id, label: accountLabelTransformation(item) }));
}

/**
 * Function transforms data for source account
 * @param data : data
 * @returns : transformed data
 */
export function accountDataTransformation(data: any) {
  return data?.filter((option: any) => (option.status === 'ACTIVE' && option.type !== 'DEPOSIT') || (option.status === 'DEBIT_BLOCKED'))
    .map((item:any) => ({ value: item.id, label: accountLabelTransformation(item) }));
}

/**
 * Function transforms data for source account for transfer
 * @param accounts : accounts
 * @param hasPrefix : boolean
 * @returns : transformed data
 */
export function sourceAccountDataTransformation(accounts: any[], hasPrefix = false) {
  return accounts?.filter((option: any) => option.status === 'ACTIVE' && !(option.type === 'CASH_BUILDER'))
    .map((item: any) => ({
      value: item.id,
      label: accountLabelTransformation(item),
      type: item.type,
      subType: item.subType,
      prefix: hasPrefix ? <LogoAccount /> : undefined
    }));
}

/**
 * Function transforms data for destination account for transfer
 * @param sourceAccount : source account id
 * @param accountOptions : account options
 * @param hasPrefix : boolean
 * @returns : transformed data
 */
export const destinationAccountDataTransformation = (sourceAccount: string, accountOptions: IAccountOptions[], hasPrefix = false) => {
  const _sourceAccount = accountOptions.find((account) => account.value === sourceAccount);
  //case when from account is CASH_BUILDER
  if(_sourceAccount && _sourceAccount.type === 'CASH_BUILDER_PLUS') {
    //all accounts except from account
    return accountOptions.filter((account) => account.value !== sourceAccount);
  }
  //case when from account is not CASH_BUILDER
  else {
     return  accountOptions.filter((account) => account.value !== sourceAccount && account.type === 'CASH_BUILDER_PLUS');
  }
}


/**
 * Function transforms data for virtual card
 * @param data : data
 * @returns : transformed data
 */
export function dataSourceVirtualCardTransformation(data: any) {
    try {
        return data?.map(function (option: any) {
            const nickname = option?.nickname ? ' (' + option.nickname + ' )' : ''
            return { value: option.id, label: option?.cardNumberLast4 ? '***' + option.cardNumberLast4 + nickname : '' };
        })
    }
    catch (e) { 
        Logger.error("ScheduleGlobalFunctions.tsx", "error", e);  
    }
}

/**
 * Function transforms label Account
 * @param data : data
 * @returns : transformed data
 */
export function accountLabelTransformation(data: any) {
        return  (data?.nickName ? data.nickName: '')+' A/c '+maskAccountNumber(data?.accountNumber,false) ;
}

/** Function transforms data for virtual card program
* @param data : data
* @returns : transformed data
*/
export function dataCardProgramTransformation(data: any) {
    return data?.map(function (option: any) {

        return { value: option.id, label: option.name };
    })
 }

 /** Function transforms data for Beneficial Owner
* @param data : data
* @returns : transformed data
*/
export function dataBeneficialOwnerTransformation(data: any) {
    return data?.business?.beneficialOwner?.map(function (option: any) {
        return { value: option.id, label: option.fullName };
    })
 }


/**
 * Function handles amount validation
 */
export const setAmountValidationOptions = {
    required: true,
    min: 0.01,
    max: 99999999.99
};

/**
 * Function handles allow zero amount validation
 */
export const setAllowZeroAmountValidationOptions = {
    required: true,
    min: 0.00,
    max: 99999999.99
};

/**
 * Function to get destination deatils of the schedule
 */
export async function getDestinationValue(data: any) {
    try{
        let url:any;
        let destinationAccountData:any;
        if (data?.externalAccount?.url) {
            url = data.externalAccount.url.split('/v1')[1]
        }
        else if (data?.account?.url) {
            url = data.account.url.split('/v1')[1]
        }
        else if (data?.internationalExternalAccount?.url) {
            url = data.internationalExternalAccount.url.split('/v1')[1]
        }
        else if (data?.contact?.url) {
            url = data.contact.url.split('/v1')[1]
        }
        else if (data?.address?.url) {
            url = data.address.url.split('/v1')[1]
        }
        else if(data?.wallet?.resourceName === 'wallet'){
            destinationAccountData=data?.wallet?.name
        }
        else if(data?.externalAccount?.accountNumberLast4){
            destinationAccountData=maskAccountNumber(data?.externalAccount?.accountNumberLast4,false)
        }
        else if(data?.externalAccount?.addressLine1){
            destinationAccountData=addressFormatter("", data?.externalAccount)
        }
        if (url) {
            try {
                const data: any = await httpClient.get(getCustomerUrl(url,true)).then(response => {
                    return response
                })
                .catch((error) => {
                        return { ...error };
                    })
                if(data?.data?.accountNumberLast4){
                    destinationAccountData=maskAccountNumber(data?.data?.accountNumberLast4,false)
                }
                switch (data?.data?.resourceName) {
                    case 'contact':
                        destinationAccountData = data?.data?.name;
                        break;
                    case 'address':
                        destinationAccountData = addressFormatter('', data?.data);
                        break;
                    case 'account':
                        destinationAccountData = accountLabelTransformation(data?.data);
                        break;
                }
            } catch (err) {
                Logger.error("EditSend.tsx", "error", err);
                return err;
            }
        }

        return destinationAccountData
    }
    catch(e){
        Logger.error("ScheduleGlobalFunctions.tsx", "error", e);
    }
}
/***
 * Getting the contact details
 *
 */
export async function getEAList(id:any) {
    try {
        const url = '/contact/id/' + id
        const data: any = await httpClient.get(getCustomerUrl(url,false)).then(response => {
            return response?.data
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        Logger.error("getEAList.tsx", "error", err);
        return err;
    }
}

/***
 * Getting the Customer details
 *
 */
export async function getCustomerDetails() {
    try {
        const url = '/'
        const data: any = await httpClient.get(getCustomerUrl(url,false)).then(response => {
            return response?.data
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        Logger.error("getCustomerDetails.tsx", "error", err);
        return err;
    }
}

/**
* function to handle source data
*/
export const sourceDataTransformer =(data: any) => {
    const newData: any = { ...data };
    try {
        newData['accountInfo'] = (data?.nickName ? data?.nickName + ' ' : '') + 'A/c '+ maskAccountNumber(data?.accountNumber, false)
    }
    catch (e) {
        Logger.error("ScheduleGlobalFunctions.tsx", "error", e);
        return false;
    }
    return newData;
}

/***
 * Getting the swiftcode details
 *
 */
 export async function getSwiftCodeDetails(url: any, base = false) {
    try {
        const data: any = await httpClient.get(getCustomerUrl(url,base)).then(response => {
            return response?.data
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        Logger.error("getSwiftCodeDetails.tsx", "error", err);
        return err;
    }
}

/**
 * Function handles setting the international routing code for swiftcode section
 * @param data : swiftcode data string
 */
export async function getRoutingCodeLabels(data: any, isEdit: boolean = false) {

    let label = '';
    let swiftCode = '';
    let showRoutingLabel = false;
    let isRoutingLabelRequired = false;
    if (isEdit) {
        swiftCode = data;
    } else if (data !== null && data !== undefined && data?.target?.value?.length > 3) {
        swiftCode = data?.target?.value;
    }

    if (swiftCode) {
        const url = "/internationalExternalAccount/swiftCode/" + swiftCode + "/detail";
        const res = await getSwiftCodeDetails(url);
        if (res && !res.errorMessage) {
            label = res?.internationalCodeLabel || '';
            isRoutingLabelRequired = res?.internationalCodeRequired ? true : false;
            showRoutingLabel = res?.internationalCodeCharacterLength && res?.internationalCodeCharacterLength !== 0 ? true : false;
        }
    }

    return { label, showRoutingLabel, isRoutingLabelRequired };
}


/**
 * function to get merchant data
 * @param id :merchant id
 * @returns : response object
 */
export async function getMerchantData(id: number) {
    try {
        return await httpClient.get(getCustomerUrl('merchant/id/' + id)).then(response => {
            return response;
        }).catch((error) => {
            return { ...error };
        })
    } catch (error) {
        return error
    }
}

/**
 * function to get location API and set Location data
 * @param data : data
 * @param setLocation method to set location data
 * @param setValue : method to set form field
 * @returns : response object
 */
export async function getLocationData(data: any,setLocation? : any,setValue? : any) {
    const url = data?.url ? data?.url.split('/v1/')[1] : getCustomerUrl('location/id/' + data?.id);
    try {
        return await httpClient.get(url).then((res:any) => {
            if(res.data){
              setLocation(res.data);
              setValue('location',res.data?.id);
            }
        });
    } catch (error) {
        Logger.error('ScheduleGlobalFunctions.tsx', 'get location data', error)
        return error
    }
}

/**
 * function to get location data
 * @param id :location url
 * @returns : response object
 */
export async function getLocation(url: number) {
    try {
        return await httpClient.get(url).then(response => {
            return response;
        }).catch((error) => {
            return { ...error };
        })
    } catch (error) {
        return error
    }
}