 /* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardHeader from '../../../Container/FxCardHeader';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxButton } from '../../../Action/FxButton';
import { ReactComponent as CardImage } from "../../../../assets/svg/card-payments-tilted-card.svg";
import FxRightDrawer from '../../../Utils/fx-right-drawer';
import { FxMerchantDrawer } from '../../Merchants/FxMerchantDrawer';
import { useHistory } from 'react-router-dom';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import HttpClient from '../../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../../libs/utils/utils';

/**
 * This component handles the activate card payments Card
 */
export const ActivateCardPayments: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const httpClient = HttpClient.getClient();
 const [show, setShow] = useState(false);
 const [loadInit, setLoadInit] = useState(false);
  const dispatch = useDispatch()
  const history = useHistory<any>();
  const uiFeat = UIFeatureImpl.getInstance();
  const fastFundAvailable = uiFeat.getFasterFunding().available;

 //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => () => {
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

 if(props?.data && !loadInit){
    setLoadInit(true);
    if(props?.data?.totalCount<1){
      setShow(true);
    }
 }

 const merchant_source = {
  pageNumber: 1,
  pageSize: 25,
  criteria: {
    filters: []
  }
}

 /**
 * This method handles the opening of the Direct Funded Merchant Creation Right Drawer Form
 */
 const openDFMerchantCreationForm = (async () => {
  const data = await FxRightDrawer.show<any>(({ onClose }) => {
       return <FxMerchantDrawer onDrawerClose={onClose} />
  })
  if(data){
      const merchantList = await httpClient.post(getCustomerUrl("/merchant/list", false), merchant_source);
      updateComponentParams(dispatch, "customer-basic-info", { 'merchantList': merchantList?.data })
      history.push('/redirect/dashboard')
  }
})

  return (
    <>
      {show && <Grid id="activate-card-payments-grid" item xs={12} >
        <FxCard id="activate-card-payments-card" className="fx-theme-passport activate-card-payments-card-grid">
          <FxCardHeader className='activate-card-payments-card-grid-header'>
            Accept Card Payments<br/> on your account
              <CardImage/>
          </FxCardHeader>
          <FxCardBody id="activate-card-payments-card-body" className="fx-card-body-quick-links">
                <Grid id="activate-card-payments-card-grid" item container xs={12} >
                    <Grid item xs={12} sm={12}>
                        <Typography className="activate-card-payments-section-note">Accept all credit and debit card payments<br/> from your account in few easy steps.</Typography>
                    </Grid>
                    {/* &nbsp; */}
                    <Grid item xs={12} sm={12}>
                        <FxButton id="activate-card-payments-card-apply-now-button" title="Edit" className="fx-button fx-button-passport-border fx-svg-theme" variant="contained"  onClick={openDFMerchantCreationForm} >
                        Apply Now
                        </FxButton>
                    </Grid>
                </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>}
    </>
  )
})
