/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { Grid, Typography } from "@mui/material";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import { useIsMobileOrTabletScreen } from "../../../libs/utils/utils";
import FxCardHeader from "../../Container/FxCardHeader";
import { ReactComponent as ThunderIcon } from '../../../assets/svg/lightning.svg';
import { ReactComponent as SelectedTickIcon} from '../../../assets/svg/interface-verification.svg';
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";


/**
 * This component handles the Faster Funding Detail Summary Card containing the different amount summaries to be displayed on the top
 */
export const FasterFundsDetailSummaryCard: React.FC<any> = React.memo((props) => {
  return (
    <>
       <FxCard id="account-ledger-card" className={useIsMobileOrTabletScreen() ? "fx-theme-passport fx-ledger-summary-card fx-ledger-summary-card-mobile" : "fx-theme-passport fx-ledger-summary-card"}>
                <FxCardHeader className="fx-card-header">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container columnSpacing={2}>
                                <Grid item>
                                    <Typography id="title-payees-info" variant="h3">Summary</Typography>    
                                </Grid>
                            </Grid> 
                            {props?.data?.effectiveDate && <Grid container item spacing={1}>
                                <Grid item>
                                    <Typography className="fx-ledger-summary-card-subtitle">AS ON&nbsp;<FxDateTimeView value={props?.data?.effectiveDate} format=" MMM DD, yyyy" /></Typography>   
                                </Grid>                                
                            </Grid>}
                        </Grid>                                             
                    </Grid>    
                </FxCardHeader>
                <FxCardBody className="fx-info-card fx-margin-top-reducer">                   
                    <Grid container columnSpacing={2} alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Grid item container className="fx-ledger-summary-total-section">                                   
                              <Grid item container direction={"column"}>
                                  <Grid item>
                                      <Grid item container columnSpacing={2} alignContent={"center"}>
                                          <Grid item>
                                              <Typography id="" variant="subtitle1" >FASTER FUND ACCESSED</Typography>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                                  <Grid item>
                                      <Typography id="" className="fx-transaction-summery-currency">
                                          <FxCurrencyView id="" value={props?.data?.fasterFundsAvailed ? props?.data?.fasterFundsAvailed :"0.00"} prefix={"$"} />
                                      </Typography>
                                  </Grid>
                                  <Grid item>
                                      <Typography>Faster Funds Accessed represents the total funds you have accessed out of your faster funds available amount.</Typography>
                                  </Grid>
                              </Grid>

                          </Grid> 
                        </Grid>
                      <Grid item xs={12} md={4} >
                      <Grid item container className="fx-faster-funding-detail-cards">                                   
                              <Grid item container direction={"column"}>
                                  <Grid item>
                                      <Grid item container columnSpacing={2} className="fx-faster-funding-detail">
                                        <Grid item className="fx-faster-funding-detail-icons">
                                        <ThunderIcon></ThunderIcon>
                                        </Grid>
                                          <Grid item className="fx-faster-funding-detail-title">
                                              <Typography id="" variant="subtitle1" >TOTAL FASTER FUNDED AMOUNT</Typography>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                                  <Grid item>
                                      <Typography id="" className="fx-transaction-summery-currency">
                                          <FxCurrencyView id="" value={props?.data?.totalUnsettledSale ? props?.data?.totalUnsettledSale :"0.00"} prefix={"$"} />
                                      </Typography>
                                  </Grid>
                                  <Grid item>
                                      <Typography> Your Faster Funded Amount is an estimate of the total incoming funds based on your account activity with Priority.</Typography>
                                  </Grid>
                              </Grid>

                          </Grid> 
                      </Grid>
                      <Grid item xs={12} md={4}>
                      <Grid item container className="">                                   
                              <Grid item container direction={"column"}>
                                  <Grid item>
                                      <Grid item container columnSpacing={2} className="fx-faster-funding-detail">
                                        <Grid item className="fx-selected-green-icon fx-faster-funding-detail-icons">
                                        <SelectedTickIcon/>
                                        </Grid>
                                        <Grid item className="fx-faster-funding-detail-title">
                                            <Typography id="" variant="subtitle1" >FASTER FUNDS AVAILABLE</Typography>
                                        </Grid>
                                      </Grid>
                                  </Grid>
                                  <Grid item>
                                      <Typography id="" className="fx-transaction-summery-currency">
                                          <FxCurrencyView id="" value={props?.data?.fasterFundsAvailable && props?.data?.fasterFundsAvailable >= 0 ? props?.data?.fasterFundsAvailable : "0.00" } prefix={"$"} />
                                      </Typography>
                                  </Grid>
                                  <Grid item>
                                      <Typography>Your available Faster Funds is calculation based on  several factors accessed by Priority and may change over time.</Typography>
                                  </Grid>
                              </Grid>

                          </Grid> 
                      </Grid>
                  </Grid>
                </FxCardBody>
            </FxCard>
    </>
  );
});
