/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Divider, Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch, useSelector } from 'react-redux';
import FxCardFooter from '../../Container/FxCardFooter';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxGridList } from '../../Data/FxGridList';
import { useMediaQuery } from 'react-responsive';
import { getKey } from '../../../libs/utils/storageManager';

    /**
     * Component used to display customer address list
     */
    const column: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "ID",
                "enable": true,
                "fieldName": "id",
                "type": "fri://fieldtype/default",
                "default": true
            },
            {
                "label": "Address",
                "enable": true,
                "fieldName": "address",
                "type": "fri://fieldtype/default",
                "default": true
            }
        ]
    }

    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            },
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/custom",
                "xs": "12",
            }

        ]
    }

    /**
    * Method to transform data
    * @param data
    * @returns transformed data object for mobile view
    */
    const newDataMobile =(data:any)=>{
        var length=data?.length-1;
        const transformedData = data?.map((item: any,index:any) => (
            length===index?{
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        {createDocumentDetails(item)}
                    </Grid>
                )}
            :
            { description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        {createDocumentDetails(item)}
                    </Grid>
                ),divider: createDivider(),
            }));
        return transformedData || [];
    };


    /**
     * Grid lay out for mobile
     * @param item 
     * @returns 
     */
    const createDocumentDetails = (item: any) => (
    
        <> <Grid item container xs={12} alignItems='center'>
        <Grid item container xs={12}>
                <Typography  variant='h4'>ID:{item?.id}</Typography>
        </Grid>
        <Grid item container xs={12}>
            <Typography variant='h4'>{item?.address}</Typography>    
        </Grid>

    </Grid>
    </>
    );

    /**
    * function to create divider to separate data
    */
    const createDivider = () => (
        <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
            <Grid item xs>
                <Divider />
            </Grid>
        </Grid>
    );


export const CustomerAddress: React.FC<any> = React.memo(
    (props: any) => {
        const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
        const componentstate = useSelector((state: any) => {
            if (state.data["company-info-card-profile"]) {
                return state.data["company-info-card-profile"]
            } else {
                return { config: { params: {} } };
            }
        });
        let address_data: any = [];
        let newData: any = [];

        if(componentstate?.data?.body){
            // Set Address for individual customer
            if (componentstate.data.body?.individual?.mailingAddress) {
                address_data = [...componentstate.data.body.individual?.mailingAddress]
            }// Set Address for business customer
            else if (componentstate.data.body?.business?.mailingAddress) {
                address_data = [...componentstate.data.body.business?.mailingAddress]
            }// Set Address for joint tenancy customer
            else if(componentstate.data.body?.owners){
                const authUserId = getKey("authUserId");
                const ownerDetails = componentstate.data.body.owners.find((x:any)=>x.id === authUserId);
                address_data = [...ownerDetails.mailingAddress]
            }

            /**
                     * mapping used to format values from API and to load data in datagrid
                     */
            newData = address_data.map((item: any) => {
                const addressLine2 = item.addressLine2 ? item.addressLine2 + ', ' : '';
                return {
                    id: item.id,
                    address: item.addressLine1 + ', ' + addressLine2 + item.city + ', ' + item.state + ', ' + item.zip
                }
            })
        }

        const dispatch = useDispatch()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        //check api call completed or not
        const contextState = componentstate?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
           <>
           {componentstate?.data?.body && <Grid id="customer-address-Grid" item xs={12}>
                <FxCard id="address-card" className=" fx-theme-passport">
                    <FxCardHeader id={'address-card#card-header'} >
                        <Grid id="address-Grid" item container justifyContent="space-between" xs={12} >
                            <Typography id="title-customer-address-info" variant="h4" >Mailing Address</Typography>
                        </Grid>
                        <div id="div-grow-title-customer-address-info" className="fx-flex-grow" />
                    </FxCardHeader>
                    <FxCardBody id="customer-address#card-body" className="fx-card-body-no-border-no-shadow">
                    {
                    dataLoadStatus ? (
                        !isMobile ? (
                        componentstate?.data?.body ? (
                            <DataGrid id="customer-profile-mailing-address-grid" tableClass="fx-table-passport" data={newData} column={column} />
                        ) : (
                            <FxSkeltonList height="8rem" />
                        )
                        ) : (
                        <FxGridList id="customer-profile-mailing-address-grid" data={newDataMobile(newData)} column={gridColumn}/>)
                    ) : (
                        <FxSkeltonList height="8rem" />
                    )
                    }
                    </FxCardBody>
                    <FxCardFooter id="customer-profile-mailing-address-Footer" className="fx-footer">
                        <Grid></Grid>
                    </FxCardFooter>
                </FxCard>
            </Grid>}
           </>
        )
    })
