/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"; // we need this to make JSX compile
import { Logger } from "../../../libs/utils/logger";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import PPIAccountDetails from "./PPIAccountDetails";

Logger.debug("ManagePPI.tsx", "Manage Passport Payment ID");
/**
 * This component handles the manage passport payment id
 */
export const ManagePPI: React.FC<any> = () => {
  const dispatch = useDispatch();
  /**
   * useEffect to set title for page
   */
  useEffect(() => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: {
        id: "page-title-details",
        data: { title: "Manage Payment ID", backButton: '/dashboard' },
      },
    });
  }, [dispatch]);

  const ppi_source = {
    url: "/ppi",
    method: "GET",
  };

  return (
    <Grid item container xs={12} sm={12} className='fx-form-grid'>
      <PPIAccountDetails
        key="manageppi"
        source={ppi_source}
        id="manage-customer-ppi"
      />
    </Grid>
  );
};
