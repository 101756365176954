/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch } from 'react-redux';
import FxCardFooter from '../../Container/FxCardFooter';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { ReactComponent as ThreeDotMenu } from '../../../assets/svg/threedot-menu-icon.svg';
import { FxButton } from '../../Action/FxButton';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxBreadCrumbs from '../../Navigation/FxBreadCrumbs';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { processAPIResponse } from '../../../libs/utils/utils';
import { FxMenu } from '../../Data/FxMenu';
import FxTextView from '../../Input/FxText/FxTextView';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

 Logger.debug("CustomerMailingAddress.tsx", "edit mailing address initializing")

const page_menu = [
    {
        title: 'Dashboard',
        link: '/'
    },
    {
        title: 'My Mailing Address',
        link: '/mailingaddresses'
    }
];

/**
 * Component used to display customer address list
 */
export const CustomerMailingAddress: React.FC<any> = React.memo((props: any) => {
        let context: any
        ({ context, props } = RegisterComponent(props));
        const newData: any=[];
        let address_data:any=[]
        const [anchorEl, setAnchorEl] = useState(null);
        const [menuData, setMenuData] = useState<any>();
        const [menuOptions, setMenuOptions] = useState<any>();
        const open = Boolean(anchorEl);

        /**
         * Method handles the onclick event in the 3-dot icon options
         * @param event : event parameter
         */
         const handleOptionClick = async (event: any) => {
            handleClose();
            if(event === 'Edit'){
                history.push(`/mailingaddress/view/${menuData.id}`);
            }
            else{
                const request: any = {
                    'isPrimary': true,
                    'usage' : {
                        'isPayorAddress': true
                    }
                }
                let status: any = await updateMailingAddress(request,menuData.id);
                status = processAPIResponse(status)
                if (status.status) {
                    //api success
                    FxSnackBar.show({
                        severity: 'success',
                        text: 'Mailing Address set as Primary Successfully!',
                    });
                    setTimeout(()=>{
                        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
                    },400)
                }
                else {
                    //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
                }
            }
        };
        /**
         * Method handles the onclick event in the 3-dot icon
         * @param event : event parameter
         */
        const handleMenuButtonClick = (event: any, data: any) => {
            setAnchorEl(event.currentTarget);
            setMenuData(data)
            if(data.isPrimary){
                setMenuOptions(optionsForPrimary)
            }
            else{
                setMenuOptions(options)
            }
        };
        /**
         * Method handles the closing of the menus
         */
        const handleClose = () => {
            setAnchorEl(null);
        };
        const options: any = [
                    {id: 'make-as-primary', title: 'Make as Primary'},
                    {id: 'edit-mailing-address', title: 'Edit'}
        ];
        const optionsForPrimary: any = [
            {id: 'edit-mailing-address', title: 'Edit'}
        ];
        if (context && context.data && context.data.body ) {
            address_data=context.data.body
            address_data.forEach((item: any, i: any) => {
                newData[i] = {};
                let addressLine2 = '';
                let mainAddress = '';
                newData[i]['id'] = item['id'];
                addressLine2 = item['addressLine2'] ? item['addressLine2'] + ', ' : '';
                mainAddress = item['addressLine1'] + ', ' + addressLine2 + item['city'] + ', ' + item['state'] + ', ' + item['zip'];
                if(item['isPrimary']){
                    newData[i]['addressLabel'] = 'Primary';
                }
                // creating a custom component for address with label
                newData[i]['address'] = <Grid item container><Grid item><FxTextView id={'address-' + i} value={mainAddress} tooltip={true}></FxTextView></Grid><Grid item>&nbsp;&nbsp;{newData[i]['addressLabel']&&<FxStatus tooltip={true} id={'address-' + i} value={newData[i]['addressLabel']} fill={true} className="fx-status"></FxStatus>}</Grid></Grid>
                // creating a custom component for actions
                newData[i].actions = <Grid item container className="fx-view-action"><Grid item><Button id={item['id']+"-list-actions"} title="" className="fx-button fx-button-dotted fx-button-dotted-icons" aria-label="more" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={(e: any) => handleMenuButtonClick(e, item)}><ThreeDotMenu /></Button></Grid><Grid item><FxMenu id={item['id']+"-list-actions-menu"} open={open} anchorEl={anchorEl} options={menuOptions} data={newData[i]} onClose={handleClose} onMenuClick={handleOptionClick}></FxMenu></Grid></Grid>

            })

        }

        /**
         * Async method used to call update mailing address API
         * @param paylaoddata :request
         * @returns
         */
         async function updateMailingAddress(paylaoddata: any,id:any) {
            try {
                const data: any = await httpClient.post('mailingAddress/id/'+id, paylaoddata).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CustomerMailingAddress.tsx", "error", err);
                return err;
            }
        }


        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "ID",
                    "enable": true,
                    "fieldName": "id",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Address",
                    "enable": true,
                    "fieldName": 'address',
                    "type": "fri://fieldtype/custom",
                    "default": true
                },
                {
                    "label": "",
                    "enable": true,
                    "fieldName": "actions",
                    "type": "fri://fieldtype/custom",
                    "style": { "width": "100px", "text-align": "center" },
                    "default": true
                  },

            ]
        }
        const history = useHistory()
        const dispatch = useDispatch()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        function redirectDetailsPage(event: any, data: any) {
            history.push(`/mailingaddress/view/${data.id}`);
        }

        return (
            <Grid id="customer-address-Grid" item xs={12}>
                <Grid container xs={12} className="fx-layout-list">
                    <FxBreadCrumbs menuItems={page_menu} id="my-mailing-address-breadcrumbs" />
                    <FxCard id="address-card" className=" fx-theme-passport">
                        <FxCardHeader id={'address-card#card-header'} >
                            <Grid id="address-Grid" item container justifyContent="space-between" xs={12} >
                                <Typography id="title-customer-address-info" variant="h4" >Mailing Address</Typography>
                                <FxButton
                                    disableRipple={false}
                                    className="fx-button fx-button-passport-border fx-svg-theme"
                                    id="profile-add-mailing-address"
                                    onClick={() => history.push('/mailingaddress/add')}
                                    startIcon={<AddIcon />}
                                > Add New
                                </FxButton>
                            </Grid>
                            <div id="div-grow-title-customer-address-info" className="fx-flex-grow" />
                        </FxCardHeader>
                        <FxCardBody id="customer-address#card-body" className="fx-card-body-no-border-no-shadow">
                            <DataGrid id="customer-profile-mailing-address-grid" tableClass="fx-table-passport" data={newData} column={column} iconClick={redirectDetailsPage} noRowCursor={true}/>
                        </FxCardBody>
                        <FxCardFooter id="customer-profile-mailing-address-Footer" className="fx-footer">
                            <Grid></Grid>
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })
