/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import React from 'react';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxPieChart } from '../../../Input/FxPieChart/FxPieChart';
import Select from 'react-select'
import moment from 'moment';
import { ISource } from '../../../../types/common.interfaces';
import { useDispatch } from 'react-redux';

type ITransactionVolume = {
    id: string,
    data?:any[],
    colors:any[],
    label:ILabelText
    source?:any
    customerId?:any
}
type ILabelText={
    text:string,
    value:any,
    labelColor?:any
}
/**
 * Component used to draw Transaction volume
 */
export const TransactionVolume: React.FC<ITransactionVolume> = React.memo(props => {

    const dispatch = useDispatch();
    let context:any
    ({ context, props } = RegisterComponent(props));
    const labelText = { text: 'Transactions', value: 0 ,labelColor:'#103046'}
    /**
     * All methods
     */
      const methods:any={
          ACH:'ACH',
          WIRE:'Wire',
          CHECK:'Check',
          INTERNATIONAL_WIRE:'Intl Wire',
          VIRTUAL_CARD:'Virtual Card',
          BOOK:'Book'
  
      }
      let volumeData:any=[]
      let noOfTrans:any=0;
      if(context?.data?.body){
          volumeData=[]
          context?.data?.body?.transactionSummary?.forEach((item:any)=>{
              volumeData.push({ name: methods[item?.method], value: item?.count })
              noOfTrans+=item?.count
          })
          labelText.value=noOfTrans
      }
      /**
       * Options in the dropdown
       */
    const options: any = [
        { label: 'Today', value: moment().format('MM/DD/YYYY')},
        { label: '1 Week', value: moment().subtract(7, 'days').format('MM/DD/YYYY')},
        { label: '1 Month', value: moment().subtract(1, 'months').format('MM/DD/YYYY') },
        { label: '6 Month', value: moment().subtract(6, 'months').format('MM/DD/YYYY')},
        { label: '1 Year', value: moment().subtract(1, 'year').format('MM/DD/YYYY')}
    ]
    
    /**
     * Method to handle slection change 
     * @param event :event
     */
    const handleChange = (event: any) => {
        const src: ISource = {
            url: "transactionSummary",
            method: "POST",
            data:
            {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                    "sortBy": "createdOn",
                    "sortOrder": "desc"
                },
                criteria: {
                    filters: [
                        {
                            "operator": "gte",
                            "key": "statusDate",
                            "values": [event?.value]
                        },
                        {
                            "operator": "lte",
                            "key": "statusDate",
                            "values": [moment().format('MM/DD/YYYY')]
                        }
                    ]
                }
            }
        }
        if(props?.customerId){
            src?.data?.criteria?.filters?.push({
                "operator": "eq",
                "key": "customerId",
                "values": [
                    props?.customerId
                ]
            },)
        }
        dispatch({ type: "DATA_API_PARAM_CHANGED", payload: { id: 'transaction-volume', source: src } });
    }
    return <Grid id="transaction-volume-main-grid" item container xs={12} direction='row'>
        <FxCard id="transaction-volume-card" className="fx-theme-passport">
            <FxCardHeader>
                <Grid item container xs={12}>
                    <Grid item xs>
                        <Typography variant='h3'>Transactions Volume</Typography>
                    </Grid>
                    <Grid item container xs={4} justifyContent={'flex-end'}>
                        <Select id="transaction-volume-select" name="transaction-volume" options={options} defaultValue={{ label: 'Today', value: 'TODAY' }} className={"fx-input-edit"} onChange={handleChange} classNames={{ option: ({ isSelected }) => isSelected ? 'fx-theme-select' : '' }} />
                    </Grid>
                </Grid>
            </FxCardHeader>
            <FxCardBody id="transaction-volume-card-body" className="fx-info-card fx-margin-top-reducer">
                <FxPieChart id='partner-transaction-volume' data={volumeData} colors={props?.colors} label={labelText} />
            </FxCardBody>
        </FxCard>
    </Grid>
})
