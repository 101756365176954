/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Divider, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxButton } from '../../Action/FxButton';
import FxRightDrawer from '../../Utils/fx-right-drawer';
import { FxFastFunding, IFastFundingPayload } from '../../DrawerPages/FxFastFunding/FxFastFunding';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

Logger.debug("MerchantFasterFunding.tsx", "Merchant Faster Funding initializing");
const httpClient = HttpClient.getClient();
/**
 * Component: Merchant Faster Funding
 * Usage: To display merchant Faster Funding
 */

export const MerchantFasterFunding: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch()
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const uiFeat = UIFeatureImpl.getInstance();

        let merchant_information_data: any = {};
        if (context && context.data && context.data.body) {
            try {
                merchant_information_data = { ...context.data.body };
            } catch (error) {
              Logger.error("MerchantFasterFunding.tsx", "error", error);
            }
        }

        
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch, props?.id, { isAccountStatusActive: false })

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
      /**
       * Account API call to check the status of the account
       */
    useEffect(() => {
      async function getAccount(id: any) {
        const response = await httpClient.get(getCustomerUrl(`/account/id/${id}`));
        if (response?.data) {
          updateComponentParams(dispatch, props?.id, { isAccountStatusActive: response?.data?.status === 'ACTIVE' ? true : false })
        }
      }
      if (merchant_information_data?.merchantAccount) {
        getAccount(merchant_information_data?.merchantAccount?.id);
      }
    }, [dispatch, props.id, merchant_information_data?.merchantAccount])
        const faster_funding_detail_section: any = [
            {
                label: 'Funding Status',
                value: merchant_information_data?.configuration?.passportFunding?.status?<Grid item container justifyContent={'end'}><FxStatus id={"fx-faster-funding-status"} tooltip={true} value={merchant_information_data?.configuration?.passportFunding?.status} fill={true} className="fx-status" /></Grid>:<Grid item container justifyContent={'end'}></Grid>,
                type: 'fri://fieldtype/custom',
            },
            {
                label: 'Passport Account#',
                value: 'passportAccountNumber',
                type: "fri://fieldtype/accountnumber",
            }
        ];
        
        function fasterFudingDataTransformer(data: any) {
            try {
              const status =data?.configuration?.passportFunding?.status
              return {
                ...data,
                fundingStatus:  <FxStatus id={"fx-faster-funding-status"} tooltip={true} value={data?.configuration?.passportFunding?.status} fill={true} className="fx-status" />,// data?.configuration?.passportFunding?.status || '-',
                passportAccountNumber: data?.merchantAccount?.accountNumber,
                lastUpdated:status==='ACTIVE'? data?.lastUpdatedOn : '-',
              };
            } catch (e) {
              Logger.error("MerchantFasterFunding.tsx", "error", e);
              return data;
            }
          }
   /**
    * method to draw fast funding banner
    */
   const openFastFundingDrawer = (async () => {
    await FxRightDrawer.show<IFastFundingPayload>(({ onClose }) => {
        return <FxFastFunding onDrawerClose={onClose} merchantId={merchant_information_data?.id} />
    })
})
        return (
          <>
            {merchant_information_data?.configuration && <Grid
              id="faster-funding-Grid "
              className="fx-margin-bottom-16"
              item
              xs={12}
            >
              <FxCard id="faster-funding-card" className="fx-theme-passport">
                <FxCardHeader id={"faster-funding-card#card-header"}>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                    className="fx-account-summary-card-accountgraph-header"
                  >
                    <Grid item className="fx-account-insight-title">
                      <Typography variant="h3">Funding Via Passport</Typography>
                    </Grid>
                   {uiFeat.getFasterFunding().available && props?.isAccountStatusActive &&(!(merchant_information_data?.configuration?.passportFunding?.status==='ACTIVE' ||
                   merchant_information_data?.configuration?.passportFunding?.status==='INITIATED') ) &&
                    <Grid item>
                      <FxButton
                        variant="contained"
                        className=" fx-button fx-button-theme"
                        title="Enable"
                        id="faster-funding-enable-btn"
                        onClick={openFastFundingDrawer}
                      >
                        {" "}
                        ENABLE
                      </FxButton>
                    </Grid>}
                  </Grid>
                </FxCardHeader>
                <Divider />
                <FxCardBody
                  id="faster-funding-card#card-body"
                  className="fx-info-card fx-margin-top-reducer"
                >
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    className="fx-transaction-summery-details"
                  >
                    <Grid item xs={12}>
                      <div className="schedule-detail-summary-card-divider"></div>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid
                      item
                      key="schedule-processing-info-container"
                      direction="row"
                      xs={12}
                    >
                      <FxInfoCard
                        notiltle={true}
                        id={"section-info-card-processing-detailsqwqwq"}
                        column={faster_funding_detail_section}
                        data={fasterFudingDataTransformer(
                          merchant_information_data
                        )}
                      ></FxInfoCard>
                    </Grid>
                  </Grid>
                </FxCardBody>
                <FxCardFooter>
                  {(merchant_information_data?.configuration?.passportFunding?.status && merchant_information_data?.configuration?.passportFunding?.status!=='INACTIVE' && merchant_information_data?.type!=='PAYFAC') && <Typography className="fx-top-area-passport-left-sub-title">
                    In case you want to disable funding via Passport, reach out
                    to customer support
                  </Typography>}
                </FxCardFooter>
              </FxCard>
            </Grid>}
          </>
        );
    })
