/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactComponent as DividerIcon } from '../../../../assets/svg/divider-icon.svg';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import HttpClient from '../../../../libs/utils/httpClient';
import { clean } from '../../../Utils/CommonBaseClass';
import { Logger } from '../../../../libs/utils/logger';
import { getCustomerUrl, processAPIResponse } from '../../../../libs/utils/utils';
import { FxButton } from '../../../Action/FxButton';
import { FxTextEdit } from '../../../Input';
import { useDispatch } from 'react-redux';
const httpClient = HttpClient.getClient();

Logger.debug("EditNicknameModal.tsx", "Edit Account Nickname Modal initializing")

/**
 * This component handles the Edit Account Nickname
 */
export const EditNicknameModal: React.FC<any> = React.memo((props) => {
    
    const { register, control, handleSubmit, formState: { isSubmitting, errors } } = useForm();

    const { accountId, nickname } = props.metadata;
    const dispatch = useDispatch();

    const account_source = {
        url: "account/list",
        method: "POST",   
        data: {
    
            "pageSize": 5,
            "pageNumber": 1,
            "sortOptions": {
                "sortOrder": "ASC",
                "sortBy": "id"
            },
            "criteria": {
                "filters": [
                    {
                        "key": "insightPeriod",
                        "operator": "eq",
                        "values": [
                            "MONTHLY"
                        ]
                    }
                ]
            }
        }
    };
   
    /**
     * Async function handles the form submission
     */
    async function onSubmit(data: any) {
        let status: any;

        const request = {
            "nickName": data.nickname,
        };

        status = await editNickname(clean(request));
        status = processAPIResponse(status)
        
        if(status.status){
            dispatch({type: "DATA_API_PARAM_CHANGED", payload: { id: 'account-list-sub-menu', source: account_source}});     
        } else {
            //api  failed
            FxSnackBar.show({
            text: status.message,
            });
        }
        props.onClose();
    }

    /**
     * Method to call api for update Account
     * @param payloadData : request payload
     */
    async function editNickname(payloadData: any) {
        try {
            const url =  '/account/id/' + accountId;
            const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData)
            return data;
        } catch (err) {
            Logger.error("EditNicknameModal.tsx", "error", err);
            return err;
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs direction='column'>
                    <span>
                        <DividerIcon />
                    </span>
                    <Grid container className="fx-modal-form flex column" paddingTop={'1.25rem'}>
                        <Grid item xs={12} sm={12}>
                            <FxTextEdit
                                register={{ ...register("nickname") }}
                                type="text"
                                passwordIcon={true}
                                className={errors.nickname ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                control={control}
                                rules={{ required: true }}
                                id="edit-nickname"
                                label={"Nickname *"}
                                name="nickname"
                                variant="outlined"
                                defaultValue={nickname? nickname: ''}
                            />
                        </Grid>
                    </Grid>
                    <span className='fx-padding-top-bottom-16'>
                        <DividerIcon />
                    </span>
                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                        <Button
                            variant="contained"
                            className="fx-button fx-button-cancel"
                            id="edit-nickname-cancel-button"
                            onClick={props.onClose}
                        >
                            Cancel
                        </Button>
                        <span className="fx-padding-right-16" />
                        <FxButton
                            disableRipple={false}
                            className="fx-button fx-button-theme"
                            id={"edit-nickname-submit-button"}
                            type="submit"
                            isSubmitting={isSubmitting}
                        >
                            SUBMIT
                        </FxButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
});
