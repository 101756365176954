/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { DATA_STATE } from '../../../../libs/reducer/dataReducer';
import { FxSkeltonList } from '../../Cards/FxSkelton';
import { FxSkeltonRightInfoCard } from '../../Cards/FxSkelton';
import { ViewLinkedDocuments } from '../ViewLinkedDocuments';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { Logger } from '../../../../libs/utils/logger';
import { getEAOrAddressDetailsFromContact } from '../../../Utils/CommonBaseClass';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { CheckImageCard } from '../CheckImageCard';
import moment from 'moment';
import { CollectInfoCard } from './CollectInfoCard';
import { SendInfoCard } from './SendInfoCard';
import { ScheduleDetailSummaryCard } from './ScheduleDetailSummaryCard';
import { useMediaQuery } from 'react-responsive';
import { ApprovalDetailSummaryCard } from './ApprovalSummary';
import { getKey } from '../../../../libs/utils/storageManager';
import { getCustomerUrl } from '../../../../libs/utils/utils';
import { SalesInfo } from './SalesInfo';


Logger.debug("TransactionCards.tsx", "Transaction card initializing")
interface ScheduleParams {
    id: string
    type: string;
}


/**
 * This component handles the redirection to corresponding schedule details cards
 */
export const TransactionCards: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        let schedule_info: any;
        let scheduleId: any;
        ({ context, props } = RegisterComponent(props));
        const params = useParams<ScheduleParams>();
        const isMobileScreen = useMediaQuery({ query: '(max-width: 999px)' });
        const schedule: any = params.type;
        const dispatch = useDispatch()
        const isTransactionApprovalEnabled = getKey('isTransactionApprovalEnabled')
        /**
         * Function to be called before loading the component
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'schedule-processing-data-source' } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'schedule-processing-data-destination' } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'collect-card-sales-info' } });
        }, [])

        if (context && context.data && context.data.body) {
            try {
                schedule_info = { ...context.data.body };
                scheduleId = schedule_info?.id;
                schedule_info.createdInfo = (context.data.body['createdBy']['username'] + '\nOn ' + moment(context.data.body['createdOn']).format('MM/DD/YYYY'));
                schedule_info.updatedInfo = (context.data.body['lastUpdatedBy']['username'] + '\nOn ' + moment(context.data.body['lastUpdatedOn']).format('MM/DD/YYYY'));
            }
            catch (e) { 
                Logger.error("TransactionCards.tsx", "error", e);
            }
        }
        /**
         * useEffect to get source and destination urls
         */
        useEffect(() => {
            let urls: any;
            if (schedule_info) {
                urls = getUrl(schedule_info, schedule)
                fetchAPIData(urls)
            }
        }, [schedule_info?.id])

        useEffect(() => {
            if (schedule === "collect" && scheduleId) {
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'collect-card-sales-info' } });
                dispatch({ type: "DATA_API_REQUESTED", payload: { id: 'collect-card-sales-info', source: { url: getCustomerUrl("/transaction/id/" + scheduleId + "/capture"), method: 'GET', baseUrl: true } } });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [scheduleId])


        /**
         * function to call source and destination API
         * @param url :urls
         */
        function fetchAPIData(url: any) {
            if (url?.sourceUrl) {
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'schedule-processing-data-source' } });
                dispatch({ type: "DATA_API_REQUESTED", payload: { id: "schedule-processing-data-source", source: { url: url?.sourceUrl?.replace("v1/", ""), method: 'GET', baseUrl: true } } });
            }
            if (url?.destinationUrl) {
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'schedule-processing-data-destination' } });
                dispatch({ type: "DATA_API_REQUESTED", payload: { id: "schedule-processing-data-destination", source: { url: url?.destinationUrl?.replace("v1/", ""), method: 'GET', baseUrl: true } } });
            }
        }
        /**
         * to get source details
         */
        const sourceInfo = useSelector((state: any) => {
            if (state.data['schedule-processing-data-source']) {
                return state.data['schedule-processing-data-source']
            }
        });
        /**
        * to get destination details
        */
        const destinationInfo = useSelector((state: any) => {
            if (state.data['schedule-processing-data-destination']) {
                return state.data['schedule-processing-data-destination']
            }
        });
        /**
         * to set values for detail summary card
         */
        const scheduleData = useMemo(() => {
            let tempData: any;
            if (schedule_info) {
                tempData = { ...schedule_info }
                tempData.sourceInfo = sourceInfo?.data?.body;
                tempData.destinationInfo = destinationInfo?.data?.body
                if (schedule_info?.destination?.contact && !schedule_info?.destination?.contact?.ppi) {
                    const externalAccountId = schedule_info?.destination?.contact?.externalAccount ? schedule_info?.destination?.contact?.externalAccount?.id : schedule_info?.destination?.contact?.mailingAddress?.id;
                    if (externalAccountId) {
                        const externalAccount = getEAOrAddressDetailsFromContact(destinationInfo?.data?.body, schedule_info?.destination?.contact?.externalAccount ? "EA" : "ADDRESS", externalAccountId, false)
                        tempData.destinationInfo = externalAccount;
                    }
                    const internationalExternalAccountId = schedule_info?.destination?.contact?.internationalExternalAccount?.id;
                    if (internationalExternalAccountId) {
                        const internationalExternalAccount = getEAOrAddressDetailsFromContact(destinationInfo?.data?.body, "EIA", internationalExternalAccountId, false);
                        tempData.destinationInfo = internationalExternalAccount;
                    }

                }
            }
            return tempData
        }, [schedule_info])

        //select schedule info by type
        /**
         * Function handles the rendering of the corresponding schedule details cards based on the schedule type
         * @returns : info card component
         */
        function getscheduleData() {
            if (schedule === "collect") {
                return <CollectInfoCard id={"collect-info-card" + scheduleId} data={schedule_info} sourceInfo={sourceInfo} destinationInfo={destinationInfo} expandImage={false}></CollectInfoCard>

            } else if (schedule === "send") {
                return <SendInfoCard id={"send-info-card" + scheduleId} data={schedule_info} sourceInfo={sourceInfo} destinationInfo={destinationInfo} ></SendInfoCard>
            }
        }

        /**
         * Get the url for the source and destination
         * @param type
         * @returns
         */
        function getUrl(schedule_info: any, type: string) {
            const urlObject: any = {
                collect: {
                    sourceUrl: schedule_info?.source?.externalAccount?.url ? schedule_info?.source?.externalAccount?.url : schedule_info?.source?.card?.url ? schedule_info?.source?.card?.url : undefined,
                    destinationUrl: schedule_info?.destination?.account?.url ? schedule_info?.destination?.account?.url : undefined
                },
                send: {
                    sourceUrl: schedule_info?.source?.account?.url ? schedule_info?.source?.account?.url : undefined,
                    destinationUrl: schedule_info?.destination?.externalAccount?.url ? schedule_info?.destination?.externalAccount?.url : schedule_info?.destination?.address ? schedule_info?.destination?.address?.url : schedule_info?.destination?.internationalExternalAccount ? schedule_info?.destination?.internationalExternalAccount?.url : undefined
                }

            }
            if (schedule_info?.method === 'BOOK') {
                urlObject['send'] = {
                    sourceUrl: schedule_info?.source?.account?.url ? schedule_info?.source?.account?.url : schedule_info?.source?.account,
                    destinationUrl: schedule_info?.destination?.account?.url ? schedule_info?.destination?.account?.url : schedule_info?.destination?.card?.url ? schedule_info?.destination?.card?.url : schedule_info?.destination?.account
                }
            }
            if (schedule_info?.destination?.contact) {
                urlObject['send'] = {
                    sourceUrl: schedule_info?.source?.account?.url ? schedule_info?.source?.account?.url : schedule_info?.source?.account,
                    destinationUrl: schedule_info?.destination?.contact?.url ? schedule_info?.destination?.contact?.url : schedule_info?.destination?.contact
                }
            }

            // URL for source account details for Collect via contact (ACH and CARD method)
            if((schedule_info?.method === 'ACH' || schedule_info?.method === 'CARD') && schedule_info?.source?.contact && urlObject.collect['sourceUrl'] === undefined) {
                urlObject.collect['sourceUrl'] = schedule_info?.source?.contact?.url;
            }
            return urlObject[type];
        }

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        /**
        * to get salesData
        */
        const salesData = useSelector((state: any) => {
            if (state.data['collect-card-sales-info']) {
                return state.data['collect-card-sales-info']?.data?.body;
            }
        });
        
        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" paddingBottom={isMobileScreen ? '6rem' : undefined} className="fx-container" >
                    <Grid container xs={12} sm={8} className="fx-container-left">
                        {dataLoadStatus ? getscheduleData() : <FxSkeltonList height="31.25rem" />}
                        {(schedule === 'send') && schedule_info?.method === 'CHECK' && schedule_info?.status === 'COMPLETED' && !schedule_info?.destination?.ppi && !schedule_info?.destination?.contact?.ppi &&
                            (dataLoadStatus ?
                                <CheckImageCard id={schedule + '-check-image-' + scheduleId} data={schedule_info}></CheckImageCard>
                                :
                                <FxSkeltonList height="12.5rem" />)
                        }
                        {(schedule === 'send') && schedule_info?.method === 'CHECK' && !schedule_info?.destination?.contact && !schedule_info?.destination?.ppi && !schedule_info?.destination?.contact?.ppi && <ViewLinkedDocuments id={'view' + schedule + '-linked-documents-' + scheduleId} data={schedule_info}></ViewLinkedDocuments>}
                        
                    </Grid>
                    {!isMobileScreen && <Grid container xs={12} sm={8} className="fx-container-right">
                        {schedule_info && scheduleData && dataLoadStatus ?
                            <ScheduleDetailSummaryCard id={"transaction-processing-card-" + scheduleId} data={scheduleData} destinationInfo={destinationInfo}></ScheduleDetailSummaryCard>
                            :
                            <FxSkeltonRightInfoCard height="25rem" />
                        }
                        {isTransactionApprovalEnabled === 'true' &&
                            <>
                            {dataLoadStatus?
                            <ApprovalDetailSummaryCard id={"transaction-summary-card-" + scheduleId} scheduleId={scheduleId}></ApprovalDetailSummaryCard>
                            :
                            <FxSkeltonRightInfoCard height="5rem" />
                        }
                            </>
                        }
                        {
                          salesData && salesData?.length >= 1 && schedule_info?.method?.toLowerCase() === 'card' && schedule_info?.isAutoCapture !== true &&
                         <SalesInfo id="card-collect-transaction-sales-data-card" salesData={salesData} scheduleId={scheduleId} />   
                        }
                        
                    </Grid>}
                    
                </Grid>
        )
    })