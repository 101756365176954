/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Grid, Typography, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as LockIcon } from '../../../assets/svg/confirm-password-lock-icon.svg';

Logger.debug("ConfirmPassword.tsx", "Confirm Password initializing")
/**
 * This component handles the Confirm Password
 */
export const ConfirmPassword: React.FC<any> = React.memo((props) => {
        const { register , handleSubmit, control } = useForm();
        const [isLoading, setIsLoading] = React.useState(false);

        /**
         * Async function handles the cancelling form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            setIsLoading(true);
            setIsLoading(false);
            props.close()
            if (props.openInfo === '1') {
                props.revealCVV()
            }
            else {
                props.showInfo()
            }
        }

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard>
                            <FxCardHeader id="confirm-password-header" className="fx-card-header fx-modal-header">
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item xs >
                                        <Typography id="title-confirm-password-modal" ></Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon">
                                        <IconButton onClick={props.close} id='confirm-password-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="confirm-password-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                    <Grid item container justifyContent="center" xs={12}>
                                        <IconButton
                                            id='confirm-password-lock-close-icon'
                                            className="fx-modal-delete-icon"
                                            size="large">
                                            <LockIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid container justifyContent="center" item xs={12} className="fx-delete-title">
                                        <Typography id="title-delete-modal" variant="h3" >
                                            Please type your Password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("password") }} type='password' passwordIcon={true} className="fx-input-edit" control={control} rules={{ required: true }} id="password" label="Password" name="password" variant="outlined" />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="confirm-password-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-passport fx-button-large"
                                        id={"confirm-password-button"}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={15}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                            'CONFIRM'
                                        )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });
