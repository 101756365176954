/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { clean, getPPISupportedPaymentMethods, updateComponentParams } from '../../Utils/CommonBaseClass';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { createSendMyAccount, getLocation, getMerchantData } from './ScheduleGlobalFunctions';
import { FxSuccess } from '../../Data/FxSuccess';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import HttpClient from '../../../libs/utils/httpClient';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { TransactionCaptureStatus } from './TransactionCaptureStatus';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';

Logger.debug("CreateSendOneTimeCARD.tsx", "create send One Time CARD initializing")

//interface for onetime send card
interface IOneTimeCardsend {
    id: string;
    isEdit?: boolean;
    verifyProps?: {
        open: boolean;
    };
    transactionId?: string;
    authCode?: string;
    isCaptureModal?: boolean;
}

interface IPaymentMethodType {
    label: string;
    value: string;
    disabled?: boolean;
}

/**
 * @author Vineetha
 * This component handles the send creation through one time for card method
 */
export const CreateSendOneTimeCARD: React.FC<IOneTimeCardsend> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const history = useHistory()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, watch, control, getValues, setError, resetField } = useForm();
        let sendId: string = '';
        const params = useParams<any>();
        const passedOtherSendId = params.othersId;
        const scheduleId = params?.id;
        const uiFeat = UIFeatureImpl.getInstance();
        const [location, setLocation] = useState<any>();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const [terminalData, setTerminalData] = useState<[{ label: string, value: string }]>();
        const [merchantList, setMerchantList] = useState<any>([]);
        const httpClient = HttpClient.getClient();
        const customerName = getKey("CustomerName") || getKey('CustomerIndividualFullName');
        // Set the initial payment methods
        const paymentMethodTypeData: IPaymentMethodType[] = [
            { label: "Card Present", value: "CARD_PRESENT" }
        ];

        const method_Tab = useMemo(() => {
            const tabs = [
                {
                    title: 'ACH',
                    link: '/transaction/send/onetime/add/ach',
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Ach Send",
                        operation: "CREATE"
                    }
                },
                {
                    title: "WIRE",
                    link: "/transaction/send/onetime/add/wire",
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Wire Send",
                        operation: "CREATE"
                    }
                },
                {
                    title: 'INTERNATIONAL WIRE',
                    link: '/transaction/send/onetime/add/internationalWire',
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "International Wire Send",
                        operation: "CREATE"
                    }
                },
                {
                    title: 'BOOK',
                    link: '/transaction/send/onetime/add/book',
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Book Send",
                        operation: "CREATE"
                    }
                },
                {
                    title: 'CARD',
                    className: "fx-link-sel",
                    link: '/transaction/send/onetime/add/card',
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Card Send",
                        operation: "CREATE"
                    }
                },
            ];

            // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
            if (getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer) {
                tabs.push({
                    title: "VIRTUAL CARD",
                    link: "/transaction/send/onetime/add/virtualcard",
                    disabled: !!scheduleId,
                    permission: {
                        entity: "Accounts",
                        name: "Virtual Card Send",
                        operation: "CREATE"
                    }
                })
            }

            return tabs;
        }, [scheduleId]);


        const [isloading, setIsloading] = useState(false);

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            handleMerchantListData();
            if (scheduleId) {
                const src = {
                    url: "/transaction/id/" + scheduleId,
                    method: "GET",
                };
                dispatch({
                    type: "DATA_API_PARAM_CHANGED",
                    payload: { id: "edit-send-to-one-time-CARD", source: src },
                });
                updateComponentParams(dispatch, "edit-send-to-one-time-CARD", {
                    isEdit: true,
                });
            } else {
                dispatch({
                    type: "DATA_UPDATE_COMPONENT_REDUCER",
                    payload: {
                        id: "page-title-details",
                        data: { title: "Send Money", backButton: "/dashboard" },
                    },
                });
            }
            const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps })
        }, []);

        /**
        * useEffect to set edit data details
        */
        useEffect(() => {
            if (context?.data?.body) {
                setFormValues(context?.data?.body);
            }
        }, [context?.data?.body]);

        /**
        * Method setting the default values for the text edit component from the context data
        */
        function setFormValues(data: any) {
            setValue("amount", data?.amount);
            setValue("purpose", data?.purpose);
        }

        /**
         * Async function handles the create send form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let authCode: any = '';
            req.type = 'REFUND';

            req.method = 'CARD';
            req.amount = data.amount;
            req.processingDetail = {
                "merchant": {
                    "id": data['merchantId'] ? data['merchantId'] : ''
                },
                terminal: {
                    id: data.terminal
                }
            };
            if(data['location']){
                req['processingDetail']['location'] = {
                    'id' : data['location']
                }
            }

            if (data.externalId) {
                req.externalId = data.externalId;
            }

            req.purpose = data.purpose;
            if (scheduleId) {
                req.id = scheduleId;
                delete req.source;
                delete req.destination;
                delete req.method;
            }

            req = clean(req);
            setIsloading(true);
            let status = await createSendMyAccount(req);
            if (status?.headers?.url) {
                sendId = (status.headers.url).split('/').pop();
            }
            if (status?.headers?.['auth-code']) {
                authCode = status.headers['auth-code']
            }
            status = processAPIResponse(status);
            setIsloading(false);

            if (status.status) {
                //api success
                updateComponentParams(dispatch, props?.id, { isCaptureModal: true, transactionId: sendId, authCode: authCode });
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message
                });
            }
        };

        const handleClose = () => {
            if (scheduleId) {
                history.push("/transactions/send/" + scheduleId);
            } else {
                history.push("/dashboard");
            }
        };

        /**
         * Transaction summary object
         */
        const amount = watch('amount');
        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            'Send Amount': {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            Purpose: { id: "purpose", value: watch("purpose") },
            'Via': {
                id: 'method',
                value: 'CARD',
            },
        };
        const tabClickFunction = () => {
            try {
                const data: any = {
                    schedule: 'send',
                    type: 'one-time',
                    formData: { amount: watch('amount'), purpose: watch('purpose') }
                }
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch (e) {
                Logger.error("CreateSendOneTimeCARD.tsx", "error", e);
            }
        }
        /**
        * Function to handle the closing of the modal.
        */

        const handleCloseModal = () => {
            const verifyProps = { ...props?.verifyProps }
            verifyProps['open'] = false;
            updateComponentParams(dispatch, props.id, { verifyProps });
        };

      /**
       * Merchant list API source
       */
        const merchant_src = {
            url: "merchant/list",
            method: "POST",
            data: {
                "criteria": {
                    "filters": [
                        {
                            "operator": "eq",
                            "key": "account.id",
                            "values": [getKey("selectedAccount")?.id]
                        }
                    ]
                }
            }
        }

        /**
         * Method to get the merchant List
         * @returns data - Merchant List Data
         */
        async function getMerchantList() {
            return await httpClient.post(getCustomerUrl(`/${merchant_src?.url}`, false), merchant_src?.data);
        }

        /**
         * Fetches terminal data for a given merchant ID
         * @param {string} merchantId - The ID of the merchant to fetch terminal data for.
         */
        const getTerminalList = async (merchantId: string) => {
            return await httpClient.get(getCustomerUrl(`/merchant/id/${merchantId}/terminal`, false));
        }

        /**
         * Fetches terminal data for a given merchant ID and updates the state with formatted data and set default value.
         * @param {string} merchantId - The ID of the merchant to fetch terminal data for.
         */
        const fetchAndSetTerminalData = async (merchantId: string) => {
            if (!merchantId) return;

            try {
                const { data } = await getTerminalList(merchantId);

                if (data?.length) {
                    setTerminalData(data.map(({ name, tid }: any) => ({
                        label: name,
                        value: tid,
                    })));

                    if (data.length === 1) {
                        setValue('terminal', data[0].tid);
                    }
                }
            } catch (error) {
                Logger.error('CreateSendOneTimeCard.tsx', 'fetching terminal data', error);
            }
        };


        /**
         * Method to transform Merchant List into label and value object
         * Setting the transformed merchant list into merchantList state object
         */
        async function handleMerchantListData() {
            const data = await getMerchantList();
            if (data?.data?.resources) {
                const transformedMerchant = data?.data?.resources?.map((mList: any) => {
                    return ({
                        value: mList.id.toString(),
                        label: `${'ID: ' + mList.id || ''}${customerName ? ', (' + customerName + ')' : ''}`
                    })
                })
                setMerchantList(transformedMerchant);
                if (transformedMerchant?.length === 1) {
                    !context?.data?.body && setValue('merchantId', transformedMerchant?.[0]?.['value']);
                }
                fetchAndSetTerminalData(getValues('merchantId'));
            }
        }

        /**
         * Method handles the onchange event
         * @param event : onchange event parameter
         */
        const onMerchantChange = async (event: any) => {
            setValue("terminal", null);
            setTerminalData(undefined);
            fetchAndSetTerminalData(getValues('merchantId'));

            setLocation(null);
            try{
                const merchantData = await getMerchantData(event.target.value).then(res => res?.data);
                const merchantLocationUrl = merchantData?.location?.url?.split('/v1/')[1];
                if(merchantLocationUrl){
                    const locationData = await getLocation(merchantLocationUrl).then(res => res.data);
                    if(locationData){
                        setLocation(locationData[0])
                        setValue('location',locationData[0]?.id);
                    }
                }
            }catch(e){
                Logger.error('CreateOnetimeCollectCard.tsx','habdle merchant change', e)
            }

        }

        return (
            <>
                <ScheduleLayout
                    id={'create-send-onetime-card'}
                    type='send'
                    loading={isloading}
                    submitTitle={props?.isEdit ? 'Save' : 'Send Money'}
                    summaryData={summaryData}
                    onSubmit={handleSubmit(onSubmit)}
                    onCancel={() => handleClose()}
                    saveEnabled={true}
                    verifyProps={props?.verifyProps}
                    handleCloseModal={handleCloseModal}
                >
                    {passedOtherSendId && <FxSuccess id="sucess-to-others" header={"Send added successfully"} description={"Send ID - " + passedOtherSendId} />}
                    <ScheduleCreateMethodTab tabItems={getPPISupportedPaymentMethods(method_Tab, "onetime")} id="create-send-onetime-book-form-card-tab" tabClick={tabClickFunction} />
                    <Grid item xs={12} sm={12}>
                        <FxMaterialSelect register={{ ...register("merchantId") }} value={''} rules={{ required: true }} control={control} key={`${context?.data?.body?.processingDetail?.merchant?.id}`} id="create-collect-onetime-card-form-card-merchant-id" placeholder='Select Merchant' name="merchantId" data={merchantList} readOnly={scheduleId ? true : false} label="Merchant*" onChange={onMerchantChange} className={errors.merchantId ? "border-error-input fx-input-edit" : "fx-input-edit"} setValue={setValue} />
                         
                    {getValues('merchantId') && location && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location *'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="add-one-time-collect-card-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={false}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                        />
                        </Grid>}
            
                </Grid>

                        
                    <Grid item xs={12} sm={12}>
                        <FxMaterialSelect
                            register={{ ...register("paymentMethodType") }}
                            control={control}
                            rules={{ required: true }}
                            readOnly={true}
                            id="create-send-onetime-card-payment-method-type"
                            name="paymentMethodType"
                            data={paymentMethodTypeData}
                            value="CARD_PRESENT"
                            label="Payment Method Type*"
                            setValue={setValue}
                        />
                    </Grid>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Grid className="fx-container-create-schdule-summery">
                                <Grid item xs={12} sm={12} className='fx-pb-4'>
                                    <FxMaterialSelect
                                        register={{ ...register("terminal") }}
                                        control={control}
                                        rules={{ required: true }}
                                        id="create-collect-onetime-card-terminal"
                                        name="terminal"
                                        placeholder='Select Terminal'
                                        data={terminalData}
                                        label="Terminal*"
                                        value={terminalData?.length === 1 ? terminalData[0].value : ""}
                                        setValue={setValue}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} className='fx-pb-4'>
                                    <FxTextEdit
                                        register={{ ...register("amount") }}
                                        className={errors.amount ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                        control={control}
                                        rules={{ required: true }}
                                        valuePattern={/-|\+|e|E/}
                                        onWheel={(e: any) => e.target.blur()}
                                        id="create-collect-onetime-card-amount"
                                        label="Amount*"
                                        type="number"
                                        name="amount"
                                        variant="outlined"
                                        placeholder='0.00'
                                        prefix={"$"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} className='fx-pb-4'>
                                    <FxTextEdit
                                        register={{ ...register("purpose") }}
                                        className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                        control={control}
                                        rules={{ required: true }}
                                        id="create-collect-onetime-card-purpose"
                                        label="Purpose*"
                                        placeholder='Purpose'
                                        name="purpose"
                                        variant="outlined" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/** Capture Modal */}
                    <FxModalGeneric
                        id={"capture-modal-popup"}
                        open={props?.isCaptureModal || false}
                        hideClose={true}
                        disableEscapeKeyDown={true} 
                        onClose={(event: any, item: any) => {
                            // Prevent closing when clicking on backdrop
                            if (item === 'backdropClick') {
                                event.stopPropagation();
                            } else {
                                updateComponentParams(dispatch, props?.id, { isCaptureModal: false });
                            }
                        }}
                        componentProps={{ transactionId: props?.transactionId, authCode: props?.authCode, backUrl: '/thanks/transactions/send-onetime-card/' }}
                        className={'fx-modal-small2medium'}
                        component={TransactionCaptureStatus}
                    >
                    </FxModalGeneric>
                </ScheduleLayout>
            </>
        )
    });
