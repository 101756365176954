/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * This component handles the redirection to home page after switching customer
 */
import React, {  useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../../libs/utils/logger';
import { getRedirectInfo } from '../../../Utils/CommonBaseClass';

Logger.debug("SwitchCustomerRedirectPage.tsx", "Switch Customer RedirectPage initializing")

export const SwitchCustomerRedirectPage: React.FC<any> = React.memo(() => {

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation<{ accountList: any }>();
    const accountList: any = location?.state?.accountList;

    /**
     * UseEffect hook redirects to home page
     */
    useEffect(() => {
        history.push(getRedirectInfo(accountList, dispatch, '/dashboard'))
    },[history, dispatch, accountList])

    return (<></>)

})