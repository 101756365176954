/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Component:LeadBeneficialOwnerListView
 * This component handles the  LeadBeneficialOwnerListView card compoent
 */
import React from "react"; // we need this to make JSX compile
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { Grid, Typography } from "@mui/material";
import FxCard from "../../../Container/FxCard";
import FxCardBody from "../../../Container/FxCardBody";
import FxCardHeader from "../../../Container/FxCardHeader";
import { useIsMobileOrTabletScreen } from "../../../../libs/utils/utils";
import { DataGrid } from "../../../Data/DataGrid";
import { FxGridList } from "../../../Data/FxGridList";

type leadBeneficialOwnerListType = {
  id: string;
  beneficialOwnerListSrc?:any;
  data?:any
}
export const LeadBeneficialOwnerListView: React.FC<leadBeneficialOwnerListType> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const isSmallScreen = useIsMobileOrTabletScreen();


  const column: any = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "ID",
        enable: true,
        fieldName: "id",
        type: "fri://fieldtype/default",
        default: true,
      },
      {
        label: "Name",
        enable: true,
        fieldName: "name",
        type: "fri://fieldtype/default",
        default: true,
      },
      {
        label: "Owner Percentage",
        enable: true,
        fieldName: "ownerPercentage",
        type: "fri://fieldtype/default",
        default: true,
      },
      {
        label: "Type",
        enable: true,
        fieldName: "type",
        type: "fri://fieldtype/default",
        default: true,
      }
    ],
  };

  const gridColumn: any = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "enable": true,
        "fieldName": "description",
        "type": "fri://fieldtype/custom",
        "xs": "12",
        "alignItems": 'center'
      },
      {
        "enable": true,
        "fieldName": "divider",
        "type": "fri://fieldtype/custom",
        "xs": "12",
      }

    ]
  }


  return (
    <>
      <Grid id="lead-beneficial-owner-info-grid " item xs={12}>
        <FxCard id="lead-beneficial-owner-info-card" className="fx-theme-passport">
          <FxCardHeader id={"lead-beneficial-owner-info-card#card-header"}>
            <Grid
              id="lead-beneficial-owner-Grid2"
              item
              container
              justifyContent="space-between"
              xs={12}
            >
              <Grid id="lead-beneficial-owner-Grid3">
                <Typography id="title-lead-beneficial-owner-info" variant="h3">
                  Beneficial Owner
                </Typography>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="lead-beneficial-owner-card-body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="lead-beneficial-owner-list-DataGrids" item xs={12}>
              {!isSmallScreen ? (
                <DataGrid
                  id="lead-beneficial-owner-card-list"
                 data={props?.data}
                  tableClass="fx-table-passport"
                  column={column}
                />
              ) : (
                <FxGridList
                  id="lead-beneficial-owner-card-list"

                  data={props?.data}
                  column={gridColumn}
                />
              )}
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </>
  );
});
