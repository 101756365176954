/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import {  Grid, Typography,  } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { FxButton } from '../../Action/FxButton';
import moment from 'moment';
import { useHistory } from 'react-router';

Logger.debug("LocationDetailsInfoCard.tsx", "LocationDetailsInfoCard initializing");
/**
 * Component: LocationDetailsInfoCard
 * Usage: To display location details
 */
export const LocationDetailsInfoCard: React.FC<any> = React.memo((props) => {

        ({ props } = RegisterComponent(props));
        let locationDetailData:any = {};
        const history = useHistory();
        
        /**
        * tranform and set user details API
        */
        if (props?.data) {
            locationDetailData = { ...props.data }
            locationDetailData['createdInfo'] = locationDetailData['createdBy']['username'] + '\nOn ' + moment(locationDetailData['createdOn']).format('MM/DD/YYYY');
            locationDetailData['updatedInfo'] = locationDetailData['lastUpdatedBy']['username'] + '\nOn ' + moment(locationDetailData['lastUpdatedOn']).format('MM/DD/YYYY');
            locationDetailData['addressFull'] = locationDetailData['address']['addressLine1'] + ', ' + (locationDetailData?.address?.addressLine2 ? locationDetailData?.address?.addressLine2 + ', ' : '') + locationDetailData['address']['city'] + ', ' + locationDetailData['address']['state'] + ', ' + locationDetailData['address']['zip']
        }

        const location_detail_left_section = [
            {
                label: 'Doing Business As',
                value: 'doingBusinessAs'
            },
            {
                label: 'Established On',
                value: 'establishmentDate',
                type: "fri://fieldtype/date",
                format: "MM/DD/yyyy",
            },
            {
                label: 'Phone Number',
                value: 'phone',
            }
        ]
        const location_detail_right_section = [
            {
                label: 'Website',
                value: 'website'
            },
            {
                label: 'Address',
                value: 'addressFull'
            },
            {
                label: 'Email',
                value: 'email'
            }
        ]

        const audit_field_left_section = [
            {
                label: 'Created By',
                value: 'createdInfo'
            }
        ]
        const audit_field_right_section = [
            {
                label: 'Last Updated By',
                value: 'updatedInfo'
            }
        ]

        /**
         * Method to handle Edit Button Click
         */
        const handleEditClick = () =>{
            history.push('/location/edit/' + props?.data?.id)
        }

        return (
            <Grid item xs={12} sm={9}>
                <FxCard className="fx-theme-passport">
                    <FxCardHeader id="location-detail-header">
                        <Grid container item xs={12} justifyContent="space-between">
                            <Typography variant="h3" >Location Information</Typography>
                            <Grid>
                                <span className='fx-padding-right-16'></span>
                                <FxButton id="location-detail-edit-button" title="Edit" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<EditIcon />} onClick={handleEditClick} >
                                    Edit
                                </FxButton>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="location-detail-Card-Body" className="fx-home-ledger-list">
                        <Grid id={"location-detail"} item xs={12} marginBottom='1rem'>
                            <FxInfoCard id="location-detail-section-info-card" title="BASIC LOCATION INFORMATION" leftcolumn={location_detail_left_section} rightcolumn={location_detail_right_section} data={locationDetailData}></FxInfoCard>
                        </Grid>
                        <Grid id={"location-detail"} item xs={12}>
                            <FxInfoCard id="location-detail-section-info-card-second" title="Audit Fields" leftcolumn={audit_field_left_section} rightcolumn={audit_field_right_section} data={locationDetailData}></FxInfoCard>
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid>
        )
    })
