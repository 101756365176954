/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useCallback, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import { maskAccountNumber } from '../../Utils/CommonBaseClass';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';
import DEFAULT_CONFIG from '../../../configs/default.json';



Logger.debug("DebitCardDetailsInfoCard.tsx", "Debit Card Details Info Card initializing");

/**
 * Component: DebitCardDetailsInfoCard
 * Usage: To display Debit Card Details Info Card
 */

export const DebitCardDetailsInfoCard: React.FC<any> = React.memo(
    (props) => {

        const dispatch = useDispatch()

        const debit_card_data = useMemo(() => {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Manage Debit Card' + (props?.data?.cardNumber ? ' (' + maskAccountNumber(props?.data?.cardNumber, false) + ')' : props?.data?.cardLast4 ? ' (***' + props?.data?.cardLast4 + ')':''),backButton:'/debitcards' } }}); 
            const debit_data = { ...props.data }
            debit_data.createdInfo = (props?.data?.createdBy?.username+ '\nOn ' + moment(props.data['createdOn']).format('MM/DD/YYYY'));
            debit_data.updatedInfo = (props?.data?.lastUpdatedBy?.username+ '\nOn ' + moment(props.data['lastUpdatedOn']).format('MM/DD/YYYY'));
            debit_data.holderName = props.data?.cardHolder && props.data?.cardHolder?.type === "BENEFICIAL_OWNER" ? props.data?.cardHolder?.name+ ' (Beneficial owner - ' + props.data?.cardHolder?.id+ ')' : props.data?.cardHolder?.name
            debit_data.cardProgram = props.data?.cardProgram
            return debit_data
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.data])

        const debit_card_section_left = [
            {
                label: 'Card Holder Name',
                value: 'holderName'
            },
            {
                label: 'Card Program',
                value: 'cardProgram'
            },
            {
                label: 'Card ID',
                value: 'id'
            },
        ];

        const debit_card_section_right = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason'
            }
        ];

        const debit_card_audit_section_left = [
            {
                label: 'Created',
                value: 'createdInfo'
            }
        ];

        const debit_card_audit_section_right = [
            {
                label: 'Last Updated',
                value: 'updatedInfo'
            }
        ];

        /**
         * This method handles the opening of the ATM Locator link
         */
        const handleAtmLocatorUrl = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            window.open(DEFAULT_CONFIG['ATM_LOCATOR_URL'], '_blank');
        }, []);

        return (
            <Grid id="debit-card-details-info-Grid " className="fx-margin-bottom-16" item xs={12}>
                <FxCard id="debit-card-details-info-card" className="fx-theme-passport">
                {props?.dataLoadStatus ?<>
                    <FxCardHeader id={'debit-card-details-info-card#card-header'}>
                        <Grid id="debit-card-details-info-card-card-header-grid" item container justifyContent="space-between" xs={12} alignItems='center' >
                            <Typography id="debit-card-details-info-card-card-header-grid-title" variant="h4" >
                               Card Information
                            </Typography>
                            <FxButton id="debit-card-details-info-card-locate-atm-button" className="fx-button fx-button-passport-border fx-svg-theme fx-atm-locator" variant="contained" startIcon={<SearchIcon/>} onClick={(event: any) => handleAtmLocatorUrl(event)} >
                                LOCATE AN ATM
                            </FxButton>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="deposit-info-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                        {props?.data && <Grid container item xs={12} direction="row" >
                            <Grid item xs={12} direction="column">
                                <FxInfoCard id={"section-info-card-debit-card-details-basic-info-one" + props.data['id']} title="BASIC INFORMATION" leftcolumn={debit_card_section_left} rightcolumn={debit_card_section_right} data={debit_card_data} />
                            </Grid>
                            <Grid item xs={12} direction="column">
                                <FxInfoCard id={"section-info-card-debit-card-details-basic-info-one" + props.data['id']} title="AUDIT FIELDS" leftcolumn={debit_card_audit_section_left} rightcolumn={debit_card_audit_section_right} data={debit_card_data} />
                            </Grid>
                        </Grid>}
                    </FxCardBody></>:
                <FxSkeltonList height='15rem'/>}
                </FxCard>
            </Grid >
        )
    })