/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { FxButton } from "../../Action/FxButton";
import { clean, maskAccountNumber, isMethodSupported, truncateChars } from "../../Utils/CommonBaseClass";
import FxCheckAvailabilityPPI from "../../Data/FxCheckAvailabilityPPI";
import { ReactComponent as EditIcon } from "../../../assets/svg/edit-new.svg";
import { FxCopyToClipboard } from "../../Data/FxCopyToClipboard";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { getKey } from "../../../libs/utils/storageManager";
import HttpClient from "../../../libs/utils/httpClient";
import { FxStatus } from "../../Input/FxStatus/FxStatus";
import FxSnackBar from "../../Utils/fx-snack-bar";
const httpClient = HttpClient.getClient();

Logger.debug("ManagePPI.tsx", "Manage Passport Payment ID");
/**
 * This component handles the form for managing passport payment id
 */
const PPIAccountDetails: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));

  const history = useHistory();
  const dispatch = useDispatch();

  const [customerData, setCustomerData] = useState<{ value: string; label: string }[]>();
  let customerInfo: any;
  let customerTypeValue: any;
  const [isLoading, setIsLoading] = useState(false);
  const [isChangePPI, setIsChangePPI] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState<boolean | undefined>(true);
  const isPPIEnabled = getKey("isPPIEnabled")?.toLowerCase() === "true";

  //rest the component on unmount.so that when redirect back page it will call the api again
  useEffect(
    () => () => {
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
  } = useForm();

  const passportPaymentID = useMemo(() => {
    if (context?.data?.body) {
      const customerPPIData = context?.data?.body;
      setValue("ppi", customerPPIData?.ppi?.replace("@ppi", ""));
      return customerPPIData?.ppi;
    }
    return "";
  }, [context?.data?.body, setValue]);

  useEffect(() => {
    if (context?.data?.body) {
      setValue(
        "book",
        context?.data?.body?.defaults?.book?.account?.url?.split(
          "account/id/"
        )[1]
      );
      setValue(
        "ach",
        context?.data?.body?.defaults?.ach?.externalAccount?.url?.split(
          "externalAccount/id/"
        )[1]
      );
      setValue(
        "check",
        context?.data?.body?.defaults?.check?.address?.url?.split(
          "mailingAddress/id/"
        )[1]
      );
      setValue(
        "wire",
        context?.data?.body?.defaults?.wire?.externalAccount?.url?.split(
          "externalAccount/id/"
        )[1]
      );
    }
  }, [context?.data?.body, customerData, setValue]);

  const ea_src = {
    url: "/externalAccount/list",
    method: "POST",
    //expiry: 3600,
    data: {
      pageNumber: 1,
      pageSize: 25,
      criteria: {
        filters: [],
      },
    },
  };

  const book_src: any = {
    url: "account/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      sortOptions: {
        sortOrder: "DESC",
        sortBy: "createdOn",
      },
      criteria: {
        filters: [
        ],
      },
    },
  };

  /**
   * Function handles transformation of source data
   * @param data : source data array
   * @returns : array
   */
  function sourceDataTransformation(data: any) {
    try {
      const optionData = data?.map(function (option: any) {
        return {
          value: option.id,
          label: <>{option.bankInfo.name +
            " (" + maskAccountNumber(option.accountNumberLast4, false) +")"} &nbsp;
            <FxStatus id="account-status" value={option?.status} fill={true} className="fx-status"></FxStatus></>
        };
      });
      return clean(optionData);
    } catch (e) { }
  }

  /**
   * Function handles transformation of book source data
   * @param data : source data array
   * @returns : array
   */
  function bookSourceDataTransformation(data: any) {
    try {
      const optionData = data?.map(function (option: any) {
        return {
          value: option.id,
          label:<>{(option?.nickName ? option.nickName : "") +
          " " +(option?.purpose ? option?.purpose : "") +" (" + maskAccountNumber(option?.accountNumber, false) +")"} &nbsp;
          <FxStatus id="account-status" value={option?.status} fill={true} className="fx-status"></FxStatus></>

        };
      });
      return clean(optionData);
    } catch (e) {
      Logger.error("PPIAccountDetails.tsx", "error", e);
    }
  }

  /**
   * Async function handles the api call for getting customer address for check withdrawal
   */
  useEffect(() => {
    async function customerApi() {
      try {
        await httpClient.get(getCustomerUrl("/", false)).then((response: any) => {
          if (response.error) {
            FxSnackBar.show({
              text: 'Please try again',
            });
          } else {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            customerInfo = response.data;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            customerTypeValue = customerInfo?.business
                ? customerInfo.business.mailingAddress
                : customerInfo?.individual?.mailingAddress;
            setCustomerData(getMailingAddress(customerTypeValue));
          }
        });
      } catch (err) {
        Logger.error("PPIAccountDetails.tsx", "error", err);
      }
    }
    customerApi();
  });


  /**
   * Method to handle transformation of customer addresses
   * @param data : source data array
   * @returns : array
   */
  function getMailingAddress(data: any) {
    return data.map((item: any) => {
      return {
        value: item?.id,
        label: item?.addressLine1 + ' ' + (item?.addressLine2 ? item?.addressLine2 + ' ' : '') + item?.city + ' ' + item?.state + ' ' + item?.zip
      }
    });
  }

  /**
   * Method to handle close button action event
   */
  const handleClose = () => {
    history.push("/dashboard");
  };

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    setIsLoading(true);
    const req = {
      ppi: data?.ppi?.trim() + "@ppi",
      defaults: {
        book: isMethodSupported("book") && data?.book
          ? {
              account: {
                id: data?.book ? data?.book.toString() : "",
              },
            }
          : undefined,
        ach: isMethodSupported("ach") && data?.ach
          ? { externalAccount: { id: data?.ach ? data?.ach.toString() : "" } }
          : undefined,
        check: isMethodSupported("check") && data?.check
          ? { address: { id: data?.check ? data?.check.toString() : "" } }
          : undefined,
        wire: isMethodSupported("wire") && data?.wire
          ? {
              externalAccount: { id: data?.wire ? data?.wire.toString() : "" },
            }
          : undefined,
      },
    };

    let ppIdStatus = await updatePaymentId(req);
    ppIdStatus = processAPIResponse(ppIdStatus);
    setIsLoading(false);
    if (ppIdStatus.status) {
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: 'PPI Updated Successfully!',
      });
      history.push("/");
    } else {
      //api  failed
      FxSnackBar.show({
        text: ppIdStatus.message,
      });
    }
  }

  /**
   * API call function to update customer ppi
   * @param req :request
   */
  async function updatePaymentId(req: any) {
    try {
      const url = "/ppi";
      const data: any = await httpClient
        .post(getCustomerUrl(url, false), req)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return { ...error };
        });
      return data;
    } catch (err) {
      return err;
    }
  }

  return (
    <Grid id={"manage-ppi-grid"} item xs={12}  container justifyContent="center">
      <Grid item xs={12}  sm={8} >
      <FxCard
        id="manage-ppi-card"
        className=" fx-card-general fx-theme-passport fx-no-box-shadow fx-manage-ppi"
      >
        {!isEmpty(context?.data) && (
          <>
            <div className="fx-form-edit-profile flex column">
              <form id="manage-ppi-form" onSubmit={handleSubmit(onSubmit)}>
                <FxCardBody
                  id={"manage-ppi-form-card-body"}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      className="fx-container-create-schdule-middle-scroll"
                    >
                      <Grid item container xs={12} sm={12} alignItems="center">
                        {!isChangePPI ? (
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            alignItems="center"
                          >
                            <Grid item container xs={8} alignItems="center">
                              <Grid item>
                                <Typography className="fx-theme-color">
                                  {"Payment ID : " + truncateChars(passportPaymentID, 25)}
                                </Typography>
                              </Grid>
                              {passportPaymentID && (
                                <Grid item>
                                  <FxCopyToClipboard
                                    id="passport-payment-id-copy-to-clipboard"
                                    value={passportPaymentID}
                                    message="Copied to clipboard"
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <Grid item container xs alignItems="center" justifyContent="flex-end">
                            <Grid item>
                              <FxButton
                                id="ppi-change"
                                variant="contained"
                                className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme"
                                startIcon={<EditIcon />}
                                onClick={() => {
                                  setIsChangePPI(true);
                                }}
                              >
                                EDIT
                              </FxButton>
                            </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={10} spacing={1}>
                            <FxCheckAvailabilityPPI
                              id={"manage-ppi-check-availability"}
                              currentPPI={passportPaymentID?.replace(
                                "@ppi",
                                ""
                              )}
                              control={control}
                              fieldName={"ppi"}
                              errors={errors}
                              watch={watch}
                              setValue={setValue}
                              register={register}
                              onVerificationFinished={(status) =>
                                setSaveEnabled(status)
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className="fx-container-create-schdule-summery-title"
                      >
                        <Typography id="check-details">
                          {"DEFAULTS"}
                        </Typography>
                      </Grid>
                      {isMethodSupported("book") && (
                        <Grid item xs={12} sm={12}>
                          <FxMaterialSelect
                            register={{ ...register("book") }}
                            id="manage-ppi-form-book"
                            name="book"
                            source={book_src}
                            control={control}
                            dataTransformation={bookSourceDataTransformation}
                            value={
                              context?.data?.body
                                ? context?.data?.body?.defaults?.book?.account?.url?.split(
                                    "account/id/"
                                  )[1]
                                : ""
                            }
                            label="BOOK"
                            setValue={setValue}
                          />
                        </Grid>
                      )}
                      {isMethodSupported("ach") && (
                        <Grid item xs={12} sm={12}>
                          <FxMaterialSelect
                            register={{ ...register("ach") }}
                            id="manage-ppi-form-ach"
                            name="ach"
                            source={ea_src}
                            control={control}
                            dataTransformation={sourceDataTransformation}
                            value={
                              context?.data?.body
                                ? context?.data?.body?.defaults?.ach?.externalAccount?.url?.split(
                                    "externalAccount/id/"
                                  )[1]
                                : ""
                            }
                            label="ACH"
                            setValue={setValue}
                          />
                        </Grid>
                      )}
                      {isMethodSupported("check") && (
                        <Grid item xs={12} sm={12}>
                          <FxMaterialSelect
                            register={{ ...register("check") }}
                            id="manage-ppi-form-check"
                            name="check"
                            control={control}
                            data={customerData}
                            dataTransformation={sourceDataTransformation}
                            value={
                              context?.data?.body
                                ? context?.data?.body?.defaults?.check?.address?.url?.split(
                                    "mailingAddress/id/"
                                  )[1]
                                : ""
                            }
                            label="CHECK"
                            setValue={setValue}
                          />
                        </Grid>
                      )}
                      {isMethodSupported("wire") && (
                        <Grid item xs={12} sm={12}>
                          <FxMaterialSelect
                            register={{ ...register("wire") }}
                            id="manage-ppi-form-wire"
                            name="wire"
                            source={ea_src}
                            control={control}
                            dataTransformation={sourceDataTransformation}
                            value={
                              context?.data?.body
                                ? context?.data?.body?.defaults?.wire?.externalAccount?.url?.split(
                                    "externalAccount/id/"
                                  )[1]
                                : ""
                            }
                            label="WIRE"
                            setValue={setValue}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} className="fx-divider-account">
                        <Divider />
                      </Grid>
                      <Grid container xs={12} justifyContent="flex-end">
                        <Grid className="fx-padding-right-15">
                          <FxButton
                            variant="contained"
                            className="fx-button fx-button-cancel"
                            id="cancel-manage-ppi-page"
                            onClick={handleClose}
                          >
                            Cancel
                          </FxButton>
                        </Grid>
                        <FxButton
                          disableRipple={false}
                          disabled={
                            !isPPIEnabled ||
                            !saveEnabled ||
                            isEmpty(getValues("ppi"))
                          }
                          className={isPPIEnabled && saveEnabled && !isEmpty(getValues("ppi")) ? "fx-button fx-button-theme" : "fx-button fx-button-disabled"}
                          id={"save-manage-ppi-page"}
                          type="submit"
                        >
                          {isLoading ? (
                            <CircularProgress
                              size={20}
                              style={{
                                color: "white",
                              }}
                            />
                          ) : (
                            "Save"
                          )}
                        </FxButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </FxCardBody>
              </form>
            </div>
          </>
        )}
      </FxCard>
      </Grid>
    </Grid>
  );
});

export default PPIAccountDetails;
