/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect, useMemo, useState } from 'react'; // we need this to make JSX compile
import { Chip, Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { Controller, useForm } from 'react-hook-form';
import { ISource } from '../../../types/common.interfaces';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useDispatch } from 'react-redux';
import HttpClient from '../../../libs/utils/httpClient';
import { useHistory, useParams } from 'react-router-dom';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { checkPermission, clean, convertEndData, convertToOnDayFormat, convertToTitleCaseAndAppendWithS, createCommonVerifyProps, deleteUnwantedRequest, renderError, transformRecurringRequestGlobal, updateComponentParams } from '../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { accountLabelTransformation, createSendMyAccount, dataSourceTransformation, getLocationData } from './ScheduleGlobalFunctions';
import { FxSwitch } from '../../Action/FxSwitch';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import { FxRecurring } from './Recurring/FxRecurring';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxFileUpload } from '../../Input/FxFile/FxFileUpload';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { ScheduleTopSection } from './ScheduleTopSection';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
const httpClient = HttpClient.getClient();

const acc_src: ISource = {
    url: "account/list",
    method: "POST",
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};

const destination_src: ISource = {
    url: "/internationalExternalAccount/list",
    method: "POST",
    data:
    {
        pageNumber: 1,
        pageSize: 25,
        criteria: {
            filters: [
            ]
        }
    }
};

const docTypeData = [
    { label: 'Contract', value: 'CONTRACT' }
];

const currency = [
    { label: 'USD', value: 'USD' }
]


Logger.debug("CreateSendAccountInternationalWIRE.tsx", "create send Account International Wire initializing")
/**
 * This component handles the send creation through my account for International Wire method
 */
export const CreateSendAccountInternationalWIRE: React.FC<any> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const history = useHistory();
        const { register, formState: { errors }, handleSubmit, setValue, resetField, control, clearErrors, setError, watch } = useForm();
        const params = useParams<any>();
        const scheduleId = params?.id;
        const withdrawalInfo = context?.data?.body;
        const isEditMode = !!scheduleId;
        const isRecurringRequired = false;
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
        const individual = uiFeat.getIndividualCustomerFeature().available;
        const [location, setLocation] = useState<any>();
        const method_Tab = useMemo(() => [
            {
                title: 'ACH',
                link: '/transaction/send/add/ach',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "CREATE"
                },
                {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "CREATE"
                }]
            },
            {
                title: 'WIRE',
                link: '/transaction/send/add/wire',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Wire Send",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "CREATE"
                }]
            }, {
                title: 'INTERNATIONAL WIRE',
                className: 'fx-link-sel',
                link: '/transaction/send/add/internationalWire',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "International Wire Send",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "CREATE"
                }]
            },
          ...(!individual? [{
                title: 'CHECK',
                link: '/transaction/send/add/check',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "CREATE"
                }]

            }] : []),
            {
                title: 'Book',
                link: '/transaction/send/transfer',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "CREATE"
                }]
            }
        ], [isEditMode]);
        const isEdit = !!props.source;
        const [isloading, setIsloading] = useState(false);
        const [selectedDestination, setSelectedDestination] = useState('');
        const [destinationData, setDestinationData] = useState<any>([]);
        const [repeatStatus, setRepeatStatus] = useState(false);
        const [allowDuplicate, setAllowDuplicate] = useState(false);
        const [recurringFormData, setRecurringFormData] = useState<any>();
        const [fileUploadData, setFileUploadData] = useState<any>();

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-send-account-internationalWire-form-card-destination' } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-account-internationalWire-form-card-file-id-file-upload', 'files': [] } });
            setValue('type', 'REGULAR')
            const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps })
            if (!scheduleId) {
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Send Money', backButton: '/dashboard' } } });
                return;
            }
            const src = {
                url: "/transaction/id/" + scheduleId,
                method: "GET",
            };
            dispatch({
                type: "DATA_API_PARAM_CHANGED",
                payload: { id: "edit-send-to-account-internationalWire", source: src },
            });
            
        }, []);

        /**
         * Method to set file upload data
         * @param event :event object
         */
        function passdata(event: any) {
            try {
                setFileUploadData(event)
                if (event.length > 0 && errors['fileError']) {
                    clearErrors('fileError');
                }
            }
            catch (e) { 
                Logger.error("CreateSendAccountInternationalWIRE.tsx", "error", e); 
            }
        }
        /**
         * Method to remove file upload data
         * @param id : file id
         */
        const removefile = (id: any) => {
            const newval = fileUploadData.filter((item: any) => item.id !== id);
            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-account-internationalWire-form-card-file-id-file-upload', 'files': newval } });
            setFileUploadData(newval)
        }

        /**
         * Method triggers if the recurring updates
         */
        const handleUpdateRecurring = (data: any) => {
            setRecurringFormData(data);
        }
        /**
         * Method enables and disables the allow duplicate
         * @param event : allow duplicate status
         */
        const handleAllowDuplicate = (event: any) => {
            setAllowDuplicate(event)
        }

        /**
         * Method enables and disables the repeat functionality
         * @param event : repeat status
         */
        const handleRepeat = (event: any) => {
            setRepeatStatus(event);
        }

        /**
         * Async function handles the api call for getting data
         * @param accountID : accountID
         * @returns : response object
         */
        async function getExternalAccountData(accountID: any) {
            try {
                const url = "/internationalExternalAccount/id/" + accountID;
                return await httpClient.get(getCustomerUrl(url, false));
            } catch (err) {
                Logger.error("CreateSendAccountInternationalWIRE.tsx", "error", err);
                return false;
            }
        }

        /**
        * Async function handles the api call for getting source data and set the data for payer by default
        */
        async function setDefaultExternalAccount(accountID: any) {
            const externalAccountData = await getExternalAccountData(accountID);
            if (externalAccountData && externalAccountData.data) {
                if (isEditMode) {
                    setValue("destination", formatToDefaultDestination(externalAccountData.data));
                    setSelectedDestination(formatToDefaultDestination(externalAccountData.data));
                }
            }
        }

        /**
         * Function handles formatting the data to international external account format
         * @param data : object of international external account data
         * @returns : label
         */
        function formatToDefaultDestination(data: any) {
            try {
                const label: any = data['holderName'] + ', ' + data['type'] + '(*' + data['accountNumberLast4'] + ')';
                return label;
            }
            catch (e) { 
                Logger.error("CreateSendAccountInternationalWIRE.tsx", "error", e); 
            }
        }

        /**
         * Function handles transformation of destination data
         * @param data : destination data array
         * @returns : array
         */
        function datadestinationTransformation(data: any) {
            setDestinationData(data);
            return data?.map((account: any) => ({
                value: account.id,
                label: account['holderName'] + ', ' + account['type'] + '(*' + account['accountNumberLast4'] + ')'
            })) || [];
        }

        /**
         * Function handles setting the data for destination section
         * @param data : destination data array
         */
        function setValueDestinationFunction(data: any) {
            if (!data.value) {
                return;
            }
            const foundItem = destinationData?.find((item: any) => item.id === data.value);
            if (!foundItem) {
                return;
            }
            setSelectedDestination(foundItem['holderName']+ ', ' + foundItem['type'] + '(*' + foundItem['accountNumberLast4'] + ')');
        }

        /**
         * Function handles cancel button click
         */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push("/transactions/send/" + context.data.body.id);
            } else {
                history.push("/dashboard");
            }
        };

        /**
         * Method handles setting form values for edit schedule scenario
         * @param data :
         */
        const setFormValues = (data: any) => {
            setValue("amount", data?.amount);
            setValue("purpose", data?.purpose);
            setValue("memo", data?.processingDetail?.memo);
            setAllowDuplicate(data?.allowDuplicate);
            if (!data?.destination?.internationalExternalAccount?.id) {
                return;
            }
            setDefaultExternalAccount(data?.destination?.internationalExternalAccount?.id);
        };

        /**
         * useEffect to set edit data details
         */
        useEffect(() => {
            if (!context?.data?.body) {
                return;
            }
            setFormValues(context?.data?.body);
            if(context?.data?.body?.processingDetail?.location){
                getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
            }
        }, [context?.data?.body]);

        /**
         * Async function handles the create send form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let request: any = {};
            let recurringRequest: any = {};
            if (!isEdit && !data.destination) {
                setError("destination", {
                    type: "manual",
                    message: "Destination is required",
                });
                return;
            }
            if (repeatStatus) {
                recurringRequest = transformRecurringRequestGlobal(data, recurringFormData)
                req = {
                    name: data?.name,
                    transactionDetail: recurringRequest['transactionDetail'],
                    action: []
                }
                req['transactionDetail']['startDate'] = data?.startDate
                request = {
                    transaction: {
                        source: {
                            account: {
                                id: data.source
                            }
                        },
                        method: 'INTERNATIONAL_WIRE',
                        type: 'REGULAR',
                        amount: data.amount,
                        purpose: data.purpose,
                        memo: data.memo,
                        allowDuplicate: allowDuplicate
                    }

                };
                if (data.destination && !isEdit) {
                    request['transaction']['destination'] = {
                        internationalExternalAccount: {
                            id: data.destination
                        }
                    }
                }
                if (data.memo) {
                    req['processingDetail'] = {};
                    req['processingDetail']['memo'] = data.memo;
                }
                if(data['location']){
                    req['processingDetail']['location'] = {
                        'id' : data['location']
                    }
                }
                req['action'].push(request)
                if (fileUploadData?.length) {
                    req["linkedDocument"] = fileUploadData.map((item: any) => ({
                        purpose: "Authorization",
                        document: { id: item.id }
                    }));
                }
            }
            else {
                req = {
                    source: {
                        account: {
                            id: data.source
                        }
                    },
                    method: 'INTERNATIONAL_WIRE',
                    type: 'REGULAR',
                    amount: data.amount,
                    purpose: data.purpose,
                    allowDuplicate: allowDuplicate
                };
                if (data.destination && !isEdit) {
                    req['destination'] = {
                        internationalExternalAccount: {
                            id: data.destination
                        }
                    }
                }
                if (data.memo) {
                    req['processingDetail'] = {
                        memo: data.memo
                    }
                }
                if(data['location']){
                    req['processingDetail']['location'] = {
                        'id' : data['location']
                    }
                }
            }
            if (isEditMode) {
                req["id"] = context?.data?.body?.id;
                const unwantedRequestArray = ["source", "destination", "method", "type"];
                deleteUnwantedRequest(req, unwantedRequestArray);
            }
            req = clean(req);
            setIsloading(true);
            let status: any;
            status = await createSendMyAccount(req);
            let withdrawalId = '';
            if (status?.headers?.url) {
                withdrawalId = (status.headers.url).split('/').pop();
            }

            status = processAPIResponse(status)
            setIsloading(false);
            if (status.status) {
                //api success
                const successMessage = withdrawalInfo ? 'Send Updated Successfully!' : repeatStatus ? 'Recurring Created Successfully!' : 'Send Created Successfully!';
                const redirectUrl = withdrawalInfo ? '/transactions/send/' + withdrawalInfo['id'] : repeatStatus ? '/thanks/transactions/send-internationalWire-recurring/' : '/thanks/transactions/send-internationalWire/';
                const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                if(status.responseData?.sessionId){
                    const verifyProps = {
                        ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, 'send'),
                        successMessage : successMessage,
                        redirectUrl: redirectUrl,
                        redirectUrlFromHeader:withdrawalInfo?false: true,
                        postSuccessEvent:handlePostSuccessEvent
                    }
                    updateComponentParams(dispatch, props.id, { verifyProps })
                }
                else{
                    if (withdrawalInfo) {
                        FxSnackBar.show({
                            autoHideDuration: 1000,
                            severity: 'success',
                            text: 'Send Updated Successfully!',
                        });
                        setTimeout(() => {
                            history.push('/transactions/send/' + withdrawalInfo['id'])
                        }, 1000);
                    }
                    else {
                        FxSnackBar.show({
                            autoHideDuration: 1000,
                            severity: 'success',
                            text: repeatStatus ? 'Recurring Created Successfully!' : 'Send Created Successfully!',
                        });
                        if (repeatStatus) {
                            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-account-internationalWire-form-card-file-id-file-upload', 'files': [] } });
                        }
                        setTimeout(() => {
                            if (repeatStatus) {
                                history.push('/thanks/transactions/send-internationalWire-recurring/' + withdrawalId)
                            }
                            else {
                                history.push('/thanks/transactions/send-internationalWire/' + withdrawalId)
                            }
    
                        }, 1000);
                    }
                }
              
            }
            else {
                //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
                }
               
            

        }

        /**
         * The main summary data
         */
        const amount = watch('amount');
        const destinationId = watch('destination');
        const destinationLabel = useMemo(() => {
            const destination = destinationData.find(({ id }: any) => id === destinationId);
            if (destination) {
                return formatToDefaultDestination(destination);
            } else {
                if (selectedDestination) {
                    return selectedDestination;
                }
            }
        }, [destinationId, destinationData, selectedDestination]);
        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            'Send Amount': {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'From': {
                id: 'from',
                value: accountLabelTransformation(getKey('selectedAccount'))
            },
            ...(businessCustomer
            ? { Purpose: { id: "purpose", value: watch("purpose") } }
            : { Memo: { id: "purpose", value: watch("purpose") } }),
            'Via': {
                id: 'method',
                value: 'INTERNATIONAL WIRE',
            },
            'To International External Account': {
                id: 'to',
                value: destinationLabel,
            },
            'Allow Duplicate': {
                id: 'allowDuplicate',
                value: allowDuplicate === true ? "Yes" : "No",
            },
            'Currency': {
                id: 'currency',
                value: 'USD',
            }
        };

        if(businessCustomer) {
            summaryData['Memo'] = {
                id: 'memo',
                value: watch('memo'),
            }
        }

        /**
         * Recurring related summary data
         */
        const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
            if (!repeatStatus || !recurringFormData) {
                return {}
            }
            const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
            const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
                ? convertToTitleCaseAndAppendWithS(
                    recurringFormData?.repeatEveryFormData?.repeatEverySelect
                )
                : '';
            const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
                'Repeat every': {
                    id: 'repeat-every',
                    value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
                },
            }
            if (onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
                result['On'] = {
                    id: 'repeat-on',
                    value: convertToOnDayFormat(recurringFormData),
                };
            }
            const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
            const ends = recurringFormData?.endFormData
                ? convertEndData(recurringFormData?.endFormData)
                : '';
            if (ends || endOn) {

            }
            result['Ends'] = endOn ? {
                id: 'repeat-ends',
                value: ends,
                ValueComponent: ({ value }) => (
                    <>
                        On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
                    </>
                )
            } : {
                id: 'repeat-ends',
                value: ends,
            };
            return result;
        }, [recurringFormData, repeatStatus]);

        const topSectionDefaultValues = {
            amount: context?.data?.body?.amount,
            source: context?.data?.body?.source?.id,
            purpose: context?.data?.body?.purpose
        }
        const tabClickFunction = () => {
            try {
                const data: any = {
                    schedule: 'send',
                    type: 'my-account',
                    formData: { amount: watch('amount'), purpose: watch('purpose') }
                }
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch (e) { 
                Logger.error("CreateSendAccountInternationalWIRE.tsx", "error", e); 
            }
        }
                /**
                 * Function to handle the closing of the modal.
                 */
            
        const handleCloseModal = () => {
            const verifyProps = {...props.verifyProps}
            verifyProps['open'] = false;
            updateComponentParams(dispatch, props.id, { verifyProps });
        };
         /** 
            *This function is called upon successful completion of a post operation.
            *It dispatches an action to update component parameters related to file uploads.
            */
            const handlePostSuccessEvent=()=>{
                dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-account-internationalWire-form-card-file-id-file-upload', 'files': [] } });
            }
        return (
          <>
            <ScheduleLayout
                id={'create-send-account-internationalWire'}
                type='send'
                loading={isloading}
                submitTitle={repeatStatus ? 'Create' : isEditMode ? 'Save Changes' : 'Send Money'}
                summaryData={{ ...summaryData, ...summaryRepeatData }}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={handleClose}
                saveEnabled={true}
                verifyProps={props?.verifyProps}
                handleCloseModal={handleCloseModal}
            >
                <ScheduleTopSection id="create-send-account-internationalWire-schedule-top-section" method='INTERNATIONAL_WIRE' schedule="send" type="my-account" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-account-internationalWire-form-card-amount-textbox" sourceId="create-send-contact-internationalWire-form-card-source" purposeId="create-send-contact-internationalWire-form-card-purpose-textbox" />
                {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="create-send-account-internationalWire-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={!scheduleId}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                    />
                </Grid>}
                <Grid item>
                    <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-account-internationalWire-form-card-tab" tabClick={tabClickFunction} />
                </Grid>
                {isEditMode ? (
                    <Grid item xs={12} sm={12}>
                        <FxTextEdit
                            register={{ ...register("destination") }}
                            className={
                                errors.destination
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                            }
                            control={control}
                            id="create-send-account-internationalWire-form-card-destination-textbox"
                            label="International External Destination Account*"
                            name="destination"
                            variant="outlined"
                            isEditable={false}
                        />
                    </Grid>
                ) : (
                    <Grid item>
                        <FxMaterialSelect
                            name={"destination"}
                            control={control}
                            rules={{ required: true }} register={{ ...register("destination") }}
                            id="create-send-account-internationalWire-form-card-destination"
                            value={''}
                            dataTransformation={datadestinationTransformation}
                            label='Select Destination International External Account'
                            source={destination_src}
                            setValue={setValue}
                            onChange={setValueDestinationFunction}
                        />
                    </Grid>
                )}
                <Grid item>
                    <FxMaterialSelect
                        name={"currency"}
                        control={control}
                        rules={{ required: true }} register={{ ...register("currency") }}
                        id="create-send-account-internationalWire-form-card-currency"
                        value={'USD'}
                        readOnly={true}
                        label='Currency*'
                        data={currency}
                        setValue={setValue}
                    />
                </Grid>
                {businessCustomer && <Grid item>
                    <FxTextEdit register={{ ...register("memo") }} className={errors.memo ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-send-account-internationalWire-form-card-company-description-textbox" label="Memo" name="memo" variant="outlined" defaultValue={context?.data?.body?.processingDetail?.memo ? context.data.body.processingDetail.memo : ''} />
                </Grid>}
                <Grid item>
                    <FxSwitch id='create-send-account-internationalWire-form-card-allow-duplicate' value={allowDuplicate} onClick={handleAllowDuplicate}></FxSwitch> <FxLabel value="Allow Duplicate"></FxLabel>
                </Grid>
                {(!isEditMode && isRecurringRequired && checkPermission({ entity: "Recurring Transaction", name: "internationalWire Send", operation: "Create" })) && (
                    <Grid item>
                        <FxSwitch id='repeat-switch' value={repeatStatus} onClick={handleRepeat}></FxSwitch> <FxLabel value="Repeat"></FxLabel>
                    </Grid>
                )}
                {repeatStatus && isRecurringRequired && (
                    <>
                        <Grid item xs={12} sm={12}>
                            <FxDateEdit
                                register={{ ...register("startDate") }}
                                className={
                                    errors.startDate
                                        ? "border-error-input fx-input-edit"
                                        : "fx-input-edit"
                                }
                                control={control}
                                id="create-send-account-internationalWire-form-card-startDate"
                                name="startDate"
                                type="date"
                                variant="outlined"
                                label={"Start Date *"}
                                setValue={setValue}
                                disablePast={true}
                                resetField={resetField}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FxTextEdit
                                register={{ ...register("name") }}
                                className={
                                    errors.name
                                        ? "border-error-input fx-input-edit"
                                        : "fx-input-edit"
                                }
                                control={control}
                                rules={{ required: true }}
                                id="create-send-account-internationalWire-form-card-name-textbox"
                                label="Name *"
                                name="name"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FxRecurring
                                id="create-send-account-internationalWire-form-card-fx-recurring"
                                updateTrigger={handleUpdateRecurring}
                                startDate={watch("startDate")}
                                register={register}
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                            <Grid item container xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <Typography className="filter-popover-label">
                                        LINKED DOCUMENTS
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    &nbsp;
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={8}>
                                        <FxMaterialSelect
                                            register={{ ...register("docType") }}
                                            id="create-send-account-internationalWire-form-card-doc-type"
                                            control={control}
                                            rules={{ required: false }}
                                            name="docType"
                                            data={docTypeData}
                                            value={"CONTRACT"}
                                            readOnly={true}
                                            label="Select a document type"
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="fx-choose-file-button">
                                        <Controller
                                            name={"fileId"}
                                            control={control}
                                            rules={{ required: false }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <>
                                                    <FxFileUpload
                                                        register={{ ...register("fileId") }}
                                                        name="fileId"
                                                        passData={passdata}
                                                        id="create-send-account-internationalWire-form-card-file-id-file-upload"
                                                        onChange={(e: any) => {
                                                            onChange(e);
                                                        }}
                                                        value={
                                                            fileUploadData ? fileUploadData : value
                                                        }
                                                        acceptedFiles={[
                                                            ".pdf",
                                                            ".docx",
                                                            ".jpg",
                                                            ".txt",
                                                            ".png",
                                                            ".jpeg",
                                                            ".xls",
                                                            ".wav",
                                                            ".xlsx",
                                                            ".tiff",
                                                            ".doc",
                                                            ".rtf",
                                                            ".bmp",
                                                            ".efx",
                                                            ".csv",
                                                            ".kswps",
                                                            ".wps",
                                                        ]}
                                                        formData={{ type: "CONTRACT" }}
                                                        setValue={setValue}
                                                        maxFileSize={10485760}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FxLabelView
                                        className="fx-label-small"
                                        id="create-send-account-internationalWire-form-card-file-types-supported"
                                    >
                                        (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc,
                                        .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                                    </FxLabelView>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div className={"error-message"}>
                                        {errors.fileError && renderError(errors.fileError)}
                                    </div>
                                </Grid>
                                {fileUploadData && (
                                    <Grid item container>
                                        {fileUploadData.map((key: any) => {
                                            return (
                                                <Chip
                                                    icon={<InsertDriveFileTwoToneIcon />}
                                                    label={key["name"]}
                                                    onDelete={() => removefile(key["id"])}
                                                    color="primary"
                                                    variant="outlined"
                                                    deleteIcon={<DeleteIcon />}
                                                />
                                            );
                                        })}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </ScheduleLayout>
          </>
        );
    });
