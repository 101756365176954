/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Component: ViewCustomerDetails
 * Usage: For View Customer Details page
 */
import React from "react"; // we need this to make JSX compile
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { Grid, Typography } from "@mui/material";
import FxCard from "../../../Container/FxCard";
import FxCardBody from "../../../Container/FxCardBody";
import FxCardHeader from "../../../Container/FxCardHeader";
import { LeadBeneficialOwnerListView } from "../Leads/LeadBenificialOwnerListView";
import { LeadMailingAddressListView } from "../Leads/LeadMailingAddressListView";
import { addressFormatter } from "../../../Utils/CommonBaseClass";
import { CustomerDetails } from "./CustomerDetails";

export const ViewCustomerDetails: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const benifilaOwner: any = [];
  const mailingAddress: any = [];

  // eslint-disable-next-line array-callback-return
  props?.data?.business?.beneficialOwner?.map((item: any) => {
    const benType = item?.actAsAuthorizedSignatory? "Authorized Signatory, Beneficial Owner": "Beneficial Owner"
    benifilaOwner.push({
      id: item?.id ? item?.id : "",
      name: item?.firstName + " " + item?.lastName,
      ownerPercentage: item?.businessDetails?.ownershipPercentage,
      type: benType
    });
  });

  // eslint-disable-next-line array-callback-return
  props?.data?.business?.mailingAddress?.map((item: any) => {
    mailingAddress.push({
      id: item?.id ? item?.id : "",
      address: addressFormatter("", item),
    });
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <>
          <Grid
            id="view-customer-details-Grid "
            className="fx-margin-bottom-16"
            item
            xs={12}
          >
            <FxCard
              id="view-customer-details-card"
              className="fx-theme-passport"
            >
              <>
                <FxCardHeader id={"view-customer-details-card#card-header"}>
                  <Grid
                    id="customer-basic-Grid2"
                    item
                    container
                    justifyContent="space-between"
                    xs={12}
                    alignItems={"center"}
                  >
                    <Grid id="customer-basic-Grid3">
                      <Typography id="title-view-customer-details" variant="h3">
                        {props?.data?.business? props?.data?.business?.legalName:props?.data?.individual?.firstName}
                      </Typography>
                      <div
                        id="div-grow-title-view-customer-details-list"
                        className="fx-flex-grow"
                      />
                    </Grid>
                  </Grid>
                </FxCardHeader>
                <FxCardBody
                  id="view-customer-details-card#card-body"
                  className="fx-info-card fx-margin-top-reducer"
                >
                  <CustomerDetails
                    id="customer-details-page"
                    data={props?.data}
                  ></CustomerDetails>
                </FxCardBody>
              </>
            </FxCard>
          </Grid>
        </>

        <Grid xs={12} sm={12}>
          {props?.data?.business?.beneficialOwner &&
            <LeadBeneficialOwnerListView
              id={"lead-beneficial-owner-list-card"}
              data={benifilaOwner}
            />
          }
        </Grid>
        {mailingAddress && (
          <Grid xs={12} sm={12}>
            <LeadMailingAddressListView
              id={"lead-mailing-address-list-card"}
              data={mailingAddress}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
