/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HttpClient from '../../../../../libs/utils/httpClient';
import { Logger } from '../../../../../libs/utils/logger';
import { getKey } from '../../../../../libs/utils/storageManager';
import { getCustomerUrl, processAPIResponse } from '../../../../../libs/utils/utils';
import { FxButton } from '../../../../Action/FxButton';
import { FxAccountTransferForm } from '../../../../Data/FxAccountTransferForm';
import { formatDate, maskAccountNumber, updateComponentParams } from '../../../../Utils/CommonBaseClass';
import FxSnackBar from '../../../../Utils/fx-snack-bar';
import FxModalGeneric from '../../../../Utils/FxModalGeneric';
import { AuthenticationVerificationModal } from '../../../AuthenticationModal/AuthenticationVerificationModal';
import { QuickTransferContainer } from './QuickTransferContainer';

Logger.debug("QuickAccountTransfer.tsx", "QuickAccountTransfer initializing");

/**
 * This component handles the Passport Cash Builder Account Quick Transfer
 */
export const QuickAccountTransfer: React.FC<any> = React.memo((props) => {

  const httpClient = HttpClient.getClient();
  const [isDisabled, setIsDisabled] = useState(true);
  const [verifyProps, setVerifyProps] = useState<any>();
  const dispatch = useDispatch();

  /**
   * to get accountList
   */
  const accountList = useSelector((state: any) => {
    if (state.data['customer-basic-info']) {
      return state.data['customer-basic-info']?.params?.accountList
    }
  });

  let resetForm: any;

  /**
   * Post success actions
   */
  const postSuccessActions = () => {
    props.onTransferAmountFinishedHandle && props.onTransferAmountFinishedHandle();
    if (resetForm) {
      resetForm();
    }
    setIsDisabled(true);
    setVerifyProps(undefined);
    updateComponentParams(dispatch,'cash-builder-dashboard',{'showQuickTransfer' : false })
  }

  /**
   * Method to handle reset
   * @param reset
   */
  const handleReset = (reset: any) => {
    resetForm = reset;
  }

  /**
  * Create transfer transaction
  */
  const createTransferTransaction = async (payload: any) => {
    try {
      return await httpClient.post(getCustomerUrl('/transaction', false), payload);
    }
    catch (err) {
        Logger.error("QuickAccountTransfer.tsx", "error", err);
        return err;
    }
  }

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    const payload = {
      amount: data.amount,
      method: "BOOK",
      type: "REGULAR",
      source: {
          account: {
              id: data.from
          }
      },
      destination: {
          account: {
              id: data.to
          }
      },
      scheduleDate: moment(formatDate(new Date())).format('MM/DD/YYYY'),
      allowDuplicate: true,
      purpose: "Investment",
    }

    let status = await createTransferTransaction(payload);
    status = processAPIResponse(status)
    if(status.status) {
      //api success
      if(status.responseData?.sessionId){
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
        const verifyProps = {
          open: true,
          channelTitle:`To verify quick transfer for $${payload.amount}, Passport will send you a one-time code. Choose how to receive your code.`,
          subTitle: `A verification code has been sent to your ${verifyMessage}. Please enter the code to complete verification.`,
          url: getCustomerUrl('/transaction', false),
          req: payload,
          initiationDetails: status.responseData,
          resendOtpUrl: `/user/id/${getKey('id')}/initiateMFA`,
          postSuccessEvent: postSuccessActions,
        }
       setVerifyProps(verifyProps);
      }
      else{
        postSuccessActions();
      }
      const fromAccount = accountList?.resources?.filter((item:any) => item.id === data.from)[0];
      const fromAccountNumber = fromAccount?.accountNumber;
      const toAccountNumber = accountList?.resources?.filter((item:any) => item.id === data.to)[0]?.accountNumber;
      const description = fromAccount?.type !== 'DEPOSIT' && fromAccount?.type === 'CASH_BUILDER_PLUS' ? `Money Transfer initiated from Cash Builder+ A/c ${maskAccountNumber(fromAccountNumber,false)} to Passport A/c ${maskAccountNumber(toAccountNumber,false)}` : `Money Transferred from Passport A/c ${maskAccountNumber(fromAccountNumber,false)} to Cash Builder+ A/c ${maskAccountNumber(toAccountNumber,false)}`;
      updateComponentParams(dispatch,'cash-builder-dashboard',{'amount' : data.amount})
      updateComponentParams(dispatch,'cash-builder-dashboard',{'description' : description})
    } else {
        //api  failed
        FxSnackBar.show({
            text: status.message,
        });
    }
  }


  /**
   * Function to handle the closing of the verification modal.
   */
  const handleCloseModal = () => {
    setVerifyProps(undefined);
  };


  const formId = "quick-account-transfer-form";

  return (
    <>
    <FxModalGeneric
      id="quick-transfer-send-book-otp"
      open={verifyProps?.open}
      onClose={handleCloseModal}
      componentProps={verifyProps}
      className={'fx-modal-small2medium'}
      component={AuthenticationVerificationModal}
    >
    </FxModalGeneric>
    <QuickTransferContainer
      id={'quick-account-transfer'}
      consentText={
        'By clicking Transfer, I authorize Passport to initiate the money movement detailed above.'
      }
      buttons={
        <FxButton
          disableRipple={false}
          className={`fx-button fx-button-theme fx-quick-transfer-submit-button ${isDisabled ? 'fx-button-theme-disabled' : ''}`}
          id={`${formId}-submit-button`}
          type="submit"
          form={formId}
          disabled={isDisabled}
        >Transfer
        </FxButton>
      }
      showWithdrawalNote={props?.showWithdrawalNote}
      destinationAccountData={props?.destinationAccountData}
      >
      <FxAccountTransferForm
        id='quick-transfer-form'
        accounts={accountList?.resources}
        formId={formId}
        onSubmit={onSubmit}
        setIsSubmitDisabled={setIsDisabled}
        onReset={handleReset}
        checkIsWithdrawal={props?.checkIsWithdrawal}
        setDestinationAccountData={props?.setDestinationAccountData}
      />
    </QuickTransferContainer>
    </>
  );
});
