/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { CreateExternalAccount } from './CreateExternalAccount';
/**
 * Component: EditExternalAccount
 * Usage: edit external account by passing the data to CreateExternalAccount
 */
export const EditExternalAccount: React.FC = () => {
        const params = useParams<any>();
        const id = params.id
        // API for get external account details
        const external_account_source = {
            url: "/externalAccount/id/" + id,
            method: "GET"
        }
        const rand=Math.floor(Math.random()*100)+1;
        return <CreateExternalAccount id={rand+'edit-external-account-' + id} source={external_account_source}></CreateExternalAccount>

    }
