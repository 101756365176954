/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as ReissueIcon } from '../../../assets/svg/reissue-icon.svg';
import { ReactComponent as DividerIcon } from '../../../assets/svg/divider-icon.svg';
import { addressFormatter, clean } from '../../Utils/CommonBaseClass';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { getKey } from '../../../libs/utils/storageManager';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxTextEdit } from '../../Input';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
const httpClient = HttpClient.getClient();

Logger.debug("ReissueCard.tsx", "Reissue Physical Card initializing")

/**
 * This component handles the reissue of Physical Card
 */
export const ReissueCard: React.FC<any> = React.memo((props) => {
        const { register, handleSubmit, control, formState: { errors, isSubmitting }, setValue } = useForm();
        const [customAddressData, setCustomAddressData] = React.useState<{value: string, label: string}[]>();

        const { debitCardId, data, setSuccess, setTitle, setDescription, setBtnText } = props.metadata

        useEffect(() => {
            const addressOptions = {
                value: data?.shippingDetail?.address?.id,
                label: addressFormatter("", data?.shippingDetail?.address)
            }
            setCustomAddressData([addressOptions]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        // reissue options
        const reissueOptions = [
            { label: "Card Damaged", value: "CARD_DAMAGED" }
        ];

        /**
         * Async function handles the form submission
         */
        async function onSubmit(data: any) {
            let status: any;

            const request = {
                "reason": data.reason,
                "comment": data.comment,
                "shippingDetails": {
                    "address": {
                        "id": data.address
                    }
                }
            };
            
            status = await reissueDebitCard(clean(request));
            status = processAPIResponse(status)
            
            if(status.status){
              setTitle('Request Placed Successfully')  
              setDescription('Your Request is placed successfully.');
              setBtnText('Go to debit card listing');
              setSuccess(true);
            } else {
              //api  failed
              FxSnackBar.show({
                text: status.message,
              });
          }
            props.onClose();
        }

        /**
         * Method to call api for Reissue Debit Card
         * @param payloadData : request payload
         */
        async function reissueDebitCard(payloadData: any) {
            try {
                const url =  '/account/id/' + getKey('selectedAccount')?.id + '/debitCard/id/' + debitCardId + '/reissue';
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData)
                return data;
            } catch (err) {
                Logger.error("ReissueDebitCard.tsx", "error", err);
                return err;
            }
        }

        return (
            <Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FxCard className='fx-reissue-debit-card-modal'>
                        <FxCardHeader id="reissue-debit-card-header" className="fx-card-header">
                            <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                                <Grid item container xs={12} justifyContent="center">
                                    <ReissueIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography id="fx-reissue-debit-card-modal-title" align="center" className="fx-reissue-debit-card-modal-title" >
                                        Reissue
                                    </Typography>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="reissue-debit-card-modal-body" className="fx-info-card">
                            <Grid item xs={12} className="fx-modal-form flex column" >
                                <Typography id="reissue-debit-card-modall-subtext" variant="h5"  className='fx-reissue-debit-card-modal-subtext'>
                                    By taking this action, a new card will issued with a different card number and expiry date from the existing card. You can continue to use the existing card for transactions till the new card is activated.
                                </Typography>
                            </Grid>
                            <Grid container className="fx-modal-form flex column">
                                <Grid item xs={12} sm={12}>
                                    <FxMaterialSelect
                                        register={{ ...register("reason") }}
                                        className={errors.reason ? "border-error-input fx-input-edit" : "fx-input-edit" }
                                        rules={{ required: true }}
                                        control={control}
                                        id="reissue-debit-card-reason"
                                        name="reason"
                                        data={reissueOptions}
                                        label="Reason*"
                                        setValue={setValue}
                                        value={''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FxMaterialSelect 
                                        register={{ ...register("address") }} 
                                        control={control}
                                        rules={{ required: true }} 
                                        className={errors.address ? "border-error-input fx-input-edit" : "fx-input-edit" } 
                                        id="reissue-debit-card-address" 
                                        name="address" 
                                        value={ ''} 
                                        data={customAddressData} 
                                        label="Shipping Address*" 
                                        setValue={setValue} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FxTextEdit
                                        register={{ ...register("comment") }}
                                        type="text"
                                        passwordIcon={true}
                                        className="fx-input-edit"
                                        control={control}
                                        rules={{ required: false }}
                                        id="reissue-debit-card-comment"
                                        label={"Comment"}
                                        name="comment"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Typography
                                    id="title-reissue-debit-card-modal"
                                    variant="h5"
                                    className='fx-reissue-debit-card-modal-note'
                                >
                                    Note: On reissuance of card, you will be charged card reissuance fee if applicable.
                                    as per your passport account aggrement.
                                </Typography>
                                <span className='fx-reissue-debit-card-modal-divider'>
                                    <DividerIcon />
                                </span>
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="reissue-debit-card-modal-footer" className="fx-footer">
                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                <Button
                                    variant="contained"
                                    className="fx-button fx-button-cancel"
                                    id="cancel-button"
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                                <span className="fx-padding-right-16" />
                                <FxButton
                                    disableRipple={false}
                                    className="fx-button fx-button-theme"
                                    id={"reissue-debit-card-button"}
                                    type="submit"
                                    isSubmitting={isSubmitting}
                                >
                                    SUBMIT
                                </FxButton>
                            </Grid>
                        </FxCardFooter>
                    </FxCard>
                </form>
            </Grid>
        );
    });
