/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react'
import { Logger } from '../../../libs/utils/logger';
import { Grid } from '@mui/material';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { getKey } from '../../../libs/utils/storageManager';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { setAmountValidationOptions } from './ScheduleGlobalFunctions';
import { getCustomerUrl } from '../../../libs/utils/utils';
import HttpClient from '../../../libs/utils/httpClient';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxLink } from '../../Action/FxLink';
import UIFeatureImpl from '../../../libs/services/uiFeatures';


Logger.debug("ScheduleTopSection.tsx", "ScheduleTopSection initializing")
/**
 * This component handles the top section of the schedules
 */
export const ScheduleTopSection: React.FC<any> = React.memo(
    (props) => {
        const httpClient = HttpClient.getClient();
        const [accountDetails, setAccountDetails] = useState<any>();
        const [showAvailableAmount, setShowAvailableAmount] = useState(false);
        const uiFeat = UIFeatureImpl.getInstance();
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available
        const location = useLocation();
        // state holding the context data for schedule top section
        const dataState = useSelector((state: any) => {
            if (state.data['schedule-top-section']) {
                return state.data['schedule-top-section']
            } else {
                return { config: { params: {} } };
            }
        });
        const dispatch = useDispatch();
        /**
         * Method checking the schedule and type matches with this item
         */
        const checkCondition = (data: any) => {
            if((data?.schedule === props.schedule) && (data?.type === props.type)){
                return true;
            }
            else{
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: {} } });
                return false;
            }
        }
        // constant holding all the schedule url
        const scheduleLocationData: any = ['/transaction/send/add/ach','/transaction/send/add/wire','/transaction/send/add/check','/transaction/send/transfer','/transaction/send/onetime/add/ach','/transaction/send/onetime/add/check','/transaction/send/onetime/add/book','/transaction/send/contact/add/ach','/transaction/send/contact/add/wire','/transaction/send/contact/add/check','/transaction/collect/add/my-account-ach','/transaction/collect/add/onetime-ach','/transaction/collect/add/mobile-check-deposit','/transaction/collect/add/onetime-card','/transaction/send/contact/add/book','/transaction/send/add/internationalWire','/transaction/send/onetime/add/internationalWire','/transaction/send/contact/add/internationalWire','/transaction/send/contact/add/virtualcard']
       
        const history = useHistory();
        /**
         * Method reset the context data value when the url changes
         */
        history.listen((location) => {
            if(!scheduleLocationData.includes(location.pathname)){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: {} } });
            }
        })

     
    /**
    * function call velocity api with account ID
    */
        async function getAccountDetails(id: number,methodChoosed:any) {
            try {
                return await httpClient.get(getCustomerUrl("account/id/" + id +'/velocityLimit?availableAmount=true&method='+methodChoosed));
            } catch (e) { 
                Logger.error("CreateCollectContactCARD.tsx", "error", e);
            }
        }

        /**
         * function to get account data and storage quick settle configuration
         * @param id :account id
         */

    /**
    * function call when user click on check available amount
   */
    const onClickAmount =()=>{
        getAccountDetails(getKey('selectedAccount')?.id,props?.method).then((accountDetail)=>{
            setAccountDetails(accountDetail?.data);
        });
        setShowAvailableAmount(true);
    }

        return <>
            <Grid item xs={12} sm={12} className={`fx-amount-wrapper ${props?.hasMarginBottom ? 'fx-py-4' : ''}`}>
                <FxTextEdit register={{ ...props.register('amount') }}
                    className={`fx-input-edit clear-number-field-arrow ${props.errors.amount ? 'border-error-input' : ''}`}
                    control={props.control} rules={setAmountValidationOptions} prefix="$"
                    id={props.amountId} label="Amount*" name="amount"
                    type="number" variant="outlined" valuePattern={/-|\+|e|E/}
                    defaultValue={props.defaultValue?.amount ? props.defaultValue['amount']  : (dataState?.data?.formData?.amount && checkCondition(dataState?.data))? dataState.data.formData.amount: ''}
                    showDecimal={true} setValue={props.setValue} onWheel={(e: any) => e.target.blur()} />
                {props.schedule === 'send' &&
                    <Grid item container className='fx-dark-grey-info-card' alignItems='center'>
                        <span>{`Available Amount (based on applicable limits): `}&nbsp;</span> 
                        {showAvailableAmount === false && <FxLink className="fx-account-summary-card-center-theme-text fx-text-ellipsis" onClick={onClickAmount} id='account-details' >Check Amount</FxLink>}
                        {showAvailableAmount && <FxCurrencyView  className='fx-account-summary-card-balance-view'  prefix={"$"} value={accountDetails?.send?.availableAmount ? accountDetails?.send?.availableAmount : ''}> </FxCurrencyView>}            
                    </Grid>}
            </Grid>
          
            {props.schedule === 'send' && <Grid item xs={12} sm={12}>
                <FxMaterialSelect register={{ ...props.register('source') }}
                    id={props.sourceId} name="source"
                    source={props.acc_src} control={props.control} rules={{ required: true }}
                    dataTransformation={props.dataSourceTransformation}
                    value={props.defaultValue?.source ? props.defaultValue['source'] : getKey('selectedAccount')?.id}
                    readOnly={true} label="Source Account" setValue={props.setValue} />
            </Grid>}
            {props.schedule === 'collect' && <Grid item xs={12} sm={12} className={props.hasMarginBottom ? 'fx-pb-4' : ''}>
                <FxMaterialSelect register={{ ...props.register('depositAccount') }}
                    id={props.destinationId} name="depositAccount"
                    source={props.acc_src} control={props.control} rules={{ required: true }}
                    dataTransformation={props.dataTransformation}
                    value={props.defaultValue?.depositAccount ? props.defaultValue['depositAccount'] : getKey('selectedAccount')?.id}
                    readOnly={true} label="Destination Account*" setValue={props.setValue} />
            </Grid>}
            <Grid item xs={12} sm={12} className={props?.isTopSectionMoved ? 'fx-padding-bottom-0': ''}>
                <FxTextEdit inputProps={{ maxLength: 128 }} register={{ ...props.register('purpose') }}
                    className={props.errors.purpose ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                    control={props.control} rules={{ required: true }}
                    id={props.purposeId} label={individualCustomer ? "Memo*" : "Purpose*"}
                    name="purpose" variant="outlined"
                    defaultValue={props.defaultValue?.purpose? props.defaultValue['purpose'] :  (dataState?.data?.formData?.purpose  && checkCondition(dataState?.data))? dataState.data.formData.purpose: ''} />
            </Grid>
        </>;
    });
