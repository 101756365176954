/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useSelector } from 'react-redux';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { useForm } from 'react-hook-form';
import { FxSwitch } from '../../Action/FxSwitch';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { FxTextEdit } from '../../Input';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxButton } from '../../Action/FxButton';
import FxCardFooter from '../../Container/FxCardFooter';
import { setAmountValidationOptions } from '../Schedules/ScheduleGlobalFunctions';

/**
 * Component: Alert Preferences List
 * Usage: To display alert list of account
*/

export const AlertPreferencesList: React.FC<any> = React.memo((props) => {
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset, control } = useForm();
  const [isEditable, setIsEditable] = useState(false);
  const [preferencesList, setPreferencesList] = useState([]);

  /**
    * To geet alert perferences from api resposne.
  */
    const originalPerferencesList = useMemo(() => {
      return props.data || [];   
    }, [props?.data]); 
   

  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => {
    setPreferencesList(originalPerferencesList);

    if(originalPerferencesList){
      // Flatten the array of items into a single array
      const flatItems = originalPerferencesList.flatMap((item:any) => item.items);

      // Create an object using 'name' as keys
      const objectByName = flatItems.reduce((acc:any, item:any) => {
      const { key, ...rest } = item;
      if (!acc[key]) {
        acc[key] = { 
          deliveryChannel:{
            email:rest.deliveryChannel?.email ? [rest.deliveryChannel.email.toString()]:['false'],
            sms:rest.deliveryChannel?.sms ? [rest.deliveryChannel.sms.toString()]:['false'],
            inApp:rest.deliveryChannel?.inApp ? [rest.deliveryChannel.inApp.toString()]:['false'],
          },
          customTrigger:rest.customTrigger 
        };
      } 
      return acc;

    }, {});
    
    reset(objectByName);
    }
  }, [originalPerferencesList, reset])

  /**
   * On handle chnage for alert access
   * @param item :Selected item
   * @param event :Clicked event
   */
  const handleAlertAccess = (item: any, event: any) => {
    const deliveryChannel: any = {inApp: item.deliveryChannel.inApp, email: item.deliveryChannel.email, sms: item.deliveryChannel.sms}
    const value = [event.target.checked.toString()];
    if (event.target.checked) {
      deliveryChannel.inApp = event.target.checked;
      item.deliveryChannel = deliveryChannel;
      setValue(`${item.key}.deliveryChannel.inApp`, value)
    } else {
      deliveryChannel.inApp = false;
      deliveryChannel.email = false;
      deliveryChannel.sms = false;
      item.deliveryChannel = deliveryChannel;
      setValue(`${item.key}.deliveryChannel.email`, value)
      setValue(`${item.key}.deliveryChannel.sms`, value)
      setValue(`${item.key}.deliveryChannel.inApp`, value)
      if(item.customTrigger){
        setCustomTrigger(item);      
      }
    }
    
    onChangeSwitchHandler(item);
  }

  /**
   * To set custom trigger value   
   * @param item :Selected item   
   */
  const setCustomTrigger=(item:any)=>{
    const customTrigger = props.getCustomTriggedValue(item);        
    for (const key in item.customTrigger) {
      const customTriggerValue = customTrigger[key];
      item.customTrigger[key] = customTriggerValue;
      setValue(`${item.key}.customTrigger.${key}`, customTriggerValue)
      clearErrors(`${item.key}.customTrigger.${key}`);
   }
  }

   /**
   * On Change switch button handler
   * @param item :Selected item
   */
  const onChangeSwitchHandler = (item: any) => {
    preferencesList.forEach((e: any) => {
      if (e.name === item.key) {
        e = item;
      }
    });

    setPreferencesList([...preferencesList]);
  }  

  /**
   * On submit handler
   * @param data :Form data
   */
  async function onSubmit(data: any) {        
    props.onSubmitAlertPerferences(data, onSaveCallback)
  }

   /**
   * On save callback handler
   */
  const onSaveCallback = useCallback(() => {
    setIsEditable(false);
  }, []); 

  const selecetdData = [
    { label: 'Daily', value: 'DAILY' }  
  ];

  /**
   * On edit button handler   
   */
  const onEditHandler =()=>{
    setIsEditable(true)
  }

  /**
   * On cancel button handler   
   */
  const onCancelHandler =()=>{
    setIsEditable(false);
    props.onCancelAlertPreferences();
  }

  /**
   * On checkbox handler(inapp, email and sms)
   * @param key :Selected key
   * @param item :Selected item
   * @param event :Clicked event
   */
  const onChangeCheckBoxHandler=(key:any, item:any, event:any)=>{

    const deliveryChannel: any = {...item.deliveryChannel};
    deliveryChannel[key] = event.target.checked;
    
    item.deliveryChannel = deliveryChannel;

    if(item.customTrigger && !checkIsDisabled(item)){
      setCustomTrigger(item);      
    }    

    onChangeSwitchHandler(item);
  } 

  // Is loading selector.
  const isLoading = useSelector((state: any) => {
    const data = state.data['manage-alert-list'] || null;
    return data?.params?.isLoading;
  });

  /**
   * To check is disable mode
   * @param item : Item
  */
  const checkIsDisabled=(item:any)=>{
    return (item.deliveryChannel.inApp || item.deliveryChannel.email || item.deliveryChannel.sms)
  }

  return (
    <Grid id="alert-grid" className='alert-perferences-grid' item xs={12}>
       <form id = {`${props.id}-form`} onSubmit={handleSubmit(onSubmit)}>
      <FxCard id="alert-list-card-Home-Card" className="fx-theme-passport" >
        <FxCardHeader id="alert-list-card-Home-Card-Header" className="fx-card-header">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={6}>
              <Typography variant='h4'>Alerts</Typography>
            </Grid>           
            <Grid item xs={6}>
              <Grid container justifyContent='end'>
                {!isEditable && <FxButton id='edit-alert-perferences' variant="contained" className="fx-button fx-button-theme fx-svg-theme " onClick={onEditHandler} startIcon={<EditIcon />} >Edit</FxButton>}
              </Grid>
            </Grid>
          </Grid>         
        </FxCardHeader>
        <FxCardBody id="alert-list-Home-Card-Body" className='fx-alert-perferences-card-body fx-form-edit-profile' >
          {preferencesList.map((i: any) => {
            return i.items.length > 0 && 
              <Grid>
                <Grid container direction="row" className="fx-alert-head-row" >
                  <Grid item xs={9} className={'fx-alert-grid-item-20 header-cell'}>{i.name}</Grid>
                  <Grid item xs={1} className={'fx-alert-grid-item-20 header-cell'}>{'IN APP'}</Grid>
                  <Grid item xs={1} className={'fx-alert-grid-item-20 header-cell'}>{'EMAIL'}</Grid>
                  <Grid item xs={1} className={'fx-alert-grid-item-20 header-cell'}>{'SMS'}</Grid>
                </Grid>
                {i.items.map((item: any) => {
                  return (
                  <Grid container direction="row" className="fx-alert-row" alignItems="center">                    
                    <Grid item xs={9} className={'fx-alert-grid-item-20 header-cell'}>
                      <Grid container>
                          <Grid item xs={1}>
                            <FxSwitch
                              id='alert-switch'
                              onChange={(x) => handleAlertAccess(item, x)}
                              disabled={!isEditable}
                              value={item.deliveryChannel.inApp || item.deliveryChannel.email || item.deliveryChannel.sms}
                            />
                          </Grid> 
                          <Grid item xs={11}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                  <Typography className='fx-alert-title'>{item.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <Grid container direction={'row'} alignItems="center" columnSpacing ={2}>
                                    <Grid item>
                                      <Typography className={'fx-alert-sub-title'} >{item.description}</Typography>    
                                    </Grid>
                                    <Grid item>
                                    {item.customTrigger && item.customTrigger.hasOwnProperty('balance') && 
                                      <FxTextEdit
                                        className={`fx-input-edit clear-number-field-arrow ${errors[`${item.key}`]?.customTrigger?.balance ? 'border-error-input' : ''} ${!(isEditable && checkIsDisabled(item)) ? 'fx-alert-preferences-customTrigger-disabled':''}`} 
                                        control={control}
                                        id={`${item.key}.customTrigger.balance`}
                                        register={{ ...register(`${item.key}.customTrigger.balance`) }}
                                        name={`${item.key}.customTrigger.balance`}
                                        variant="outlined"
                                        prefix='$'
                                        rules={setAmountValidationOptions}   
                                        type="number" 
                                        valuePattern={/-|\+|e|E/}                                                                           
                                        defaultValue={item.customTrigger.balance}
                                        setValue={setValue}
                                        isEditable={isEditable && checkIsDisabled(item)}
                                      />}

                                      {item.customTrigger && item.customTrigger.hasOwnProperty('cadence') &&
                                       <FxMaterialSelect 
                                        className={!(isEditable && checkIsDisabled(item)) ? 'fx-alert-preferences-customTrigger-disabled':''}                                     
                                        register={{ ...register(`${item.key}.customTrigger.cadence`) }}  
                                        control={control}
                                        id={`${item.key}.customTrigger.cadence`}
                                        name={`${item.key}.customTrigger.cadence`}                            
                                        defaultValue={item.customTrigger.cadence}
                                        data={selecetdData}
                                        readOnly={!(isEditable && checkIsDisabled(item))}
                                        setValue={setValue} />
                                      }   
                                    </Grid>
                                  </Grid>
                                                                    
                                </Grid>                            
                            </Grid>    
                          </Grid>
                      </Grid>                      
                    </Grid>                     
                    <Grid className='fx-alert-grid-item-20 header-cell' xs={1} >
                      <FxCheckEdit
                          className={`fx-alert-checkbox ${!isEditable? 'diabled-check-box':''}`}
                          updateOptions={true}
                          data={[{ label: '', value: 'true', checked: item.deliveryChannel.inApp }]}
                          id={`${item.key}.deliveryChannel.inApp`}
                          register={{ ...register(`${item.key}.deliveryChannel.inApp`) }}
                          name={`${item.key}.deliveryChannel.inApp`}
                          control={control}
                          disabled={!isEditable}
                          onChange={(event:any)=> onChangeCheckBoxHandler('inApp', item, event)}
                          setValue={setValue} />                     
                    </Grid>
                      <Grid className='fx-alert-grid-item-20 header-cell' xs={1} >
                        <FxCheckEdit
                          className={`fx-alert-checkbox ${!isEditable? 'diabled-check-box':''}`}
                          updateOptions={true}
                          data={[{ label: '', value: 'true', checked: item.deliveryChannel.email }]}
                          id={`${item.key}.deliveryChannel.email`}
                          register={{ ...register(`${item.key}.deliveryChannel.email`) }}
                          name={`${item.key}.deliveryChannel.email`}
                          control={control}
                          disabled={!isEditable}
                          onChange={(event:any)=> onChangeCheckBoxHandler('email', item, event)}
                          setValue={setValue} />
                      </Grid>
                      <Grid className='fx-alert-grid-item-20 header-cell' xs={1} >
                        <FxCheckEdit
                          className={`fx-alert-checkbox ${!isEditable? 'diabled-check-box':''}`}
                          updateOptions={true}
                          data={[{ label: '', value: 'true', checked: item.deliveryChannel.sms }]}
                          id={`${item.key}.deliveryChannel.sms`}
                          register={{ ...register(`${item.key}.deliveryChannel.sms`) }}
                          name={`${item.key}.deliveryChannel.sms`}
                          control={control}
                          disabled={!isEditable}
                          onChange={(event:any)=> onChangeCheckBoxHandler('sms', item, event)}
                          setValue={setValue} />
                      </Grid>              
                  </Grid>
                  )
                  
                })}
            </Grid>
          })}
        </FxCardBody>
        <FxCardFooter id="alert-preference-footer" className={!props?.isEdit ? `fx-footer fx-footer-approval-form` : 'fx-footer'}>
          {isEditable &&
            <Grid direction="row" justifyContent="start" className="fx-modal-footer" paddingTop='1.75rem'>

              <FxButton variant="contained"
                className="fx-button fx-button-cancel"
                id="alert-preference-cancel-button"
                onClick={onCancelHandler} 
              >
                Cancel
              </FxButton>

              <span className="fx-padding-right-16" />
              <FxButton
                disableRipple={false}
                className="fx-button fx-button-theme"
                id={"alert-preference-submit-button"}
                type='submit'
                isSubmitting={isLoading}
              >
                SAVE CHANGES             
              </FxButton>
            </Grid>
          }
        </FxCardFooter>
      </FxCard>
      </form>
    </Grid >
  )
})
