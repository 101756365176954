/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';


interface FxAccessDeniedProps {
    id?: any;
    title?: any;
    subComponent?: any;
};

/**
 * This Component handles the Access denied message
 */
export const FxAccessDenied: React.FC<FxAccessDeniedProps> = React.memo(
    (props) => {
        const dispatch = useDispatch()

        /**
         * reset the component
         * */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if(!props?.subComponent){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Access Denied' } } });
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        
        return (
            <Grid id={props?.id + "-Grid"} item xs={12} className="fx-no-access">
                <Grid item container justifyContent="center" xs={12}>
                    <Typography variant="h3" id="fx-403-first-message" >4</Typography>
                    <Typography variant="h2" id="fx-403-second-message">0</Typography>
                    <Typography variant="h3" id="fx-403-third-message" >3</Typography>
                </Grid>

                <Grid id={props?.id + "-grid-container"} container xs={12} sm={12} justifyContent="center" alignItems="center">
                    <Grid>
                        <Typography id={props?.id + "-title"} className="fx-no-access-title" > {props?.title ? props.title : 'Access Denied'} </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    })

