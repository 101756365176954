/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/**
 * Component handles the displaying of beneficial owner details 
 */
import React, { useEffect, useMemo } from 'react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as VerifiedIcon } from '../../../../assets/svg/verified-new-icon.svg';
import { ReactComponent as TriangleDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../../../assets/svg/triangle-up-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/edit-new.svg';
import { useMediaQuery } from 'react-responsive';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { convertToUpperAndTitleCase, maskAccountNumber, removeUnderScoreConvertTitleCase, stateValueFormatter, toTitleCase, updateComponentParams } from '../../../Utils/CommonBaseClass';
import FxLabelView from '../../../Input/FxLabel/FxLabelView';
import { FxDelete } from '../../../Data/FxDelete';
import { FxSectionInfoCard } from '../../Cards/FxSectionInfoCard';
import { AddBeneficialOwner } from './AddBeneficialOwner';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';


export const BeneficialOwnerDetailsCard: React.FC<any> = React.memo((props) => {
        ({ props } = RegisterComponent(props));
        
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        const dispatch = useDispatch();
        useEffect(() => {
        
            updateComponentParams(dispatch, props.id, { showDetails: false, deleteBusinessOwner: false, editView: false })
        },[dispatch,props.id])
       
        const ownerProfileDetails = [
            {
                label: 'Date of Birth',
                value: `dob`
            },
            {
                label: 'Social Security Number',
                value: `ssn`
            },
            {
                label: 'Phone Number',
                value: `mobilePhone`
            },
            {
                label: 'US Citizenship Status',
                value: `usCitizen`
            }
        ]

        const ownerProfileAddress = [
            {
                label: 'Address Line 1',
                value: `addressLine1`
            },
            {
                label: 'Address Line 2',
                value: `addressLine2`
            },
            {
                label: 'City',
                value: `city`
            },
            {
                label: 'State',
                value: `state`
            },
            {
                label: 'Zip',
                value: `zip`
            }
        ]
        /**
         * handle business owner view
         */
        const showHandleDetails = () => {
            const showDetails = !props.showDetails;
            updateComponentParams(dispatch, props.id, { showDetails })

        }

        /**
         * Constant memoizes the beneficial owner details to populate
         */
        const ownerDetails: any = useMemo(() => {
            const data: any = { ...props?.item }
            data['ssn'] = maskAccountNumber(data?.ssn, false)
            data['state']=stateValueFormatter(data?.state)
            data['usCitizen'] = (data?.usCitizen && data?.usCitizen === 'US_CITIZEN') ?  convertToUpperAndTitleCase(data?.usCitizen):removeUnderScoreConvertTitleCase(data?.usCitizen)
            return data
        }, [props?.item]);

        /**
         * Method handles the removal of the beneficial owner
         * @param index 
         */
        const remove = (index: number) => {
                updateComponentParams(dispatch, props.id, { deleteBusinessOwner: false })
                props.remove && props.remove(index)
        };
      
        /**
        * Method handles the close event of the FxDelete component
        */
        const deleteBusinessOwnerClose = () => {
            updateComponentParams(dispatch, props.id, { deleteBusinessOwner: false })

        };
        /**
         * method to handle edit data and edit page
         * @param data :data
         */
        const editBusinessOwner = (data: any) => {
            updateComponentParams(dispatch, props.id, { showDetails: false, editView: true })
        }

        /**
         * Method handles the form submission
         */
        const handleOnSubmit = () => {
            updateComponentParams(dispatch, props.id, { editView: false })
        }
        /**
         * Method handles the form cancellation
         */
        const handleOnCancel = () => {
            updateComponentParams(dispatch, props.id, { editView: false })
        }
       
       
        return (
            <Grid container id='business-owner-card-details' xs={12} className='fx-business-owner-view'>
                <FxCard id="signUp-business-owner-form-card-view" className={isSmallScreen?"fx-theme-passport fx-business-owner-view-border":"fx-theme-passport"}>
                <FxCardHeader>
                <Typography className='fx-new-invite-owner-profile-label'>{props?.index + 1 + ". "} Owner Profile</Typography>
                    <Grid item xs >
                        <Grid container justifyContent="flex-end">
                           {!props.editView && <Grid item className="fx-info-kyc-view-topbutton">
                                <IconButton
                                    id={"btn-show-hide-ico"}
                                    className="fx-button fx-border-gray"
                                    onClick={showHandleDetails}
                                    size="large">{props.showDetails ? <TriangleUp /> : <TriangleDown />}</IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </FxCardHeader>
                    <div className="fx-form-edit-profile">
                        <FxCardBody id="signUp-beneficialOwner-form-card-body" className="fx-info-card" >
                            <Grid container direction="row" className="fx-form-edit-profile " xs={12}>
                                <Grid item container xs={12}>
                                    {!props.editView && <><Grid item xs={12} >
                                        {props?.item?.beneficialOwner?.[props.index]?.ownerShipPercentage !== '' ? <Grid item sm={12} xs={12}>
                                            <Typography id="business-owner-name-label" variant="h6" className='fx-new-invite-owner-profile-label-sub-header'>{toTitleCase(props?.item?.firstName + ' ' + props?.item?.lastName) + ' (Ownership - ' + props?.item?.ownerShipPercentage + '%)'}</Typography>
                                        </Grid> :
                                            <Grid item sm={12} xs={12}>
                                                <Typography id="business-owner-name-label" variant="h5" >{toTitleCase(props?.item?.firstName + ' ' + props?.item?.lastName)}</Typography>
                                            </Grid>}
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography id="business-owner-name-label" variant='h6' className='fx-new-invite-owner-profile-sub-label' >{props?.item?.jobtitle}</Typography>
                                    </Grid>
                                    <Grid item container xs={12} justifyContent='space-between'>
                                        <Grid item>
                                            <Typography id="business-owner-name-label" variant='h6' className='fx-new-invite-owner-profile-sub-label' >{props?.item?.email}</Typography>
                                        </Grid>
                                        <Grid item>
                                        {!props.editView &&
                                            <Grid item container justifyContent='flex-end' className='fx-new-invite-owner-profile-action-grid'>
                                                <Grid item>
                                                    <IconButton
                                                        id={"edit-business-owner"}
                                                        className="fx-button fx-transparent fx-theme-color fx-svg-color"
                                                        onClick={() => { editBusinessOwner(props?.item) }}
                                                        size="large"><EditIcon /> &nbsp; EDIT</IconButton>
                                                </Grid>
                                                <Grid item>
                                                   {!props?.item?.id && <FxDelete entity="businessowner" id={'delete-business-owner'} type="button" buttonId={'delete-business-owner-submit'} buttonTitle="REMOVE" header={"Are you sure want to delete this Business Owner?"} description="You cannot undo this action." successEntity="Business Owner" noEntity={true} open={props.deleteBusinessOwner} close={deleteBusinessOwnerClose} onSuccess={() => { remove(props?.index) }} />}
                                                    {props?.item?.id && <FxDelete entity="beneficialOwner" noRedirection={true} type="button"  buttonId={'delete-business-owner-submit'} buttonTitle="REMOVE" header={"Are you sure want to delete this Business Owner?"} description="You cannot undo this action." successEntity="Business Owner" id={props?.item?.id} open={props.deleteBusinessOwner} close={deleteBusinessOwnerClose} onSuccess={() => { remove(props?.index) }} />}
                                                </Grid>
                                            </Grid>}
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} alignItems="center" >
                                        <Grid item container xs={6}>
                                            {(props?.item?.authSignatory?.[0] === 'authorized' ? true : props?.item?.authSignatory === true) &&
                                                <>
                                                    <Grid item container className='fx-new-invite-auth-rep'>
                                                    <Grid item>
                                                        <VerifiedIcon />
                                                    </Grid>
                                                    <Grid item >
                                                        <FxLabelView id="business-owner-name-label" className="" >&nbsp;AUTHORIZED REPRESENTATIVE</FxLabelView>
                                                    </Grid>
                                                    </Grid>
                                                </>}
                                        </Grid>
                                    </Grid>

                                    {props.showDetails && !props.editView && <Grid item xs={12} className="fx-new-invite-business-owner-info-card">
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <FxSectionInfoCard id={"business-owner-info-card"} title={props?.index + 1 + '. OWNER PROFILE'} column={ownerProfileDetails} data={ownerDetails} />
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <FxSectionInfoCard id={"business-owner-address-info-card"} title={"PRIMARY ADDRESS"} column={ownerProfileAddress} data={ownerDetails} />
                                    </Grid>}
                                    </>}
                                    {props.editView && <Grid item xs={12}>
                                    <AddBeneficialOwner id='view-beneficial-owner-add' onSubmit={handleOnSubmit} onCancel={handleOnCancel} register={props.register} control={props.control} errors={props.errors} setValue={props.setValue} setError={props.setError} clearErrors={props.clearErrors} handleSubmit={props.handleSubmit} businessCategory={props.businessCategory} data={props.fields} watch={props.watch} index={props.index} isEdit={true} trigger={props.trigger}/>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </FxCardBody>
                    </div>
                </FxCard>
            </Grid>
        );
    }
)
