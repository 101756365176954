/* eslint-disable eqeqeq */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from 'react'
import { Grid, Typography } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../../libs/utils/logger';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import { ReactComponent as ThanksIcon } from '../../../../assets/svg/thanks.svg';
import { ReactComponent as RedCrossIcon } from "../../../../assets/svg/red-cross.svg";
import { ReactComponent as BackArrowIcon } from '../../../../assets/svg/page-left.svg';
import { ReactComponent as ForwardArrowIcon } from '../../../../assets/svg/page-right.svg';
import { FxButton } from '../../../Action/FxButton';
import { FxLink } from '../../../Action/FxLink';
import { useMediaQuery } from 'react-responsive';
import { pushGTM } from '../../../../libs/utils/utils';
import { TransactionMethodAndTypeMapper } from '../../../../libs/utils/TransactionMethodAndTypeMapper';
import { ReactComponent as EAThankYouImage } from '../../../../assets/svg/ea-thank-you.svg';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { getScheduleType } from '../../../Utils/CommonBaseClass';
const RECURRING_CREATION_MESSAGE = 'Based on your request, we have successfully created a recurring transaction.';


Logger.debug("ThankYouPage.tsx", "thank you page initializing")
/**
 * This component handles the thank you page. Create pages are getting redirected to thank you page after successful api call
 */
export const ThankYouPage: React.FC<any> = React.memo(
    (props) => {
        const params = useParams<any>();
        const page: any = (params.page) ? (params.page) : ''
        const type: string = params?.type || '';
        const id: string = params?.id || '';
        let pageInfo: any;
        let submitButton: any;
        const dispatch = useDispatch()
        const history = useHistory()
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        const location:any = useLocation()
        const authCode = location.state?.authCode;
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available;

        if (props.buttonText) {
            submitButton = props.buttonText
        }
        else {
            if(type.includes('send')) {
                submitButton = 'SEND ANOTHER'
            } else if (type.includes('collect')) {
                submitButton = getScheduleType(businessCustomer,'uppercase') + ' ANOTHER'
            } else {
                submitButton = type === 'co-owner' ? 'ADD ANOTHER' : 'CREATE ANOTHER'
            }
        }
        /**
         * to change heading
         */
        useEffect(() => {
            const gtmData: any = {};
            if (type.includes('send')) {
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Send Money', backButton: '/transactions' } } });
                /**
                 * Configuring sendTrigger GTM event after creating a send
                 */
                gtmData['event'] = "sendTrigger";
            }
            else if (type === 'onetime-card' || type === 'onetime-ach' || type.includes('collect') || type === 'from-self-card' || type === 'mobile-check-deposit') {
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: getScheduleType(businessCustomer,'title') + ' Money', backButton: '/transactions' } } });
                /**
                 * Configuring collectTrigger GTM event after creating a collect
                 */
                gtmData['event'] = "collectTrigger";
            }
            if((gtmData['event'] === "sendTrigger" || gtmData['event'] === "collectTrigger") && TransactionMethodAndTypeMapper[type]){
                gtmData['transactionMethod'] = TransactionMethodAndTypeMapper[type]?.method;
                gtmData['transactionType'] = TransactionMethodAndTypeMapper[type]?.type;
                pushGTM(gtmData);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        /**
         * Method handles the onclick event to go to My Account page
         */
        const handleGotoAccount = () => {
            history.push('/');
        };

        /**
         * Method to split the large message
         * @param message 
         * @param splitChar 
         * @returns 
         */
        function splitMessage(message: string, splitChar: string) {
            const messageArray: any = message.split(splitChar)
            return <Grid item container justifyContent="center" xs={12}>{
                messageArray.map((item: any, index: any) => (
                    <Typography id={`title-thank-you-message-${index}`} className={'fx-thanks-ea-message'} align='center'>{item}</Typography>
                ))
            }
            </Grid>
        }
        /**
         * Method handles the onclick event to go to Users page
         */
        const handleGotoUsers = () => {
            if (type === 'authorized-user') {
                history.push('/authorizedusers');
            }
            else {
                history.push('/');
            }
        };
           /**
         * Method handles the onclick event to go to Users page
         */
           const handleGotoCoOwners = () => {
            if (type === 'co-owner') {
                history.push('/co-owner/profile');
            }
            else {
                history.push('/');
            }
        };
        /**
        * Method handles the onclick event to go to My Payees page
        */
        const handleGotoPayee = () => {
            if (type === 'create-payee') {
                history.push('/payees');
            }
            else {
                history.push('/');
            }
        }

        /**
        * Method handles the onclick event to go to Approval Policy page
        */
        const handleGotoPolicyList = () => {
            if (type === 'approvalpolicy') {
                history.push('/approvalpolicy');
            }
            else {
                history.push('/');
            }
        }

        /**
        * Method handles the onclick event to go to Webhook List page
        */
        const handleGotoWebhookList = () => {
            if (type === 'webhooks') {
                history.push('/webhooks');
            }
            else {
                history.push('/');
            }
        }

        /**
        * Method handles the onclick event to go to Funding Rule Listing page
        */
         const handleGotoFundingRuleList = () => {
            if (type === 'fundingrule') {
                history.push('/fundingrules/active');
            }
            else {
                history.push('/');
            }
        }

        /**
        * Method handles the onclick event to go to External Accounts page
        */
        const handleGotoExternalAccount = () => {
            if (type === 'externalaccount') {
                history.push('/externalaccounts');
            }
            else {
                history.push('/');
            }
        }


         /**
        * Method handles the onclick event to go to Int External Accounts page
        */
         const handleGotoIntExternalAccount = () => {
            if (type === 'internationalexternalaccount') {
                history.push('/internationalexternalaccounts');
            }
            else {
                history.push('/');
            }
        }
        /**
        * Method handles the onclick event to go to send page
        */
        const handleGotoTransactions = () => {
            const transactionTypes = [
                'collect-contact-card', 'collect-contact-ach', 'send-contact-ach', 'send-contact-wire', 
                'send-contact-check', 'send-onetime-ach', 'send-ach', 'send-wire', 'send-check', 
                'send-book', 'send-transfer', 'send-contact-book', 'send-onetime-internationalWire', 
                'send-internationalWire', 'send-contact-internationalWire', 'send-onetime-virtual-card', 
                'send-contact-virtual-card', 'send-onetime-card', 'onetime-card', 'onetime-ach', 
                'collect-my-account', 'my-account-ach', 'mobile-check-deposit', 'collect-self-ach'
            ];
        
            const failedCardTypes = ['send-failedonetime-card', 'failedonetime-card'];
        
            if (transactionTypes.includes(type)) {
                history.push('/transactions');
            } else if (failedCardTypes.includes(type)) {
                history.push('/transactionsHistory');
            } else if (type.includes('recurring')) {
                history.push('/recurring');
            }
        };
        

        /**
         * Method handles the onclick event to go back to the create page of corresponding entity
         */
        const handleMakeAnotherClick = () => {
            let url = '/';
            // eslint-disable-next-line array-callback-return
            pageInfo?.breadcrumbs?.map((item: any, index: number) => {
                if (index == pageInfo?.breadcrumbs?.length - 2) {
                    url = item.link;
                }
            })
            history.push(url);
        };

        /**
         * View link computation based on type
         */
        const viewLinkPathWithoutId = useMemo(() => {
            if(!type) {
                return;
            }
            if(type === 'create-payee'){
                return '/payee/view/';
            }
            if(type.includes('recurring')) {
                return '/recurring/details/';
            }
            if(type.startsWith('send')) {
                return '/transactions/send/';
            }
            if(type.startsWith('collect') || type === 'onetime-card' || type === 'onetime-ach' || type === 'my-account-ach' || type === 'mobile-check-deposit' || type === 'failedonetime-card') {
                return '/transactions/collect/';
            }
            if(type === 'externalaccount') {
                return '/externalaccount/view/'
            }
            if(type === 'internationalexternalaccount') {
                return '/internationalexternalaccount/view/'
            }
            if(type === 'authorized-user') {
                return '/authorizedusers/view/'
            }
            if(type === 'approvalpolicy'){
                return '/approvalpolicy/view/' 
            }
            if(type === 'webhooks'){
                return '/webhook/view/' 
            }
            if(type === 'fundingrule'){
                return '/fundingrule/view/' 
            }
        }, [type]);

        /**
         * Method to view transaction details
         */
        const viewDetails = useCallback((id: string) => {
            if(!viewLinkPathWithoutId) {
                return;
            }
            history.push(viewLinkPathWithoutId + id);
        }, [viewLinkPathWithoutId, history]);

        const pageData: any = {
            "transactions": {
                "breadcrumbs": [{
                    title: 'Dashboard',
                    link: '/'
                }],
                "pages": {
                    "my-account-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Collect Money from',
                                link: '/collect'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/collect/add/my-account-ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a collect from an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "co-owner": {
                        "breadcrumbs": [
                            {
                                title: 'Co-Owners',
                                link: '/co-owner/profile'
                            },
                            {
                                title: 'Add Co-Owners',
                                link: '/co-owner/add'
                            }
                        ],
                        "message": "Co-Owner will receive an email to accept the Passport Account Agreement.",
                        "reference": "Name: #id"
                    },
                    "from-self-card": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: 'From Self',
                                link: '/transaction/collect/add/from-self-card'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a collect from a card account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "edit-collect-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/transactions/collect/'
                            },
                            {
                                title: 'Collect #id',
                                link: '/transactions/collect/#id'
                            },
                            {
                                title: 'Edit',
                                link: '/transaction/collect/edit/onetime-ach/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated a collect from an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "edit-collect-card": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/transactions/collect/'
                            },
                            {
                                title: 'Collect #id',
                                link: '/transactions/collect/#id'
                            },
                            {
                                title: 'Edit',
                                link: '/transaction/collect/edit/card/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated a collect from an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "mobile-check-deposit": {
                        "breadcrumbs": [
                            {
                                title: 'Collect Money from',
                                link: '/collect'
                            },
                            {
                                title: 'Check Deposit',
                                link: '/transaction/collect/add/mobile-check-deposit'
                            }
                        ],
                        "message": `Based on your request, we have successfully scheduled a ${getScheduleType(businessCustomer,'lowercase')} via check.`,
                        "reference": "Transaction Ref: #id"
                    },
                    "onetime-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: 'Onetime ACH',
                                link: '/transaction/collect/add/onetime-ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a collect from onetime ACH account.",
                        "reference": "Transaction Ref: #id"
                    },
                    "onetime-card": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: 'From Payer',
                                link: '/transaction/collect/add/onetime-card'
                            }
                        ],
                        "message": "Based on your request, we have successfully created the transaction.",
                        "reference": "Transaction Ref: #id"
                    },
                    "failedonetime-card": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: 'From Payer',
                                link: '/transaction/collect/add/onetime-card'
                            }
                        ],
                        "message": "We are unable to process your transaction. Please check your terminal to review the error details.",
                        "reference": "Transaction Ref: #id"
                    },
                    "payer-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Collect Money from',
                                link: '/collect'
                            },
                            {
                                title: 'My Payer',
                                link: '/transaction/collect/add/payer-ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a collect from your Payer's external account.",
                        "reference": "Transaction Ref: #id"
                    },
                    "payer-card": {
                        "breadcrumbs": [
                            {
                                title: 'Collect Money from',
                                link: '/collect'
                            },
                            {
                                title: 'My Payer',
                                link: '/transaction/collect/add/payer-ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a collect from your Payer's card account.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/add/ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-internationalWire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/add/internationalWire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an international external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-ach-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/add/ach'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "send-onetime-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/onetime/add/ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-onetime-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/onetime/add/wire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-onetime-card": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/onetime/add/card'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-failedonetime-card": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/onetime/add/card'
                            }
                        ],
                        "message": "We are unable to process your transaction. Please check your terminal to review the error details.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-onetime-internationalWire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/onetime/add/internationalWire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an international external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-wallet-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Wallet',
                                link: '/transaction/send/wallet/add/ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-wallet-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Wallet',
                                link: '/transaction/send/wallet/add/wire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-wallet-transfer": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Wallet',
                                link: '/transaction/send/wallet/add/transfer'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to wallet shared with you.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-wallet-transfer-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Wallet',
                                link: '/transaction/send/wallet/add/transfer'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "wallet-shared-transfer": {
                        "breadcrumbs": [
                            {
                                title: 'Shared Wallets',
                                link: '/wallet/mine'
                            },
                            // {
                            //     title: 'Wallet',
                            //     link:'/wallet/shared/view/' + 93
                            // },
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Wallet',
                                link: '/transaction/send/wallet/add/transfer'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to wallet shared with you.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-wallet-check": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Wallet',
                                link: '/transaction/send/wallet/add/check'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/add/wire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-wire-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/add/wire'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "send-check": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/add/check'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to your mailing address.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-check-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/add/check'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "send-book": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/onetime/add/book'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to an external account you own.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-other-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "Others",
                                link: '/transaction/send/other/add/ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to Others.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-other-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "Others",
                                link: '/transaction/send/other/add/wire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to Others.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-other-check": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "Others",
                                link: '/transaction/send/other/add/check'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to Others.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-other-transfer": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'Others',
                                link: '/transaction/send/other/add/transfer'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to other customer linked Passport Account.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-contact-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Send to Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "collect-contact-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: "My Account",
                                link: '/transaction/collect/contact/add/ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Collect from Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "collect-contact-card": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: "My Account",
                                link: '/transaction/collect/contact/add/card'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Collect from Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "collect-self-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/collect/add/my-account-ach'
                            }
                        ],
                        "message": `Based on your request, we have successfully scheduled a ${getScheduleType(businessCustomer,'lowercase')} from an external account you own.`,
                        "reference": "Transaction Ref: #id"
                    },
                    "collect-self-ach-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/collect/add/my-account-ach'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "send-contact-ach-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/ach'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "collect-contact-ach-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: "My Account",
                                link: '/transaction/collect/contact/add/ach'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "collect-contact-card-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Collect',
                                link: '/collect'
                            },
                            {
                                title: "My Account",
                                link: '/transaction/collect/contact/add/card'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "send-contact-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/wire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Send to Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-contact-wire-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/wire'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "send-contact-check": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/check'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Send to Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-contact-check-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/check'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "send-contact-book": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Payee',
                                link: '/transaction/send/contact/add/book'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Send to Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-contact-internationalWire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Payee',
                                link: '/transaction/send/contact/add/internationalWire'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Send to Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-contact-book-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/book'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "edit-send": {
                        "breadcrumbs": [
                            {
                                title: 'Send',
                                link: '/transactions/send/'
                            },
                            {
                                title: 'Send #id',
                                link: '/transactions/send/#id'
                            },
                            {
                                title: 'Edit',
                                link: '/transaction/send/edit/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated a send.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-transfer": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/transfer'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a send to your linked Passport Account.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-transfer-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/transfer'
                            }
                        ],
                        "message": RECURRING_CREATION_MESSAGE,
                        "reference": "Recurring Transaction Ref: #id"
                    },
                    "collect-my-account": {
                        "breadcrumbs": [
                            {
                                title: 'Collect Money from',
                                link: '/collect'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/collect/add/my-account'
                            },
                            {
                                title: 'Add External Account',
                                link: '/externalaccount/add/my-account'
                            }
                        ],
                        "message": "Based on your request, we have successfully added an External Account.",
                        "reference": "Transaction Ref: #id"
                    },
                    "collect-payer-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Collect Money from',
                                link: '/collect'
                            },
                            {
                                title: 'My Payer',
                                link: '/transaction/collect/add/payer-ach'
                            },
                            {
                                title: 'Add Payer',
                                link: '/create-payer/payer-ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Payer.",
                        "reference": "Payer ID: #id"
                    },
                    "collect-payer-card": {
                        "breadcrumbs": [
                            {
                                title: 'Collect Money from',
                                link: '/collect'
                            },
                            {
                                title: 'My Payer',
                                link: '/transaction/collect/add/payer-card'
                            },
                            {
                                title: 'Add Payer',
                                link: '/create-payer/payer-card'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Payer.",
                        "reference": "Payer ID: #id"
                    },
                    "withdrawal-external-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/withdrawal/ach'
                            },
                            {
                                title: 'Add External Account',
                                link: '/externalaccount/add/withdrawal-ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully added an External Account.",
                        "reference": "EA ID: #id"
                    },
                    "withdrawal-external-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/withdrawal/wire'
                            },
                            {
                                title: 'Add External Account',
                                link: '/externalaccount/add/withdrawal-wire'
                            }
                        ],
                        "message": "Based on your request, we have successfully added an External Account.",
                        "reference": "EA ID: #id"
                    },
                    "edit-withdrawal-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Withdrawal',
                                link: '/transactions/withdrawal'
                            },
                            {
                                title: 'Withdrawal #id',
                                link: '/transactions/withdrawal/#id'
                            },
                            {
                                title: 'Edit',
                                link: '/transaction/edit-withdrawal/ach/#id'
                            },
                        ],
                        "message": "Based on your request, we have successfully updated withdrawal.",
                        "reference": "Withdrawal ID: #id"
                    },
                    "edit-withdrawal-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Withdrawal',
                                link: '/transactions/withdrawal'
                            },
                            {
                                title: 'Withdrawal #id',
                                link: '/transactions/withdrawal/#id'
                            },
                            {
                                title: 'Edit',
                                link: '/transaction/edit-withdrawal/wire/#id'
                            },
                        ],
                        "message": "Based on your request, we have successfully updated withdrawal.",
                        "reference": "Withdrawal ID: #id"
                    },
                    "edit-withdrawal-check": {
                        "breadcrumbs": [
                            {
                                title: 'Withdrawal',
                                link: '/transactions/withdrawal'
                            },
                            {
                                title: 'Withdrawal #id',
                                link: '/transactions/withdrawal/#id'
                            },
                            {
                                title: 'Edit',
                                link: '/transaction/edit-withdrawal/check/#id'
                            },
                        ],
                        "message": "Based on your request, we have successfully updated withdrawal.",
                        "reference": "Withdrawal ID: #id"
                    },
                    "send-payee-ach": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Payee',
                                link: '/transaction/send/add/ach'
                            },
                            {
                                title: 'Add Payee',
                                link: '/create-payee/send-ach'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Payee.",
                        "reference": "Payee ID: #id"
                    },
                    "send-payee-wire": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Payee',
                                link: '/transaction/send/add/wire'
                            },
                            {
                                title: 'Add Payee',
                                link: '/create-payee/send-wire'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Payee.",
                        "reference": "Payee ID: #id"
                    },
                    "send-payee-check": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Payee',
                                link: '/transaction/send/add/check'
                            },
                            {
                                title: 'Add Payee',
                                link: '/create-payee/send-check'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Payee.",
                        "reference": "Payee ID: #id"
                    },
                    "create-payee": {
                        "breadcrumbs": [
                            {
                                title: 'Dashboard',
                                link: '/'
                            },
                            {
                                title: 'Contacts',
                                link: '/payees'

                            },
                            {
                                title: 'Add Contact',
                                link: '/payee/add'
                            }
                        ],
                        "message": "We have successfully added a Contact for you.",
                        "reference": "Contact ID: #id"
                    },
                    "send-contact-virtual-card": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/virtualcard'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a Send to Contact.",
                        "reference": "Transaction Ref: #id"
                    },
                    "send-contact-virtual-card-recurring": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: "My Payee",
                                link: '/transaction/send/contact/add/virtualcard'
                            }
                        ],
                        "message": "Based on your request, we have successfully created a recurring.",
                        "reference": "Recurring Ref: #id"
                    },
                    "send-onetime-virtual-card": {
                        "breadcrumbs": [
                            {
                                title: 'Send Money to',
                                link: '/send'
                            },
                            {
                                title: 'My Account',
                                link: '/transaction/send/onetime/add/virtualcard'
                            }
                        ],
                        "message": "Based on your request, we have successfully scheduled a one time send via Virtual Card",
                        "reference": "Transaction Ref: #id"
                    },
                }
            },
            "manage": {
                "breadcrumbs": [{
                    title: 'Dashboard',
                    link: '/'
                }],
                "pages": {
                    "externalaccount": {
                        "breadcrumbs": [
                            {
                                title: 'Manage External Accounts',
                                link: '/externalaccounts'
                            },
                            {
                                title: 'Add External Account',
                                link: '/externalaccount/add'
                            }
                        ],
                        "message": "Based on your request, we have successfully added an External Account.|Please verify your external account by checking for two micro deposits with the descriptor 'ACCTVERIFY.' These deposits can range from 0.01 to $0.99 and may take up to two business days to appear in your account.|Refer below image as a reference of how the amounts will reflect in your bank account statement.",
                        "image":EAThankYouImage,
                        "islargeDescription":true,
                        "isSplit":true,
                        "reference": "EA ID: #id"
                    }, "edit-externalaccount": {
                        "breadcrumbs": [
                            {
                                title: 'Manage External Accounts',
                                link: '/externalaccounts'
                            },
                            {
                                title: 'Edit External Account',
                                link: '/externalaccount/edit/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated the External Account.",
                        "reference": "EA ID: #id"
                    }, "internationalexternalaccount": {
                        "breadcrumbs": [
                            {
                                title: 'Manage International External Accounts',
                                link: '/internationalexternalaccounts'
                            },
                            {
                                title: 'Add International External Account',
                                link: '/internationalexternalaccount/add'
                            }
                        ],
                        "message": "Based on your request, we have successfully added an International External Account.",
                        "reference": "IEA ID: #id"
                    }, "edit-internationalexternalaccount": {
                        "breadcrumbs": [
                            {
                                title: 'Manage International External Accounts',
                                link: '/internationalexternalaccounts'
                            },
                            {
                                title: 'Edit International External Account',
                                link: '/internationalexternalaccount/edit/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated the International External Account.",
                        "reference": "IEA ID: #id"
                    }, "payer": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Payer',
                                link: '/payers'
                            },
                            {
                                title: 'Add Payer',
                                link: '/create-payer'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Payer.",
                        "reference": "Payer ID: #id"
                    }, "edit-payer": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Payer',
                                link: '/payers'
                            },
                            {
                                title: 'Edit Payer',
                                link: '/edit-payer/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated the Payer.",
                        "reference": "Payer ID: #id"
                    }, "payee": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Payee',
                                link: '/payees'
                            },
                            {
                                title: 'Add Payee',
                                link: '/create-payee'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Payee.",
                        "reference": "Payee ID: #id"
                    }, "edit-payee": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Payee',
                                link: '/payees'
                            },
                            {
                                title: 'Edit Payee',
                                link: '/payee/edit/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated the Payee.",
                        "reference": "Payee ID: #id"
                    }, "user": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Users',
                                link: '/users'
                            },
                            {
                                title: 'Add User',
                                link: '/user/add'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a user. ()",
                        "reference": "User ID: #id"
                    }, "edit-user": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Users',
                                link: '/users'
                            }, {
                                title: 'User',
                                link: '/user/view/#id'
                            }, {
                                title: 'Edit User',
                                link: '/user/edit/#id'
                            }
                        ],
                        "message": "Based on your request, we have successfully updated user.",
                        "reference": "User ID: #id"
                    },
                    "card": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Card',
                                link: '/cards'
                            },
                            {
                                title: 'Add Card',
                                link: '/create-card'
                            }
                        ],
                        "message": "Based on your request, we have successfully added a Card.",
                        "reference": "Card ID: #id"
                    },
                    "debit-card": {
                        "breadcrumbs": [
                            {
                                title: 'Request Debit Card',
                                link: '/debit-card'
                            }
                        ],
                        "message": "We have received your request to issue debit card. You should received it within 2-7 business days."
                    },
                    "create-wallet": {
                        "breadcrumbs": [
                            {
                                title: 'My Wallet',
                                link: '/wallet/mine'
                            },
                            {
                                title: 'Add Wallet',
                                link: '/wallets/add'
                            }
                        ],
                        "message": "We have successfully created a Wallet for you.",
                        "reference": "Wallet ID: #id"
                    },
                    "authorized-user": {
                        "breadcrumbs": [
                            {
                                title: 'Authorized Users',
                                link: '/authorizedusers'
                            },
                            {
                                title: 'Add Authorized Users',
                                link: '/authorizedusers/add'
                            }
                        ],
                        "message": "We have successfully created an authorized user for you.",
                        "reference": "Authorized User ID: #id"
                    },
                    "approvalpolicy": {
                        "breadcrumbs": [
                            {
                                title: 'Manage Policy',
                                link: '/approvalpolicy'
                            },
                            {
                                title: 'Approval Policy',
                                link: '/approvalpolicy/add'
                            }
                        ],
                        "message": "We have successfully created your approval policy.",
                        "reference": "Approval Policy Ref: #id"
                    },
                    "webhooks": {
                        "breadcrumbs": [
                            {
                                title: 'Webhooks',
                                link: '/webhooks'
                            },
                            {
                                title: 'Add Webhook',
                                link: '/webhook/add'
                            }
                        ],
                        "message": "We have successfully created your webhook.",
                        "reference": "Webhook Ref: #id"
                    },
                    "fundingrule": {
                        "breadcrumbs": [
                            {
                                title: 'Split Funding',
                                link: '/fundingrules/active'
                            },
                            {
                                title: 'Add Split Funding',
                                link: '/fundingrule/create'
                            }
                        ],
                        "message": "Based on your request, we have successfully created a new Funding Rule.",
                        "reference": "Rule ID: #id"
                    },
                    "co-owner": {
                        "breadcrumbs": [
                            {
                                title: 'Co-Owners',
                                link: '/co-owner/profile'
                            },
                            {
                                title: 'Add Co-Owners',
                                link: '/co-owner/add'
                            }
                        ],
                        "message": "Co-Owner will receive an email to accept the Passport Account Agreement.",
                        "reference": "Name: #firstName"
                    },
                }
            }
        };

        // Function to generate buttons based on the type
        const generateButtons = () => {
            if (type === 'create-wallet') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoAccount}
                    >
                        GO TO MY WALLET
                    </FxButton>
                );
            }

            if (type === 'authorized-user') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoUsers}
                    >
                        GO TO AUTHORIZED USERS
                    </FxButton>
                );
            }
            if (type === 'co-owner') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-co-owner-button"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoCoOwners}
                    >
                        GO TO OWNERS
                    </FxButton>
                );
            }

            if (type === 'create-payee') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button-payee"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoPayee}
                    >
                        GO TO MY CONTACT
                    </FxButton>
                );
            }

            if (type === 'approvalpolicy') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button-payee"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoPolicyList}
                    >
                        GO TO POLICY LIST
                    </FxButton>
                );
            }

            if (type === 'webhooks') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button-payee"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoWebhookList}
                    >
                        GO TO WEBHOOK LIST
                    </FxButton>
                );
            }
            

            if (type === 'fundingrule') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-funding-rule-listing-button"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoFundingRuleList}
                    >
                        GO TO RULE LISTING
                    </FxButton>
                );
            }

            if (type === 'externalaccount') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button-external-account"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoExternalAccount}
                    >
                        GO TO EXTERNAL ACCOUNTS
                    </FxButton>
                );
            }

            if (type === 'internationalexternalaccount') {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button-international-external-account"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoIntExternalAccount}
                    >
                        GO TO INTERNATIONAL EXTERNAL ACCOUNTS
                    </FxButton>
                );
            }

            if (
                type !== 'create-wallet' &&
                type !== 'create-payee' &&
                type !== 'authorized-user' &&
                type !== 'send-contact-ach' &&
                type !== 'collect-contact-ach' &&
                type !== 'collect-contact-card' &&
                type !== 'send-contact-wire' &&
                type !== 'send-contact-wire-recurring' &&
                type !== 'send-contact-check' &&
                type !== 'send-contact-check-recurring' &&
                type !== 'send-onetime-ach' &&
                type !== 'send-ach' &&
                type !== 'send-wire' &&
                type !== 'send-wire-recurring' &&
                type !== 'send-check' &&
                type !== 'send-check-recurring' &&
                type !== 'send-book' &&
                type !== 'send-transfer' &&
                type !== 'send-transfer-recurring' &&
                type !== 'my-account-ach' &&
                type !== 'mobile-check-deposit' &&
                type !== 'onetime-card' &&
                type !== 'failedonetime-card' &&
                type !== 'send-onetime-card' &&
                type !== 'send-failedonetime-card' &&
                type !== 'send-ach-recurring' &&
                type !== 'send-contact-book' &&
                type !== 'externalaccount' &&
                type !== 'internationalexternalaccount' &&
                type !== 'internationalexternalaccount' &&
                type !== 'send-contact-virtual-card' &&
                type !== 'send-contact-virtual-card-recurring' &&
                type !== 'send-onetime-virtual-card' &&
                type !== 'collect-self-ach' &&
                type !== 'collect-self-ach-recurring' &&
                type !== 'send-contact-internationalWire' &&
                type !== 'send-onetime-internationalWire' &&
                type !== 'send-internationalWire' &&
                type !== 'send-contact-ach-recurring' &&
                type !== 'collect-contact-card-recurring' &&
                type !== 'collect-contact-ach-recurring' &&
                type !== 'send-contact-book-recurring' &&
                type !== 'onetime-ach'
            ) {
                return (
                    <FxButton
                        variant="contained"
                        className="fx-button fx-button-passport-border fx-svg-theme"
                        id="goto-account-button"
                        startIcon={<BackArrowIcon />}
                        onClick={handleGotoAccount}
                    >
                        GO TO MY ACCOUNT
                    </FxButton>
                );
            }

            return (
                <FxButton
                    className="fx-button fx-button-passport-border fx-svg-theme"
                    variant="contained"
                    id="transaction-list"
                    startIcon={<BackArrowIcon />}
                    onClick={handleGotoTransactions}
                >
                    GO TO {type?.includes('recurring') ? 'RECURRING': ''} TRANSACTION ACTIVITY
                </FxButton>
            );
        };

        pageInfo = getPageData(pageData, type, page, id);

        return (
            <Grid id="thank-you-Grid" item xs={12} className="">
                <FxCard id="thank-you-page-card" className=" fx-card-general fx-theme-passport fx-no-shadow">
                    <FxCardBody id="thank-you-page-body" className="fx-info-card fx-thankyou" >
                        <Grid container xs={12} sm={12} alignItems={isSmallScreen?"center":"flex-start"}>
                            <Grid item container xs={12} >
                                {!isSmallScreen && <Grid item xs={type?.includes('recurring') ? 3: pageInfo?.islargeDescription?3:4}></Grid>}
                                <Grid item xs >
                                    <Grid item container>
                                        <Grid item container justifyContent="center" xs={12}>
                                           { (type === 'failedonetime-card' || type === 'send-failedonetime-card') ? <RedCrossIcon className='fx-pb-4' /> : <ThanksIcon className="fx-icon" /> } 
                                        </Grid>
                                        {(type === 'collect-contact-card' || type === 'collect-contact-ach' || type === 'send-contact-ach' || type === 'send-contact-wire' || type === 'send-contact-check' || type === 'send-onetime-ach' || type === 'onetime-ach' || type === 'collect-my-account' || type == 'send-ach' || type === 'send-wire' || type === 'send-check' || type === 'send-book' || type === 'send-transfer' || type === 'my-account-ach' || type === 'mobile-check-deposit' || type === 'send-contact-book' || type === 'collect-self-ach-recurring' || type === 'send-contact-virtual-card' || type === 'send-onetime-virtual-card' || type === 'send-onetime-internationalWire' || type === 'send-internationalWire' || type === 'send-contact-internationalWire' || type === 'onetime-card' || type === 'send-onetime-card') ?
                                            <Grid item container justifyContent="center" xs={12} className="fx-page-header">
                                                <Typography id="title-thank-you" variant="h3" >{type?.includes('recurring') ? 'Recurring': ''} Transaction Created Successfully</Typography>
                                            </Grid> : (type === 'approvalpolicy')? 
                                            <Grid item container justifyContent="center" xs={12} className="fx-page-header">
                                            <Typography id="title-thank-you" variant="h3" >Approval Policy Created Successfully</Typography>
                                            </Grid> : (type === 'fundingrule')? 
                                            <Grid item container justifyContent="center" xs={12} className="fx-page-header">
                                            <Typography id="title-thank-you" variant="h3" >Funding Rule Created Successfully</Typography>
                                            </Grid> : (type === 'webhooks')? 
                                            <Grid item container justifyContent="center" xs={12} className="fx-page-header">
                                            <Typography id="title-thank-you" variant="h3">Webhook Created Successfully</Typography>
                                            </Grid> :
                                            (type === 'failedonetime-card' || type === 'send-failedonetime-card')? 
                                            <Grid item container justifyContent="center" xs={12} className="fx-page-header">
                                            <Typography id="title-thank-you" variant="h3">Transaction Failed</Typography>
                                            </Grid> :
                                            <Grid item container justifyContent="center" xs={12} className="fx-page-header">
                                                <Typography id="title-thank-you" variant="h3" >Thank You!</Typography>
                                            </Grid>}
                                        {!pageInfo?.isSplit && <Grid item container justifyContent="center" xs={12} >
                                            <Typography id="title-thank-you-message" className={pageInfo?.image?'fx-thanks-ea-message':'fx-thankyou-message'} align='center'>{pageInfo?.message}</Typography>
                                        </Grid>}
                                        {pageInfo?.isSplit && splitMessage(pageInfo?.message,'|')}
                                        {pageInfo?.image && <Grid container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center" className='fx-thanks-ea-image' xs={12} ><pageInfo.image /></Grid>}
                                            {authCode && <Grid item container alignItems='baseline' xs={12} justifyContent="center">
                                                <Typography id="title-thank-you-message" className='fx-thankyou-message'>Auth Code: {authCode?.toUpperCase()}</Typography>
                                            </Grid>}
                                        <Grid item container alignItems='baseline' xs={12} justifyContent="center">
                                                <Typography id="title-thank-you-message" className='fx-thankyou-message'>{pageInfo?.reference}</Typography>
                                            {viewLinkPathWithoutId && <Grid item container xs={2} alignItems='center' justifyContent="center">
                                                <FxLink className='fx-link-thank-you' id={'view-transaction-details'} onClick={() => viewDetails(id)}>View </FxLink><ForwardArrowIcon className='fx-thanks-arrow-right' />
                                            </Grid>}
                                        </Grid>
                                        &nbsp;&nbsp;
                                        {/* Render the buttons using the generateButtons function */}
                                        <Grid className='fx-grid-margin-bottom-mobile' item container justifyContent="center" xs={12}>
                                            {generateButtons()}
                                            {(type !== 'debit-card' && type !== 'create-wallet') && <>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme fx-thank-you-page-button"
                                                    id={submitButton + "-transfer"}
                                                    endIcon={<ForwardArrowIcon />}
                                                    type="submit"
                                                    onClick={handleMakeAnotherClick}>{submitButton}</FxButton>
                                            </>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!isSmallScreen && <Grid item xs={type?.includes('recurring') ? 3:pageInfo?.islargeDescription?2:4}></Grid>}
                            </Grid>
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid>
        )
    });

/**
 * Method to set page data by type
 */
function getPageData(pageData: any, type: any, page: any, id: any) {
    try {
        let selected: any = '';
        Object.entries(pageData).forEach(([node, nodprops]) => {
            if (page == node) {
                Object.entries(pageData[node]['pages']).forEach(([subnode, subnodprops]) => {
                    if (subnode == type) {
                        selected = subnodprops;
                        selected.breadcrumbs?.unshift(pageData[node]['breadcrumbs'][0]);
                        selected.breadcrumbs?.push({ title: 'Success', link: '/thanks' });
                        appendId(id, selected.breadcrumbs);
                        selected.reference = selected.reference?.replace("#id", id);
                    }
                });
            }
        });
        return selected;
    } catch (e) { 
        Logger.error("ThankYouPage.tsx", "error", e);
    }
}

/**
 * Method to append ID on breadcrumbs
 */
function appendId(id: any, breadcrumbs: any) {
    if(!breadcrumbs || breadcrumbs.length === 0) {
        return breadcrumbs;
    }
    breadcrumbs.forEach((item: any) => {
        item.link = item.link.replace("#id", id);
        item.title = item.title.replace("#id", id);
    });
    return breadcrumbs;
}

