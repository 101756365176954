import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { ReactComponent as TransactionIcon } from "../../../assets/svg/txn-capture-icon.svg";
import { useHistory } from 'react-router';

const httpClient = HttpClient.getClient();

Logger.debug("TransactionCaptureStatus.tsx", "To show the captured status of transaction")

interface ICaptureStatus {
    metadata?: any;
    onClose(): void;
}

/**
 * @author Vineetha
 * This component handles the transaction capture status
 */
export const TransactionCaptureStatus: React.FC<ICaptureStatus> = React.memo((props) => {

    const { transactionId , authCode, backUrl, isCollect} = props.metadata;
    const [isPolling, setIsPolling] = useState(true);
    const [attempts, setAttempts] = useState(0);
    const history = useHistory()
    const pollingAttempts = 25;

    /**
     * Redirects the user to the failed transaction screen.
     *
     * @param {boolean} isCollect - Determines which URL pattern to replace.
     *                              If true, it replaces 'onetime-card' with 'failedonetime-card'.
     *                              If false, it replaces 'send-onetime-card' with 'send-failedonetime-card'.
     * @param {string} backUrl - The base URL to modify based on the transaction type.
     * @param {string} transactionId - The transaction ID to append to the modified URL.
     * @param {string} authCode - An authorization code passed as state with the navigation.
     */
    const navigateToFailedTransaction = (isCollect: boolean, backUrl: string, transactionId: string, authCode: string) => {
        const url = isCollect 
            ? backUrl?.replace('onetime-card', 'failedonetime-card') 
            : backUrl?.replace('send-onetime-card', 'send-failedonetime-card');

        history.push(`${url}${transactionId}`, { authCode });
    };

    //Fetch transaction capture status
    const getTransactionCaptureStatus = async () => {
        try {
            const url = '/transaction/id/' + transactionId;
            const response: any = await httpClient.get(getCustomerUrl(url, false))
            if (response?.data?.status !== 'PROCESSING') {
                setIsPolling(false); // Stop polling when you get a response
                props.onClose();

                if(response?.data?.status !== 'FAILED'){
                    history.push(`${backUrl}${transactionId}`, { authCode});
                } else {
                    navigateToFailedTransaction(isCollect, backUrl, transactionId, authCode);
                }
            }
        } catch (err) {
            Logger.error("TransactionCaptureStatus.tsx", "error", err);
            return err;
        }
    }

    //For calling transaction capture api based on interval till api status is fetched
    useEffect(() => {
        const pollTransactionStatus = () => {
            getTransactionCaptureStatus();
            setAttempts(prev => prev + 1); // Increment attempts count
        };
    
        if (isPolling && attempts < pollingAttempts) {
            const intervalId = setInterval(pollTransactionStatus, 3000); // Call every 3 seconds
    
            return () => clearInterval(intervalId); // Cleanup on unmount or when isPolling changes
        } else if (attempts >= pollingAttempts) {
            setIsPolling(false); // Stop polling after polling attempts
            props.onClose();
            navigateToFailedTransaction(isCollect, backUrl, transactionId, authCode);
        }
    }, [isPolling, attempts, getTransactionCaptureStatus]);

    return (
        <Grid container direction="row" spacing={2} className="fx-modal-form flex column" paddingLeft={"1rem"} >
            <Grid item container justifyContent="center" xs={12}> <TransactionIcon /> </Grid>
            <Grid container justifyContent="center" item xs={12} className="fx-delete-title">
                <Typography id="debit-card-actions-popup-modal-title-text" variant="h3">
                    Transaction in Progress..
                </Typography>
            </Grid>
            <Grid container justifyContent="center" item xs={12} className='fx-transaction-modal-text'>
                <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                    Please wait while the customer completes their transaction by swiping, tapping, or inserting their card. 
                </Typography>
            </Grid>
        </Grid>
    );
});
