/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { EditMerchant } from './EditMerchant';


/**
 * This component loads the Edit Merchant component
 */
export const EditMerchantHome: React.FC =
    () => {
        const params = useParams<any>();
        const id = params.id;
        const merchant_source = {
            url: "/merchant/id/" + id,
            method: "GET"
        }
        const rand=Math.floor(Math.random()*100)+1;
        return (
            <Grid item container xs={12}>
                <Grid item xs={12} sm={12}>
                    <EditMerchant key="editmerchant" id={rand+"-edit-merchant-component-"+id} source={merchant_source}></EditMerchant>
                </Grid>
            </Grid>

        )
    }
