/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ISource } from '../../../types/common.interfaces';
import { clean, renderError, updateComponentParams } from '../../Utils/CommonBaseClass';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButton } from '../../Action/FxButton';
import { merchantSettlementAccountListDataTransformation } from '../Schedules/ScheduleGlobalFunctions';
import FxSnackBar from '../../Utils/fx-snack-bar';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import { FxAutoComplete } from '../../Input/FxAutoComplete/FxAutoComplete';
import FxRadioEdit from '../../Input/FxRadio/FxRadioEdit';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import { FxSkeltonList } from '../Cards/FxSkelton';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';

const httpClient = HttpClient.getClient();
type MethodType = 'ecom' | 'moto';

Logger.debug("EditMerchant.tsx", "Edit merchant initializing");

/**
 * Component: EditMerchant
 * Usage: Updating Merchant
 */
export const EditMerchant: React.FC<any> = React.memo((props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control, resetField, watch } = useForm();
        const params = useParams<any>();
        const merchantId = params.id;
        let merchant_location_source_url:any;

        useEffect(()=> {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Merchant (ID '+merchantId+')', backButton:'/merchant/view/'+merchantId} } });
            updateComponentParams(dispatch,  props.id, { processApi: false, showSubCategory: false, category: '', ecom: '', pos: '', moto: '', monthly: '', yearly: '', onetime: '', showInfo: false, showEcomPayments: false, showPosPayments: false, showMotoPayments: false, showMonthlySummary: false, showYearlySummary: false, showOnetimeSummary: false, showSpecificTypeField: false, initValues: false, showDischargeDate: false})

            return () => {
                dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });  
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const merchantInfo: any = context?.data?.body;
        if (context && context.data && context.data.body) {
            merchant_location_source_url = merchantInfo.location.url.split('/v1/')[1];
        }

        /**
         * Method to call API for Location Details
         * @param url API Url
         */
        const getLocationDetails = async (url:any) => {
            const res = await httpClient.get(url);
            if(res?.data){
                updateComponentParams(dispatch,props?.id,{locationData: res?.data})
            }
        }
        
        useEffect(()=>{
            if(merchant_location_source_url){
                getLocationDetails(merchant_location_source_url);
            }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },[merchant_location_source_url]);

        const acc_src: ISource = {
            url: "account/list",
            method: "POST",
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "sortOptions": {
                    "sortOrder": "DESC",
                    "sortBy": "createdOn"
                },
                "criteria": {
                    "filters": [
                        {
                            "key": "status",
                            "operator": "nin",
                            "values": [
                                "INACTIVE"
                            ]
                        }
                    ]
                }
            }
        };

        const [isLoading, setIsLoading] = useState(false);
        const submitButton = 'Save Changes'
        const [categories, setCategories] = useState<any>();

        const history = useHistory();
        const deliveryTimeData = [
                            { value: 'WEEK', label: 'Week' },
                            { value: 'FORTNIGHT', label: 'Fortnight' },
                            { value: 'MONTH', label: 'Month' },
                            { value: 'TWO_MONTHS', label: '2 Months' },
                            { value: 'OVER_TWO_MONTHS', label: 'Over 2 months' },
        ]

        /**
         * Data set for the ECOM Labelled Checkbox
         */
        const allowEcom = [
            {
                label: 'Website (ECOM)',
                value: 'yes',
                checked: props?.showEcomPayments ?? false,
            }
        ];
    
        /**
         * Data set for the POS Labelled Checkbox
         */
        const allowPos = [
            {
                label: 'At my store/shop(POS)',
                value: 'yes',
                checked: props?.showPosPayments ?? false,
            }
        ];
    
        /**
         * Data set for the MOTO Labelled Checkbox
         */
        const allowMoto = [
            {
                label: 'Mail order telephone order(MOTO)',
                value: 'yes',
                checked: props?.showMotoPayments ?? false,
            }
        ];
    
        /**
         * Data set for the MONTHLY Labelled Checkbox
         */
        const allowMonthly = [
            {
                label: 'Monthly',
                value: 'yes',
                checked: props?.showMonthlySummary ?? false
            }
        ];
    
        /**
         * Data set for the YEARLY Labelled Checkbox
         */
        const allowYearly = [
            {
                label: 'Yearly',
                value: 'yes',
                checked: props?.showYearlySummary ?? false
            }
        ];
    
        /**
         * Data set for the ONETIME Labelled Checkbox
         */
        const allowOnetime = [
            {
                label: 'One time',
                value: 'yes',
                checked: props?.showOnetimeSummary ?? false
            }
        ];

        /**
         * Populating the fetched values from the GET API to their respective nodes
         */
        if(merchantInfo && !props?.initValues){
            updateComponentParams(dispatch,  props.id, { initValues: true })

            // populating sales methods values
            if(merchantInfo.saleDetails?.saleMethod?.ecom){
                updateComponentParams(dispatch,  props.id, { ecom: merchantInfo.saleDetails.saleMethod.ecom, showEcomPayments: parseFloat(merchantInfo.saleDetails.saleMethod.ecom).toFixed(2) > '0.00' ? true : false })
                setValue('saleMethodEcom',merchantInfo.saleDetails.saleMethod.ecom)
            }
            if(merchantInfo.saleDetails?.saleMethod?.pos){
                updateComponentParams(dispatch,  props.id, { pos: merchantInfo.saleDetails.saleMethod.pos, showPosPayments: parseFloat(merchantInfo.saleDetails.saleMethod.pos).toFixed(2) > '0.00' ? true : false })
                setValue('saleMethodPos',merchantInfo.saleDetails.saleMethod.pos)
            }
            if(merchantInfo.saleDetails?.saleMethod?.moto){
                updateComponentParams(dispatch,  props.id, { moto: merchantInfo.saleDetails.saleMethod.moto, showMotoPayments: parseFloat(merchantInfo.saleDetails.saleMethod.moto).toFixed(2) > '0.00' ? true : false })
                setValue('saleMethodMoto',merchantInfo.saleDetails.saleMethod.moto)
            }

            //populating the categoryType
            if(merchantInfo.categoryType){
                updateComponentParams(dispatch,  props.id, { category: merchantInfo.categoryType })
            }

            //identifying whether to show the additional info or not
            if((parseFloat(merchantInfo.saleDetails?.saleMethod?.moto) + parseFloat(merchantInfo.saleDetails?.saleMethod?.ecom)) > parseFloat('20')){
                updateComponentParams(dispatch,  props.id, { showInfo: true })
            }

            //populating the monthly summary field
            if(merchantInfo.cardNotPresent?.billingMethod?.monthly){
                updateComponentParams(dispatch,  props.id, { monthly: merchantInfo.cardNotPresent?.billingMethod?.monthly, showMonthlySummary: parseFloat(merchantInfo.cardNotPresent?.billingMethod?.monthly).toFixed(2) > '0.00' ? true : false })
                setValue('monthly',merchantInfo.cardNotPresent?.billingMethod?.monthly)
            }
            //populating the yearly summary field
            if(merchantInfo.cardNotPresent?.billingMethod?.yearly){
                updateComponentParams(dispatch,  props.id, { yearly: merchantInfo.cardNotPresent?.billingMethod?.yearly, showYearlySummary: parseFloat(merchantInfo.cardNotPresent?.billingMethod?.yearly).toFixed(2) > '0.00' ? true : false })
                setValue('yearly',merchantInfo.cardNotPresent?.billingMethod?.yearly)
            }
            //populating the onetime summary field
            if(merchantInfo.cardNotPresent?.billingMethod?.onetime){
                updateComponentParams(dispatch,  props.id, { onetime: merchantInfo.cardNotPresent?.billingMethod?.onetime, showOnetimeSummary: parseFloat(merchantInfo.cardNotPresent?.billingMethod?.onetime).toFixed(2) > '0.00' ? true : false })
                setValue('onetime',merchantInfo.cardNotPresent?.billingMethod?.onetime)
            }
            //populating the advertising method field
            if(merchantInfo.cardNotPresent?.advertisingMethod){
                setValue('advertisingMethod',merchantInfo.cardNotPresent?.advertisingMethod)
            }
            //populating the internet business type field
            if(merchantInfo.cardNotPresent?.internetBusinessType){
                setValue('internetBusinessType',merchantInfo.cardNotPresent?.internetBusinessType)
                if(merchantInfo.cardNotPresent?.internetBusinessType === 'OTHER'){
                    updateComponentParams(dispatch,  props.id, { showSpecificTypeField: true })
                }
            }
            if(merchantInfo.cardNotPresent?.specificType){
                setValue('specificType',merchantInfo.cardNotPresent?.specificType)
            }
            if(merchantInfo?.priorBankruptcy){
                updateComponentParams(dispatch,  props.id, { showDischargeDate: true })

            }
            updateComponentParams(dispatch,  props.id, { processApi: true })

        }

        const displayLabelValidation = {
            required:  true ,
            maxLength: 40,
            minLength:3
        }

        /**
         * Validations for the field: Specific Type
         */
        const specificFieldValidation = {
            required:  props?.showSpecificTypeField ?? false,
        }

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            history.push('/merchant/view/'+context?.data?.body?.id)
        };

        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            let req: any = {
                "saleDetails": {
                "averageSalesVolumes": data['averageSalesVolumes'],
                "averagePurchase": data['averagePurchase'],
                "saleMethod": {},
                "productDescription":data['productDescription'],
                "averageDeliveryTime": data['averageDeliveryTime']
                },
            };

            if(data['priorBankruptcy']){
                req.priorBankruptcy = data['priorBankruptcy'] === 'yes' ? true : false;
            }

            //Adding the ecom/pos/moto nodes only if they are enabled
            req.saleDetails.saleMethod.ecom = data['saleMethodEcom'] && data['saleMethodEcom'] !== '' ? data['saleMethodEcom'] : '0.00';
            req.saleDetails.saleMethod.pos = data['saleMethodPos'] && data['saleMethodPos'] !== '' ? data['saleMethodPos'] : '0.00';
            req.saleDetails.saleMethod.moto = data['saleMethodMoto'] && data['saleMethodMoto'] !== '' ? data['saleMethodMoto'] : '0.00';


            if(data['location']){
                req={
                    ...req,
                    "location" : [
                        {
                            "id" : data['location']
                        }
                    ]
                }
            }

            //Adding the dateOfischarge node if priorBankruptcy is set TRUE
            if(data['priorBankruptcy'] === 'yes'){
                req.dateOfDischarge = data['dateOfDischarge']
            }

            //Adding the nodes for cardNotPresent Use Case
            if(props?.showInfo){
                const billingMethodNodes: any = {};
                billingMethodNodes.monthly = data['monthly'] && data['monthly'] != '' ? data['monthly'] : '0.00';
                billingMethodNodes.yearly = data['yearly'] && data['yearly'] !== '' ? data['yearly'] : '0.00';
                billingMethodNodes.onetime = data['onetime'] && data['onetime'] !== '' ? data['onetime'] : '0.00';

                const cardNotPresentObj: any = {
                    "internetBusinessType": data['internetBusinessType'],
                    "advertisingMethod": data['advertisingMethod'],
                    "billingMethod": billingMethodNodes,
                }
                if(props?.showSpecificTypeField){
                    cardNotPresentObj['specificType'] = data['specificType']
                }
                req={
                    ...req,
                    "cardNotPresent": clean(cardNotPresentObj)
                }
            }
            
            let status: any = await updateMerchant(req);
            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Merchant Updated Successfully!',
                });
                history.push('/merchant/view/'+context?.data?.body?.id);
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setIsLoading(false);

        }

        /**
         * Method to call api  for update merchant
         * @param paylaoddata : request payload
         */
        async function updateMerchant(paylaoddata: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('merchant/id/'+context?.data?.body?.id,false), paylaoddata).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("EditMerchant.tsx", "error", err);
                return err;
            }
        }

      // Method transforms the categories
      function transformCategories(data: any){
        try{
            setCategories(data);
            const newData: any = [];
            data?.forEach((item: any) => {
                let checkFlag = false;
                newData?.forEach((category: any)=> {
                    if(category['label'] === item.category){
                        checkFlag = true;
                    }
                })
                if(!checkFlag){
                    const obj = { label: item.category, value: item.category };
                    newData.push(obj);
                }
            });
            return newData;
        }
        catch(e){
            Logger.error("EditMerchant.tsx", "transformCategories", "error", e);
        }
      }

      // Method transforms the sub categories
      function transformSubCategories(data: any){
        try{
            const newData: any = [];
            data?.forEach((item: any) => {
                if(item.category === props?.category){
                    const obj = { label: item.code+' ('+item.description+')', value: item.code };
                    newData.push(obj);
                }
            });
            return newData;
        }
        catch(e){
            Logger.error("EditMerchant.tsx", "transformSubCategories", "error", e);
        }

      }
      // Method handles the change event of category
      const handleCategoryChange = (event: any) => {
        try{
            updateComponentParams(dispatch,  props.id, { category: event.target.value })
        }
        catch(e){
            Logger.error("EditMerchant.tsx", "handleCategoryChange", "error", e);
        }
      }

        // Method handles the sale methods total percentage condition
        const checkPercentageCondition = (method: any, value: any) => {
            try{
                let totalPercent:any = 0;
                if(method === 'ecom'){
                    const ecomValue:any = value?parseFloat(value).toFixed(2):0;
                    const posValue:any = props?.pos?parseFloat(props?.pos).toFixed(2):0;
                    const motoValue:any = props?.moto?parseFloat(props?.moto).toFixed(2):0;
                    totalPercent = ecomValue*1+posValue*1+motoValue*1;
                }
                else if(method === 'pos'){
                    const ecomValue:any = props?.ecom?parseFloat(props?.ecom).toFixed(2):0;
                    const posValue:any = value?parseFloat(value).toFixed(2):0;
                    const motoValue:any = props?.moto?parseFloat(props?.moto).toFixed(2):0;
                    totalPercent = ecomValue*1+posValue*1+motoValue*1;
                }
                else if(method === 'moto'){
                    const ecomValue:any = props?.ecom?parseFloat(props?.ecom).toFixed(2):0;
                    const posValue:any = props?.pos?parseFloat(props?.pos).toFixed(2):0;
                    const motoValue:any = value?parseFloat(value).toFixed(2):0;
                    totalPercent = ecomValue*1+posValue*1+motoValue*1;
                }
                if(totalPercent != 100.00){
                        setError("percentageError", {
                            type: "manual",
                            message: "The total percentage of sales methods should be 100%",
                        });
                    }
                    else{
                        clearErrors('percentageError')
                    }
            }
            catch(e){
                Logger.error("EditMerchant.tsx", "checkPercentageCondition", "error", e);
            }

        }
        // Method handles the change event for ecom field
        const handleEcomChange = (event: any) => {
            try{
                const value = event.target.value;
                setValue('saleMethodEcom',value)
                updateComponentParams(dispatch,  props.id, { ecom: value })
                checkPercentageCondition('ecom',value)
                checkEligibilityForAdditionalInfo('ecom', value);
                if(value){
                    clearErrors('saleMethodEcom')
                }
                else{
                    setError("saleMethodEcom", {
                        type: "manual",
                        message: "This field is required",
                    });
                }
            }
            catch(e){
                Logger.error("EditMerchant.tsx", "handleEcomChange", "error", e);
            }
        }
        // Method handles the change event for pos field
        const handlePosChange = (event: any) => {
            try{
                const value = event.target.value;
                setValue('saleMethodPos',value)
                updateComponentParams(dispatch,  props.id, { pos: value })
                checkPercentageCondition('pos',value)
                if(value){
                    clearErrors('saleMethodPos')
                }
                else{
                    setError("saleMethodPos", {
                        type: "manual",
                        message: "This field is required",
                    });
                }
            }
            catch(e){
                Logger.error("EditMerchant.tsx", "handlePosChange", "error", e);
            }
        }
        // Method handles the change event for moto field
        const handleMotoChange = (event: any) => {
            try{
                const value = event.target.value;
                setValue('saleMethodMoto',value)
                updateComponentParams(dispatch,  props.id, { moto: value })
                checkPercentageCondition('moto',value)
                checkEligibilityForAdditionalInfo('moto', value);
                if(value){
                    clearErrors('saleMethodMoto')
                }
                else{
                    setError("saleMethodMoto", {
                        type: "manual",
                        message: "This field is required",
                    });
                }
            }
            catch(e){
                Logger.error("EditMerchant.tsx", "handleMotoChange", "error", e);
            }
        }
        // declaring the source variable for merhant setting
         const merchant_setting_source: ISource = {
            url: "merchant/setting",
            baseUrl:true,
            method: "GET",
            data: {
                "pageNumber": 1,
                "pageSize": 25,
                "criteria": {
                    "filters": []
                }
            }
        }

        const location_src = {
            url: "location/list",
            method: "POST",
            data: {
              pageNumber: 1,
              pageSize: 5,
              criteria: {
                filters: [
                  {
                    key: "status",
                    operator: "eq",
                    values: ["ACTIVE"]
                  },
                ],
              },
            },
        }

        const locationFilterOptions: any = [
        { label: "Doing Business As", value: "doingBusinessAs", operator: "like" },
        ];

        /**
         * Function handles transformation of destination data
         * @param data : destination data array
         * @returns : array
         */
        function destinationTransformer(data: any) {
            return data?.map((item: any) => {
            const addressLine2 = item.address.addressLine2 ? item.address.addressLine2 + ', ' : '';
            return {
                label: item.doingBusinessAs + " (" + item.address.addressLine1 + ', ' + addressLine2 + item.address.city + ', ' + item.address.state + ', ' + item.address.zip + ")",
                value: item.id,
            }
            }) || [];
        }

        const locationFilter = "doingBusinessAs";

        /**
         * Method to handle form change events when the Payment Method Checkboxes & Billing Summary Checkboxes are checked/unchecked
         * @param event :event object
        */
        const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            const { name, checked } = event.target;
            if(name === 'allowEcomMethod'){
                updateComponentParams(dispatch,  props.id, { showEcomPayments: !props?.showEcomPayments })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { ecom: null })
                    setValue('saleMethodEcom', null);
                    checkEligibilityForAdditionalInfo('ecom', '');
                    checkPercentageCondition('ecom',null);
                }
            }
            if(name === 'allowPosMethod'){
                updateComponentParams(dispatch,  props.id, { showPosPayments: !props?.showPosPayments })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { pos: null })
                    setValue('saleMethodPos', null);
                    checkPercentageCondition('pos',null)
                }
            }
            if(name === 'allowMotoMethod'){
                updateComponentParams(dispatch,  props.id, { showMotoPayments: !props?.showMotoPayments })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { moto: null })
                    setValue('saleMethodMoto', null);
                    checkEligibilityForAdditionalInfo('moto', '');
                    checkPercentageCondition('moto',null)
                }
            }
            if(name === 'allowMonthlySummary'){
                updateComponentParams(dispatch,  props.id, { showMonthlySummary: !props?.showMonthlySummary })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { monthly: null })
                    setValue('monthly', null);
                    checkBillingSummaryPercentage('monthly',null)
                }
            }
            if(name === 'allowYearlySummary'){
                updateComponentParams(dispatch,  props.id, { showYearlySummary: !props?.showYearlySummary })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { yearly: null })
                    setValue('yearly', null);
                    checkBillingSummaryPercentage('yearly',null)
                }
            }
            if(name === 'allowOnetimeSummary'){
                updateComponentParams(dispatch,  props.id, { showOnetimeSummary: !props?.showOnetimeSummary })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { onetime: !props?.onetime })
                    setValue('onetime', null);
                    checkBillingSummaryPercentage('onetime',null)
                }
            }
        }

    /**
     * Dropdown data for the field: Internet Business Type
     */
    const internetBusinessTypeDropdownData = [
        { value: 'ADVERTISEMENT', label: 'Advertisement' },
        { value: 'AUCTION', label: 'Auction' },
        { value: 'DOMAIN_REGISTRATION', label: 'Domain Registration' },
        { value: 'INTERNET_SERVICE_GATEWAY', label: 'Internet Service Gateway' },
        { value: 'SELLING_DIGITAL_GOODS', label: 'Selling Digital Goods' },
        { value: 'SELLING_HARD_GOODS', label: 'Selling Hard Goods' },
        { value: 'SERVICE', label: 'Service' },
        { value: 'WEB_HOSTING', label: 'Web Hosting' },
        { value: 'WEB_PAGE_DESIGN', label: 'Web Page Design' },
        { value: 'OTHER', label: 'Other' },
    ]

    /**
     * Dropdown data for the field: Advertising Method
     */
    const advertisingMethodDropdownData = [
        { value: 'Online', label: 'Online' },
        { value: 'Magazine', label: 'Magazine' },
        { value: 'Flyers/Brochure', label: 'Flyers/Brochure' },
        { value: 'Social Media', label: 'Social Media' },
        { value: 'Not Applicable', label: 'Not Applicable' },
    ];

    /**
     * This method handles the post entering operations of the below mentioned fields
     * @param type 
     * @param event 
     */
    const handleShareChange = (type: string, event: any) => {
        try {
            const value = event.target.value;
            setValue(type, value);

            switch(type) {
                case 'monthly':
                    updateComponentParams(dispatch,  props.id, { monthly: value})
                    break;
                case 'yearly':
                    updateComponentParams(dispatch,  props.id, { yearly: value})
                    break;
                case 'onetime':
                    updateComponentParams(dispatch,  props.id, { onetime: value})
                    break;
                default:
                    break;
            }
    
            checkBillingSummaryPercentage(type, value);
            if (value) {
                clearErrors(type);
            }
        } catch (e) {
            Logger.error("AddMerchant.tsx", `handle${type.charAt(0).toUpperCase() + type.slice(1)}ShareChange`, "error", e);
        }
    };
    
    /**
     * Method handles the change event for Monthly field
     * @param event 
     */
    const handleMonthlyShareChange = (event: any) => handleShareChange('monthly', event);

    /**
     * Method handles the change event for Yearly field
     * @param event 
     */
    const handleYearlyShareChange = (event: any) => handleShareChange('yearly', event);

    /**
     * Method handles the change event for Onetime field
     * @param event 
     */
    const handleOnetimeShareChange = (event: any) => handleShareChange('onetime', event);

    /**
     * This method parses the values of the billing summary fields
     * @param val 
     * @returns 
     */
    const parseValue: any = (val: any) => {
        const num: any = parseFloat(val).toFixed(2);
        return val ? ( num * 1) : 0;
    }

    /**
     * Method handles the sale methods total percentage condition
     * @param method 
     * @param value 
     */
    const checkBillingSummaryPercentage = (method: string, value: any) => {
        const monthlyValue: any = parseValue(method === 'monthly' ? value : props?.monthly);
        const yearlyValue: any = parseValue(method === 'yearly' ? value : props?.yearly);
        const onetimeValue: any = parseValue(method === 'onetime' ? value : props?.onetime);
        const totalPercent = monthlyValue + yearlyValue + onetimeValue;
        if (totalPercent !== 100.00) {
            setError("billingSummaryError", {
                type: "manual",
                message: "Total sum of Monthly, Yearly and One Time transaction should be 100%",
            });
        } else {
            clearErrors('billingSummaryError');
        }
    };

    /**
     * Static Data for the Radio Button Field: Prior Bankruptcy
     */
    const priorBankruptcyRadioOptions = [
        { label: "Yes", value: "yes"},
        { label: "No", value: "no"}
      ];

    /**
     * This method handles the change of the state of the Radio Button: Prior Bankruptcy
     * @param event 
     */
      const handlePriorBankruptcyRadioChange = (event: any) => {
        setValue('priorBankruptcy',event.target.value)
        updateComponentParams(dispatch,  props.id, { showDischargeDate: (event.target.value === 'yes' ? true : false) })
      }
      
    /**
     * This method hanldes the change of the dropdown field: Internet Business Type
     * and sets showSpecificTypeField as true when 'OTHER' is selected
     * @param e 
     */
      const businessTypeChangeHandler = (e: any) => {
        console.log(e);
        updateComponentParams(dispatch,  props.id, { showSpecificTypeField: (e?.target.value === 'OTHER' ? true : false) })
    }

    /**
     * This section handles the rendering of the Card Not Present Fields
     * if the sum of MOTO & ECOM is greater than 20%
     * @returns 
     */
      const cardNotPresentFields = () => {
        return (
            <>
                <Grid item xs={12} sm={12}>
                    <Typography className="filter-popover-label">CARD NOT PRESENT QUESTIONNAIRE</Typography>
                </Grid>
                <Grid item xs={12} sm={props?.showSpecificTypeField ? 4 : 6}>
                <FxMaterialSelect register={{ ...register("internetBusinessType") }} id="add-merchant-form-internet-business-type" name="internetBusinessType"
                        control={control} rules={{ required: true }}  
                        placeholder="Select Internet Business Type"
                        label="Internet Business Type*" setValue={setValue} data={internetBusinessTypeDropdownData} onChange={(e: any) => businessTypeChangeHandler(e)}/>
                </Grid>
                {props?.showSpecificTypeField && <Grid item xs={12} sm={props?.showSpecificTypeField ? 4 : 6}>
                    <FxTextEdit register={{ ...register("specificType") }} className={errors.specificType ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                    rules={specificFieldValidation} control={control} id="add-merchant-form-specific-type-field" label="Specific Type*" placeholder="Input Text"
                    name="specificType" variant="outlined" isEditable={true} />
                </Grid>}
                <Grid item xs={12} sm={props?.showSpecificTypeField ? 4 : 6}>
                <FxMaterialSelect register={{ ...register("advertisingMethod") }} id="add-merchant-form-advertising-method" name="advertisingMethod"
                        control={control} rules={{ required: true }} placeholder='Select Advertising Method'
                        readOnly={false} label="Advertising Method*" setValue={setValue} data={advertisingMethodDropdownData} />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography className="filter-popover-label" >BILLING SUMMARY*</Typography>
                    <Typography variant='subtitle1' className='card-title-note-text'>Total sum of Monthly, Yearly and One Time transaction should be 100%</Typography>
                </Grid>
                <Grid item xs={12} sm={12} className="fx-sales-method-grid">
                {/** Monthly */}
                <Grid item xs={12} sm={12} display={'flex'}>
                    <Grid item xs={4} sm={4}>
                        <FxCheckEdit register={{ ...register('allowMonthlySummary') }}
                            rules={{ required: false }} control={control}
                            id="debit-terms-condition" name="allowMonthlySummary"
                            className="fx-privacy-text" data={allowMonthly} row="vertical"
                            onChange={(e: any) => {
                                handleChange(e);
                            }} setValue={setValue} />
                    </Grid>
                    {props?.showMonthlySummary && <Grid item xs={4} sm={4}>
                    <FxTextEdit register={{ ...register("monthly") }} className={errors.monthly ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                rules={{required: false}} control={control} id="add-merchant-form-card-billing-summary-monthly-textbox" label="Monthly"
                                placeholder="Monthly" name="monthly" variant="outlined" isEditable={true} suffix="%" showDecimal={false} setValue={setValue} onChange={handleMonthlyShareChange} value={props?.monthly} />
                    </Grid>}
                </Grid>
                {/** Yearly */}
                <Grid item xs={12} sm={12} display={'flex'}>
                    <Grid item xs={4} sm={4}>
                        <FxCheckEdit register={{ ...register('allowYearlySummary') }}
                            rules={{ required: false }} control={control}
                            id="debit-terms-condition" name="allowYearlySummary"
                            className="fx-privacy-text" data={allowYearly} row="vertical"
                            onChange={(e: any) => {
                                handleChange(e);
                            }} setValue={setValue} />
                    </Grid>
                    {props?.showYearlySummary && <Grid item xs={4} sm={4}>
                    <FxTextEdit register={{ ...register("yearly") }} className={errors.yearly ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                rules={{required: false}} control={control} id="add-merchant-form-card-billing-summary-monthly-textbox" label="Yearly"
                                placeholder="Yearly" name="yearly" variant="outlined" isEditable={true} suffix="%" showDecimal={false} setValue={setValue} onChange={handleYearlyShareChange} value={props?.yearly}/>
                    </Grid>}
                </Grid>
                {/** Onetime */}
                <Grid item xs={12} sm={12} display={'flex'}>
                    <Grid item xs={4} sm={4}>
                        <FxCheckEdit register={{ ...register('allowOnetimeSummary') }}
                            rules={{ required: false }} control={control}
                            id="debit-terms-condition" name="allowOnetimeSummary"
                            className="fx-privacy-text" data={allowOnetime} row="vertical"
                            onChange={(e: any) => {
                                handleChange(e);
                            }} setValue={setValue} />
                    </Grid>
                    {props?.showOnetimeSummary && <Grid item xs={4} sm={4}>
                    <FxTextEdit register={{ ...register("onetime") }} className={errors.onetime ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                rules={{required: false}} control={control} id="add-merchant-form-card-billing-summary-monthly-textbox" label="Onetime"
                                placeholder="Onetime" name="onetime" variant="outlined" isEditable={true} suffix="%" showDecimal={false} setValue={setValue} onChange={handleOnetimeShareChange} value={props?.onetime}/>
                    </Grid>}
                </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div className={'error-message'}>
                        {errors.billingSummaryError && renderError(errors.billingSummaryError)}
                    </div>
                </Grid>
            </>
        )
    }

    /**
       * This method checks that if the value of the field ECOM and MOTO is greater than 20
       * then make the additional info fields like 'Card not present Questionnare' & 'Billing Summary' visible.
       * Else hide them
       * @param method 
       * @param value 
       */
      const checkEligibilityForAdditionalInfo = (method: MethodType, value: string | number) => {
        const parseValue = (val: any) => val ? parseFloat(val).toFixed(2) : '0.00';
        const ecomValue = parseFloat(parseValue(method === 'ecom' ? value : props?.ecom));
        const motoValue = parseFloat(parseValue(method === 'moto' ? value : props?.moto));
        const totalPercent = ecomValue + motoValue;
        updateComponentParams(dispatch,  props.id, { showInfo: totalPercent > 20 })
      };

        return (
            <Grid container id="edit-merchant-main-grid" xs={12} >
                <Grid id="edit-merchant-first-grid" item xs={12} sm={8}>
                    <Grid id="edit-merchant-sub-grid" container spacing={1} >
                        <Grid id="edit-merchant-second-grid" item xs={12}>
                            <div className="fx-form-edit-profile flex column">
                                {!props?.processApi && <FxSkeltonList height="40rem" />}
                                {props?.processApi && <form id="edit-merchant-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="edit-merchant-form-card" className="fx-theme-passport">
                                        <FxCardHeader id="edit-merchant-form-card-header">
                                        </FxCardHeader>
                                        <FxCardBody id="edit-merchant-form-card-body" className="fx-info-card fx-margin-top-reducer fx-merchant-create-form-container" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                    <Grid item xs={12} sm={12}>
                                                    <Typography  className="filter-popover-label" >Please share below details to enable merchant processing services for card payments</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                           <FxMaterialSelect register={{ ...register("industry") }} rules={{ required: true }} className={errors.industry ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-merchant-form-industry" name="industry" label="Industry*" placeholder="Please select your industry" setValue={setValue} onChange={handleCategoryChange} readOnly={true} value={merchantInfo?.categoryType} source={merchant_setting_source} dataTransformation={transformCategories}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                           <FxMaterialSelect register={{ ...register("mcCode") }} rules={{ required: true }} className={errors.mcCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-merchant-form-mc-code" name="mcCode" data={transformSubCategories(categories)} label="Merchant Category Code*"  placeholder="Please select" setValue={setValue} readOnly={true} value={merchantInfo?.categoryCode}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("productDescription") }} className={errors.productDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={displayLabelValidation} control={control} id="edit-merchant-form-product-description" label="Product Description*" placeholder="Product Description" name="productDescription" variant="outlined" isEditable={true} defaultValue={merchantInfo?.saleDetails?.productDescription}/>
                                                    </Grid>
                                                    {(props?.locationData?.length === 0) && <Grid item xs={12} sm={12} marginTop={"-1.5rem"}>
                                                        <FxLabel className="fx-label-top" id="location-label">Location</FxLabel>
                                                        <FxAutoComplete
                                                            register={{ ...register("location") }}
                                                            control={control}
                                                            rules={{ required: false }}
                                                            id="edit-merchant-form-location"
                                                            name="location"
                                                            source={location_src}                        
                                                            searchBy={locationFilter}
                                                            className="fx-input-edit"
                                                            dataTransformer={destinationTransformer}
                                                            setError={setError}
                                                            clearError={clearErrors}
                                                            setValue={setValue}
                                                            resetField={resetField}
                                                            filterOptions={locationFilterOptions}
                                                            placeholder={"Search Location"}
                                                            isEditable={true}
                                                            value={''}
                                                        />
                                                    </Grid>}

                                                    {/** Prior Bankruptcy */}
                                                    <Grid item xs={12} sm={12} >
                                                        <Typography>Prior Bankruptcy</Typography>
                                                        <FxRadioEdit register={{ ...register("priorBankruptcy") }} control={control} 
                                                        className={'radio-edit'} data={priorBankruptcyRadioOptions} id="add-merchant-form-card-prior-bankruptcy-radio-button" 
                                                        name="priorBankruptcy" defaultValue={merchantInfo?.priorBankruptcy ? 'yes' : 'no'} setValue={props?.setValue} 
                                                        onChange={handlePriorBankruptcyRadioChange} />
                                                    </Grid>
                                                    {props?.showDischargeDate && <Grid item xs={12} sm={6} >
                                                        <FxDateEdit register={{ ...register("dateOfDischarge") }} className={errors.dateOfDischarge ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="dateOfDischarge" label="Date of Discharge" name="dateOfDischarge" setValue={setValue} variant="outlined" disableFuture={true} value={watch('dateOfDischarge')} defaultValue={merchantInfo?.dateOfDischarge ?? ''} />
                                                    </Grid>}

                                                    {(props?.locationData?.length > 0) && <Grid item xs={12} sm={12}>
                                                        <FxLabel className="fx-label-top" id="location-label">Location</FxLabel>
                                                        <FxAutoComplete
                                                            register={{ ...register("location") }}
                                                            control={control}
                                                            rules={{ required: false }}
                                                            id="edit-merchant-form-location"
                                                            name="location"
                                                            source={location_src}                        
                                                            searchBy={locationFilter}
                                                            className="fx-input-edit"
                                                            dataTransformer={destinationTransformer}
                                                            setError={setError}
                                                            clearError={clearErrors}
                                                            setValue={setValue}
                                                            resetField={resetField}
                                                            filterOptions={locationFilterOptions}
                                                            placeholder={"Search Location"}
                                                            isEditable={true}
                                                            value={{
                                                                label: props?.locationData[0]?.doingBusinessAs + " (" + props?.locationData[0]?.address?.addressLine1 + ', ' + (props?.locationData[0]?.address?.addressLine2 ? props?.locationData[0]?.address?.addressLine2 + ', ' : '') + props?.locationData[0]?.address?.city + ', ' + props?.locationData[0]?.address?.state + ', ' + props?.locationData[0]?.address?.zip + ")",
                                                                value: props?.locationData[0]?.id,
                                                            }}
                                                        />
                                                    </Grid>}
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography  className="filter-popover-label" >ABOUT YOUR SALES</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("averageSalesVolumes") }} className={errors.averageSalesVolumes ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                    rules={{ required: true }} control={control} id="edit-merchant-form-card-average-sales-volumes-textbox" label="Average Monthly Sales Volume*"
                                                                    placeholder="Average Monthly Sales Volume" name="averageSalesVolumes" variant="outlined"  isEditable={true} prefix="$" showDecimal={true} setValue={setValue} defaultValue={merchantInfo?.saleDetails?.averageSalesVolumes}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("averagePurchase") }} className={errors.averagePurchase ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                    rules={{ required: true }} control={control} id="edit-merchant-form-card-average-purchase-textbox" label="Average Purchase*"
                                                                    placeholder="Average Purchase" name="averagePurchase" variant="outlined"  isEditable={true} prefix="$" showDecimal={true} setValue={setValue} defaultValue={merchantInfo?.saleDetails?.averagePurchase}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxMaterialSelect register={{ ...register("averageDeliveryTime") }} rules={{ required: true }} className={errors.averageDeliveryTime ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-merchant-form-average-delivery-time" name="averageDeliveryTime" data={deliveryTimeData} label="Average Delivery Time*" value={merchantInfo?.saleDetails?.averageDeliveryTime?merchantInfo?.saleDetails?.averageDeliveryTime:"WEEK"} readOnly={false} placeholder="Select delivery time" setValue={setValue} />
                                                    </Grid>
                                                    {/** ECOM/POS/MOTO Fields */}
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography  className="filter-popover-label" >HOW DO YOU ACCEPT PAYMENTS?*</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} className="fx-sales-method-grid">
                                                    {/** ECOM */}
                                                    <Grid item xs={12} sm={12} display={'flex'}>
                                                        <Grid item xs={4} sm={4}>
                                                            <FxCheckEdit register={{ ...register('allowEcomMethod') }}
                                                                rules={{ required: false }} control={control}
                                                                id="debit-terms-condition" name="allowEcomMethod"
                                                                className="fx-privacy-text" data={allowEcom} row="vertical"
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                }} setValue={setValue} />
                                                        </Grid>
                                                        {props?.showEcomPayments && <Grid item xs={4} sm={4}>
                                                            <FxTextEdit register={{ ...register("saleMethodEcom") }} className={errors.saleMethodEcom ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                rules={{required: false}} control={control} id="add-merchant-form-card-sale-method-ecom-textbox" placeholder='Website(ECOM)'
                                                                name="saleMethodEcom" variant="outlined" isEditable={true} suffix="%" showDecimal={true} setValue={setValue} onChange={handleEcomChange} value={props?.ecom} />
                                                        </Grid>}
                                                    </Grid>
                                                    {/** POS */}
                                                    <Grid item xs={12} sm={12} display={'flex'}>
                                                        <Grid item xs={4} sm={4}>
                                                            <FxCheckEdit register={{ ...register('allowPosMethod') }}
                                                                rules={{ required: false }} control={control}
                                                                id="debit-terms-condition" name="allowPosMethod"
                                                                className="fx-privacy-text" data={allowPos} row="vertical"
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                }} setValue={setValue} />
                                                        </Grid>
                                                        {props?.showPosPayments && <Grid item xs={4} sm={4}>
                                                            <FxTextEdit register={{ ...register("saleMethodPos") }} className={errors.saleMethodPos ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                        rules={{required: false}} control={control} id="add-merchant-form-card-sale-method-pos-textbox" placeholder='At my store/shop(POS)'
                                                                        name="saleMethodPos" variant="outlined" isEditable={true} suffix="%" showDecimal={true} setValue={setValue} onChange={handlePosChange} value={props?.pos}/>
                                                        </Grid>}
                                                    </Grid>
                                                    {/** MOTO */}
                                                    <Grid item xs={12} sm={12} display={'flex'}>
                                                        <Grid item xs={4} sm={4}>
                                                            <FxCheckEdit register={{ ...register('allowMotoMethod') }}
                                                                rules={{ required: false }} control={control}
                                                                id="debit-terms-condition" name="allowMotoMethod"
                                                                className="fx-privacy-text" data={allowMoto} row="vertical"
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                }} setValue={setValue} />
                                                        </Grid>
                                                        {props?.showMotoPayments && <Grid item xs={4} sm={4}>
                                                            <FxTextEdit register={{ ...register("saleMethodMoto") }} className={errors.saleMethodMoto ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                    rules={{required: false}} control={control} id="add-merchant-form-card-sale-method-moto-textbox" placeholder='Mail order telephone order(MOTO)'
                                                                    name="saleMethodMoto" variant="outlined" isEditable={true} suffix="%" showDecimal={true} setValue={setValue} onChange={handleMotoChange} value={props?.moto}/>
                                                        </Grid>}
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                         <div className={'error-message'}>
                                                            {errors.percentageError && renderError(errors.percentageError)}
                                                        </div>
                                                    </Grid>

                                                    {props?.showInfo && cardNotPresentFields()}
                                                    
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography  className="filter-popover-label">PROVIDE YOUR CARD SETTLEMENT ACCOUNT</Typography>
                                                    </Grid>
                                                     <Grid item xs={12} sm={12} >
                                                        <FxMaterialSelect register={{ ...register("source") }} id="edit-merchant-form-source" name="source"
                                                            source={acc_src} control={control} rules={{ required: true }}
                                                            dataTransformation={merchantSettlementAccountListDataTransformation}
                                                            value={merchantInfo?.merchantAccount?.id} readOnly={true} label="Select Account*" setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} ></Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="edit-merchant-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="edit-merchant-form-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"edit-merchant-form-card-submit-button"}
                                                    type="submit"
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>

                                </form>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });