/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid, Typography } from '@mui/material';
import { checkPermission } from '../../../Utils/CommonBaseClass';
import { FxAccessDenied } from './FxAccessDenied';
import { FxLink } from '../../../Action/FxLink';


/**
 * This component handles the display of chart card
 */
interface IButton {
  text: string;
  value: any;

}
interface IFxChartCard {
  permission: any;
  title: string;
  onButtonClick: any;
  buttons: IButton[];
  chartVariation:string;
}
export const FxChartCard: React.FC<IFxChartCard> = React.memo((props) => {

  /**
  * function to get buttons
  * @returns :button
  */
  function getButtons() {
    const permission = props.permission;
    return props.buttons.map((item: any, index: any) => {
      return <FxLink id={'date-' + index} permissions={permission} className={props.chartVariation === item.text ? 'fx-link fx-link-sel' : 'fx-link'} onClick={() => { props.onButtonClick(item) }} >{item.text}</FxLink>
    })
  }

  return (
    <>
      <Grid className="fx-account-summary-card fx-theme-passport fx-accountinfo">
        <Grid item container justifyContent='space-between' alignItems='center' className='fx-account-summary-card-accountbalance'>
          <Grid item>
            <Typography variant='h5'>{props.title}</Typography>
          </Grid>
          <Grid className='fx-account-insight-graph-link' item>
            {getButtons()}
          </Grid>
        </Grid>
        {<Grid >
          {checkPermission(props.permission) ? <Grid item xs={12}>
            {props.children}
          </Grid> : <FxAccessDenied id={'passport-insight-chart-access-denied'} title={'You are not authorized to access this card'} />}
        </Grid >}
      </Grid>
    </>
  )
})