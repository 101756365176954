/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Component:Lead Detail Card
 * This component handles the  Leads View component
 */
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { LeadDetailsView } from './LeadDetailsView';
import { useParams } from 'react-router';
import { fetchDataFromState } from '../../../Utils/CommonBaseClass';

type leadType = {
    id: string;
}
export const LeadDetailsCard: React.FC<leadType> = React.memo((props) => {

    const dispatch = useDispatch();
    const params = useParams<any>(); 
    const inviteStatus = useSelector((state: any) =>
        fetchDataFromState(state, props?.id)
    );

    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'lead-details-view-page' } });
    }, [dispatch])
    
    const src = {
        url :`/lead/id/${params?.id}`,
        method: "GET"
    }

    return (
        <Grid container xs={12}>
            <LeadDetailsView id="lead-details-view-page" source={src} invitStatus={inviteStatus}/>
        </Grid>
    )
})
