/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { CreateInternationalExternalAccount } from './CreateInternationalExternalAccount';
/**
 * Component: EditInternationalExternalAccount
 * Usage: edit external account by passing the data to CreateInternationalExternalAccount
 */
export const EditInternationalExternalAccount: React.FC = () => {
        const params = useParams<any>();
        const id = params.id
        // API for get external account details
        const external_account_source = {
            url: "/internationalExternalAccount/id/" + id,
            method: "GET"
        }
        const rand=Math.floor(Math.random()*100)+1;
        return <CreateInternationalExternalAccount id={rand+'edit-international-external-account-' + id} source={external_account_source}></CreateInternationalExternalAccount>

    }
