/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable no-empty */
import React, { useEffect, useMemo } from "react"; // we need this to make JSX compile
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Logger } from "../../../../libs/utils/logger";
import FxCard from "../../../Container/FxCard";
import FxCardBody from "../../../Container/FxCardBody";
import FxCardFooter from "../../../Container/FxCardFooter";
import { FxTextEdit } from "../../../Input/FxText/FxTextEdit";
import { FxAutoComplete } from "../../../Input/FxAutoComplete/FxAutoComplete";
import { createCommonVerifyProps, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { getEAOrAddressDetailsFromContact, isMethodSupported, checkPermission, toTitleCase } from "../../../Utils/CommonBaseClass";
import { processAPIResponse, pushGTM } from "../../../../libs/utils/utils";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { getKey } from "../../../../libs/utils/storageManager";
import { FxButton } from "../../../Action/FxButton";
import FxModalGeneric from '../../../Utils/FxModalGeneric';
import { AuthenticationVerificationModal } from '../../AuthenticationModal/AuthenticationVerificationModal';
import { accountDataTransformation, createSendMyAccount, getEAList, setAmountValidationOptions } from "../../Schedules/ScheduleGlobalFunctions";
import FxLabelView from "../../../Input/FxLabel/FxLabelView";
import { FxAccessDenied } from "./FxAccessDenied";
import FxSnackBar from "../../../Utils/fx-snack-bar";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { ReactComponent as ArrowPermsDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as ArrowPermsUp } from '../../../../assets/svg/triangle-up-icon.svg';
import { useLocation } from "react-router";

Logger.debug(
  "QuickTransfer.tsx",
  "QuickTransfer initializing"
);
/**
 * This component handles the Quick Transfer
 */
export const QuickTransfer: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const dispatch = useDispatch();

  const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, resetField, control, clearErrors, setError, reset } = useForm();

  const { isDestination, sourceAccount, clearButton, isPpiContact, selectedPPI, methodTypeData, accordionOpen, contacEA, selectedMethod } = props;
  const location = useLocation();

  const methodsArray = [
    { label: 'ACH', value: 'ACH' },
    { label: 'WIRE', value: 'WIRE' },
    { label: 'BOOK', value: 'BOOK' }
  ]

  const destinationFilter = "name";

  const src = {
    url: "contact/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 5,
      criteria: {
        filters: [
          {
            key: "allowedMethod",
            operator: "in",
            values: ["ACH", "WIRE", "BOOK"],
          },
        ],
      },
    },
  }

  /**
  * to get accountList
  */
  const accountList = useSelector((state: any) => {
    if (state.data['customer-basic-info']) {
      return state.data['customer-basic-info']?.params?.accountList
    }
  });

  /**
   * Method to get account options
   */
  const accountOptions=useMemo(()=>{
    return accountDataTransformation(accountList?.resources)
  },[accountList])

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    updateComponentParams(dispatch, props.id, { accordionOpen: true });
    setValue("type", "REGULAR");
    return () => {
      dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: "quick-transfer-send-book-form-card-destination" } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */

  async function onSubmit(data: any) {
    let req: any = {};
    if (!data.destination) {
      setError("destination", {
        type: "manual",
        message: "Destination is required",
      });
      return;
    }
    if (!data.destination) {
      setError("destination", {
        type: "manual",
        message: "Destination is required",
      });
      return;
    }
    req = {
      source: {
        account: {
          id: data?.sourceAccount || getKey("selectedAccount")?.id,
        },
      },
      method: data?.method,
      type: "REGULAR",
      amount: data.amount,
      purpose: data.purpose,
    };
    if (data.destination) {
      if (data.method === "BOOK") {
        req["destination"] = {
          contact: {
            id: data.destination
          },
        };
      } else {
        req["destination"] = {
          contact: {
            id: data.destination,
            externalAccount: {
              id: data.externalAccount
            }
          },
        };
      }

    }
    let status: any;
    status = await createSendMyAccount(req);
    status = processAPIResponse(status);
    if (status.status) {
      const postSuccessActions = () => {
        /**
         * Configuring quickSendTrigger GTM event after creating a quick send
         */
        const gtmData: any = {
          event: "quickSendTrigger",
          transactionMethod: toTitleCase(data.method),
          transactionType: "Contact"
        }
        pushGTM(gtmData)
        handleClear();
        updateComponentParams(dispatch, props?.id, { 'isDestination': true, 'sourceAccount': false, 'clearButton':false });
      }
      const successMessage = 'Send Created Successfully!';
      if (status.responseData?.sessionId) {
        const verifyMessage = status.responseData?.email ? `email ${status.responseData?.email}` : status.responseData?.phone ? `phone ${status.responseData?.phone}` : '';
        const verifyProps = {
          ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'send'),
          successMessage: successMessage,
          postSuccessEvent: postSuccessActions,
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
      } else {
        FxSnackBar.show({
          autoHideDuration: 3000,
          severity: 'success',
          text: successMessage
        });
        postSuccessActions();
      }
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }

  /**
   * Method handles clearing the data of the form
   */
  const handleClear = () => {
    try {
      dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
      updateComponentParams(dispatch, props?.id, { 'isDestination': false, 'accordionOpen': true, 'sourceAccount': false });
      reset({
        amount: '',
        destination: undefined,
        externalAccount: undefined,
        method: '',
        passportPaymentId: '',
        purpose: ''
      });
      setValue('method', '');
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'quick-transfer-send-book-form-card-destination' } });
    }
    catch (e) {
      Logger.error("QuickTransfer.tsx", "error", e);
     }
  }

  useEffect(() => {
    if(!clearButton){
      updateComponentParams(dispatch, props?.id, { 'isDestination': true, 'sourceAccount':true });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clearButton])

  const destinationFilterOptions: any = [
    { label: "Contact Name", value: "name", operator: "like" },
  ];

  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  function destinationTransformer(data: any) {
    return data?.map((item: any) => {
      return {
        label: item.name + " (ID: " + item.id + ")",
        value: item.id,
      }
    }) || [];
  }
  /**
   * method to handle change autocomplete
   * @param event 
   */
  const handleChange = (event: any) => {
    if (event && event?.value) {
      setExternalAccount(event?.value);
      updateComponentParams(dispatch, props?.id, { 'isDestination': true, 'clearButton': true });
    } else {
      setValue('method', '');
      updateComponentParams(dispatch, props?.id, { 'isDestination': true, 'clearButton': false, 'isPpiContact': false, 'methodTypeData': methodsArray, 'contacEA': [] });
    }
    FxSnackBar.closeForce();
  };

  /**
   * getting the destination data
   * @param contactId
   */
  function setExternalAccount(contactId: any) {
    updateComponentParams(dispatch, props?.id, { 'isDestination': false,'contacEA':[] });
    getEAList(contactId).then((data: any) => {
      const ea = getEAOrAddressDetailsFromContact(data, "EA", "", false, true);
      if (data && data.externalAccount) {
        updateComponentParams(dispatch, props?.id, { 'methodTypeData': [{ label: 'ACH', value: 'ACH' }, { label: 'WIRE', value: 'WIRE' }] });
        setValue('method', selectedMethod);
      }
      if (data && data.ppi) {
        updateComponentParams(dispatch, props?.id, { 'isPpiContact': true, 'selectedPPI': data.ppi, 'methodTypeData': [{ label: 'BOOK', value: 'BOOK' }] });
        setValue('method', 'BOOK');
        if (isMethodSupported("book")) {
        } else {
          FxSnackBar.show({
            text: 'Selected payment method is not supported for sending money to contact with PPI. Available methods: ' + getKey('ppiSupportedMethods'),
          });
        }
      }
      else {
        if (ea.length === 0) {
          setValue('externalAccount', ea?.[0]?.value);
        }
        updateComponentParams(dispatch, props?.id, { 'isPpiContact': false, 'selectedPPI': "", 'contacEA': ea });
      }
    });
  }
  const hasSelectedAccount = !!getKey('selectedAccount');

  //Method to handle open/close accordion
  const handleAccordionChange = () =>{
    updateComponentParams(dispatch, props.id, { accordionOpen: !accordionOpen});
  }
  /**
   * Function to handle the closing of the modal.
   */
  const handleCloseModal = () => {
    updateComponentParams(dispatch, props.id, { verifyProps: { open: false } });
  };


  return (

    <Grid id={"quick-transfer-send-book-main-grid"} item xs={12}>
      <FxModalGeneric
        id="quick-transfer-send-book-otp"
        open={props.verifyProps?.open}
        onClose={handleCloseModal}
        componentProps={props.verifyProps}
        className={'fx-modal-small2medium'}
        component={AuthenticationVerificationModal}
      >
      </FxModalGeneric>
      <FxCard
        id={"quick-transfer-send-book-card"}
        className="fx-theme-passport fx-quick-transfer fx-quick-transfer-header"
      >
        {checkPermission({ entity: "Other Entities", name: "Contact", operation: "View" }) && (checkPermission({ entity: "Accounts", name: "Book Send", operation: "Create" })|| checkPermission({ entity: "Accounts", name: "Ach Send", operation: "Create" })|| checkPermission({ entity: "Accounts", name: "Wire Send", operation: "Create" })) ? <div className="fx-form-edit-profile flex column">
          <form
            id="quick-transfer-send-book-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FxCardBody
              id={"quick-transfer-send-book-form-card-body"}
              className="fx-info-card"
            >
              <Accordion expanded={!!accordionOpen} onChange={() => handleAccordionChange()}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Grid item sm={12} xs={12}>
                    <Grid direction='row' container justifyContent="space-between" alignItems={'baseline'}>
                      <Grid item xs={9}>
                        <Typography className="fx-quick-transfer-header-text">
                          Quick Send
                        </Typography>
                      </Grid>
                      {<Grid item className='fx-dashboard-toggle-icon'>
                        {!accordionOpen ? <ArrowPermsUp /> : <ArrowPermsDown />}
                      </Grid>}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                className="fx-container-create-schdule"
              >
                <Grid item container xs={12} sm={12} className="fx-container-create-schdule-middle-scroll" >
                      {(location?.pathname === '/home' && !!sourceAccount) && <Grid item xs={12} sm={12}>
                        <FxMaterialSelect
                          register={{ ...register("sourceAccount") }}
                          id="create-send-source-account"
                          name="sourceAccount"
                          data={accountOptions}
                          control={control}
                          label="Select Source Account"
                          rules={{ required: true }}
                          clearError={clearErrors}
                          className={
                            errors.sourceAccount
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          setValue={setValue}
                        />
                      </Grid>}
                  <Grid item xs={12} sm={12} className="fx-quick-transfer-autocomplete-group">
                    <FxLabelView className="fx-label-top" id="quick-transfer-send-book-form-card-contact-label">Name</FxLabelView>
                    {isDestination && (
                      <FxAutoComplete
                        register={{ ...register("destination") }}
                        control={control}
                        rules={{ required: true }}
                        id="quick-transfer-send-book-form-card-destination"
                        name="destination"
                        source={src}                        
                        searchBy={destinationFilter}
                        className={
                          errors.destination
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                        }
                        dataTransformer={destinationTransformer}
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        filterOptions={destinationFilterOptions}
                        placeholder={"Search Contact"}
                        isEditable={true}
                        setParam={handleChange}
                      />
                    )}
                  </Grid>
                  {isPpiContact && (
                    <Grid item xs={12} sm={12}>
                      <FxTextEdit
                        control={control}
                        rules={{ required: false }}
                        id="quick-transfer-send-book-form-card-ppi-textbox"
                        label="Payment ID"
                        name="passportPaymentId"
                        variant="outlined"
                        value={selectedPPI}
                        isEditable={false}
                        className={"fx-input-edit fx-input-edit-disabled"}
                      />
                    </Grid>
                  )}
                  {contacEA?.length > 0 && <Grid item xs={12} sm={12}>
                    <FxMaterialSelect
                      register={{ ...register("externalAccount") }}
                      id="create-send-contact-form-card-ea"
                      name="externalAccount"
                      data={contacEA}
                      control={control}
                      label="Contact External Account"
                      rules={{ required: true }}
                      value={contacEA.length === 1 ? contacEA?.[0]?.value : ''}
                      clearError={clearErrors}
                      className={
                        errors.externalAccount
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit"
                      }
                      setValue={setValue}
                    />
                  </Grid>}
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={7}>
                      <FxTextEdit register={{ ...register("amount") }}
                        className={errors['amount'] ? 'border-error-input fx-input-edit clear-number-field-arrow' : 'fx-input-edit clear-number-field-arrow'}
                        control={control} rules={setAmountValidationOptions} prefix="$"
                        id="quick-transfer-send-book-form-card-amount-textbox" label="Amount" name="amount"
                        type="number" variant="outlined" valuePattern={/-|\+|e|E/} min={0.01}
                        showDecimal={true} setValue={props.setValue} onWheel={(e:any)=>e.target.blur()}/>
                    </Grid>
                    <Grid item xs={5}>
                      <FxMaterialSelect
                        register={{ ...register("method") }}
                        className={
                          errors.method
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                        }
                        rules={{ required: true }}
                        control={control}
                        id="quick-transfer-send-book-form-card-method-select"
                        name="method"
                        data={methodTypeData}
                        value=''
                        setValue={setValue}
                        label="Method"
                        placeholder="Select Method"
                      />
                    </Grid>
                  </Grid> 
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <FxTextEdit register={{ ...register("purpose") }}
                        className={'fx-input-edit'}
                        control={control}  
                        id="quick-transfer-send-book-form-card-purpose-textbox" 
                        label="Purpose" 
                        rules={{ required: true }}
                        name="purpose" 
                        variant="outlined"
                        setValue={props.setValue} />
                    </Grid> 
                  </Grid>
                  <FxCardFooter id="quick-transfer-send-book-form-card-footer">
                    <Grid container xs={12} justifyContent="flex-end">
                      {clearButton && <FxButton
                        type="submit"
                        onClick={handleClear}
                        variant="contained"
                        id="quick-transfer-send-book-form-card-clear-button"
                        className="fx-button fx-button-cancel"
                      >
                        Clear
                      </FxButton>}
                        <span className="fx-padding-right-16" />
                        <FxButton
                          disableRipple={false}
                          forbiddenMessage={(location?.pathname !== '/home' && !hasSelectedAccount) ? "Passport Account Activation is pending. Please Activate to proceed." : ''}
                          className={!clearButton ? "fx-button fx-button-theme fx-quick-transfer-submit-button" : "fx-button fx-button-theme"}
                          id={"quick-transfer-send-book-form-card-create-button"}
                          type="submit"
                          isSubmitting={isSubmitting}
                        >Send Money
                        </FxButton>
                    </Grid>
                  </FxCardFooter>
                </Grid>
              </Grid>
              </AccordionDetails>
              </Accordion>

            </FxCardBody>
          </form>
        </div> : <FxAccessDenied id={'passport-quick-transfer-access-denied'} title={'You are not authorized to access this card'} subComponent={true}/>}
      </FxCard>
    </Grid>
  );
});
