/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import React from 'react';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import { RecentLeads } from './RecentLeads';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
/**
 * component which handles recent inviations
 */
export const RecentInviteCard: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const leadSrc = {
        url: "/lead/list",
        method: "POST",
        data: {
            "pageNumber": 1,
            "pageSize": 25,
            "sortOptions": {
              "sortBy": "createdOn",
              "sortOrder": "desc"
            },
            "criteria": {
              "filters": [
                
              ]
            }
          }
      }

    /**
    * method to load list based on type
    * @returns :list
    */
    const listdata = () => {
        return <RecentLeads id={"recent-invited-leads"} source={leadSrc} />
    }

    return <Grid id="home-recent-invite-card-Home-Grid" item xs={12} >
        <FxCard id="home-recent-invite-card-Home-Card" className="fx-card-general fx-theme-passport" >
            <FxCardHeader id="home-recent-invite-card-Home-Card-Header" className="fx-card-header">
                <Grid item container xs={12} direction='row' spacing={1}>
                    <Grid item xs={12} className="fx-flex-grow">
                        <Grid className='fx-tab-border'>
                        <Typography variant="h3">Recent Leads Activity</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </FxCardHeader>
            {listdata()}
        </FxCard>
    </Grid>
})