 /* eslint-disable  @typescript-eslint/no-explicit-any */
 /* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Component : AccountTag
 * Usage     : displays account tags
 * 
 */
import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import FxCard from "../../../Container/FxCard";
import FxCardBody from "../../../Container/FxCardBody";
import FxCardHeader from "../../../Container/FxCardHeader";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add-new-icon.svg";
import { AddTags } from "./AddTags";
import { FxChip } from "../../../Input/FxChip/FxChip";
import CloseIcon from '@mui/icons-material/Close';
import FxNoGridDataImage from "../../../Data/FxNoGridDataImage";


export const AccountTag: React.FC<any> = React.memo((props) => {
  const [open, setOpen] = useState(false);

  /**
   * fetching account details from state, which includes tag details
  */
  const accDetails = useSelector((state: any) => {
    if (state.data["customer-basic-info"]) {
      return state.data["customer-basic-info"]?.params?.accountDetails;
    }
  });

  /**
   * function handle modal open
   */
  const handleOpen = () => {
    setOpen(true);
  };

  /**
   * function handle modal close
   */
  const handleManageTagClose = () => {
    setOpen(false);
  };

  const handleDelete = (tagToDelete: any) => {}

  return (
    <>
      <Grid
        id="home-my-account-grid"
        item
        xs={12}
        container
      >
        <FxCard id="account-tag-card" className="fx-theme-passport">
          <FxCardHeader>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              className="fx-card-header"
            >
              <Grid item className="fx-account-insight-title">
                <Typography variant="h3">Tags</Typography>
              </Grid>
              <Grid item>
                <FxButton
                  id="account-tag-add-new-button"
                  variant="contained"
                  className="fx-button fx-button-passport-border fx-svg-theme"
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Manage TAGS
                </FxButton>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody>
            {accDetails?.tags ? (
              <FxChip
                id={"account-tag"}
                data={accDetails?.tags}
                handleDelete={handleDelete}
                className={'fx-button fx-account-tag-chips'}
                deleteIcon={<CloseIcon />}
              />
            ) : 
            <Grid item container justifyContent={'center'}>
              <Grid item>
                <FxNoGridDataImage /> 
                <Typography>No data to show</Typography>
              </Grid>
            </Grid>}
          </FxCardBody>
        </FxCard>
      </Grid>
      {open && (
        <AddTags
          id="account-tag-modal"
          open={open}
          onClose={handleManageTagClose}
        />
      )}
    </>
  );
});