/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router';
import { FxButtonGroup } from "../../Action/FxButtonGroup";
import { FxCardTransactionList } from "./MethodTransactions/FxCardTransactionList";
import { FxOtherTransactionList } from "./MethodTransactions/FxOtherTransactionList";

/**
 * This component handles the listing of the Rule Funding Linked Card Events
 */
export const FundingLinkedTransactionDetail: React.FC<any> = React.memo((props) => {
  //get ID from page url
  const history = useHistory<any>()
  //get the Rule id and Rule name
  interface RouteParams {
    id: string
    name: string
  }
  const params = useParams<RouteParams>();
  const id = params?.id
  const name = params?.name;
  const dispatch = useDispatch();
  const applicableMethods = history?.location?.state?.methods;
  const parentRuleId = history?.location?.state?.parentRuleId;
  //Types recevied from fundinglist will be shown as tabs
  const typeData = applicableMethods?.map((ele: any, index: any) => {
    return { id: ele, label: ele, value: ele, selected: index === 0 ? true : false }
  })
  const [selectedMethod, setSelectedMethod] = useState(applicableMethods?.[0]);


  /**
   * useEffect to load title while loading page
   */
  useEffect(() => {
    if(!applicableMethods){
      history.push('/fundingrules/active')
    }
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: {
        id: "page-title-details",
        data: { title: "Rule: " + name + " (ID: " + (parentRuleId? parentRuleId : id)+ ")", backButton: history?.location?.state?.backUrl ? history?.location?.state?.backUrl : "/fundingrules/active" },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
  * Method handles the onchange of the Funding Rules method
  */
  const handleTypeChange = (event: any) => {
    if (selectedMethod !== event.value) {
      setSelectedMethod(event.value);
      dispatch({ type: "DATA_COMPONENT_INIT", payload: { id:`funding-linked-other-transactions-data-grid-list-${event.value}`,  } });
      dispatch({ type: "DATA_COMPONENT_INIT", payload: { id:`funding-linked-transactions-list-card-other-methodfilter` } });
    }
  };

  return (
    <Grid id="faster-funds-first-grid" item xs={12}>
      <Grid className="fx-tab-border">
        <FxButtonGroup
          id="active-funding-rule-queue-list-card-button-group"
          options={typeData}
          onClick={(event: any) => handleTypeChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        {selectedMethod === 'CARD' ? <FxCardTransactionList id={id + 'method' + selectedMethod} ruleId={id} method={selectedMethod} parentRuleId={parentRuleId}></FxCardTransactionList> :
            <FxOtherTransactionList id={id + 'method' + selectedMethod} method={selectedMethod} ruleId={id} parentRuleId={parentRuleId}></FxOtherTransactionList>}
      </Grid>
    </Grid>
  );
});
