/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../../libs/utils/logger';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { ReactComponent as ThanksIcon } from '../../../../assets/svg/circular-tick-with-shadows.svg';  
import { ReactComponent as DividerIcon } from "../../../../assets/svg/divider-icon.svg";
import { getKey } from '../../../../libs/utils/storageManager';
import ClockTimer from '../../../../assets/images/clock-timer.gif';
import FxImage from '../../../Data/FxImage';


Logger.debug("PriorityCapitalAccessAllowedConfirmation.tsx", "PriorityCapitalAccessAllowedConfirmation Modal initializing")

/**
 * This component handles the display of the Congratulations modal signifying the successful access of the Priority Capital Page
 * and will navigate to the Priority Capital Page
 */
export const PriorityCapitalAccessAllowedConfirmation: React.FC<any> = (props) => {

    const backToRedirectUrl = getKey('goPriorityCapitalUrl')

    /**
     * This useEffect handles the redirection to the Pipe Capital Page 
     * once the user sees this confirmation popup modal
     */
    useEffect(() => {
        const navigateToPipeDashboard = () => {
            //Navigate to the Pipe Capital Page in another tab after n-seconds
            setTimeout(() => {
                window.location.href= backToRedirectUrl
            }, 15000);
        };
    
        navigateToPipeDashboard();
      }, []);

    return (
        <Grid className='fx-merchant-congrats'>
            <FxCard className='fx-merchant-congrats-card fx-merchant-congrats-icon'>
                <FxCardHeader id="fx-merchant-congrats-modal-header" className="fx-card-header fx-modal-header fx-merchant-congrats-header">
                    <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                        <Grid item container xs={12} justifyContent="center">
                            <ThanksIcon width={'8.58rem'} height={'8.58rem'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography id="fx-merchant-congrats-modal-title" align="center" className="fx-enable-physical-card-modal-title" >
                                Congratulations!
                            </Typography>
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="fx-merchant-congrats-modal-body" className="fx-merchant-congrats-body">
                    <Grid container item xs={12} className="fx-merchant-congrats-body-description" justifyContent={"center"}>
                        <Typography id="fx-merchant-congrats-modal-subtext" paddingBottom='0.75rem'>
                            You've successfully designated Passport as your funding account and now have access to Priority Capital.
                        </Typography>
                    </Grid>
                </FxCardBody>
                <FxCardFooter id="fx-merchant-congrats-modal-footer" className="fx-merchant-congrats-footer">
                    <Grid className="fx-merchant-congrats-footer-divider">
                        <DividerIcon />
                    </Grid>
                    <Grid xs={12} className="fx-merchant-congrats-footer-sub-desc" marginTop={'1rem'} display={'flex'}>
                        <Grid className='fx-merchant-congrats-footer-icon' alignContent={'flex-start'}>
                            <FxImage id="clock-timer-card-image" className={'congrats-clock-timer'} src={ClockTimer}/>
                        </Grid>
                        <Grid>
                            <Typography id="fx-merchant-congrats-modal-subtext" alignContent={'flex-end'}>
                                You will be redirected to the Priority Capital page shortly.
                            </Typography>
                        </Grid>
                    </Grid>
                </FxCardFooter>
            </FxCard>
        </Grid>
    );
};
