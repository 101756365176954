/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { RegisterComponent } from '../../../libs/saga/dataSaga';


Logger.debug("MerchantLocationCard.tsx", "Merchant Location Card initializing");
/**
 * Component: Merchant Location Card
 * Usage: To display merchant location information
 */

export const MerchantLocationCard: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch()
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let merchant_location_data: any;
        
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        if (context && context?.data && context?.data?.body) {
            try{
                //check if data from api is an array or an object
                if(Array.isArray(context?.data?.body)){
                    if(context?.data?.body?.length > 0){
                        merchant_location_data = {...context?.data?.body[0]};
                    }
                }else{
                    merchant_location_data = {...context?.data?.body};
                }
                if(merchant_location_data){
                    const addressLine2 = merchant_location_data?.address?.addressLine2 ? merchant_location_data?.address?.addressLine2 + ', ' : '';
                    merchant_location_data['fullAddress'] = merchant_location_data?.address?.addressLine1 + ', ' + addressLine2 + merchant_location_data?.address?.city + ', ' + merchant_location_data?.address?.state + ', ' + merchant_location_data?.address?.zip;
                }
            }catch(e){
                Logger.error("MerchantLocationCard.tsx",e)
            }
        }

        // declare location information left section columns
        const merchant_location_information_section_left = [
            {
                label: 'Doing Business As',
                value: 'doingBusinessAs',
            },
            {
                label: 'Location ID',
                value: 'id',
            },
        ];

        // declare contact information right section columns
        const merchant_location_information_section_right = [
            {
                label: 'Address',
                value: 'fullAddress'    
            }
        ];
       
        
        return (
            <>
                {merchant_location_data && 
                    <FxInfoCard 
                        id={"info-card-"+props.id} 
                        title="LOCATION INFORMATION" 
                        leftcolumn={merchant_location_information_section_left} 
                        rightcolumn={merchant_location_information_section_right} 
                        data={merchant_location_data} 
                    />
                }
            </>
        )
    }
)
