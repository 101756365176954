 /* eslint-disable  @typescript-eslint/no-explicit-any */
 /* eslint react-hooks/exhaustive-deps: "off" */
 /* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from 'react'
import { QuickTransfer } from './QuickTransfer';
import { Grid } from '@mui/material';
import { AccountListCard } from './AccountListCard';
import { useIsMobileOrTabletScreen, useIsMobileScreen } from '../../../../libs/utils/utils';
import { getKey } from '../../../../libs/utils/storageManager';
import { useDispatch, useSelector } from 'react-redux';
import FxTopBanner from '../../../Utils/fx-top-banner';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';

/**
 * Component used to draw dashboard of accounts
 */
export const DashboardAccountHome: React.FC<any> = React.memo((props) => {

    const isMobileScreen = useIsMobileOrTabletScreen();
    const isSmallScreen = useIsMobileScreen();
    const customerStatus = getKey("customerStatus");
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
    const jointTenancy= uiFeat.getJointTenancyFeature().available;
    const bannerCarouselData:any = [];
    const dispatch = useDispatch()

    /**
     * UseEffect to close all topbaner when initializing the component
     */
    useEffect(()=>{
        FxTopBanner.closeForce('faster_funding_banner');
        FxTopBanner.closeForce('deposit_account');
        FxTopBanner.closeForce('cash_builder_plus_account');
    },[])

    /**
     * to get source details
    */
    const sourceInfo = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
            return state.data['customer-basic-info']
        }
    });

    const accountList = sourceInfo?.params?.accountList?.resources;
    const activeAccountStatus = accountList?.some((item:any) => (item.type !== 'CASH_BUILDER_PLUS' || item.type !== 'CASH_BUILDER') && item.status === 'ACTIVE');
    const hasInvestmentAccount = sourceInfo?.params?.hasInvestmentAccount;     
    const hasCashBuilderAccount = sourceInfo?.params?.hasCashBuilderAccount;  

    /**
     * to show/hide investment account banner 
     */
    const canShowInvestmentBanner = useMemo(() => {
        if(businessCustomer && activeAccountStatus && !hasInvestmentAccount){
            return true;
        }else{
            return false;
        }
    },[businessCustomer, activeAccountStatus, hasInvestmentAccount]);
    
    const isInvestmentAccountCreated = useSelector((state: any) => (state.data['investment-account-created']?.data?.isInvestmentAccountCreated || null));
    
    /**
    * to show/hide Cash Builder+ account banner 
    */
    const canShowCashBuilderBanner = useMemo(() => {
        return !jointTenancy && activeAccountStatus && !hasCashBuilderAccount && customerStatus === 'ACTIVE';
    },[jointTenancy, activeAccountStatus, hasCashBuilderAccount, customerStatus]);
    const isCashBuilderAccountCreated = useSelector((state: any) => (state.data['cash-builder-account-created']?.data?.isCashBuilderAccountCreated || null));


    /**
     * useEffect to load fast funding banner
     */
    useEffect(() => {

        if(hasInvestmentAccount || isInvestmentAccountCreated) {
            FxTopBanner.closeForce('deposit_account');
        }
        if(hasCashBuilderAccount || isCashBuilderAccountCreated){
            FxTopBanner.closeForce('cash_builder_plus_account');
        }

        //  to open Cash Builder+ Banner
        if(canShowCashBuilderBanner && !isCashBuilderAccountCreated && getKey('enableCashBuilderAccount') === 'true' && getKey('role') === 'ADMIN'){
            bannerCarouselData.push({id: 'cash_builder_plus_account', component: 'topCashBuilderBanner'})
        }
        
        //  to open Treasure manual
        if(canShowInvestmentBanner && !isInvestmentAccountCreated && getKey('enableInvestmentAccount') === 'true' && getKey('role') === 'ADMIN'){
            bannerCarouselData.push({id: 'deposit_account', component: 'topTreasuryBanner'})
        }

        if(bannerCarouselData.length > 0){
            updateComponentParams(dispatch, 'customer-basic-info', { 'topBannerData': bannerCarouselData })
            FxTopBanner.storeData(bannerCarouselData);
          }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canShowInvestmentBanner, isInvestmentAccountCreated, isMobileScreen, canShowCashBuilderBanner, isCashBuilderAccountCreated])

    const account_source = {
        url: "account/list",
        method: "POST",   
        data: {
    
            "pageSize": 5,
            "pageNumber": 1,
            "sortOptions": {
                "sortOrder": "ASC",
                "sortBy": "id"
            },
            "criteria": {
                "filters": [
                    {
                        "key": "insightPeriod",
                        "operator": "eq",
                        "values": [
                            "MONTHLY"
                        ]
                    }
                ]
            }
        }
    };

    return (
        <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className={"fx-passport-home fx-container"} >
            <Grid item container xs={12} className="fx-container-left">
                <AccountListCard id="account-list-sub-menu" source={account_source}/>
                {isSmallScreen && <QuickTransfer id="home-quick-transfer-card" />}
            </Grid>
            {!isSmallScreen && <Grid container className="fx-container-right">
                <QuickTransfer id="home-quick-transfer-card" />
            </Grid>}
        </Grid>
    )
})
