/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useCallback, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add-new-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete-icon.svg";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { updateComponentParams } from "../../../Utils/CommonBaseClass";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import FxMaterialMultipleSelect from "../../../Input/FxSelect/FXMaterialMultipleSelect";

/**
 * Component: Funding Rules association Section
 * Usage: To display association Section of Funding Rules Creation Screen
 */
export const FxPartnerAssociationSectionCard: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const dispatch = useDispatch();

  const customertypes = [
    { value: 'BUSINESS', label: 'Business' },
    { value: 'INDIVIDUAL', label: 'Individual' }
  ]

  useEffect(() => {
    //if there is api data that needs to be rendered in case of clone then, refactoring the data to a more component friendly format
    const customerValue = props?.value?.name ? props?.value?.name : props?.value?.key ? props?.value?.key?.toLowerCase() : '';
    updateComponentParams(dispatch, props?.id, { 'partnerParamData': customerValue })
    props.setValue(`funding-rule-partner-association.${props.index}.name`, customerValue);
    if (props?.data || props?.value) {
      updateComponentParams(dispatch, props?.id, { 'partnerSelectedTags': [] })
      if (props?.value?.value) {
        if ((props?.value?.name || props?.value?.key)) {
          if(props?.value?.key?.toLowerCase() === 'customer_enrolled_state' || props?.value?.name?.toLowerCase() === 'customer_enrolled_state'){
          if (Array.isArray(props?.value?.value)) {
            const tags = apiDataTransformer(props?.value?.value, customerValue);
            updateComponentParams(dispatch, props?.id, { 'partnerSelectedTags': tags })
          }
         } else {
          props.setValue(`funding-rule-partner-association.${props.index}.value`, Array.isArray(props?.value?.value) ? typeof props?.value?.value?.[0] === 'string' ? props?.value?.value?.[0] : '': props?.value?.value || '');
         }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, props?.value]);

  useEffect(() => {
    updateComponentParams(dispatch, props?.id, {
      'partnerParamOptions': [
        {
          label: "Customer Enrolled State",
          value: 'customer_enrolled_state',
          disabled: false,
        },
        {
          label: "Customer Type",
          value: 'customer_type',
          disabled: false,
        }
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * This method transforms the data received from the Api to handle the clone usecase
   * @param data 
   */
  function apiDataTransformer(data: any, key: any = '') {
    const newData = data;
    const myData =
      newData &&
      newData?.map((data: any) => {
        if (data.label) {
          return data;
        }
        return { value: `${data.trim()}`, label: `${geCountryLabel(data.trim())}` };
      });

    let transformedData: any = [];

    if (key === 'customer_enrolled_state' && props?.usastate && myData?.length === props?.usastate?.length - 1) {
      transformedData = [...[{ label: 'Select All', value: 'Select All' }, ...myData]];
    }

    props.setValue(`funding-rule-partner-association.${props.index}.value`, transformedData ? transformedData : myData);
    return transformedData?.length > 0 ? transformedData : myData;
  }

    //Method to transform data for mcc dropdown
    const geCountryLabel = (code: any) => {
      const data = props?.usastate?.find((item: any) => item?.value === code);
  
      return data?.label ? data?.label : code;
    }

  /**
   * Method returns whether an item is already present in the association array
   * @param data : form data
   * @param item : form field value
   * @returns : whether the item is already there or not
   */
  const checkValueExists = (data: any, item: any) => {
    const index = data?.["funding-rule-partner-association"]?.findIndex(
      (association: any) => association?.name === item
    )
    const exists = data?.["funding-rule-partner-association"]?.find(
      (association: any) => association?.name === item && props?.index !== index
    )

    return exists ? true : false;
  };

  const { watch } = props;

  /**
   * Dropdown values for the field Named - Parametern
   */
  const partnerParameterDropdownData = useCallback(() => {
    const data = [
      {
        label: "Customer Enrolled State",
        value: 'customer_enrolled_state',
        disabled: checkValueExists(watch(), 'customer_enrolled_state'),
      },
      {
        label: "Customer Type",
        value: 'customer_type',
        disabled: checkValueExists(watch(), 'customer_type'),
      }
    ];
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  /**
   * reset the property
   * */
  useEffect(() => () => {
    updateComponentParams(dispatch, props?.id, { 'partnerSelectedTags': [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Method to handle on change of Param field
   * @param event
   */
  const handleParamChange = (event: any) => {
    props.setValue(`funding-rule-partner-association.${props.index}.operator`, 'in');
    updateComponentParams(dispatch, props?.id, { 'partnerParamData': event?.target?.value })
    updateComponentParams(dispatch, props?.id, { 'partnerSelectedTags': [] })
  };

  return (
    <Grid
      container
      item
      direction="row"
      className="fx-form-edit-profile"
      spacing={2}
      id={"add-funding-rule-partner-association" + props.index}
      key={"association" + props.index}
    >
      <Grid
        item
        display={"inline-flex"}
        alignItems={"center"}
        maxWidth={"fit-content"}
      >
        <Typography className="fx-summary-title" variant="h3">
          {props.index + 1}.
        </Typography>
      </Grid>
      <Grid item xs={3} display={'inline-flex'} justifyContent={"space-between"}>
        <Grid item xs={12}>
          <FxMaterialSelect
            register={{
              ...props.register(`funding-rule-partner-association.${props.index}.name`),
            }}
            control={props.control}
            className={
              props.errors.name
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            rules={{ required: true }}
            id={props.index + "-" + props.index + "-funding-rule-partner-association-name"}
            name={`funding-rule-partner-association.${props.index}.name`}
            data={props?.partnerParamOptions || []}
            label="Select Parameter"
            setValue={props.setValue}
            placeholder="Select"
            onChange={handleParamChange}
            value={props?.value?.key ? props?.value?.key?.toLowerCase() : ''}
            onFocus={() => {
              updateComponentParams(dispatch, props?.id, {
                'partnerParamOptions': partnerParameterDropdownData()
              })
            }}
          />
        </Grid>
      </Grid>
      {props?.partnerParamData === 'customer_enrolled_state' ?
        <Grid item xs className="fx-contact-user-card fx-authorized-user-select split-funding-association">
          <FxMaterialMultipleSelect
            register={{
              ...props.register(`funding-rule-partner-association.${props.index}.value`),
            }}
            id={`funding-rule-partner-association.${props.index}.value`}
            name={`funding-rule-partner-association.${props.index}.value`}
            data={props?.usastate}
            control={props.control}
            canSearch={true}
            rules={{ required: true }}
            listBoxClass="fx-auto-complete-custom-auto-complete-dropdown-display-block"
            fixedOptions={[]}
            limitTags={4}
            selectAllLabel={"Select All"}
            label="Value *"
            setValue={props.setValue}
            isEdit={false}
            value={props?.partnerSelectedTags}
            isSelectAllRequired={true}
            readOnly={watch(`funding-rule-partner-association.${props.index}.name`) ? false : true}
          /></Grid> :
        <Grid item xs>
          <FxMaterialSelect
            register={{
              ...props.register(`funding-rule-partner-association.${props.index}.value`),
            }}
            className={
              props.errors.value
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            id={`funding-rule-partner-association.${props.index}.value`}
            name={`funding-rule-partner-association.${props.index}.value`}
            data={customertypes}
            placeholder="Select"
            control={props.control}
            rules={{ required: true }}
            label="Value*"
            setValue={props.setValue}
            readOnly={watch(`funding-rule-partner-association.${props.index}.name`) ? false : true}
          />
        </Grid>}
      <Grid
        item
        xs={"auto"}
        spacing={2}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item>
          <FxButton
            id={props.index + "-" + props.index + "-delete-condition-partnerassociation-btn"}
            title="Delete"
            className={`fx-button fx-approval-button-icon fx-condition-btn`}
            variant="contained"
            onClick={props.handlePartnerRemove}
            startIcon={<DeleteIcon />}
          />
        </Grid>
        <Grid item>
          <FxButton
            id={props.index + "-" + props.index + "-add-condition-partnerassociation-btn"}
            title="Add"
            className={`fx-button fx-approval-button-icon fx-condition-btn ${props.isAddButtonDisabled ? "fx-button-disabled" : ""
              }`}
            disabled={props.isAddButtonDisabled}
            variant="contained"
            onClick={props.handlePartnerAppend}
            startIcon={<AddIcon />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
