/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { CustomerInfoCard } from './CustomerInfoCard';
import { CustomerAddress } from './CustomerAddress';
import { CustomerChangePassword } from './CustomerChangePassword';
import { useMediaQuery } from 'react-responsive'
import { CompanyInfoCard } from './CompanyInfoCard';
import { useDispatch } from 'react-redux';
import { CommunicationPreference } from './CommunicationPreference';
import { CustomerOtpMfaSettings } from './CustomerOtpMfaSettings';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * Component used to view company profile page cards
 */

export const CustomerProfile: React.FC = () => {

        
        const individual_source = {
            url: "/",
            method: "GET"
        }
        const dispatch = useDispatch();


        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available
        const jointTenancyCustomer = uiFeat.getJointTenancyFeature().available
        const info_source = {
            url: "/profile" ,
            method: "GET",
            baseUrl: true
        }

        /**
         * useeffect to load titlw while loading page
         */
        useEffect(()=> {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: businessCustomer ? 'User Information' :'Customer Information'} } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <>
                <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                    <Grid container xs={12} sm={8} className="fx-container-left" >
                        {businessCustomer && <CustomerInfoCard id="customer-info-card-profile" source={info_source}></CustomerInfoCard>} 
                        {(individualCustomer  || jointTenancyCustomer) &&<><CompanyInfoCard id="company-info-card-profile" source={individual_source}></CompanyInfoCard>
                        <CustomerAddress id="customer-profile-individual-address-comp-card"></CustomerAddress></>}
                        
                        {isSmallScreen &&
                            <CustomerChangePassword source={info_source} id="customer-profile-change-password"></CustomerChangePassword>
                        }
                        
                    </Grid>
                    {!isSmallScreen &&<>
                        <Grid container xs={12} sm={4} className="fx-container-right">
                            <Grid item xs={12}>
                                <CustomerChangePassword source={info_source} id="customer-profile-change-password"></CustomerChangePassword>
                            </Grid>
                            <Grid item xs={12}>
                                <CommunicationPreference source={info_source} id="customer-profile-communication-preference" />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomerOtpMfaSettings source={info_source} id='customer-otp-mfa-settings'/>
                            </Grid>
                            
                        </Grid></>
                    }
                </Grid>
            </>
        )
    }


