 /* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useMemo, useState } from 'react'; // we need this to make JSX compile
import { Button, Grid, IconButton, Modal, Typography, CircularProgress, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import HttpClient from '../../../../libs/utils/httpClient';
import { Logger } from '../../../../libs/utils/logger';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { b64toBlobAndDownload } from '../../../../libs/utils/downloadDoc';
import { FxButton } from '../../../Action/FxButton';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import { getKey } from '../../../../libs/utils/storageManager';
import { getCustomerUrl } from '../../../../libs/utils/utils';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import { useMediaQuery } from "react-responsive";
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';

Logger.debug("AccountConfigurationsCard.tsx", "Account Configuration initializing")
const httpClient = HttpClient.getClient();

/**
 * This component handles the Account Statement Download
 */
export const AccountStatementDownload: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, control, watch } = useForm();
        const dispatch = useDispatch();
        const isTabScreen = useMediaQuery({ query: '(max-width: 999px)' })
        let activationDate:any = props?.entityActivationDate ? props.entityActivationDate : getKey("selectedAccount")?.activationDate;
        const currentYear = new Date().getFullYear();
        const activationYear = new Date(activationDate).getFullYear();
        let currentYearDefault: any = Number(moment().format('YYYY'));
        const currentMonthDefault: any = props?.currentMonthDefault?.toString() || moment().subtract(1, 'months').format('MM');
       
        if(currentMonthDefault === "12"){
            currentYearDefault = currentYearDefault-1;
        }
        const currentMonth = new Date().getMonth();
        const activationMonth = new Date(activationDate).getMonth();

        //reset the component so that when redirect back page it will reset the isMessage to false
        useEffect(() => {
            updateComponentParams(dispatch, props?.id, {isMessage: false});
        }, [])

        /**
         * Method set the default value fo the year selection
         */
        useEffect(() => {
            if(currentYearDefault){
                setValue('yearSelect',currentYearDefault);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentYearDefault])


        /**
         * Method prefills the single digit integer with a 0 and convert to string
         * @param number : value from the month dropdown
         * @returns : formatted value
         */
        function prefillWithZero(number: number) {
            if (number >= 0 && number <= 9) {
              return `0${number}`;
            } else {
              return number.toString();
            }
          }
          
        const month_select_options = [
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' }
        ];

        /**
         * Method sets the month options data based on the activation date and selected year
         */
        const month_options = useMemo(() => {
            const defMonthOptions = [...month_select_options];
            if (watch('yearSelect')?.toString() === currentYear?.toString()) {
                defMonthOptions.splice(currentMonth, 13 - currentMonth)
            } else if (watch('yearSelect')?.toString() === activationYear?.toString()) {
                defMonthOptions.splice(0, activationMonth);
            }
            if(activationYear?.toString() === currentYear?.toString()){
                defMonthOptions.splice(0, activationMonth);
            }
            return defMonthOptions;
        }, [watch('yearSelect'), currentYear, activationYear, currentMonth, activationMonth]);
        
        /**
         * Method set the value of month on the basis of current year and selected year
         */
        useEffect(() => {
            if (watch('yearSelect')?.toString() === currentYear?.toString()) {
                setValue('monthSelect', watch("monthSelect") || currentMonth);
            } else if (watch('yearSelect')?.toString() === activationYear?.toString()) {
                setValue('monthSelect', activationMonth + 1);
            } else {
                setValue('monthSelect', 1);
            }
            if(activationYear?.toString() === currentYear?.toString()){
                if((activationMonth >= currentMonth) && !props?.isMessage){
                    updateComponentParams(dispatch, props.id, { isMessage: true })
                }
            }
        }, [watch('yearSelect'), currentYear, activationYear, currentMonth, activationMonth, setValue]);


        /**
         * Method returns the year options for selecting year
         * @returns : array of year options
         */
        const getYear = () => {
            const yearOptions = [];
            for (let i = 0; i <= currentYear - activationYear; i++) {
                yearOptions.push({ value: currentYear - i, label: currentYear - i });
            }
            if (!activationDate) {
                yearOptions.push({ value: currentYear, label: currentYear });
            }
            return yearOptions;
        };
        
        
        /**
         * Method handles the onclick event for closing modal
         */
        const handleClose = () => {
            if(props.onClose){
                props.onClose();
            }
        };
        /**
         * Async function handles the api call for downloading account statement
         * @param event
         */
        async function onSubmit(event: any) {
            try {
                // Prepare the request object based on entity type
                const request = {
                        period: `${prefillWithZero(event.monthSelect)}/${event.yearSelect}`,
                    };
        
                // Determine the base path and URL
                const basePath = props?.entity === 'merchant'
                    ? `merchant/id/${props.entityId}/statement`
                    : `account/id/${getKey("selectedAccount")?.id}/statement`;
        
                const url = getCustomerUrl(basePath, false);
        
                try {
                    // Send the request and handle the response
                    const response = await httpClient.post(url, request);
        
                    if (response?.data?.base64Content) {
                        b64toBlobAndDownload(response.data.base64Content, undefined, 'AccountStatement', 'pdf');
                        FxSnackBar.show({ severity: 'success', text: 'Account Statement Downloaded Successfully' });
                    } else {
                        FxSnackBar.show({ text: 'Account Statement Download Failed!' });
                    }
                } catch (err) {
                    const errorMessage = (err as { response?: { data?: { errorMessage?: string } } }).response?.data?.errorMessage || "Account Statement Download Failed!";
                    FxSnackBar.show({ text: props?.entity === 'merchant' ? errorMessage : 'Account Statement Download Failed!' });
                    Logger.error("AccountConfigurationsCard.tsx :: onSubmit", "error", err);
                } finally {
                    handleClose();
                }
            } catch (e) {
                Logger.error("AccountConfigurationsCard.tsx :: onSubmit", "unexpected error", e);
            }

        }

        return (
            <Grid container className={"fx-container fx-account-statement"}>
                    <Modal
                        style={{ overflow: 'scroll' }}
                        open={props.open}
                        onClose={handleClose}
                        disableEnforceFocus={true}
                        disableAutoFocus={false}
                        className={isTabScreen ?"fx-modal fx-modal-medium":'fx-modal fx-modal-small2medium'}
                    >
                        <div className="modal-content" >
                            <div className="modal-body">
                                <form id="account-statement-download-submit" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard className="fx-theme-passport">
                                        <FxCardHeader id="download-account-statement-header">
                                            <Grid container direction="row" item xs={12} justifyContent="space-between">
                                                <Grid item container xs={11} justifyContent="flex-start">
                                                    <Typography id="title-download-account-statement-modal" variant="h4">
                                                        Download Account Statement
                                                    </Typography>
                                                </Grid>
                                                <Grid className="fx-modal-close-icon" item xs={1}>
                                                    <IconButton
                                                        onClick={handleClose}
                                                        id='download-account-statement-modal-close-icon'
                                                        size="large">
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </FxCardHeader>
                                        <FxCardBody id="download-account-statement-modal-body" className="fx-info-card" >
                                            {props?.isMessage? <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant={'h5'} className='fx-account-statement-message'>Account statement will be available for you to download starting next month.</Typography>
                                                </Grid>
                                            </Grid>:
                                            <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant={'h5'}>Select period to download account statement</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FxMaterialSelect register={{ ...register("yearSelect") }} rules={{ required: true }} className={errors.yearSelect ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="yearSelect-type" name="yearSelect" data={getYear()} label="" value={watch('yearSelect') || currentYearDefault} setValue={setValue}/>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FxMaterialSelect register={{ ...register("monthSelect") }} rules={{ required: true }} className={errors.monthSelect ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="monthSelect-type" name="monthSelect" data={month_options} label="" value={watch('monthSelect') || currentMonthDefault} setValue={setValue}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>}
                                        </FxCardBody>
                                        {!props?.isMessage && <FxCardFooter id="download-account-statement-modal-Footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <Button variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"download-account-statement-submit-button"}
                                                    type="submit"
                                                    isSubmitting={isSubmitting}
                                                >   Download
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>}
                                    </FxCard>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </Grid>
        );
    })







