/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { ReactComponent as LeftDownArrow } from "../../../assets/svg/arrow-left-downbig.svg";
import { ReactComponent as RightUpArrow } from "../../../assets/svg/arrow-right-topbig.svg";
import { getKey } from "../../../libs/utils/storageManager";
import {
  truncateChars,
  updateComponentParams,
} from "../../Utils/CommonBaseClass";
import { IDataGridProps, IFilterColumns, IFilterFields } from "../../../types/common.interfaces";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import { DataGrid } from "../../Data/DataGrid";
import FxCardFooter from "../../Container/FxCardFooter";
import { FasterFundsDetailSummaryCard } from "./FasterFundsDetailSummaryCard";
import { getCustomerUrl } from "../../../libs/utils/utils";
import { Logger } from "../../../libs/utils/logger";
import HttpClient from "../../../libs/utils/httpClient";
import FxCardHeader from "../../Container/FxCardHeader";
import { Filter } from "../../Data/Filter";
const httpClient = HttpClient.getClient();

/**
 * This component handles the listing of the Faster Funds Availed ledger records
 */
export const FasterFundsDetail: React.FC<any> = React.memo((props) => {
  const dispatch = useDispatch();
  const [creditData, setCreditData] = useState();
  let creditLineData:any;

  const accountId = getKey("selectedAccount")?.id;

  /**
   * Method contains the API source related info for the cardEvents/list API
   */
  function sourceInfo() {
    var filters: any = [];
    if (accountId) {
      const src = {
        url: "cardEvent/list",
        method: "POST",
        data: {
          pageNumber: 1,
          pageSize: 25,
          sortOptions: {
            sortBy: "lastUpdatedOn",
            sortOrder: "desc",
          },
          criteria: {},
        },
      };
      try {
        filters.push(
        {
          "key": "account.id",
          "operator": "eq",
          "values": [getKey("selectedAccount")?.id]
        },
        {
            "operator": "in",
            "key": "status",
            "values": ['INITIATED', 'SETTLED', 'BATCH_CLOSED']
        },
        {
          "operator": "eq",
          "key": "type",
          "values": ['SALE']
      },{
        "operator": "eq",
        "key": "isFasterFunded",
        "values": ['1']
      })

        src.data.criteria = { filters };
      } catch (e) {
        Logger.error("FasterFundsDetail.tsx", "error",e); 
      }
      return src;
    } else {
      return {
        url: "",
        method: "POST",
        data: {},
      };
    }
  }

  /**
   * useEffect to load title while loading page
   */
  useEffect(() => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: {
        id: "page-title-details",
        data: { title: "Faster Funds Detail",subTitle:true, backButton: "/accountledgers" },
      },
    });
    getCreditLimitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Method calls the GET creditline API to get the credit amount details and sets those values in the state
   */
  async function getCreditLimitData() {
    creditLineData = await getCreditLineDetails();
    setCreditData(creditLineData);
    
  }

  /**
   * Method to call the Credit Line API to get the Faster Funds Available Amount
   */
  const getCreditLineDetails = async () => {
    try {
        const data: any = await httpClient
          .get(getCustomerUrl("/account/id/" + getKey("selectedAccount")?.id + "/fasterFunds", false));
          if(data){
            return data.data;
          }
          
      } catch (err) {
        Logger.error("TransactionHistory.tsx", "error", err);
        return err;
      }
}

  /**
   * useEffect to reset the filter while unmount the page
   */
  useEffect(
    () => () => {
      updateComponentParams(dispatch, "faster-funds-card-filter", {
        resetFilter: true,
      });
    },
    [dispatch]
  );

  const column: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        "enable": true,
        "fieldName": "icon",
        "type": "fri://fieldtype/custom",
        "default": true,
        "style": { "width": "4.5rem", "padding-left": "0 !important" },
        "dataStyle": { "text-align": "left !important" }
      },
      {
        "label": "DATE",
        "enable": true,
        "fieldName": "createdOn",
        "type": "fri://fieldtype/date",
        "default": true,
        style: { width: "10rem" },
      },
      {
        label: "BATCH ID",
        enable: true,
        fieldName: "trimmedBatchId",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "7rem" },
      },
      {
        label: "TYPE",
        enable: true,
        fieldName: "type",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "7rem" },
      },
      {
        label: "AUTH CODE",
        enable: true,
        fieldName: "authCode",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "7rem" },
      },
      {
        label: "STATUS",
        enable: true,
        fieldName: "status",
        type: "fri://fieldtype/status",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "AMOUNT",
        enable: true,
        fieldName: "amount",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "5rem", "text-align": "end" },
      },
      {
        "enable": true,
        "fieldName": "spacer",
        "type": "fri://fieldtype/custom",
        "default": true,
        "style": { "width": "5.5rem"}
    },
      {
        "label": "LAST UPDATED ON",
        "enable": true,
        "fieldName": "lastUpdatedOn",
        "type": "fri://fieldtype/date",
        "default": true,
        "style": { "width": "4.5rem"}
    },
    ],
  };

  /**
   * Method handles the transformation of faster funded ledger data
   * @param data
   * @returns : transformed data
   */
  function transactionHistoryList(data: any) {
    const newData: any = [];
    data?.forEach((item: any, i: number) => {
      newData[i] = { ...data[i] };
      newData[i].status = item?.status;
      newData[i].statusReason = <><span title={item?.statusReason}>{` ${item.statusReason ? truncateChars(item?.statusReason, 35) : ''}`} &nbsp;</span></>
      newData[i].amount = item?.type === "SALE" ? (
          <FxCurrencyView
            className={"balance-amount-green"}
            justifyContent="flex-end"
            value={item.amount}
            prefix={"$"}
            showPlus
          /> ) : (
            <FxCurrencyView
            className={"balance-amount-green"}
            justifyContent="flex-end"
            value={item.amount}
            prefix={"$"}
            showMinus
          />
          )
      newData[i].icon =
        item?.type === "SALE" ? (
          <div className={"fx-passport-debit-credit-svg"}>
            <LeftDownArrow />
          </div>
        ) : (
          <div className={"fx-passport-debit-credit-svg"}>
            <RightUpArrow />
          </div>
        );
    });
    return newData;
  }

  const filterFields: IFilterFields = {
    "fields": [
        {
          "label": "Batch ID",
          "fieldName": "trimmedBatchId",
          "type": "fri://fieldtype/textfield",
          "operator":"eq"
        },
        {
          "label": "Amount",
          "fieldName": "amount",
          "type": "fri://fieldtype/amountrange",
          "data": [],
        },
        {
          "label": "Auth Code",
          "fieldName": "authCode",
          "type": "fri://fieldtype/textfield",
          "operator":"eq"
        },
        {
            "label": "Last Updated On",
            "toLabel": "To",
            "fieldName": "lastUpdatedOn",
            "type": "fri://fieldtype/daterange",
            "data": [],
            "notAllowFutureDate": true,
        }
    ]
  }

  const datagridProps: IDataGridProps = {
    id: "faster-funds-data-grid-list",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  return (
    <Grid id="faster-funds-first-grid" item xs={12}>
      {/* Summary Card */}
      <Grid item xs={12} className="fx-flex-grow fx-ea-type-button-mobile">
        <FasterFundsDetailSummaryCard data={creditData}/>
      </Grid>
      {/* Listing */}
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid container xs={12} className="fx-layout-list">
        <FxCard id="faster-funds-card" className="fx-theme-passport">
          <FxCardHeader>
            <Grid id="account-ledger-header-title" item xs justifyContent="flex-start">
              <Typography variant="h3" >Card Transactions</Typography>
            </Grid>
            <Grid id="account-ledger-header-first-grid" item xs justifyContent="flex-end" className="fx-account-ledger-header" display="flex">
                <Grid id="account-ledger-card-header-filter-grid" item className="fx-inline-flex">
                  <Filter id="account-ledger-card-filter" field={filterFields} dataGridProps={datagridProps} hiddenParameter={['account.id', 'status', 'type','isFasterFunded']}></Filter>
                </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="faster-funds-card-body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="faster-funds-card-body-grid" item xs={12}>
                <DataGrid
                  id="faster-funds-data-grid-list"
                  tableClass="fx-table-passport"
                  dataTransformer={transactionHistoryList}
                  source={sourceInfo()}
                  column={column}
                  pagination={true}
                  pageNumber={1}
                  pageSize={25}
                  resultPerPage={25}
                />
            </Grid>
          </FxCardBody>
          <FxCardFooter id="faster-funds-card-footer"></FxCardFooter>
        </FxCard>
      </Grid>
    </Grid>
  );
});
