/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'
import { Grid, Typography } from '@mui/material';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { checkPermission, removeUnderScoreConvertTitleCase, truncateChars } from '../../../Utils/CommonBaseClass';
import { FxAccessDenied } from './FxAccessDenied';
import feeDetails from '../../../../libs/utils/feeDetails.json';

/**
 * This component handles the list of fee details list
 */
export const FeeDetailsList: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    /**
     * async to call feerate card details
     */
    // const rateCardId = useMemo(async () => {
    //     try {
    //         context?.data?.body?.forEach((item:any)=>{
    //             if(item?.feeRateCard?.name===feeName){
    //                 updateComponentParams(dispatch, 'fee-ratecard-details', { 'feeId': item?.feeRateCard?.id })
    //             }
    //         })
    //     } catch (e: any) { }
    // }, [context?.data?.body])

    // const rateCardDetails = useMemo(async () => {
    //     if (props?.feeId) {
    //         const data: any = await httpClient.get('feeRateCard/id/' + props?.feeId).then((response) => {
    //             updateComponentParams(dispatch, 'fee-ratecard-details', { 'rateCardDetails': response?.data?.feeCategories })
    //             return response
    //         })
    //             .catch((error) => {
    //                 return { ...error };
    //             })
    //         return data;
    //     }
    // }, [props?.feeId])

     /**
    * to set permission
    */
     const permission = useMemo(() => {
         return checkPermission({
            entity: "Accounts",
            name: "Ledger",
            operation: "view"
        });
    }, []);

    return (
        <Grid id="fee-details-list-Home-Grid" item xs={12} className={'fx-account-activation-fee-details-list'}>
            <Grid container spacing={3} id="fee-details-list-Home-Card">
                <Grid item xs={12}>
                    <Typography id="title-fee-details-list" variant="h3" className={'fx-fee-details-list-header'} >Passport Commercial account fee schedule</Typography>
                </Grid>
                {permission ?
                    <Grid item xs={12} id="fee-details-list-Home-Card-Body" className="fx-fee-details-list">
                        <div style={{ height: '30rem', overflow: 'auto' }}>
                            {feeDetails !== undefined && feeDetails?.map((item: any) => {
                                return (
                                    <div className={'fx-fee-details-list-section'}>
                                        <Grid container direction="row">
                                            <Grid item xs={12} sm={12}>
                                                <Typography className="fx-fee-details-list-headerText"
                                                    variant="h6"> {removeUnderScoreConvertTitleCase(item?.groupName)}
                                                    {item?.sup && <span className='fx-margin-left-4'><sup>{item?.sup}</sup></span> }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {item?.feeCodes?.map((obj: any, index: number) => {
                                            const isEvenRow = index % 2 === 0;
                                            return (
                                                <Grid container direction="row"
                                                    className={`fx-fee-details-list-column ${isEvenRow ? 'odd-row' : 'even-row'}`}>
                                                    <Grid item container xs className="fx-fee-details-list-column-content">
                                                        <Grid title={obj?.name} container item xs alignItems={'center'}>
                                                            <Typography variant="h4">{truncateChars(obj?.name, 60)}&nbsp;
                                                            {obj?.nameSup && <sup>{obj?.nameSup}</sup>}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item container xs={4} className='fx-currency-view'>{obj?.maximumFee }
                                                        <span className='fx-margin-left-4'>{obj?.feeSup && <sup>{obj?.feeSup}</sup>}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </div>
                                )
                            })}

                            {feeDetails === undefined && <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography align='center' className='fx-no-data'> No data to show </Typography>
                                </Grid>
                            </Grid>}
                            <Grid item xs={12} className={'fx-fee-activation'}>
                                <Typography className={'fx-fee-agreement'}>
                                    <p>
                                    <sup>1</sup> This fee may increase if USPS postage increases.
                                    <br />
                                    <sup>2</sup> This fee only applies to certain merchants as set forth in Section 4.2 of the Passport Account Agreement
                                    <br />
                                    <sup>3</sup> The first three cards are free. This per card fee will be charged after the first three cards.
                                    </p>
                                </Typography>
                            </Grid>
                        </div>
                    </Grid> : <FxAccessDenied id={'fee-details-list-access-denied'} title={'You are not authorized to access this card'} />}
            </Grid>
        </Grid>
    )
})
