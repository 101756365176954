/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { Logger } from '../../../libs/utils/logger';
import { FxSkeltonRightInfoCard } from '../Cards/FxSkelton';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { ExternalAccountInfoCard } from './ExternalAccountInfoCard';
import { ExternalAccountValidationStatus } from './ExternalAccountValidationStatus';

Logger.debug("AccountConfigurationsCard.tsx", "Account Configuration initializing");

/**
 * Component: ExternalAccountCard
 * Usage: To display external account profile cards
 */
export const ExternalAccountCard: React.FC<any> = React.memo((props) => {
        let context: any;
        let account_info: any = {};
        const dispatch = useDispatch();
        //check if the user is on mobile device for responsive content
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        let accountNumber: any = '';
        ({ context, props } = RegisterComponent(props));
        /**
         * Check if external account data available
         */
        if (context && context.data && context.data.body) {
            account_info = context.data.body;
            if (account_info?.card?.cardNumberLast4) {
                accountNumber = account_info.card.cardNumberLast4
            } else if (account_info?.accountNumberLast4) {
                accountNumber = account_info.accountNumberLast4
            }
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'External Account ** ' + accountNumber,backButton:'/externalaccounts' } }});
        }

        /**
         * Method to rest the component on unmount.so that when redirect back page it will call the api again
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid container className={"fx-container"}>
                <Grid container xs={12} alignItems="flex-start" justifyContent="space-between">
                    <Grid container xs={12} sm={8} className="fx-container-left" >
                        {dataLoadStatus ?
                            (context?.data?.body && <ExternalAccountInfoCard id="external-account-details-info-card" data={account_info} />)
                            :
                            <div className={'fx-margin-bottom-2 fx-width-100'}>
                                <FxSkeltonList height="25rem" />
                            </div>
                        }
                        {isSmallScreen &&
                            (dataLoadStatus ?
                                    <ExternalAccountValidationStatus id="external-account-validation-status-card" data={account_info} />
                                    :
                                    <FxSkeltonRightInfoCard height="10rem" />
                            )
                        }
                    </Grid>
                    {!isSmallScreen && <Grid container xs={12} sm={4} className="fx-container-right">
                        {dataLoadStatus ?
                            <ExternalAccountValidationStatus id="external-account-validation-status-card" data={account_info} />
                            :
                            <FxSkeltonRightInfoCard height="10rem" />
                        }
                    </Grid>}
                </Grid>
            </Grid>
        );
    })