/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';

//icons
import { Logger } from '../../../libs/utils/logger';
import { toTitleCase } from '../../../libs/utils/string';
import { FxButton } from '../../Action/FxButton';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardHeader from '../../Container/FxCardHeader';
import { FxDelete } from '../../Data/FxDelete';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxRoutingCodeLabel } from '../../Input/FxSwiftCode/FxRoutingCodeLabel';


Logger.debug("InternationalExternalAccountInfoCard.tsx", "External Account InfoCard initializing");
/**
 * Component: International ExternalAccountInfoCard
 * Usage: To display international external account information
 */

export const InternationalExternalAccountInfoCard: React.FC<any> = React.memo(
    (props) => {
        const history = useHistory();
        const options: any = [];
        let edit: boolean = true;

        let international_external_account_data: any;
        /**
         * Transform data
         */
        if (props?.data) {
            try {
                international_external_account_data = { ...props.data }
                international_external_account_data.createdInfo = (props.data['createdBy']['userName'] + '\nOn ' + moment(props.data['createdOn']).format('MM/DD/YYYY'));
                international_external_account_data.updatedInfo = (props.data['lastUpdatedBy']['userName'] + '\nOn ' + moment(props.data['lastUpdatedOn']).format('MM/DD/YYYY'));
                if (props.data.card?.cardNumberLast4) {
                    international_external_account_data.accountNumber = '** ' + props.data.card.cardNumberLast4;
                    international_external_account_data.accountNumberTitle = '** ' + props.data.card.cardNumberLast4;
                } else if (props.data?.accountNumberLast4) {
                    international_external_account_data.accountNumber = '** ' + props.data.accountNumberLast4;
                    international_external_account_data.accountNumberTitle = '** ' + props.data.accountNumberLast4
                }
                international_external_account_data.holderType = props.data?.holderType ? toTitleCase(props.data.holderType) : '';
                international_external_account_data.bankAccountType = props.data?.type ? toTitleCase(props.data.type) : '';
                if (international_external_account_data?.routingNumber && international_external_account_data?.bankInfo) {
                    international_external_account_data.routingNumberInfo = international_external_account_data.routingNumber + ' (' + international_external_account_data.bankInfo.name + ')';
                }
                international_external_account_data.purpose = props.data?.purpose ? props.data.purpose : '';
               if (props.data['status'] === 'BLOCKED') {
                    edit = false;
                }
            } catch (e) {
                console.log(e)
            }
        }

        const swiftCode = useMemo(() => {
            if (international_external_account_data?.swiftCode) {
                return international_external_account_data?.swiftCode;
            }
        },[international_external_account_data?.swiftCode]);

        const exteranl_account_section_card_data = [
            {
                label: 'Card Number',
                value: 'card.cardNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Brand',
                value: 'card.brand'
            },
            {
                label: 'Holder Name',
                value: 'card.holderName'
            },
        ]
        const external_account_bank_section_left_one = [
            {
                label: 'Account Type',
                value: 'bankAccountType'
            },
            {
                label: 'Holder Type',
                value: 'holderType'
            },
        ];
        const external_account_bank_section_left_two = [
            {
                label: 'Account Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'International Bank Account Number',
                value: 'accountNumber'
            },
            {
                label: 'Swift Code',
                value: 'swiftCode'
            }

        ];
        const external_account_bank_section_left_three = [
            {
                label: 'Created',
                value: 'createdInfo'
            }

        ];
        const external_account_bank_section_right_one = [
            {
                label: 'Account Holder Name',
                value: 'holderName'
            },
            {
                label: 'Account Holder Address',
                value: 'holderAddress',
                type: 'fri://fieldtype/address'
            }
        ];
        const external_account_bank_section_right_two = [
            {
                label: (swiftCode && <FxRoutingCodeLabel defaultValue={swiftCode} />) || '',
                value: 'internationalRoutingCode'
            },
            {
                label: 'Accepted Currency',
                value: 'acceptedCurrency',
                type: 'fri://fieldtype/acceptedCurrency'
            },
            {
                label: 'Purpose',
                value: 'purpose'
            }
        ];
        const external_account_bank_section_right_three = [
            {
                label: 'Last Updated',
                value: 'updatedInfo'
            }

        ];

        const editPermission = {
            entity:"Other Entities",
            name:"External Account",
            operation:"EDIT"
          }
    
        const deletePermission = {
            entity:"Other Entities",
            name:"External Account",
            operation:"DELETE"
          }


        return (
            <Grid id="interational-external-account-info-Grid " className="fx-margin-bottom-16" item xs={12}>
                <FxCard id="deposit-info-info-card" className="fx-theme-passport">
                    <FxCardHeader id={'interational-external-account-info-card#card-header'}>
                        <Grid id="interational-external-account-Grid2" item container justifyContent="space-between" xs={12} >
                            <Grid id="interational-external-account-Grid3"  >
                                <Typography id="interational-external-account-title-label" variant="h4" >International External Account Information</Typography>
                                <div id="div-grow-title-payees-list" className="fx-flex-grow" />
                            </Grid>
                            <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                              {edit && <Grid item><FxButton id="edit-interational-external-account-button" permissions={editPermission} variant="contained" className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={<EditIcon />} onClick={() => history.push("/internationalexternalaccount/edit/" + props?.data?.id)}>Edit</FxButton></Grid>}
                              {(options.length > 0) && options.map((option: any) => (
                                <Grid item key={option}>
                                    <FxButton title={option.title} id={option.id} variant="contained" permissions={option?.permission} className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={option.icon}>
                                      {option.btnText}
                                    </FxButton>
                                </Grid>
                              ))}
                              <Grid item><FxDelete domId={'delete-interational-externalaccount-menu'} id={props.data['id']} entity={'internationalExternalAccount'} permissions={deletePermission} buttonTitle="Delete" header={"Are you sure you want to Delete this International External Account " + international_external_account_data?.accountNumberTitle} description="You will not be able to recover it" redirectUrl={'/internationalexternalaccounts'}/></Grid>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="international-ea-deposit-info-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                        {/* Info card section */}
                        <Grid container item xs={12} direction="row" >
                            {international_external_account_data && <><Grid item xs={12} direction="column">
                                <FxInfoCard id={"section-info-card-interational-external-account-basic-info-one" + props.data['id']} title="BASIC INFORMATION" leftcolumn={external_account_bank_section_left_one} rightcolumn={external_account_bank_section_right_one} data={international_external_account_data} />
                            </Grid>
                                <Grid item xs={12}>
                                    <FxInfoCard id={"section-info-card-interational-external-account-basic-info-two" + props.data['id']} title="ACCOUNT INFORMATION" leftcolumn={external_account_bank_section_left_two} rightcolumn={external_account_bank_section_right_two}  data={international_external_account_data} />
                                </Grid>
                                <Grid item xs={12}><FxInfoCard id="section-info-card-interational-external-account-basic-info-three" title="AUDIT FIELDS" leftcolumn={external_account_bank_section_left_three} rightcolumn={external_account_bank_section_right_three} data={international_external_account_data} /></Grid>
                            </>}
                            {international_external_account_data?.card && <FxInfoCard id={"section-info-card-deposit-destination" + props.data['id']} title="" column={exteranl_account_section_card_data} data={props?.data} />}
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
