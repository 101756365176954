/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { FxButton } from "../../Action/FxButton";
import { addressFormatter, clean } from "../../Utils/CommonBaseClass";
import HttpClient from "../../../libs/utils/httpClient";
import { getCustomerUrl } from "../../../libs/utils/utils";
import { getKey } from "../../../libs/utils/storageManager";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import FxSnackBar from '../../Utils/fx-snack-bar';
import { ReactComponent as BlockReplaceIcon } from "../../../assets/svg/reissue-icon.svg";
import { ReactComponent as DividerIcon } from "../../../assets/svg/divider-icon.svg";
const httpClient = HttpClient.getClient();

Logger.debug("BlockAndReplaceDebitCard.tsx", "Block And Replace Debit Card initializing");
/**
 * This component handles the BlockAndReplace action of the Debit Card
 */
export const BlockAndReplaceDebitCard: React.FC<any> = React.memo((props) => {
  const { register, handleSubmit, control, setValue,  formState: { errors, isSubmitting }  } = useForm();
  const [customeData, setCustomeData] = React.useState<{value: string, label: string}[]>();

  const { debitCardId, data, setSuccess, setTitle, setDescription, setBtnText } = props.metadata

  useEffect(() => {
    const addressOptions = {
        value: data?.shippingDetail?.address?.id,
        label: addressFormatter("", data?.shippingDetail?.address)
    }
    setCustomeData([addressOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Async function handles the form submission
   */
  async function onSubmit(data: any) {

    const request = {
      "reason": data.reason,
      "comment": data.comment,
      "shippingDetails": {
        "address": {
            "id": data.address
        }
    }
    };

    const status:any = await replaceDebitCard(clean(request));

    if(status.status){
      setTitle('Request Sent') 
      setDescription('Your Request to block & replace is placed successfully.');
      setSuccess(true);
      setBtnText('Go to debit card listing');
    } else {
      //api  failed
      FxSnackBar.show({
          text: status.message,
      });
    }
    props.onClose();
  }

  /**
   * Method to call api for Replacing Debit Card
   * @param payloadData : request payload
   */
  async function replaceDebitCard(payloadData: any) {
    try {
      const url =
        "/account/id/" +
        getKey("selectedAccount")?.id +
        "/debitCard/id/" +
        debitCardId +
        "/replace";
      const data: any = await httpClient
        .post(getCustomerUrl(url, false), payloadData)
      return data;
    } catch (err) {
      Logger.error("BlockAndReplaceDebitCard.tsx", "error", err);
      return err;
    }
  }

  // replace options
  const replaceOptions = [
    { label: "Card Lost", value: "CARD_LOST" },
    { label: "Card Stolen", value: "CARD_STOLEN" },
    {
      label: "Suspected Fraudulent Activity",
      value: "SUSPECTED_FRAUDULENT_ACTIVITY",
    }
  ];

  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FxCard className='fx-replace-debit-card-modal'>
          <FxCardHeader id="replace-debit-card-header" className="fx-card-header">
            <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                <Grid item container xs={12} justifyContent="center">
                  <BlockReplaceIcon />
                </Grid>
                <Grid item xs={12}>
                    <Typography id="fx-replace-debit-card-modal-title" align="center" className="fx-replace-debit-card-modal-title" >
                      Block & Replace
                    </Typography>
                </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="replace-debit-card-modal-body" className="fx-info-card">
            <Grid item xs={12} className="fx-modal-form flex column">
              <Typography id="title-delete-modal" variant="h5" className="fx-replace-debit-card-modal-subtext">
                If your card is lost, stolen or you suspect fraudulent activity, you can report it here. By taking this action, your card will be immediately cancelled and a new card will be issued with different card number.
              </Typography>
            </Grid>
            <Grid container className="fx-modal-form flex column">
              <Grid container xs={12} sm={12}>
                <FxMaterialSelect
                  register={{ ...register("reason") }}
                  className={errors.reason ? "border-error-input fx-input-edit" : "fx-input-edit" }
                  rules={{ required: true }}
                  control={control}
                  id="replace-debit-card-reason"
                  name="reason"
                  data={replaceOptions}
                  label="Reason*"
                  setValue={setValue}
                  value={''}
                />
              </Grid>
              <Grid container xs={12} sm={12}>
              <FxMaterialSelect register={{ ...register("address") }} control={control}
                  rules={{ required: true }} className={errors.address ? "border-error-input fx-input-edit" : "fx-input-edit" } id="fx-debit-card-replacement-address" name="address" value={ ''} data={customeData} label="Shipping Address*" setValue={setValue} />
              </Grid>
              <Grid container xs={12} sm={12}>
                <FxTextEdit
                  register={{ ...register("comment") }}
                  type="text"
                  passwordIcon={true}
                  className="fx-input-edit"
                  control={control}
                  rules={{ required: false }}
                  id="fx-debit-card-replacement-comment"
                  label={"Comment"}
                  name="comment"
                  variant="outlined"
                />
              </Grid>
              <Typography
                id="title-replace-debit-card-modal"
                variant="h5"
                className="fx-replace-debit-card-modal-note"
              >
                Note: On replacement of card, you will be charged card replacement fee if applicable as per your passport account agreement. Replacement card will be delivered within 4-7 days.
              </Typography>
              <span className="fx-replace-debit-card-modal-divider">
                <DividerIcon />
              </span>
            </Grid>
          </FxCardBody>
          <FxCardFooter id="replace-debit-card-modal-Footer" className="fx-footer">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              className="fx-modal-footer"
            >
              <Button
                variant="contained"
                className="fx-button fx-button-cancel"
                id="cancel-button"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <span className="fx-padding-right-16" />
              <FxButton
                disableRipple={false}
                className="fx-button fx-button-theme"
                id={"replace-debit-card-submit-button"}
                type="submit"
                isSubmitting={isSubmitting}
              >
                SUBMIT
              </FxButton>
            </Grid>
          </FxCardFooter>
        </FxCard>
      </form>
    </Grid>
  );
});
