/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/**
 * Component handles the display of the MFA preference card in the user profile
 */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { Method, callAPI, clean, updateComponentParams } from '../../Utils/CommonBaseClass';
import { FxSwitch } from '../../Action/FxSwitch';
import { processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';


export const CustomerOtpMfaSettings: React.FC<any> = React.memo((props: any) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch()

    /**
     * Useeffect hook checks transaction mfa status and updates it in the field value
     **/  
    useEffect(() => {
      if(context?.data?.body){
          const value = !!context?.data?.body?.preference?.mfaPreference?.enableForTransaction;
          updateComponentParams(dispatch, props.id, { enableForTransaction: value});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[context?.data?.body])

    /**
     * Method handles the enabling and disabling of the transaction MFA
     * @param value : switch value
     */
    const handleTransactionSwitch = async (value: any) => {
        updateComponentParams(dispatch, props.id, { txnSwitchDisabled: true });
        const url = '/user/preference';
        const req = {
            mfaPreference:{
                enableForTransaction: value
            }
        }
        const response = await callAPI(url, Method.POST, clean(req))
        const status = processAPIResponse(response)
        if (status.status) {
            // otp send success
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }
        else {
            //otp send failed
            FxSnackBar.show({
            text: status.message,
            });
        }
        updateComponentParams(dispatch, props.id, { txnSwitchDisabled: false })

    }


    return (
        <Grid id="customer-otp-settings-grid" item xs={12} className='fx-customer-otp-settings'>
            <FxCard id="customer-otp-settings-card" className="fx-theme-passport">
                <FxCardHeader id={'customer-otp-settings-card-header'} >
                    <Grid item container justifyContent="" xs={12} alignItems="center">
                        <Typography id="customer-otp-settings-card-title" className='fx-customer-otp-settings-title'>MFA Preference</Typography>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="customer-otp-settings-card-body" className="fx-info-card fx-margin-top-reducer">
                    <Grid item container direction="row" key="password-updated" >
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item>&nbsp;</Grid>
                        <Grid item container justifyContent={'space-between'}>
                            <Grid item>
                                <Typography className='fx-customer-otp-settings-label'>MFA for Transactions</Typography>
                            </Grid>
                            <Grid item className='fx-customer-otp-settings-switch-grid'>
                                <FxSwitch id="customer-otp-settings-transactions-switch"
                                          defaultChecked={props.enableForTransaction}
                                          onClick={handleTransactionSwitch}
                                          isThin={true}
                                          noTick={true}
                                          disabled={props.txnSwitchDisabled}/>
                            </Grid> 
                        </Grid>
                    </Grid>
                </FxCardBody>
            </FxCard>
        </Grid>
    )
})