/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { IFilterColumns, IFilterFields, IDataGridProps } from '../../../types/common.interfaces';
import { IPrimaryFilterData } from '../../Data/FxPrimaryFilter';
import { checkPermission, maskAccountNumber, truncateChars, updateComponentParams } from '../../Utils/CommonBaseClass';
import { Filter } from '../../Data/Filter';
import { Logger } from '../../../libs/utils/logger';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { FxPrimaryFilter } from '../../Data/FxPrimaryFilter';
import { FxDivider } from '../../Data/FxDivider';
import { useMediaQuery } from 'react-responsive'
import { FxButton } from '../../Action/FxButton';
import { useDispatch } from 'react-redux';
import { FxButtonGroup } from '../../Action/FxButtonGroup';
import { FxGridList } from '../../Data/FxGridList';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import FxCardFooter from '../../Container/FxCardFooter';

/**
 * Component: InternationalExternalAccountList
 * Usage: For international external accounts listing
 */
export const InternationalExternalAccountList: React.FC<any> = React.memo(() => {
  const dispatch = useDispatch();

  const addPermission = {
    entity:"Other Entities",
    name:"International External Account",
    operation:"CREATE"
  }

  const viewPermission = {
    entity:"Other Entities",
    name:"International External Account",
    operation:"VIEW"
  }

  /**
  * useeffect to load title while loading page
  */
  useEffect(() => {
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'International External Accounts' } } });
  }, [dispatch]);

  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => () => {
    updateComponentParams(dispatch, 'international-external-accounts-filter', { resetFilter: true });
  }, [dispatch]);

  //API for international external accounts list
  const src = {
    url: "/internationalExternalAccount/list",
    method: "POST",
    data: {
      "pageNumber": 1,
      "pageSize": 25,
      "criteria": {
        "filters": []
      }
    }
  }

  // IEA list columns
  const column: IFilterColumns = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "label": "ID",
        "enable": true,
        "fieldName": "id",
        "type": "fri://fieldtype/default",
        "default": true
      },
      {
        "label": "STATUS",
        "enable": true,
        "fieldName": "status",
        "type": "fri://fieldtype/status",
        "default": true
      },
      {
        "label": "Account Holder",
        "enable": true,
        "fieldName": "holderName",
        "type": "fri://fieldtype/acceptedCurrency",
        "default": true
      },
      {
        "label": "ACCOUNT #",
        "enable": true,
        "fieldName": "accountNumberLast4",
        "type": "fri://fieldtype/accountnumber",
        "default": true
      },
      {
        "label": "Created On",
        "enable": true,
        "fieldName": "createdOn",
        "type": "fri://fieldtype/date",
        "default": true
      },
      {
        "label": "Last Updated On",
        "enable": true,
        "fieldName": "lastUpdatedOn",
        "type": "fri://fieldtype/date",
        "default": true
      },
      {
        "label": "Last Updated By",
        "enable": true,
        "fieldName": "lastUpdatedBy",
        "type": "fri://fieldtype/default",
        "default": true
      }
    ]
  };

  const gridColumn: any = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "enable": true,
        "fieldName": "description",
        "type": "fri://fieldtype/custom",
        "xs": "12",
        "alignItems": 'center'
      },
      {
        "enable": true,
        "fieldName": "divider",
        "type": "fri://fieldtype/custom",
        "xs": "12",
      }

    ]
  }
  
  const history = useHistory()
  function redirectToCreatePage() {
    history.push('/internationalexternalaccount/add');
  }

  /**
   * Method to redirect to the international external account details page
   * @param event :event object
   * @param data : external account data
   */
  function redirectDetailsPage(event: any, data: any) {
    if(checkPermission(viewPermission)){
      history.push(`/internationalexternalaccount/view/${data.id}`);
    }
  }
  //datagrid props
  const datagridProps: IDataGridProps = {
    id: "international-external-accounts-card-external-account-list",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };
  // filter fields
  const filterFields: IFilterFields = {
    "fields": [
      {
        "label": "Status",
        "fieldName": "status",
        "type": "fri://fieldtype/checkbox",
        "data": [
          { label: "Inactive", value: "INACTIVE", "checked": false },
          { label: "Active", value: "ACTIVE", "checked": false },
          { label: "Blocked", value: "BLOCKED", "checked": false }
        ],
      },
      {
        "label": "Created On",
        "toLabel": "To",
        "fieldName": "createdOn",
        "type": "fri://fieldtype/daterange",
        "data": [],
        "defaultValue": false
      },
      {
        "label": "Last Updated On",
        "toLabel": "To",
        "fieldName": "lastUpdatedOn",
        "type": "fri://fieldtype/daterange",
        "data": [],
        "defaultValue": false

      }
    ]
  }
  const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
  /**
   * Method to transform data
   * @param data : int external account data
   */
  function transormEAList(data: any) {
    try {
      return data?.map((item: any) => {
        return {
          ...item,
          accountNumberLast4: maskAccountNumber(item?.accountNumberLast4, false),
          lastUpdatedBy: item.lastUpdatedBy?.username,
        };
      });
    }
    catch (e) {
      Logger.error("InterationalExternalAccountList.tsx", "error", e);
      return false;
    }
  }

  /**
   * Method to transform data
   * @param data : int external account data
   */
  function transormEAListGridTransformer(data: any) { 
    try{          
      const transformedData = data?.map((item: any, i: number) => {
        const row = {
          id:item.id,
          description:(
            <Grid item container xs={12} className='fx-grid-list-content'>
              <Grid item container xs={12} alignItems='center'>
                <Grid item container xs={12}>
                  <Grid item>
                    <Grid>
                      <Typography variant='h4'>ID: {item?.id}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs>
                    <Grid item xs>
                      <Grid item container justifyContent="flex-end">
                        <FxStatus id="international-external-account-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item>
                    <Typography variant='h4'>{maskAccountNumber(item?.accountNumberLast4, false)}</Typography>
                  </Grid>
                  <Grid item container xs>
                    <Grid item xs>
                      <Grid item container justifyContent="flex-end"><Typography className='fx-customer-info-label'>{truncateChars(item?.holderName, 25)}</Typography></Grid>
                    </Grid>
                  </Grid>              
                </Grid>
              </Grid>
          </Grid>
          ),
          divider:(
            <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
              <Grid item xs>
                <Divider />
              </Grid>
          </Grid>
          )
        };       

        return row;
      });  

      return transformedData || [];
    }catch (e) {
      Logger.error("InterationalExternalAccountList.tsx", "error", e);
      return false;
    }        
   }

  //primary filters data
  const primaryFilterData: IPrimaryFilterData[] = [
    { label: 'ID', value: 'id', operator: 'eq' },
    { label: 'Holder Name', value: 'holderName', operator: 'like' }
  ];

  //type of EA
  const typeData = [
    { id: 'domestic', value: 'DOMESTIC', label: 'DOMESTIC', selected: false},
    { id: 'international', value: 'INTERNATIONAL', label: 'INTERNATIONAL', selected: true }
  ];


   // Method handles the onchange of the ea type
 const handleTypeChange = (item: any) => {
  try {
      if (item.value && item.value === 'DOMESTIC') {
            history.push('/externalaccounts');
      }
  }
  catch (e) {
    Logger.error("InterationalExternalAccountList.tsx", "error", e);
   }
}

  return (
      <Grid id="international-external-accounts-grid" item xs={12}>
        <Grid item xs={12} className="fx-flex-grow fx-ea-type-button-mobile" >
        <FxButtonGroup id="international-external-accounts-card-button-group" options={typeData} onClick={handleTypeChange} />
      </Grid>
      <Grid item xs={12} >&nbsp;</Grid>
      <Grid container xs={12}>
        <FxCard id="international-external-accounts-card" className="fx-theme-passport">
          <FxCardHeader id="international-external-accounts-card-header" className="fx-card-header">
            <Grid id="international-external-accounts-card-header-first-grid" item container justifyContent="space-between" xs={12} >
              <Grid>
                 <Grid item>
                   <FxPrimaryFilter id="international-external-accounts-primary-filter" permissions={viewPermission} name="externals-primary-filter" data={primaryFilterData} dataGridProps={datagridProps} />
                 </Grid>
              </Grid>
              <Grid id="international-external-accounts-filter-grid" item container spacing={2} className="fx-action-block fx-inline-flex">
                <Grid item>
                  <Filter id="international-external-accounts-filter" permissions={viewPermission} field={filterFields} dataGridProps={datagridProps} />
                </Grid>
                <Grid item className="fx-action-block-divider">
                  <FxDivider />
                </Grid>
                <Grid item>
                  <FxButton id="international-external-accounts-add-button" permissions={addPermission} variant="contained" className="fx-button fx-button-theme fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} >Add New</FxButton>
                </Grid>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="international-external-accounts-card-body" className="fx-card-body-no-border-no-shadow">
            <Grid id="international-external-accounts-card-body-grid" item xs={12}>
              {!isSmallScreen ? <DataGrid id="international-external-accounts-card-external-account-list" permissions={viewPermission} rowClick={redirectDetailsPage} tableClass="fx-table-passport" dataTransformer={transormEAList} source={src} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                : <FxGridList id="international-external-accounts-card-external-account-list" source={src} permissions={viewPermission} rowClick={redirectDetailsPage} dataTransformer={transormEAListGridTransformer} column={gridColumn} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />}
            </Grid>
          </FxCardBody>
          <FxCardFooter id="international-external-accounts-list-card-footer">
          </FxCardFooter>
        </FxCard>
      </Grid>
    </Grid>
  )
})
