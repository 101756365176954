/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCardBody from '../../Container/FxCardBody';
import { ReactComponent as LeftDownArrow } from '../../../assets/svg/arrow-left-downbig.svg'
import { ReactComponent as RightUpArrow } from '../../../assets/svg/arrow-right-topbig.svg'
import { getKey } from '../../../libs/utils/storageManager';
import { FxPaymentModeStatus } from '../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxGridList } from '../../Data/FxGridList';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import moment from 'moment';
import { ReactComponent as LightningIcon } from '../../../assets/svg/lightning-icon.svg';
import { truncateChars } from '../../Utils/CommonBaseClass';
import { IFilterColumns } from '../../../types/common.interfaces';
import { getBatchId } from '../../Utils/batch';
import { useIsMobileScreen } from '../../../libs/utils/utils';

/**
 * This component handles the list of Card Transaction List
 */
export const CardTransactionListing: React.FC<any> = React.memo(
    (props) => {
        const isSmallScreen = useIsMobileScreen();

        let src: any = {
            url: "cardEvent/list",
            method: "POST",
            data: {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                    "sortBy": "lastUpdatedOn",
                    "sortOrder": "desc"
                },
                "criteria": {
                    "filters": [
                        {
                            "operator": "eq",
                            "key": "trimmedBatchId",
                            "values": [props.batchId]
                        }
                    ]
                }
            }
        }
        if(!getKey("selectedAccount")?.id){
            src = {
                url: "",
                method: "POST",
                data: {}
            }
        }
        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "icon",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem", "paddingLeft": "0 !important" },
                    "dataStyle": { "textAlign": "left !important" }
                },
                {
                    "label": "DATE",
                    "enable": true,
                    "fieldName": "transactionDate",
                    "type": "fri://fieldtype/date",
                    "default": true,
                    "style": { "width": "15rem" }
                },
                {
                    "label": "TYPE",
                    "enable": true,
                    "fieldName": "type",
                    "type": "fri://fieldtype/default",
                    "default": true,
                    "style": { "width": "15rem" }
                },
                {
                    "label": "AUTH CODE",
                    "enable": true,
                    "fieldName": "authCode",
                    "type": "fri://fieldtype/default",
                    "default": true,
                    "style": { "width": "15rem" }
                },
                {
                    "label": "STATUS",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true,
                    "style": { "width": "10rem" }
                },
                {
                    "label": "AMOUNT",
                    "enable": true,
                    "fieldName": "amount",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": {"text-align": "end","width": "10rem"}
                },
                {
                    "enable": true,
                    "fieldName": "spacer",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "10rem"}
                },
                {
                    "label": "LAST UPDATED ON",
                    "enable": true,
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/date",
                    "default": true,
                    "style": {"width": "20rem"}
                }
            ]
        }
        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }

            ]
        }

        /**
         * Method handles the transformation of Card Transaction List
         * @param data : Transaction List
         * @returns : transformed data
         */
        function transactionList(data: any) {
            return data?.map((item: any) => {
                const newData: any = { ...item };
                newData.transactionDate = item.transactionDate;
                newData.status = item.status;
                newData.type = item.type;
                newData.authCode = item.authCode;
                newData.batchId = item.batchId;
                newData.statusReason = <><span title={item?.statusReason}>{` ${item.statusReason ? truncateChars(item?.statusReason, 30) : ''}`} &nbsp;</span></>
                newData.amount = <FxCurrencyView className={"balance-amount-green"} justifyContent="flex-end" value={item.amount} prefix={"$"}/> 
                newData.icon =
                  item?.type === "SALE" ? (
                    <div className={"fx-passport-debit-credit-svg"}>  <LeftDownArrow />  </div>
                  ) : (
                    <div className={"fx-passport-debit-credit-svg"}>  <RightUpArrow /> </div>
                  );
        
                return newData;
            });
        }

         /**
         * @param data : Transaction List
         * @returns : transformed data
         */
        const transactionListGridTransformer = (data: any) => {
            const newData: any = [];
            let cur_date: any = '';
             data?.forEach((item: any,i:number) => {
                newData[i] = { ...data[i] }
                let title: any
                if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
                    cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                    title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                }
                if (!title) {
                    newData[i].divider = true
                }
                if (title) {
                    newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
                }
                 newData[i].description = <Grid item container xs={12} className='fx-grid-list-content'>
                     <Grid item container xs={2} alignItems='center' justifyContent={'flex-start'}>
                         {item.source?.account?.id ? <Grid item container xs={12} ><Grid className={"fx-passport-debit-credit-svg"} ><RightUpArrow /></Grid></Grid> :
                             <Grid item container xs={12} ><Grid className={"fx-passport-debit-credit-svg"} ><LeftDownArrow /></Grid></Grid>}
                     </Grid>
                     <Grid item container xs={10} alignItems='center'>
                         <Grid item container xs={12}>
                             <Grid item>
                                 <Grid title={item?.purpose}><Typography variant='h3'>{truncateChars(item?.purpose, 25)}&nbsp;</Typography></Grid>
                             </Grid>
                             <Grid item>
                                 &nbsp;<FxPaymentModeStatus tooltip={true} id={'transaction-method'} value={item?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>
                             </Grid>
                             {item.processingDetail?.quickSettle && 
                                <Grid>
                                    <span className='fx-quick-settle-icon'>
                                        <LightningIcon />
                                    </span>
                                </Grid>}
                             <Grid item container xs justifyContent='end'>
                                 {item.source?.account?.id && <Grid item xs>
                                     <Grid><FxCurrencyView value={item?.amount} justifyContent="flex-end" prefix={"$"} showMinus /></Grid>
                                 </Grid>}
                                 {item.destination?.account?.id && <Grid item xs>
                                     <Grid><FxCurrencyView value={item?.amount} justifyContent="flex-end" prefix={"$"} showPlus /></Grid>
                                 </Grid>}
                             </Grid>
                         </Grid>
                         <Grid item container xs={12} spacing={1} alignItems='center'>
                             <Grid item>
                                 <Grid><Typography variant='h5'>Txn ID: {item?.id}</Typography></Grid>
                             </Grid>
                             {
                               (item?.processingDetail?.batchId || item?.processingDetail?.trimmedBatchId)  && 
                                <Grid item>
                                    <Grid><Typography variant='h5'>Batch ID: {getBatchId(item?.processingDetail)} </Typography></Grid>
                                </Grid>
                             }
                             <Grid item>
                                 <FxStatus id="transaction-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                             </Grid>
                         </Grid>
                     </Grid>
                 </Grid>
            })
            return newData;
        }


        const permissionArray: any = [
            {
                entity: "Accounts",
                name: "Card Collect",
                operation: "View"
            }
        ]

        return (

            <Grid id="card-transactions-list-first-grid" item xs={12}>
                <Grid container xs={12} className="fx-layout-list" >
                    <FxCardBody id="batch-card-transactions-list-card-body-2" className="fx-card-body-no-border-no-shadow">
                        <Grid id="batch-card-transactions-list-card-body-grid" item xs={12}>
                        {!isSmallScreen ? <DataGrid id="card-transaction-list-schedule-1" multiplePermission={true} permissions={permissionArray} tableClass="fx-table-passport" dataTransformer={transactionList} source={src} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                        : <FxGridList id="card-transaction-list-schedule-1" source={src} permissions={permissionArray} dataTransformer={transactionListGridTransformer} column={gridColumn} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25}/> }
                        </Grid>
                    </FxCardBody>
                </Grid>
            </Grid>
        )
    })
