/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AgreementsEnum, ISource } from '../../../types/common.interfaces';
import { clean, renderError, updateComponentParams } from '../../Utils/CommonBaseClass';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { FxButton } from '../../Action/FxButton';
import { merchantSettlementAccountListDataTransformation } from '../Schedules/ScheduleGlobalFunctions';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxAutoComplete } from '../../Input/FxAutoComplete/FxAutoComplete';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import { FxLink } from '../../Action/FxLink';
import FxRadioEdit from '../../Input/FxRadio/FxRadioEdit';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import DEFAULT_CONFIG from '../../../configs/default.json'

const httpClient = HttpClient.getClient();
const httpClientExt = HttpClient.externalUrl({ responseType: 'blob' });
type MethodType = 'ecom' | 'moto';


Logger.debug("AddMerchant.tsx", "Add merchant initializing");
/**
 * Component: AddMerchant
 * Usage: Adding Merchant
 */

export const AddMerchant: React.FC<any> = React.memo((props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control, resetField, watch } = useForm();

        const [fileInfo, setFileInfo] = useState<any>();
        /**
         * getting the merchant agreements & the merchant rate card from the merchantAgreementConfigurations
         */
        const merchantAgreementConfigurations = getKey('merchantAgreementConfigurations') || [];
        const merchantAgreement = merchantAgreementConfigurations.find((config: any) => config.name === AgreementsEnum.MERCHANT_AGREEMENT)?.value || '';
        const merchantRateCard = merchantAgreementConfigurations.find((config: any) => config.name === AgreementsEnum.MERCHANT_RATE_CARD)?.value || '';

        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch,  props.id, { processApi: false, category: '', categories: [], showSubCategory: false, ecom: '', pos: '', moto: '', monthly: '', yearly: '', onetime: '', showInfo: false, showEcomPayments: false, showPosPayments: false, showMotoPayments: false, showMonthlySummary: false, showYearlySummary: false, showOnetimeSummary: false, showSpecificTypeField: false, btnDisabled: true, showDischargeDate: false})
            return () => {
                dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });  
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        /**
         * Useeffect to call the GET api to get the PDF data and convert it to base64
         */
        useEffect(() => {
            const fetchPdf = async () => {
                try {
                    const base64Pdf = await getPdfFile();
                    setFileInfo(base64Pdf);
                } catch (err) {
                   Logger.error("AddMerchant.tsx", "fetchPdf", "error", err);
                   return false;
                }
            };
            fetchPdf();
        }, []);

        const acc_src: ISource = {
            url: "account/list",
            method: "POST",
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "sortOptions": {
                    "sortOrder": "DESC",
                    "sortBy": "createdOn"
                },
                "criteria": {
                    "filters": [
                        {
                            "key": "status",
                            "operator": "nin",
                            "values": [
                                "INACTIVE"
                            ]
                        }
                    ]
                }
            }
        };

        const [isLoading, setIsLoading] = useState(false);
        const submitButton ='Add Merchant';
        const isEdit = !!props?.source;
        const [category, setCategory] = useState('');
        const [categories, setCategories] = useState<any>();
 
        const deliveryTimeData = [
                            { value: 'WEEK', label: 'Week' },
                            { value: 'FORTNIGHT', label: 'Fortnight' },
                            { value: 'MONTH', label: 'Month' },
                            { value: 'TWO_MONTHS', label: '2 Months' },
                            { value: 'OVER_TWO_MONTHS', label: 'Over 2 months' },
        ]

        if (!isEdit && !props?.processApi) {
            updateComponentParams(dispatch,  props.id, { processApi: true })
        }

        const displayLabelValidation = {
            required:  true ,
            maxLength: 40,
            minLength:3
        }

        /**
         * Validations for the field: Specific Type
         */
        const specificFieldValidation = {
            required:  props?.showSpecificTypeField ?? false ,
        }

        /**
         * Method to close the drawer on clicking cancel button
         */
        const handleClose = (event:any) => {
            props?.onDrawerClose(event, { });
        };

        /**
         * This method converts the pdf data into base64 format
         */
        async function convertPdfToBase64(pdf: Blob): Promise<string> {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(pdf);
            fileReader.onload = () => {
              const fileInfo = (fileReader.result as string)?.replace(
                "data:application/pdf;base64,",
                ""
              );
              if (fileInfo) {
                resolve(fileInfo);
              } else {
                reject("Failed to convert PDF to Base64");
              }
            };
            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        }

        /**
         * This method calls the GET api to get PDF data and then converts it into base64 format
         */
        const getPdfFile = async () => {
          try {
            const response = await httpClientExt.get(merchantAgreement);
            const base64Pdf = await convertPdfToBase64(response.data);
            return base64Pdf;
          } catch (error) {
            console.error("Error fetching or converting PDF:", error);
            throw error;
          }
        }

        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {

            let req: any = {
                "type": "DIRECT_FUNDED",
                "priorBankruptcy": data['priorBankruptcy'] === 'yes' ? true : false,
                "saleDetails": {
                "averageSalesVolumes": data['averageSalesVolumes'],
                "averagePurchase": data['averagePurchase'],
                "saleMethod": {},
                "productDescription":data['productDescription'],
                "averageDeliveryTime": data['averageDeliveryTime']
                },
                "categoryCode": data['mcCode'],
                "merchantAccount": {
                    "id": data['source'],
                    "type": "ACCOUNT"
                },
                "linkedDocument": [
                    {
                        "purpose": "AUTHORIZATION",
                        "document": {
                            "type": 'MERCHANT_AGREEMENT',
                            "name": 'MERCHANTAGGREMENT.pdf',
                            "base64encodedContent": fileInfo
                        }
                    }
                ]
            };

            //Adding the ecom/pos/moto nodes only if they are enabled
            if(data['allowEcomMethod']?.[0] === 'yes'){
                req.saleDetails.saleMethod.ecom = data['saleMethodEcom'];
            }
            if(data['allowPosMethod']?.[0] === 'yes'){
                req.saleDetails.saleMethod.pos = data['saleMethodPos'];
            }
            if(data['allowMotoMethod']?.[0] === 'yes'){
                req.saleDetails.saleMethod.moto = data['saleMethodMoto'];
            }
            
            //Adding the dateOfischarge node if priorBankruptcy is set TRUE
            if(data['priorBankruptcy'] === 'yes'){
                req.dateOfDischarge = data['dateOfDischarge']
            }
            if(data['location']){
                req={
                    ...req,
                    "location" : [
                        {
                            "id" : data['location']
                        }
                    ]
                }
            }

            //Adding the nodes for cardNotPresent Use Case
            if(props?.showInfo){
                const billingMethodNodes: any = {};
                if(data['allowMonthlySummary']?.[0] === 'yes'){
                    billingMethodNodes.monthly = data['monthly'];
                }
                if(data['allowYearlySummary']?.[0] === 'yes'){
                    billingMethodNodes.yearly = data['yearly'];
                }
                if(data['allowOnetimeSummary']?.[0] === 'yes'){
                    billingMethodNodes.onetime = data['onetime'];
                }

                const cardNotPresentObj: any = {
                    "internetBusinessType": data['internetBusinessType'],
                    "advertisingMethod": data['advertisingMethod'],
                    "billingMethod": billingMethodNodes,
                }
                if(props?.showSpecificTypeField){
                    cardNotPresentObj['specificType'] = data['specificType']
                }
                req={
                    ...req,
                    "cardNotPresent": clean(cardNotPresentObj)
                }
            }

            setIsLoading(true);
            let status: any = await createMerchant(clean(req));
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                props?.setMerchantCreated(true);
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        /**
         * Method to call api  for create merchant
         * @param payloadData : request payload
         */
        async function createMerchant(payloadData: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('merchant',false), payloadData).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateMerchant.tsx", "error", err);
                return err;
            }
        }

      // Method transforms the categories
      function transformCategories(data: any){
        try{
            setCategories(data);
            const newData: any = [];
            data?.forEach((item: any) => {
                let checkFlag = false;
                newData?.forEach((category: any)=> {
                    if(category['label'] === item?.category){
                        checkFlag = true;
                    }
                })
                if(!checkFlag){
                    const obj = { label: item?.category, value: item?.category };
                    newData.push(obj);
                }
            });
            return newData;
        }
        catch(e){
            Logger.error("AddMerchant.tsx", "transformCategories", "error", e);
        }

      }

      // Method transforms the sub categories
      function transformSubCategories(data: any){
        try{
            const newData: any = [];
            data?.forEach((item: any) => {
                if(item.category === category){
                    const obj = { label: item.code+' ('+item.description+')', value: item.code };
                    newData.push(obj);
                }
            });
            return newData;
        }
        catch(e){
            Logger.error("AddMerchant.tsx", "transformSubCategories", "error", e);
        }

      }
      // Method handles the change event of category
      const handleCategoryChange = (event: any) => {
        try{
            setCategory(event.target.value)
            updateComponentParams(dispatch,  props.id, { showSubCategory: true})
        }
        catch(e){
            Logger.error("AddMerchant.tsx", "handleCategoryChange", "error", e);
        }
      }

      /**
       * This method checks that if the value of the field ECOM and MOTO is greater than 20
       * then make the additional info fields like 'Card not present Questionnare' & 'Billing Summary' visible.
       * Else hide them
       * @param method 
       * @param value 
       */
      const checkEligibilityForAdditionalInfo = (method: MethodType, value: string | number) => {
        const parseValue = (val: any) => val ? parseFloat(val).toFixed(2) : '0.00';
        const ecomValue = parseFloat(parseValue(method === 'ecom' ? value : props?.ecom));
        const motoValue = parseFloat(parseValue(method === 'moto' ? value : props?.moto));
        const totalPercent = ecomValue + motoValue;
        updateComponentParams(dispatch,  props.id, { showInfo: totalPercent > 20 })
      };

        // Method handles the sale methods total percentage condition
        const checkPercentageCondition = (method: any, value: any) => {
            let totalPercent:any = 0;
            if(method === 'ecom'){
                const ecomValue:any = value?parseFloat(value).toFixed(2):0;
                const posValue:any = props?.pos?parseFloat(props?.pos).toFixed(2):0;
                const motoValue:any = props?.moto?parseFloat(props?.moto).toFixed(2):0;
                totalPercent = ecomValue*1+posValue*1+motoValue*1;
            }
            else if(method === 'pos'){
                const ecomValue:any = props?.ecom?parseFloat(props?.ecom).toFixed(2):0;
                const posValue:any = value?parseFloat(value).toFixed(2):0;
                const motoValue:any = props?.moto?parseFloat(props?.moto).toFixed(2):0;
                totalPercent = ecomValue*1+posValue*1+motoValue*1;
            }
            else if(method === 'moto'){
                const ecomValue:any = props?.ecom?parseFloat(props?.ecom).toFixed(2):0;
                const posValue:any = props?.pos?parseFloat(props?.pos).toFixed(2):0;
                const motoValue:any = value?parseFloat(value).toFixed(2):0;
                totalPercent = ecomValue*1+posValue*1+motoValue*1;
            }
            if(totalPercent != 100.00){
                    setError("percentageError", {
                        type: "manual",
                        message: "Total Sale Percentage (pos, moto, ecom) should sum up to 100%",
                    });
                }
                else{
                    clearErrors('percentageError')
                }
        }

        // Method handles the change event for ecom field
        const handleEcomChange = (event: any) => {
                const value = event.target.value;
                setValue('saleMethodEcom',value)
                updateComponentParams(dispatch,  props.id, { ecom: value })
                // setEcom(value)
                checkPercentageCondition('ecom',value)
                checkEligibilityForAdditionalInfo('ecom', value);
                if(value){
                    clearErrors('saleMethodEcom')
                }
                else{
                    setError("saleMethodEcom", {
                        type: "manual",
                        message: "This field is required",
                    });
                }
        }

        // Method handles the change event for pos field
        const handlePosChange = (event: any) => {
                const value = event.target.value;
                updateComponentParams(dispatch,  props.id, { pos: value })
                setValue('saleMethodPos',value)
                checkPercentageCondition('pos',value)
                if(value){
                    clearErrors('saleMethodPos')
                }
                else{
                    setError("saleMethodPos", {
                        type: "manual",
                        message: "This field is required",
                    });
                }
        }

        // Method handles the change event for moto field
        const handleMotoChange = (event: any) => {
                const value = event.target.value;
                setValue('saleMethodMoto',value)
                updateComponentParams(dispatch,  props.id, { moto: value })
                checkPercentageCondition('moto',value)
                checkEligibilityForAdditionalInfo('moto', value);
                if(value){
                    clearErrors('saleMethodMoto')
                }
                else{
                    setError("saleMethodMoto", {
                        type: "manual",
                        message: "This field is required",
                    });
                }
            }

        /**
         * Method to handle form change events when the Payment Method Checkboxes & Billing Summary Checkboxes are checked/unchecked
         * @param event :event object
        */
        const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            const { name, checked } = event.target;
            if (name === 'ProgramGuideTerms') {
                updateComponentParams(dispatch,  props.id, { btnDisabled: (event.target.checked === true ? false : true) })
            }
            if(name === 'allowEcomMethod'){
                updateComponentParams(dispatch,  props.id, { showEcomPayments: !props?.showEcomPayments })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { ecom: null })
                    setValue('saleMethodEcom', null);
                    checkEligibilityForAdditionalInfo('ecom', '');
                    checkPercentageCondition('ecom',null);
                }
            }
            if(name === 'allowPosMethod'){
                updateComponentParams(dispatch,  props.id, { showPosPayments: !props?.showPosPayments })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { pos: null })
                    setValue('saleMethodPos', null);
                    checkPercentageCondition('pos',null)
                }
            }
            if(name === 'allowMotoMethod'){
                updateComponentParams(dispatch,  props.id, { showMotoPayments: !props?.showMotoPayments })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { moto: null })
                    setValue('saleMethodMoto', null);
                    checkEligibilityForAdditionalInfo('moto', '');
                    checkPercentageCondition('moto',null)
                }
            }
            if(name === 'allowMonthlySummary'){
                updateComponentParams(dispatch,  props.id, { showMonthlySummary: !props?.showMonthlySummary })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { monthly: null })
                    setValue('monthly', null);
                    checkBillingSummaryPercentage('monthly',null)
                }
            }
            if(name === 'allowYearlySummary'){
                updateComponentParams(dispatch,  props.id, { showYearlySummary: !props?.showYearlySummary })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { yearly: null })
                    setValue('yearly', null);
                    checkBillingSummaryPercentage('yearly',null)
                }
            }
            if(name === 'allowOnetimeSummary'){
                updateComponentParams(dispatch,  props.id, { showOnetimeSummary: !props?.showOnetimeSummary })
                if(!checked){
                    updateComponentParams(dispatch,  props.id, { onetime: null })
                    setValue('onetime', null);
                    checkBillingSummaryPercentage('onetime',null)
                }
            }
        }

        const merchant_setting_source = {
            url: "/merchant/setting",
            baseUrl:true,
            method: "GET",
            data: {
                "pageNumber": 1,
                "pageSize": 25,
                "criteria": {
                    "filters": []
                }
            }
        }

        const location_src = {
            url: "location/list",
            method: "POST",
            data: {
              pageNumber: 1,
              pageSize: 5,
              criteria: {
                filters: [
                  {
                    key: "status",
                    operator: "eq",
                    values: ["ACTIVE"]
                  },
                ],
              },
            },
        }

        const locationFilterOptions: any = [
        { label: "Doing Business As", value: "doingBusinessAs", operator: "like" },
        ];

        /**
         * Function handles transformation of destination data
         * @param data : destination data array
         * @returns : array
         */
        function destinationTransformer(data: any) {
            return data?.map((item: any) => {
                const { address, doingBusinessAs, id } = item;
                const { addressLine1, addressLine2, city, state, zip } = address;
                const address2String = addressLine2 ? addressLine2 + ', ' : '';
        
                const label = `${doingBusinessAs} (${addressLine1}, ${address2String}${city}, ${state}, ${zip})`;
        
                return {
                    label,
                    value: id,
                };
            }) || [];
        }

        const locationFilter = "doingBusinessAs";

    /**
    Method to view the Program Guide
     */
    const getProgramGuide = useCallback( (event:any) => {
        event.preventDefault();
        window.open(DEFAULT_CONFIG['PROGRAM_GUIDE_URL'], '_blank');
    }, []);


    /**
     * Data set for the Program Guide Labelled Checkbox
     */
    const ProgramGuideTerms = [
        {
            label: (
                <span>
                    By submitting this form, you agree to the <FxLink className={'fx-fee-agreement-link'} onClick={getProgramGuide}><span className='fx-submit-agree-color'>Program Guide</span></FxLink> for the Sponsor Bank, to receiving electronic communication from Priority, and you certify that the information provided is complete and correct.
                </span>),
            value: 'yes'
        }
    ];

    /**
     * Data set for the ECOM Labelled Checkbox
     */
    const allowEcom = [
        {
            label: 'Website (ECOM)',
            value: 'yes'
        }
    ];

    /**
     * Data set for the POS Labelled Checkbox
     */
    const allowPos = [
        {
            label: 'At my store/shop(POS)',
            value: 'yes'
        }
    ];

    /**
     * Data set for the MOTO Labelled Checkbox
     */
    const allowMoto = [
        {
            label: 'Mail order telephone order(MOTO)',
            value: 'yes'
        }
    ];

    /**
     * Data set for the MONTHLY Labelled Checkbox
     */
    const allowMonthly = [
        {
            label: 'Monthly',
            value: 'yes'
        }
    ];

    /**
     * Data set for the YEARLY Labelled Checkbox
     */
    const allowYearly = [
        {
            label: 'Yearly',
            value: 'yes'
        }
    ];

    /**
     * Data set for the ONETIME Labelled Checkbox
     */
    const allowOnetime = [
        {
            label: 'One time',
            value: 'yes'
        }
    ];

    /**
    Method to open Merchant Services Agreement in new window
    */
    const getMerchantServicesAgreement = useCallback( (event:any) => {
        event.preventDefault();
        window.open(merchantAgreement, '_blank');
    }, []);

    /**
    Method to open Merchant Rate Card Agreement in new window
    */
    const getMerchantRateCardAgreement = useCallback( (event:any) => {
        event.preventDefault();
        window.open(merchantRateCard, '_blank');
    }, []);

    /**
     * Dropdown data for the field: Internet Business Type
     */
    const internetBusinessTypeDropdownData = [
        { value: 'ADVERTISEMENT', label: 'Advertisement' },
        { value: 'AUCTION', label: 'Auction' },
        { value: 'DOMAIN_REGISTRATION', label: 'Domain Registration' },
        { value: 'INTERNET_SERVICE_GATEWAY', label: 'Internet Service Gateway' },
        { value: 'SELLING_DIGITAL_GOODS', label: 'Selling Digital Goods' },
        { value: 'SELLING_HARD_GOODS', label: 'Selling Hard Goods' },
        { value: 'SERVICE', label: 'Service' },
        { value: 'WEB_HOSTING', label: 'Web Hosting' },
        { value: 'WEB_PAGE_DESIGN', label: 'Web Page Design' },
        { value: 'OTHER', label: 'Other' },
    ]

    /**
     * Dropdown data for the field: Advertising Method
     */
    const advertisingMethodDropdownData = [
        { value: 'Online', label: 'Online' },
        { value: 'Magazine', label: 'Magazine' },
        { value: 'Flyers/Brochure', label: 'Flyers/Brochure' },
        { value: 'Social Media', label: 'Social Media' },
        { value: 'Not Applicable', label: 'Not Applicable' },
    ];


    /**
     * This method handles the post entering operations of the below mentioned fields
     * @param type 
     * @param event 
     */
    const handleShareChange = (type: string, event: any) => {
        try {
            const value = event.target.value;
            setValue(type, value);

            switch(type) {
                case 'monthly':
                    updateComponentParams(dispatch,  props.id, { monthly: value})
                    break;
                case 'yearly':
                    updateComponentParams(dispatch,  props.id, { yearly: value})
                    break;
                case 'onetime':
                    updateComponentParams(dispatch,  props.id, { onetime: value})
                    break;
                default:
                    break;
            }
    
            checkBillingSummaryPercentage(type, value);
            if (value) {
                clearErrors(type);
            }
        } catch (e) {
            Logger.error("AddMerchant.tsx", `handle${type.charAt(0).toUpperCase() + type.slice(1)}ShareChange`, "error", e);
        }
    };
    
    /**
     * Method handles the change event for Monthly field
     * @param event 
     */
    const handleMonthlyShareChange = (event: any) => handleShareChange('monthly', event);

    /**
     * Method handles the change event for Yearly field
     * @param event 
     */
    const handleYearlyShareChange = (event: any) => handleShareChange('yearly', event);

    /**
     * Method handles the change event for Onetime field
     * @param event 
     */
    const handleOnetimeShareChange = (event: any) => handleShareChange('onetime', event);

    /**
     * This method parses the values of the billing summary fields
     * @param val 
     * @returns 
     */
    const parseValue: any = (val: any) => {
        const num: any = parseFloat(val).toFixed(2);
        return val ? ( num * 1) : 0;
    }

    /**
     * Method handles the sale methods total percentage condition
     * @param method 
     * @param value 
     */
    const checkBillingSummaryPercentage = (method: string, value: any) => {
        const monthlyValue: any = parseValue(method === 'monthly' ? value : props?.monthly);
        const yearlyValue: any = parseValue(method === 'yearly' ? value : props?.yearly);
        const onetimeValue: any = parseValue(method === 'onetime' ? value : props?.onetime);
        const totalPercent = monthlyValue + yearlyValue + onetimeValue;
        if (totalPercent !== 100.00) {
            setError("billingSummaryError", {
                type: "manual",
                message: "Total sum of Monthly, Yearly and One Time transaction should be 100%",
            });
        } else {
            clearErrors('billingSummaryError');
        }
    };

    /**
     * Static Data for the Radio Button Field: Prior Bankruptcy
     */
    const priorBankruptcyRadioOptions = [
        { label: "Yes", value: "yes"},
        { label: "No", value: "no"}
    ];

    /**
     * This method handles the change of the state of the Radio Button: Prior Bankruptcy
     * @param event 
     */
    const handlePriorBankruptcyRadioChange = (event: any) => {
        setValue('priorBankruptcy',event.target.value)
        updateComponentParams(dispatch,  props.id, { showDischargeDate: (event.target.value === 'yes' ? true : false) })
    }

    /**
     * This method hanldes the change of the dropdown field: Internet Business Type
     * and sets showSpecificTypeField as true when 'OTHER' is selected
     * @param e 
     */
    const businessTypeChangeHandler = (e: any) => {
        updateComponentParams(dispatch,  props.id, { showSpecificTypeField: (e?.target.value === 'OTHER' ? true : false) })
    }

    /**
     * This section handles the rendering of the Card Not Present Fields
     * if the sum of MOTO & ECOM is greater than 20%
     * @returns 
     */
    const cardNotPresentFields = () => {
        return (
            <>
                <Grid item xs={12} sm={12}>
                    <Typography className="filter-popover-label">CARD NOT PRESENT QUESTIONNAIRE</Typography>
                </Grid>
                <Grid item xs={12} sm={props?.showSpecificTypeField ? 4 : 6}>
                    <FxMaterialSelect register={{ ...register("internetBusinessType") }} id="add-merchant-form-internet-business-type" name="internetBusinessType"
                        control={control} rules={{ required: true }}  
                        placeholder="Select Internet Business Type"
                        label="Internet Business Type*" setValue={setValue} data={internetBusinessTypeDropdownData} onChange={(e: any) => businessTypeChangeHandler(e)}/>
                </Grid>
                {props?.showSpecificTypeField && <Grid item xs={12} sm={props?.showSpecificTypeField ? 4 : 6}>
                    <FxTextEdit register={{ ...register("specificType") }} className={errors.specificType ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                    rules={specificFieldValidation} control={control} id="add-merchant-form-specific-type-field" label="Specific Type*" placeholder="Input Text"
                    name="specificType" variant="outlined" isEditable={true} />
                </Grid>}
                <Grid item xs={12} sm={props?.showSpecificTypeField ? 4 : 6}>
                    <FxMaterialSelect register={{ ...register("advertisingMethod") }} id="add-merchant-form-advertising-method" name="advertisingMethod"
                        control={control} rules={{ required: true }} placeholder='Select Advertising Method'
                        readOnly={false} label="Advertising Method*" setValue={setValue} data={advertisingMethodDropdownData} />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography  className="filter-popover-label">BILLING SUMMARY*</Typography>
                </Grid>
                <Grid item xs={12} sm={12} className="fx-sales-method-grid">
                    {/** Monthly */}
                    <Grid item xs={12} sm={12} display={'flex'}>
                        <Grid item xs={4} sm={4}>
                            <FxCheckEdit register={{ ...register('allowMonthlySummary') }}
                                rules={{ required: false }} control={control}
                                id="debit-terms-condition" name="allowMonthlySummary"
                                className="fx-privacy-text" data={allowMonthly} row="vertical"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }} setValue={setValue} />
                        </Grid>
                        {props?.showMonthlySummary && <Grid item xs={4} sm={4}>
                        <FxTextEdit register={{ ...register("monthly") }} className={errors.monthly ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                    rules={{required: false}} control={control} id="add-merchant-form-card-billing-summary-monthly-textbox" label="Monthly"
                                    placeholder="Monthly" name="monthly" variant="outlined" isEditable={true} suffix="%" showDecimal={false} setValue={setValue} onChange={handleMonthlyShareChange} value={props?.monthly ?? ''} />
                        </Grid>}
                    </Grid>
                    {/** Yearly */}
                    <Grid item xs={12} sm={12} display={'flex'}>
                        <Grid item xs={4} sm={4}>
                            <FxCheckEdit register={{ ...register('allowYearlySummary') }}
                                rules={{ required: false }} control={control}
                                id="debit-terms-condition" name="allowYearlySummary"
                                className="fx-privacy-text" data={allowYearly} row="vertical"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }} setValue={setValue} />
                        </Grid>
                        {props?.showYearlySummary && <Grid item xs={4} sm={4}>
                        <FxTextEdit register={{ ...register("yearly") }} className={errors.yearly ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                    rules={{required: false}} control={control} id="add-merchant-form-card-billing-summary-monthly-textbox" label="Yearly"
                                    placeholder="Yearly" name="yearly" variant="outlined" isEditable={true} suffix="%" showDecimal={false} setValue={setValue} onChange={handleYearlyShareChange} value={props?.yearly ?? ''}/>
                        </Grid>}
                    </Grid>
                    {/** Onetime */}
                    <Grid item xs={12} sm={12} display={'flex'}>
                        <Grid item xs={4} sm={4}>
                            <FxCheckEdit register={{ ...register('allowOnetimeSummary') }}
                                rules={{ required: false }} control={control}
                                id="debit-terms-condition" name="allowOnetimeSummary"
                                className="fx-privacy-text" data={allowOnetime} row="vertical"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }} setValue={setValue} />
                        </Grid>
                        {props?.showOnetimeSummary && <Grid item xs={4} sm={4}>
                        <FxTextEdit register={{ ...register("onetime") }} className={errors.onetime ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                    rules={{required: false}} control={control} id="add-merchant-form-card-billing-summary-monthly-textbox" label="Onetime"
                                    placeholder="Onetime" name="onetime" variant="outlined" isEditable={true} suffix="%" showDecimal={false} setValue={setValue} onChange={handleOnetimeShareChange} value={props?.onetime ?? ''}/>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className='sales-method-errors-billing-summary'>
                    <div className={'error-message'}>
                        {errors.billingSummaryError && renderError(errors.billingSummaryError)}
                    </div>
                </Grid>
            </>
        )
    }
    
        return (
            <Grid container xs={12} sm={12} justifyContent="space-between" className="fx-container fx-form-grid fx-merhant-create-form-main-grid">
                <Grid id="add-merchant-first-grid" item xs={12}>
                    <Grid id="add-merchant-sub-grid" container spacing={1} >
                        <FxCard id="add-merchant-form-card" className="fx-theme-passport fx-merchant-form-card">
                            <div className="fx-form-edit-profile">
                                {!isEdit && <form id="add-merchant-form" onSubmit={handleSubmit(onSubmit)}>
                                        <FxCardHeader id="add-merchant-form-card-header"></FxCardHeader>
                                        <FxCardBody id="add-merchant-form-card-body" className="fx-info-card fx-margin-top-reducer fx-margin-top-reducer-media" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                    <Grid item xs={12} sm={12} className='fx-merchant-add-form-info-labels' marginBottom={'2rem'}>
                                                        <Typography className="filter-popover-label fx-merchant-add-form-info-labels-text">Unlock the World of Card Payments - Tell us about your Business</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                           <FxMaterialSelect register={{ ...register("industry") }} rules={{ required: true }} className={errors.industry ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="add-merchant-form-industry" name="industry" source={merchant_setting_source}  label="Industry*" placeholder="Please select your industry" setValue={setValue} onChange={handleCategoryChange} dataTransformation={transformCategories}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                           {props?.showSubCategory && <FxMaterialSelect register={{ ...register("mcCode") }} rules={{ required: true }} className={errors.mcCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="add-merchant-form-mccode" name="mcCode" data={transformSubCategories(categories)} label="Merchant Category Code*"  placeholder="Please select" setValue={setValue} />}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("productDescription") }} className={errors.productDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={displayLabelValidation} control={control} id="add-merchant-form-product-description" label="Product Description*" placeholder="Product Description" name="productDescription" variant="outlined" isEditable={true} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} marginTop={"-1.5rem"}>
                                                            <FxLabel className="fx-label-top" id="location-label">Location</FxLabel>
                                                            <FxAutoComplete
                                                                register={{ ...register("location") }}
                                                                control={control}
                                                                rules={{ required: false }}
                                                                id="add-merchant-form-location"
                                                                name="location"
                                                                source={location_src}                        
                                                                searchBy={locationFilter}
                                                                className="fx-input-edit"
                                                                dataTransformer={destinationTransformer}
                                                                setError={setError}
                                                                clearError={clearErrors}
                                                                setValue={setValue}
                                                                resetField={resetField}
                                                                filterOptions={locationFilterOptions}
                                                                placeholder={"Search Location"}
                                                                isEditable={true}
                                                            />
                                                        </Grid>
                                                    
                                                    {/** Prior Bankruptcy */}
                                                    <Grid item xs={12} sm={12} >
                                                        <Typography>Prior Bankruptcy</Typography>
                                                        <FxRadioEdit register={{ ...register("priorBankruptcy") }} control={control} 
                                                        className={'radio-edit'} data={priorBankruptcyRadioOptions} id="add-merchant-form-card-prior-bankruptcy-radio-button" 
                                                        name="priorBankruptcy" defaultValue={'no'} setValue={props?.setValue} 
                                                        onChange={handlePriorBankruptcyRadioChange} />
                                                    </Grid>
                                                    {props?.showDischargeDate && <Grid item xs={12} sm={6} >
                                                        <FxDateEdit register={{ ...register("dateOfDischarge") }} className={errors.dateOfDischarge ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="dateOfDischarge" label="Date of Discharge" name="dateOfDischarge" setValue={setValue} variant="outlined" disableFuture={true} value={watch('dateOfDischarge')}/>
                                                    </Grid>}

                                                    {/** About Your Sales Section */}
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography  className="filter-popover-label" >ABOUT YOUR SALES</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("averageSalesVolumes") }} className={errors.averageSalesVolumes ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                    rules={{ required: true }} control={control} id="add-merchant-form-card-average-sales-volumes-textbox" label="Average Monthly Sales Volume*"
                                                                    placeholder="Average Monthly Sales Volume" name="averageSalesVolumes" variant="outlined"  isEditable={true} prefix="$" showDecimal={true} setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("averagePurchase") }} className={errors.averagePurchase ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                    rules={{ required: true }} control={control} id="add-merchant-form-card-average-purchase-textbox" label="Average Purchase*"
                                                                    placeholder="Average Purchase" name="averagePurchase" variant="outlined"  isEditable={true} prefix="$" showDecimal={true} setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxMaterialSelect register={{ ...register("averageDeliveryTime") }} rules={{ required: true }} className={errors.averageDeliveryTime ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="add-merchant-form-average-delivery-time" name="averageDeliveryTime" data={deliveryTimeData} label="Average Delivery Time*" value="WEEK" readOnly={false} placeholder="Select delivery time" setValue={setValue} />
                                                    </Grid>

                                                    {/** ECOM/POS/MOTO Fields */}
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography  className="filter-popover-label" >HOW DO YOU ACCEPT PAYMENTS?*</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} className="fx-sales-method-grid">
                                                    {/** ECOM */}
                                                    <Grid item xs={12} sm={12} display={'flex'} alignItems={'center'}>
                                                        <Grid item xs={4} sm={4}>
                                                            <FxCheckEdit register={{ ...register('allowEcomMethod') }}
                                                                rules={{ required: false }} control={control}
                                                                id="debit-terms-condition" name="allowEcomMethod"
                                                                className="fx-privacy-text" data={allowEcom} row="vertical"
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                }} setValue={setValue} />
                                                        </Grid>
                                                        {props?.showEcomPayments && <Grid item xs={4} sm={4}>
                                                            <FxTextEdit register={{ ...register("saleMethodEcom") }} className={errors.saleMethodEcom ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                rules={{required: false}} control={control} id="add-merchant-form-card-sale-method-ecom-textbox" placeholder='Website(ECOM)'
                                                                name="saleMethodEcom" variant="outlined" isEditable={true} suffix="%" showDecimal={true} setValue={setValue} onChange={handleEcomChange} value={props?.ecom ?? ''} />
                                                        </Grid>}
                                                    </Grid>
                                                    {/** POS */}
                                                    <Grid item xs={12} sm={12} display={'flex'} alignItems={'center'}>
                                                        <Grid item xs={4} sm={4}>
                                                            <FxCheckEdit register={{ ...register('allowPosMethod') }}
                                                                rules={{ required: false }} control={control}
                                                                id="debit-terms-condition" name="allowPosMethod"
                                                                className="fx-privacy-text" data={allowPos} row="vertical"
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                }} setValue={setValue} />
                                                        </Grid>
                                                        {props?.showPosPayments && <Grid item xs={4} sm={4}>
                                                            <FxTextEdit register={{ ...register("saleMethodPos") }} className={errors.saleMethodPos ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                        rules={{required: false}} control={control} id="add-merchant-form-card-sale-method-pos-textbox" placeholder='At my store/shop(POS)'
                                                                        name="saleMethodPos" variant="outlined" isEditable={true} suffix="%" showDecimal={true} setValue={setValue} onChange={handlePosChange} value={props?.pos ?? ''}/>
                                                        </Grid>}
                                                    </Grid>
                                                    {/** MOTO */}
                                                    <Grid item xs={12} sm={12} display={'flex'} alignItems={'center'}>
                                                        <Grid item xs={4} sm={4}>
                                                            <FxCheckEdit register={{ ...register('allowMotoMethod') }}
                                                                rules={{ required: false }} control={control}
                                                                id="debit-terms-condition" name="allowMotoMethod"
                                                                className="fx-privacy-text" data={allowMoto} row="vertical"
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                }} setValue={setValue} />
                                                        </Grid>
                                                        {props?.showMotoPayments && <Grid item xs={4} sm={4}>
                                                            <FxTextEdit register={{ ...register("saleMethodMoto") }} className={errors.saleMethodMoto ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number"
                                                                    rules={{required: false}} control={control} id="add-merchant-form-card-sale-method-moto-textbox" placeholder='Mail order telephone order(MOTO)'
                                                                    name="saleMethodMoto" variant="outlined" isEditable={true} suffix="%" showDecimal={true} setValue={setValue} onChange={handleMotoChange} value={props?.moto ?? ''}/>
                                                        </Grid>}
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} className='sales-method-errors'>
                                                         <div className={'error-message'}>
                                                            {errors.percentageError && renderError(errors.percentageError)}
                                                        </div>
                                                    </Grid>

                                                    {/** Card Not Present Fields */}
                                                    {props?.showInfo && cardNotPresentFields()}

                                                    {/** Settlement Account Selection dropdown */}
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography  className="filter-popover-label">PROVIDE YOUR CARD SETTLEMENT ACCOUNT</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} >
                                                        <FxMaterialSelect register={{ ...register("source") }} id="add-merchant-form-source" name="source"
                                                            source={acc_src} control={control} rules={{ required: true }}
                                                            dataTransformation={merchantSettlementAccountListDataTransformation}
                                                            value={getKey("selectedAccount")?.id} readOnly={getKey("selectedAccount")?.id ? true : false} label="Choose Account*" setValue={setValue} />
                                                    </Grid>

                                                    {/** Agreements and Rate Cards */}
                                                    <Grid item xs={12} className={'fx-df-merchant-agreements'}>
                                                        <Typography className={'fx-fee-agreement'}>
                                                            Click here to view the <FxLink className={'fx-fee-agreement-link'} onClick={getMerchantServicesAgreement}><span className='normal-font'>Merchant Services Agreement</span></FxLink> & <FxLink className={'fx-fee-agreement-link'} onClick={getMerchantRateCardAgreement}><span className='normal-font'>Rate Card</span></FxLink>.
                                                        </Typography>
                                                    </Grid>

                                                    {/** Agreements and Rate Cards */}
                                                    <Grid item xs={12} className={'fx-submit-agree'}>
                                                        <FxCheckEdit register={{ ...register('ProgramGuideTerms') }}
                                                            rules={{ required: 'Please click Agree' }} control={control}
                                                            id="debit-terms-condition" name="ProgramGuideTerms"
                                                            className="fx-privacy-text" data={ProgramGuideTerms} row="vertical"
                                                            onChange={(e: any) => {
                                                                handleChange(e);
                                                            }} setValue={setValue} />
                                                    </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="add-merchant-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="add-merchant-form-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className={props?.btnDisabled?"fx-button fx-button-disabled":"fx-button fx-button-theme"}
                                                    id={"add-merchant-form-card-submit-button"}
                                                    type="submit"
                                                    disabled={props?.btnDisabled}
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                </form>}
                            </div>
                        </FxCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });