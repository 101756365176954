/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { Logger } from '../../../libs/utils/logger';
import { SplitFundingDetail } from './SplitFundingDetail';
import { getCustomerUrl } from '../../../libs/utils/utils';
import HttpClient from "../../../libs/utils/httpClient";
import { getKey } from '../../../libs/utils/storageManager';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

Logger.debug("SplitFundingInfo.tsx", "split funding  Details initializing");

/**
 * Component: SplitFundingDetails
 * Usage: To display split funding 
 */
export const SplitFundingInfo: React.FC<any> = React.memo(() => {
    interface RouteParams {
        id: string
    }
    const uiFeat = UIFeatureImpl.getInstance();
    const checkIsPartner=uiFeat.getPartnerFeature().available;
    const params = useParams<RouteParams>();
    /**
     * get ID from  url
     */
    const id = params.id
    /**
   * policy details API
   */
    const split_rule = {
        url: "fundingRule/id/" + id,
        method: "GET"
    }

    const [fundingRuleList, setfundingRuleList] = useState([]);
    const [mccCodelist, setMccCodeList] = useState([]);
    const httpClient = HttpClient.getClient();

    useEffect(() => {
        if(!checkIsPartner){
            getMccCodeList();
        }
        getSplitFundingList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Method to get Authorized Users List
     */
    const getSplitFundingList = async () => {
        try {
            const payloadData = {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                    "sortBy": "priority",
                    "sortOrder": "ASC"
                },
                "criteria": {
                    "filters": []
                }
            }
            await httpClient.post(getCustomerUrl('/fundingRule/list'), payloadData).then(response => {
                setfundingRuleList(response?.data?.resources)
            });
        } catch (e) { 
            Logger.error('SplitFundingInfo.tsx','error',e)
        }
    }
    /**
     * Method to get Authorized Users List
     */
    const getMccCodeList = async () => {
        try {
            const payloadData = {
                pageNumber: 1,
                pageSize: 100,
                sortOptions: {
                  sortBy: "lastUpdatedOn",
                  sortOrder: "desc",
                },
                criteria: {
                  filters: [
                    {
                      key: "code",
                      operator: "like",
                      values: [""],
                    },
                    {
                        key: "entityId",
                        operator: "eq",
                        values: [getKey('customerId')],
                    }
                  ],
                },
            }
            await httpClient.post(getCustomerUrl('/merchant/mcc'), payloadData).then(response => {
                setMccCodeList(response?.data?.settings)
            });
        } catch (e) { 
            Logger.error('SplitFundingInfo.tsx','error',e)
        }
    }

    return (
        <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
            {<SplitFundingDetail id="split-funding-info-card" source={split_rule} fundingRuleList={fundingRuleList} mccCodelist={mccCodelist} />

            }

        </Grid>
    )
})