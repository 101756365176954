/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { Paper, TableContainer } from '@mui/material';
import { ExternalAccountCard } from './ExternalAccountCard';
import { FxAccessDenied } from '../Index/Passport/FxAccessDenied';
import { checkPermission } from '../../Utils/CommonBaseClass';
/**
 * Component: ExternalAccountDetails
 * Usage: To display external account details
 */
export const ExternalAccountDetails =
    (props:any) => {
        interface RouteParams {
            id: string
        }
        //get ID from page url
        const params = useParams<RouteParams>();
        const id = params.id
        //API request for get external account details
        const account_source = {
            url:  "/externalAccount/id/" + id,
            method: "GET"
        }

        /**
        * to set permission
        */
        const permission = useMemo(() => {
            const viewPermission = {
                entity:"Other Entities",
                name:"External Account",
                operation:"VIEW"
            }
           return checkPermission(viewPermission)
        }, [])

        const accountCardId = "external-account-details-card" + id;
        return (
            <>  
                {permission ?  <ExternalAccountCard id={accountCardId} source={account_source}></ExternalAccountCard>
                  : <Paper>
                        <TableContainer className={"fx-grid-container"}>
                            <FxAccessDenied id={props?.id + "-access-denied"} title={'You are not authorized to access this page'} />
                        </TableContainer>
                    </Paper>}
            </>
        )

    }
