/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'; // we need this to make JSX compile
import { Grid, Modal, Typography } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';

//icons
import { ReactComponent as MenuIcon } from '../../../assets/svg/gear-icon-svg.svg';
import { Logger } from '../../../libs/utils/logger';
import { toTitleCase } from '../../../libs/utils/string';
import { FxButton } from '../../Action/FxButton';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardHeader from '../../Container/FxCardHeader';
import { FxDelete } from '../../Data/FxDelete';
import { addressFormatter, checkPermission } from '../../Utils/CommonBaseClass';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { ValidateExternalAccount } from './ValidateExternalAccount';
import { VerifyMicroDeposit } from './VerifyMicroDeposit';
import { useMediaQuery } from 'react-responsive';


Logger.debug("ExternalAccountInfoCard.tsx", "External Account InfoCard initializing");
/**
 * Component: ExternalAccountInfoCard
 * Usage: To display external account information
 */

export const ExternalAccountInfoCard: React.FC<any> = React.memo(
    (props) => {
        const history = useHistory();
        const [validateEAOpen, setValidateEAOpen] = useState(false);
        const [verifyMicroOpen, setVerifyOpen] = useState(false);
        const options: any = [];
        let edit: boolean = true;
        let isVerifyMicro: boolean = true;
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });


        const handleVerifyOpen = () => {
            setVerifyOpen(true);
        };

        /**
         * Method to handle change event
         * @param event : event object
         */
        const handleChange = (event: any) => {
            if (event.split(' ')[0] === 'Validate') {
                setValidateEAOpen(true);
            }
            if (event.split(' ')[0] === 'Verify') {
                handleVerifyOpen()
            }
        }

        /**
        * function to check permission  for verify and validate button
        */
        function checkViewPermission(data: any) {
            const permissionObject: any = {
                'other_entities_external_card_validate': {
                    entity: "Other Entities",
                        name: "External Account",
                        operation: "EDIT"
                },
                'other_entities_external_card_verify': {
                    entity: "Other Entities",
                    name: "External Account",
                    operation: "VERIFY"
                }
            }
            return checkPermission(permissionObject[data])
        }

        /**
         * Handle close event
         */
        const handleClose = () => {
            setValidateEAOpen(false);
            setVerifyOpen(false);
        };

        let external_account_data: any;
        let external_account_source: any;
        /**
         * Transform data
         */
        if (props?.data) {
            try {
                external_account_data = { ...props.data }
                external_account_source = {
                    url: "/externalAccount/id/" + props.data['id'],
                    method: "GET"
                }

                external_account_data.createdInfo = (props.data['createdBy']['username'] + '\nOn ' + moment(props.data['createdOn']).format('MM/DD/YYYY'));
                external_account_data.updatedInfo = (props.data['lastUpdatedBy']['username'] + '\nOn ' + moment(props.data['lastUpdatedOn']).format('MM/DD/YYYY'));
                if (props.data.card?.cardNumberLast4) {
                    external_account_data.accountNumber = '** ' + props.data.card.cardNumberLast4;
                    external_account_data.accountNumberTitle = '** ' + props.data.card.cardNumberLast4;
                } else if (props.data?.accountNumberLast4) {
                    external_account_data.accountNumber = '** ' + props.data.accountNumberLast4;
                    external_account_data.accountNumberTitle = '** ' + props.data.accountNumberLast4
                }
                external_account_data.holderType = props.data?.holderType ? toTitleCase(props.data.holderType) : '';
                external_account_data.bankAccountType = props.data?.type ? toTitleCase(props.data.type) : '';
                external_account_data.holderAddress=props.data?.holderAddress?addressFormatter("",props.data?.holderAddress):'';

                external_account_data.wireRoutingNumberInfo = external_account_data?.wireRoutingNumber ? external_account_data.wireRoutingNumber : '';
                if (external_account_data?.wireRoutingNumber && external_account_data?.wireBankInfo) {
                    external_account_data.wireRoutingNumberInfo = external_account_data.wireRoutingNumber + ' (' + external_account_data.wireBankInfo.name + ')';
                }
                if (external_account_data?.routingNumber && external_account_data?.bankInfo) {
                    external_account_data.routingNumberInfo = external_account_data.routingNumber + ' (' + external_account_data.bankInfo.name + ')';
                }
                if ((props.data['status'] === 'ACTIVE')) {
                    edit = false;
                    isVerifyMicro = true;
                } 
                else if (props.data['status'] === 'BLOCKED') {
                    isVerifyMicro = true;
                    edit = false;
                }
                if (isVerifyMicro && checkViewPermission("other_entities_external_card_verify")) {
                    options.push({
                        id: 'externalaccount-verify-option',
                        btnText: 'Verify',
                        title: 'Verify Micro Deposit',
                        icon: <MenuIcon />,
                        permission: {
                            entity:"Other Entities",
                            name: "External Account",
                            operation: "VERIFY"
                        }
                    })
                }
                
            
            } catch (e) {
                Logger.error("ExternalAccountInfoCard.tsx","error",e);
            }
        }
        const exteranl_account_section_card_data = [
            {
                label: 'Card Number',
                value: 'card.cardNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Brand',
                value: 'card.brand'
            },
            {
                label: 'Holder Name',
                value: 'card.holderName'
            },
        ]
        const external_account_bank_section_left_one = [
            {
                label: 'Holder Type',
                value: 'holderType'
            },
              {
                label: 'Holder Phone',
                value: 'holderPhone'
            },
        ];
        const external_account_bank_section_left_two = [
            {
                label: 'Account Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Account Number',
                value: 'accountNumber'
            },
            {
                label: 'Account Type',
                value: 'bankAccountType'
            },

        ];
        const external_account_bank_section_left_three = [
            {
                label: 'Created',
                value: 'createdInfo'
            }

        ];
        const external_account_bank_section_right_one = [
            {
                label: 'Holder Name',
                value: 'holderName'
            },
            {
                label: 'Holder Address',
                value: "holderAddress"
            },

        ];
        const external_account_bank_section_right_two = [
            {
                label: 'Routing Number',
                value: 'routingNumberInfo'
            },
            {
                label: 'Wire Routing Number',
                value: 'wireRoutingNumberInfo'
            }
        ];
        const external_account_bank_section_right_three = [
            {
                label: 'Last Updated',
                value: 'updatedInfo'
            }

        ];

        const editPermission = {
            entity:"Other Entities",
            name:"External Account",
            operation:"EDIT"
          }
    
        const deletePermission = {
            entity:"Other Entities",
            name:"External Account",
            operation:"DELETE"
          }


        return (
            <Grid id="external-account-info-Grid " className="fx-margin-bottom-16" item xs={12}>
                <FxCard id="deposit-info-info-card" className="fx-theme-passport">
                    <FxCardHeader id={'dexternal-account-info-card#card-header'}>
                        <Grid id="external-account-Grid2" item container justifyContent="space-between" alignItems={'center'} xs={12} >
                            <Grid id="external-account-Grid3"  >
                                <Typography id="external-account-title-label" variant="h4" >External Account Information</Typography>
                                <div id="div-grow-title-payees-list" className="fx-flex-grow" />
                            </Grid>
                            <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                              {edit && <Grid item><FxButton id="edit-external-account-button" permissions={editPermission} variant="contained" className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={<EditIcon />} onClick={() => history.push("/externalaccount/edit/" + props?.data?.id)}>{!isSmallScreen && 'Edit'}</FxButton></Grid>}
                              {(options.length > 0) && options.map((option: any) => (
                                <Grid item key={option}>
                                    <FxButton title={option.title} id={option.id} variant="contained" permissions={option?.permission} className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={option.icon} onClick={() => { handleChange(option.title) }}>
                                      {!isSmallScreen && option.btnText}
                                    </FxButton>
                                </Grid>  
                              ))}
                              <Grid item><FxDelete domId={'delete-externalAccount-menu'} id={props.data['id']} entity={'externalAccount'} permissions={deletePermission} buttonTitle={!isSmallScreen ? "Delete" : ''} header={"Are you sure you want to Delete this External Account " + external_account_data?.accountNumberTitle} description="You will not be able to recover it" redirectUrl={'/externalaccounts'}/></Grid>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    {/* validate EA modal */}
                    <Modal
                        style={{ overflow: 'scroll' }}
                        open={validateEAOpen}
                        onClose={handleClose}
                        disableEnforceFocus={true}
                        disableAutoFocus={false}
                        className="fx-modal fx-modal-small2medium"
                    >
                        <ValidateExternalAccount id={'update-external-account'} source={external_account_source} close={handleClose} />
                    </Modal>
                    {/* verify micro deposit modal */}
                    <Modal
                        style={{ overflow: 'scroll' }}
                        open={verifyMicroOpen}
                        onClose={handleClose}
                        disableEnforceFocus={true}
                        disableAutoFocus={false}
                        className="fx-modal fx-modal-small2medium"
                    >
                        <VerifyMicroDeposit id={'verify-micro-deposit'} source={external_account_source} close={handleClose} />
                    </Modal>
                    <FxCardBody id="deposit-info-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                        {/* Info card section */}
                        <Grid container item xs={12} direction="row" >
                            {external_account_data && <><Grid item xs={12} direction="column">
                                <FxInfoCard id={"section-info-card-external-account-basic-info-one" + props.data['id']} title="BASIC INFORMATION" leftcolumn={external_account_bank_section_left_one} rightcolumn={external_account_bank_section_right_one} data={external_account_data} />
                            </Grid>
                                <Grid item xs={12}>
                                    <FxInfoCard id={"section-info-card-external-account-basic-info-two" + props.data['id']} title="ACCOUNT INFORMATION" leftcolumn={external_account_bank_section_left_two} rightcolumn={external_account_bank_section_right_two}  data={external_account_data} />
                                </Grid>
                                <Grid item xs={12}><FxInfoCard id="section-info-card-external-account-basic-info-three" title="AUDIT DETAILS" leftcolumn={external_account_bank_section_left_three} rightcolumn={external_account_bank_section_right_three} data={external_account_data} /></Grid>
                            </>}
                            {external_account_data?.card && <FxInfoCard id={"section-info-card-deposit-destination" + props.data['id']} title="" column={exteranl_account_section_card_data} data={props?.data} />}
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
