/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Component: CustomerDetails
 * Usage: For View Customer Details page
 */
import React from "react"; // we need this to make JSX compile
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { Grid } from "@mui/material";
import { FxInfoCard } from "../../Cards/FxInfoCard";
import { useDispatch } from "react-redux";
import { addressFormatter, maskAccountNumber, stateValueFormatter, truncateChars, updateComponentData } from "../../../Utils/CommonBaseClass";
import moment from "moment";
import { Logger } from "../../../../libs/utils/logger";

export const CustomerDetails: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const { data } = props;
  const dispatch = useDispatch();
  const business:any={}
  const individual:any={}
  if(data?.business){
    business.authEmail=data?.business?.beneficialOwner?.filter((item:any)=>item.actAsAuthorizedSignatory===true)?.[0]?.email
    business.email=data?.business?.email
    business.ppi=data?.ppi?.ppi
  }
  if(data?.individual){
    individual.email=data?.individual?.email
  }


  /**
   * configuring left card
   */
  const view_section_left = [
    {
      label: "PPI ID ",
      value: "ppi.ppi",
      type: 'fri://fieldtype/default',
      truncateChars: 25
    },
    {
      label: "Account Created",
      value: "createdOn",
      type: "fri://fieldtype/date",
      format: "MMM DD, yyyy",
    },
  ];

  /**
   * configuring right cards
   */
  const view_section_right = [
    {
      label: "Customer ID",
      value: "id",
    },
    {
      label: "KYC Status",
      value: "status",
      type: "fri://fieldtype/status",
    },
  ];

  /**
   * configuring left card
   */
  const view_individual_section_left = [
    {
      label: "Name ",
      value: "name",
    },
    {
      label: "Date Of Birth",
      value: "dob",
      type: "fri://fieldtype/date",
      format: "MMM DD, yyyy",
    },
    {
      label: "Email",
      value: "individual.email",
      type: 'fri://fieldtype/default',
      truncateChars: 25,

    },
    {
      label: "Is Paperless",
      value: "isPaperless",
    },
    {
      label: "Primary Address",
      value: "primaryAddress",
    },
  ];

  /**
   * configuring right cards
   */
  const view_individual_section_right = [
    {
      label: "SSN",
      value: "ssn",
    },
    {
      label: "Mobile Phone",
      value: "mobilePhone",
    },
    {
      label: "Home Phone",
      value: "homePhone",
    },
    {
      label: "Work Phone",
      value: "workPhone",
    },
  ];

  /**
   * configuring left card
   */
  const view_section_left_business_info = [
    {
      label: "Doing Business As ",
      value: "doingBusinessAs",
    },
    {
      label: "Business Category",
      value: "businessCategory",
    },
    {
      label: "EIN",
      value: "ein",
    },
    {
      label: "State of incorporation",
      value: "stateOfIncorporation",
    },
    {
      label: "Website",
      value: "website",
    },
  ];
  /**
   * configuring right cards
   */
  const view_section_right_business_info = [
    {
      label: "Mobile",
      value: "phone",
    },
    {
      label: "Email",
      value: "business.email",
      type: 'fri://fieldtype/default',
      truncateChars: 25
    },

    {
      label: "Is Paperless",
      value: "isPaperless",
    },
    {
      label: "Date of incorporation",
      value: "dateOfIncorporation",
      type: "fri://fieldtype/date",
      format: "MMM DD, yyyy",
    },
    {
      label: "Mailing Address",
      value: "mailingAddresses",
    },
  ];

  /**
   * configuring left card
   */
  const view_section_left_authorized_signatory_info = [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "DOB",
      value: "dob",
      type: "fri://fieldtype/date",
      format: "MMM DD, yyyy",
    },
    {
      label: "Primary Address",
      value: "primaryAddress",
    },
    {
      label: "Secondary Identification Type",
      value: "secondaryIdentificationType",
    },
    {
      label: "Country Of issuance",
      value: "countryOfIssuance",
    },
  ];

  /**
   * configuring right cards
   */
  const view_section_right_authorized_signatory_info = [
    {
      label: "SSN",
      value: "ssn",
    },
    {
      label: "Mobile",
      value: "mobilePhone",
    },
    {
      label: "Home phone",
      value: "homePhone",
    },
    {
      label: "Work Phone",
      value: "workPhone",
    },
    {
      label: "Email",
      value: "business.authEmail",
      type: 'fri://fieldtype/default',
      truncateChars: 25
    },

    {
      label: "Secondary Identification ID",
      value: "siId",
    },

    {
      label: "Is US citizen",
      value: "isUsCitizen",
    },
  ];

  /**
   * configuring left card
   */
  const view_section_left_audit_info = [
    {
      label: "Created",
      value: "createdOn",
    },
  ];

  /**
   * configuring right cards
   */
  const view_section_right_audit_info = [
    {
      label: "Last Updated",
      value: "lastUpdatedOn",
    },
  ];

  /**
   * Transform data and set title
   * @param info
   * @returns
   */
  const dataTransformerBasicInfo = (info: any) => {
    updateComponentData(dispatch, 'page-title-details', { title: 'View Customer Info', backButton: "/customers" })
    let newData: any;
    try {
      newData = { ...info };
      newData.name = info?.individual?.fullName;
      newData.dob = info?.individual?.dob;
      newData.isPaperless = info?.isPaperless ? 'True' : 'False';
      newData.primaryAddress=info?.individual?.mailingAddress? addressFormatter('', info?.individual?.mailingAddress?.find((address: any) => address.isPrimary === true)):'';
      newData.ssn = maskAccountNumber(info?.individual?.last4ssn,false);
      newData.mobilePhone = info?.individual?.mobilePhone;
      newData.homePhone = info?.individual?.homePhone;
      newData.workPhone = info?.individual?.workPhone;
      return newData;
    } catch (e) {
      Logger.error("CustomerDetails.tsx", "error", e);
      return false;
    }
  };

  /**
   * Transform data and set title
   * @param info
   * @returns
   */
  const dataTransformer = (info: any) => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: {
        id: "page-title-details",
        data: { title: 'View Customer Info', backButton: "/customers" },
      },
    });
    let newData: any;
    try {
      newData = { ...info };
      newData.type = info?.business ? "Business" : "Individual";
      newData.phone = info?.business?.phone;
      newData.dateOfIncorporation = info?.business?.dateOfIncorporation;
      newData.isPaperless = info?.isPaperless ? "Yes" : "No";
      newData.mailingAddresses =
      truncateChars(info?.business?.mailingAddress?.[0]?.addressLine1 +
        ", " +
        info?.business?.mailingAddress?.[0]?.addressLine2 +
        ", " +
        info?.business?.mailingAddress?.[0]?.city +
        ", " +
        info?.business?.mailingAddress?.[0]?.state +
        ", ",40 )+
        info?.business?.mailingAddress?.[0]?.zip;
      newData.doingBusinessAs = info?.business?.doingBusinessAs;
      newData.businessCategory = info?.business?.businessCategory;
      newData.ein = info?.business?.ein;
      newData.stateOfIncorporation = info?.business?.stateOfIncorporation? stateValueFormatter(info?.business?.stateOfIncorporation):'';
      newData.website = info?.business?.website;
      return newData;
    } catch (e) {
      Logger.error("CustomerDetails.tsx", "error", e);
      return false;
    }
  };

  /**
   * Transform data and set title
   * @param info
   * @returns
   */
  const dataTransformerAuthorizedSignatory = (info: any) => {
    let newData: any;
    try {
      newData = { ...info };
      
      const actasAuthSignatory:any=info?.business?.beneficialOwner?.filter((item:any)=>item.actAsAuthorizedSignatory===true)
      if(actasAuthSignatory){
        // business.email = actasAuthSignatory?.[0]?.email,
      newData.name = actasAuthSignatory?.[0]?.firstName;
      newData.dob = actasAuthSignatory?.[0]?.dob;
      newData.primaryAddress=info?.business?.mailingAddress? addressFormatter('', info?.business?.mailingAddress?.find((address: any) => address.isPrimary === true)):'';
      newData.secondaryIdentificationType =
        actasAuthSignatory[0]?.secondaryIdentification?.type;
      newData.stateOfIssuance =
        actasAuthSignatory[0]?.secondaryIdentification?.stateOfIssuance;
      newData.ssn = actasAuthSignatory?.[0]?.last4ssn;
      newData.mobilePhone = actasAuthSignatory?.[0]?.mobilePhone;
      newData.workPhone = actasAuthSignatory?.[0]?.workPhone;
      newData.homePhone = actasAuthSignatory?.[0]?.homePhone;
      newData.siId =
        actasAuthSignatory?.[0]?.secondaryIdentification?.id;
      newData.isUsCitizen =
        actasAuthSignatory?.[0]?.secondaryIdentification?.isUsCitizen;
        
      return newData;
      }
    } catch (e) {
      Logger.error("CustomerDetails.tsx", "error", e);
      return false;
    }
  };

  /**
   * Transform data and set title
   * @param info
   * @returns
   */
  const dataTransformerAuditDetails = (info: any) => {
    let newData: any;
    try {
      newData = { ...info };
      newData.createdOn =  info['createdBy']['username'] + '\nOn ' + moment(info['createdOn']).format('MM/DD/YYYY');
      newData.lastUpdatedOn = info['lastUpdatedBy']['username'] + '\nOn ' + moment(info['lastUpdatedOn']).format('MM/DD/YYYY');
      return newData;
    } catch (e) {
      Logger.error("CustomerDetails.tsx", "error", e);
      return false;
    }
  };

  return (
    <Grid container item xs={12} direction="row">
      {props?.data?.type === "BUSINESS" && (
        <>
          <Grid item xs={12} direction="column">
            <FxInfoCard
              id={"partner-customer-view-fxbody-infocard"}
              title="BASIC INFO"
              dataTransformer={dataTransformer}
              leftcolumn={view_section_left}
              rightcolumn={view_section_right}
              data={data}
            />
          </Grid>
          <Grid item xs={12} direction="column">
            <FxInfoCard
              id={"partner-customer-view-fxbody-infocard-business-info"}
              title="BUSINESS INFO"
              dataTransformer={dataTransformer}
              leftcolumn={view_section_left_business_info}
              rightcolumn={view_section_right_business_info}
              data={data}
            />
          </Grid>
          <Grid item xs={12} direction="column">
            <FxInfoCard
              id={
                "partner-customer-view-fxbody-infocard-authorized-signatory-info"
              }
              title="AUTHORIZED SIGNATORY INFORMATION"
              dataTransformer={dataTransformerAuthorizedSignatory}
              leftcolumn={view_section_left_authorized_signatory_info}
              rightcolumn={view_section_right_authorized_signatory_info}
              data={data}
            />
          </Grid>
        </>
      )}

      {props?.data?.type === "INDIVIDUAL" && (
        <>
          <Grid item xs={12} direction="column">
            <FxInfoCard
              id={"partner-customer-view-fxbody-infocard"}
              title="BASIC INFO"
              dataTransformer={dataTransformerBasicInfo}
              leftcolumn={view_individual_section_left}
              rightcolumn={view_individual_section_right}
              data={data}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} direction="column">
        <FxInfoCard
          id={"partner-customer-view-fxbody-infocard-authorized-signatory-info"}
          title="AUDIT DETAILS"
          dataTransformer={dataTransformerAuditDetails}
          leftcolumn={view_section_left_audit_info}
          rightcolumn={view_section_right_audit_info}
          data={data}
        />
      </Grid>
    </Grid>
  );
});
