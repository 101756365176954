import React, { useEffect, useMemo } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getCustomerUrl, useIsMobileScreen } from '../../../libs/utils/utils';
import { getKey, setKey } from '../../../libs/utils/storageManager';
import { checkPermission, fetchDataFromState, maskAccountNumber, updateComponentParams } from '../../Utils/CommonBaseClass';
import { LogoAccount } from '../../Utils/Logo';
import { FxLink } from '../../Action/FxLink';
import { ReactComponent as EditIcon } from '../../../assets/svg/new-edit-icon.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download-home.svg';
import { ReactComponent as YieldIcon } from '../../../assets/svg/yield-icon.svg';
import { FxButton } from '../../Action/FxButton';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxAccessDenied } from '../../Page/Index/Passport/FxAccessDenied';
import { AccountStatementDownload } from '../../Page/Index/Passport/AccountStatementDownload';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { EditNicknameModal } from '../../Page/Index/Passport/EditNicknameModal';
import moment from 'moment';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';

Logger.debug("DepositPlusAccountBalanceCard.tsx", "DepositPlusAccountBalanceCard initializing");
const httpClient = HttpClient.getClient()

/**
 * This component handles the Passport Cash Builder Account Balance Component
 */
const accountStatementPermission = {
  entity: "Accounts",
  name: "Statement",
  operation: "Download"
}

export const DepositPlusAccountBalanceCard: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props))
  const dispatch = useDispatch();
  let template: any;
  let totalYield: any;
  const data = { availableBalance: 0, accountNumber: null }
  const isMobileScreen = useIsMobileScreen();
  
  /**
    * Method to get switch customer details from state
    */
  const switchData = useSelector((state: any) =>
    fetchDataFromState(state, 'switch-customer-data')?.data
  );

  /**
   * This method calculates the 1st day of the year to pass in to the API request
   * @param moment 
   * @returns 
   */
  function getFirstDayOfCurrentYear(moment:any) {
    const currentDate = moment();
    return currentDate.startOf('year').format('MM/DD/YYYY');
  }

   /**
   * function to get interest history data
   */
   async function getInterestHistory(accountId: any) {
    try {
      const startDate = getFirstDayOfCurrentYear(moment);
      const reqBody = {
        pageNumber: 1,
        pageSize: 25,
        sortOptions: {
          'sortOrder': 'asc',
          'sortBy': 'createdOn'
        },
        criteria: {
          filters: [
            {
              "operator": "gte",
              "key": "processDate",
              "values": [startDate]
            }
          ]
        }
      }
      const res = await httpClient.post(getCustomerUrl(`account/id/${accountId}/interestHistory/list`, false), reqBody);
      totalYield = calculateTotalInterest(res);
      updateComponentParams(dispatch, props?.id, { 'totalYield': totalYield });
    }
    catch (err) {
      Logger.error("DepositPlusAccountBalanceCard.tsx", 'function getInterestHistory', "error", err);
      return err;
    }
  }

  /**
   * This method calculates the Total Yield Interest from the API response recieved
   */
  const calculateTotalInterest = (data: any) => {
    return data?.data?.resources?.reduce((total: any, item: any) => {
      return total + parseFloat(item.interestAmount);
    }, 0);
  }

  /**
   * useEffect to get interest history
   */
  useEffect(() => {
    if(props?.accountData?.id){
      getInterestHistory(props?.accountData?.id);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[props?.accountData]);

  /**
   * checking props data available
   */
  const accountData = props.accountData;
  if (accountData && (accountData?.id === switchData?.selectedAccount?.id)) {
    data.availableBalance = accountData.availableBalance?.amount;
    data.accountNumber = accountData.accountNumber;
    template = accountData.configuration?.debit?.checkProcessing?.defaultRemittanceTemplate
    setKey("accountinfotemplate", template)
  }

  /**
   * Method handles the onclick event for opening modal
   */
  const handleOpen = () => {
    updateComponentParams(dispatch, props?.id, { 'open': true });
  };

  /*
  * Method handles the closing of the account statement modal
  */
  const handleAccountStatementClose = () => {
    updateComponentParams(dispatch, props?.id, { 'open': false });
  }

  /**
  * to set permission
  */
  const permission = useMemo(() => {
    return checkPermission({
      entity: "Accounts",
      name: "Account",
      operation: "View"
    })
  }, []);

  const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);

  /**
   * Update Selected Account when Account List is changed
   */
  useMemo(()=> {
    return accountListInfo?.data?.body?.resources?.filter((item:any) => {
      if(item.id === getKey('selectedAccount')?.id){
        setKey('selectedAccount',item);
        updateComponentParams(dispatch,'page-header-info',{'updated':true});
      }
      return item;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accountListInfo])

  /**
  * Method handles the closing of edit nickname modal
  */
  const handleEditNicknameModalClose = () => {
    updateComponentParams(dispatch,props?.id, {'editNicknameModalOpen':false})
  }

  /**
  * Method handles the opening of edit nickname modal
  */
  const handleEditNicknameModalOpen = () => {
    updateComponentParams(dispatch,props?.id, {'editNicknameModalOpen':true})
  }

  return (
    <>
        <Grid className="fx-theme-passport fx-account-summary-card fx-accountinfo fx-grey-background fx-account-summary-card-center fx-deposit-account-info-account-card">
            {permission ? 
                <Grid xs={12} container item direction='column'>
                    <Grid container item xs direction='row' className='fx-deposit-account-info-account'>
                        <Grid item className='fx-accountinfo-logo'>
                            <div id='deposit-plus-account-details'>
                                <LogoAccount />
                            </div>
                        </Grid>
                        <Grid container item xs direction='column' className='fx-accountinfo-details'>
                            <Grid item display='flex' alignItems='center'>
                                <FxLink id='deposit-plus-account-details'>
                                    <Typography id="deposit-plus-account-info" className='fx-account-list-menu-sel'>
                                        {getKey("selectedAccount")?.nickName ? getKey("selectedAccount")?.nickName  : 'Cash Builder+ Account'}&nbsp;&nbsp;
                                    </Typography>
                                </FxLink>
                                {getKey("selectedAccount")?.id && <FxLink id='edit-nickname-icon' onClick={handleEditNicknameModalOpen} tooltip={true} title='Edit Nickname'>
                                    <EditIcon />
                                </FxLink>}
                            </Grid>
                            {getKey("selectedAccount")?.id && <Grid container item xs direction='row'>
                                <Grid item className='fx-accountinfo-active-account'>
                                    <Typography variant='h3'>
                                        Cash Builder+ Account {maskAccountNumber(data?.accountNumber || getKey("selectedAccount")?.accountNumber,false)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                        </Grid>
                        {isMobileScreen && getKey("selectedAccount")?.id && getKey("selectedAccount")?.status !== 'INACTIVE' &&<Grid item container xs={3} alignContent="flex-start" justifyContent={'flex-end'}>
                            <FxButton
                                permissions={accountStatementPermission}
                                variant="contained"
                                className="fx-button fx-button-rounded"
                                title='Download Statement'
                                id="account-statement"
                                onClick={handleOpen}
                            >
                                <DownloadIcon />
                            </FxButton>
                        </Grid>}    
                    </Grid>      

                    {/** Avaialble Balance Text + amount */}
                    <Grid className={'fx-deposit-account-info-available-balance-text-space'}>
                        <Grid container item xs={12} direction='row' marginBottom={'0.75rem'}>
                            <Grid item xs={11} className={'fx-deposit-account-info-available-balance-text'}>
                                <Typography >AVAILABLE BALANCE</Typography>
                            </Grid>
                        </Grid>
                        <Grid item className="fx-account-summary-currency">
                            <FxCurrencyView id="home-account-info-card-currencyview" value={data?.availableBalance ? data?.availableBalance : 0} prefix={"$"} />
                        </Grid>
                    </Grid>
        
                    {/** Total Yield Section */}
                    <Grid container item direction="row" xs={12} className="fx-deposit-account-info-bottom-section">
                        <Grid item className='fx-deposit-account-info-bottom-section-logo'>
                            <YieldIcon />
                        </Grid>    
                        <Grid item container xs direction='column' marginLeft={'1rem'}>
                            <Grid item className='fx-deposit-account-info-bottom-section-text'>
                                Total Yield Received As of {moment(new Date()).format('MMM DD , YYYY')}
                            </Grid>
                            <Grid item className='fx-deposit-account-info-bottom-section-value'>
                                <FxCurrencyView id="home-account-info-card-currencyview" value={props?.totalYield} prefix={"$"} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid> : <FxAccessDenied id={'passport-account-access-denied'} title={'You are not authorized to access this card'} />
            }
        </Grid>

        {/** Account Statement Download Modal */}
        {props?.open && 
        <AccountStatementDownload
            id="deposit-plus-account-balance-card-asd" 
            open={props?.open} 
            onClose={handleAccountStatementClose} 
        />}

        {/** Edit Account Nickname Modal */}
        {props?.editNicknameModalOpen && 
        <FxModalGeneric
            id={"edit-account-nickname"}
            open={props?.editNicknameModalOpen}
            onClose={handleEditNicknameModalClose}
            componentProps={{ accountId: getKey('selectedAccount')?.id, nickname: getKey('selectedAccount')?.nickName}}
            className={'fx-modal-small2medium'}
            component={EditNicknameModal}
            title='Edit Nickname'
        >
        </FxModalGeneric>}
    </>
  )
})
