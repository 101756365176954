/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { DebitCardDetailsInfoCard } from './DebitCardDetailsInfoCard';
import { DebitCardTextInfo } from './DebitCardTextInfo';
import { DebitCardView } from './DebitCardView';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { DigitalFirstInfoModal } from './DigitalFirstInfoModal';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';

Logger.debug("DebitCardDetailsCard.tsx", "Debit Card Details Card initializing");

/**
 * Component: DebitCardDetailsCard
 * Usage: To display Debit Card Details Card
 */

export const DebitCardDetailsCard: React.FC<any> = React.memo((props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let debit_card_info: any = {};
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        const dispatch = useDispatch();

        /**
        * Function to be called before loading the component
        */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        /**
        * to open digital first info modal
        */
        useEffect(() => {
            if(context?.data?.body){
                updateComponentParams(dispatch, props.id, { digitalFirstInfoModalOpen : context?.data?.body?.status === 'ACTIVE' && context?.data?.body?.properties?.isDigitalFirst && !context?.data?.body?.properties?.cardPresentTransactionsEnabled})
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [context?.data?.body?.properties])

        /**
        * Check if external account data available
        */
        if (context && context.data && context.data.body) {
            debit_card_info = context.data.body;
        }

        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;
        
        return (
            <Grid container className={"fx-container"}>
                <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" >
                        {context?.data?.body &&
                            isSmallScreen ? 
                            <Grid container xs={12} >
                                <DebitCardDetailsInfoCard id="debit-card-details-info-card" data={debit_card_info} dataLoadStatus={dataLoadStatus} />
                                <DebitCardView id='debit_card_view' data={debit_card_info} dataLoadStatus={dataLoadStatus} />
                                <DebitCardTextInfo id="Debit-Card-Text-Info" data={debit_card_info}/>
                            </Grid>:
                            <>
                                <Grid container xs={12} sm={8} md={7} lg={8}>
                                    <DebitCardDetailsInfoCard id="debit-card-details-info-card" data={debit_card_info} dataLoadStatus={dataLoadStatus}/>
                                    <DebitCardTextInfo id="Debit-Card-Text-Info" data={debit_card_info}/></Grid>
                                <Grid container xs={12} sm={4} md={5} lg={4}>
                                    <DebitCardView id='debit_card_view' data={debit_card_info} dataLoadStatus={dataLoadStatus} />
                                </Grid>
                            </>
                        }
                </Grid>
                <FxModalGeneric
                    id={"enable-physical-card"}
                    open={props.digitalFirstInfoModalOpen}
                    onClose={() => {
                        updateComponentParams(dispatch, props.id, { digitalFirstInfoModalOpen : false})
                    }}
                    className={'fx-modal-small2medium'}
                    component={DigitalFirstInfoModal}
                >
                </FxModalGeneric>
            </Grid>
        )
    })
