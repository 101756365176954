/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import { ReactComponent as ThunderIcon } from '../../../assets/svg/lightning-icon.svg';
import { ReactComponent as ColorDot } from '../../../assets/svg/solid-grey-circle.svg';
import { ReactComponent as LockIcon } from '../../../assets/svg/lock-colour-icon.svg';
import { ReactComponent as SelectedTickIcon} from '../../../assets/svg/interface-verification.svg';
import { ReactComponent as SettlementAdjustmentIcon } from '../../../assets/svg/resend-icon.svg';



import { useIsMobileOrTabletScreen } from "../../../libs/utils/utils";
import { ShowInfoToolTip } from "../../Utils/CommonBaseClass";

Logger.debug(
  "BatchGraphCategory.tsx",
  "Batch Graph Category Cards initializing"
);

/**
 * Component used to show the Batch Graph Category Cards
 */

export const LedgerBatchCategoryCard: React.FC<any> = (props) => {
  ({ props } = RegisterComponent(props));
  return (
    <Grid container xs={12}>
      <Grid container xs={12} className="ledger-graph-category-card-header">
        <Grid
          item
          xs={1}
          justifyContent={"flex-start"}
        >
          {props?.item?.title === 'FASTER FUNDED'?<span className='fx-quick-settle-icon'><ThunderIcon/></span>:props?.item?.title === 'SETTLED'?<span className="fx-selected-green-icon"><SelectedTickIcon/></span>:props?.item?.title === 'SETTLEMENT ADJUSTMENTS'?<span className="fx-resend-icon"><SettlementAdjustmentIcon/></span>:props?.item?.title ==='PROCESSOR HOLDS'?<LockIcon/> : <span className="fx-quick-settlement-hold-icon" ><ColorDot/></span>}
        </Grid>
        <Grid
          item
          xs={11}
          justifyContent={"flex-start"}
          className="ledger-graph-category-card-title"
        >
          <Typography>{props?.item?.title}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} className="ledger-graph-category-card-amount">
      <FxCurrencyView value={(props?.item?.amount) ? props?.item?.amount : '0.00'} prefix={'$'}></FxCurrencyView>

      </Grid>
      <Grid item  xs={11}
          justifyContent={"flex-start"} className="ledger-graph-category-card-amount" minHeight= {!useIsMobileOrTabletScreen() ? "8rem":''} >
         {props?.item?.description? <Typography>{props?.item?.description}</Typography> : 
          <Grid item container xs={12}>
          <Grid item container xs={12} justifyContent="space-between" className='fx-settlement-adjustment-grid'>
              <Grid item>
                <Typography variant={'h6'}>Fee & charges</Typography>
              </Grid>
              <Grid item>
              <FxCurrencyView value={props?.batchSummaryData?.feeAmount} prefix={"$"}/>
              </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" className='fx-settlement-adjustment-grid' >
            <Grid item> 
                <Typography variant={'h6'} display={'flex'} alignItems={'center'}>Suspended Funds {ShowInfoToolTip('fx-hold-split-tooltips','Funds held from settlement due to a processing exception.')}</Typography>
              </Grid>
              <Grid item>
               <FxCurrencyView value={props?.batchSummaryData?.suspendedAmount} prefix={"$"}/>
              </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" className='fx-settlement-adjustment-grid'>
            <Grid item>
                <Typography variant={'h6'}  display={'flex'} alignItems={'center'}>Reserved Funds {ShowInfoToolTip('fx-hold-split-tooltips','Funds held from settlement as agreed upon during onboarding.')}</Typography>
              </Grid>
              <Grid item>
                <FxCurrencyView value={props?.batchSummaryData?.reserveAmount} prefix={"$"}/>
              </Grid>
          </Grid>
        </Grid>
         }
 

      </Grid>
    </Grid>
  );
};
