/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { useForm } from 'react-hook-form';
import FxRadioEdit from '../../../Input/FxRadio/FxRadioEdit';
import { renderPhoneError, setEmailValidationOptions, setPhoneValidationOptions, convertToPhoneRequestFormat, updateComponentParams, deeplyClean } from '../../../Utils/CommonBaseClass';
import { FxTextEdit } from '../../../Input';
import FxPhoneEdit from '../../../Input/FxPhone/FxPhoneEdit';
import { AUTH_STRINGS } from '../../../../constants/strings';
import { FxButton } from '../../../Action/FxButton';
import { getCustomerUrl, processAPIResponse } from '../../../../libs/utils/utils';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import HttpClient from '../../../../libs/utils/httpClient';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { ReactComponent as AddIcon } from '../../../../../src/assets/svg/add-new-icon.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Logger } from '../../../../libs/utils/logger';
const httpClient = HttpClient.getClient();

interface InputErrType {
    type: string;
    message: string;
}
/**
 * component used to send quick invite
 */
export const QuickInvite: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch()
    const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, control, clearErrors, setError, reset, getValues,watch } = useForm();
    const history = useHistory();
    /**
     * useEffect to set default value 
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        setValue('customerType', 'BUSINESS')
        setValue('shareVia', 'EMAIL')
        updateComponentParams(dispatch, props?.id, { 'shareVia': 'EMAIL', })
    }, [dispatch,props.id,setValue])
    /**
    * Share types
    */
    const shareViaTypes = [
        { value: 'EMAIL', label: 'Email' },
        { value: 'SMS', label: 'SMS' }
    ];
    /**
     * Customer types
     */
    const customerTypeData = [
        { value: 'BUSINESS', label: 'Business' },
        { value: 'INDIVIDUAL', label: 'Individual' }
    ]

    /**
  * Handling email erroro
  * @param err 
  * @returns 
  */
    const renderEmailError = (err: InputErrType): string => {
        if (err.type === 'required') {
            return AUTH_STRINGS.ERRORS.EMAIL_PLEASE_ENTER;
        }
        return err.message;
    };
    
    /**
     * handle reset form after submit/succesfull invitation
     */
    const resetForm = useCallback(() => {
        reset({
            legalName: '',
            customerType: 'BUSINESS',
            shareVia: 'EMAIL',
            email: '',
            invitePhone: ''
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /**
     * handle submit
     * @param data :data
     */
    const onSubmit = async (data: any) => {
        try {
            const sendToObj: any = {
                EMAIL: {
                    sendTo: [{ email: data['email']}],
                },
                SMS: {
                    sendTo: [{ phone: convertToPhoneRequestFormat(data['invitePhone']) }],
                },
            };
            const customerType = data['customerType'].toString();
        
            const receiverType='CUSTOMER' ;
            const request:any = {
                customerType,
                receiverType,
            };
            const beneficailOwner:any=[{
                email: data['email'],
                mobilePhone: convertToPhoneRequestFormat(data['invitePhone']),
                actAsAuthorizedSignatory: true
            }]
            if(customerType==="BUSINESS"){
                request.business = {legalName:data['legalName'],email:data['email']};
                request.business['beneficialOwner']=beneficailOwner;
            }
            else{
                request.individual={firstName:data['firstName'],lastName:data['lastName']}
            }
           
            const req: any = {
                ...request,
                ...sendToObj[data['shareVia']]
            }
            let status: any = await inviteApi(deeplyClean(req));
            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Invitation sent successfully',
                });
                dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'lead-insights' } });
                dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'recent-invited-leads'} });
                resetForm()
                updateComponentParams(dispatch, props.id, { 'shareVia': 'EMAIL', })
            } else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
        } catch (error) {    
            Logger.debug("QuickInvite.tsx", 'An error occurred:', error)
        }
    }

    /**
    * Method used to call Invite API
    * @param paylaoddata :form data
    * @returns
    */
    async function inviteApi(paylaoddata: any) {
        try {
            const data: any = await httpClient.post(getCustomerUrl('invite/signUp'), paylaoddata).then(response => {
                return response;
            })
                .catch((error) => {
                    return { ...error };
                })
            return data;
        } catch (err) {
            Logger.error("QuickInvite.tsx", "error", err);
            return false;
        }
    }
    /**
     * Handle click events
     */
    const handleQuickInviteClick=()=>{
        let additionalData:any={}
        const sharVia=getValues('shareVia')
        const quickInviteData:any={
            EMAIL:{
                email:getValues('email')
            },
            SMS:{
                sms:getValues('invitePhone')
            }
        }
        const customerType=getValues('customerType')
        if(customerType==='BUSINESS'){
            additionalData={"legalName":getValues('legalName')}
        }
        else{
            additionalData={"firstName":getValues('firstName'),"lastName":getValues('lastName')}
        }
        const quickData:any={
            ...quickInviteData[sharVia],
            customerType,
            additionalData
        }
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'lead-invite', data: quickData} });
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'recent-invited-leads'} });
    
       history.push('/lead/invite')
      
    }
     /**
     * Method handles customer Type change
     */
    const handleChangeCustomerType = (event: any) => {
        setValue("legalName",'');
        setValue("firstName",'');
        setValue("lastName",'');
    }
    /**
     * Method handles share via change
     */
    const handleChangeVia = (event: any) => {
        setValue("email",'');
        setValue("invitePhone",'');
        updateComponentParams(dispatch, props?.id, { 'shareVia': event?.target?.value, })
    }

    return <Grid id="quick-invite-main-grid" item container xs={12} direction='row'>
        <FxCard id={"quick-invite-card"} className="fx-theme-passport fx-quick-invite fx-quick-invite-header">
            <FxCardHeader><Typography variant='h3'>Quick Invite</Typography></FxCardHeader></FxCard>
        <FxCard id="quick-invite-card" className="fx-theme-passport fx-quick-invite fx-form-edit-profile">
            <form id="quick-invite-form" onSubmit={handleSubmit(onSubmit)}>
                <FxCardBody id="quick-invite-card-body" className="fx-info-card fx-margin-top-reducer" >
                    <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                        <Grid item xs={12} sm={12}>
                            <FxRadioEdit register={{ ...register("customerType") }} label="LEAD TYPE" control={control} data={customerTypeData} id="customerType" name="customerType" row="vertical" setValue={setValue} onChange={handleChangeCustomerType}/>
                        </Grid>
                        {watch('customerType')==='BUSINESS' && <Grid item xs={12} sm={12}>
                        <FxTextEdit register={{ ...register("legalName") }}  className={errors.legalName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="legalName" name="legalName" label="Legal Business Name*" />
                        </Grid>}
                        {watch('customerType')==='INDIVIDUAL' &&<> <Grid item xs={6} sm={6}>
                        <FxTextEdit register={{ ...register("firstName") }}  className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }}  id="quick-invite-firstName" name="firstName" label="First Name*"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <FxTextEdit register={{ ...register("lastName") }}  className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="lastName" name="lastName" label="Last Name*" />
                        </Grid></>}
                        <Grid item xs={12} sm={12}>
                            <FxRadioEdit register={{ ...register("shareVia") }} label="SHARE VIA *" control={control} data={shareViaTypes} id="share-via-type" name="shareVia"  row="vertical" setValue={setValue} onChange={handleChangeVia} />
                        </Grid>
                        {props?.shareVia === 'EMAIL' && <Grid item xs={12}>
                            <FxTextEdit register={{ ...register("email") }} control={control} rules={(setEmailValidationOptions(true))} className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} id="email" name="email" label="Email *" isEditable={true}  setValue={setValue}   />
                            <div className={'error-message'}>
                                {errors.email && renderEmailError(errors.email)}
                            </div>
                        </Grid>}
                        {props?.shareVia === 'SMS' && <Grid item xs={12}>
                            <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(true)} setValue={setValue} disableFlag={false} setError={setError} clearErrors={clearErrors} className={errors.invitePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="invitePhone" name="invitePhone" label="Phone *" />
                            <div className={'error-message'}>
                                {errors.invitePhone && renderPhoneError(errors.invitePhone)}
                            </div>
                        </Grid>}
                        <Grid item xs={12} container justifyContent={'flex-start'} className={'fx-quick-invite-fill-info-button-container'}>
                          <FxButton
                            className="fx-button fx-quick-invite-fill-info-button"
                            id="button-fill-customer-info-and-share"
                            onClick={handleQuickInviteClick}
                            startIcon={<AddIcon />}
                          > FILL LEAD INFO AND SHARE
                          </FxButton>
                        </Grid>
                    </Grid>
                </FxCardBody>
                <FxButton
                    disableRipple={false}
                    className={"fx-button fx-button-theme fx-quick-invite-submit-button"}
                    id={"quick-invite-form-card-submit-button"}
                    type="submit"
                    isSubmitting={isSubmitting}
                >Send Invite
                </FxButton>
            </form>
        </FxCard>
    </Grid>
})