/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';

interface FxPermissionDeniedHomeProps {
    id?: any;
    title?: any;
};

/**
 * This Component handles the Permission denied message
 */
export const FxPermissionDeniedHome: React.FC<FxPermissionDeniedHomeProps> = React.memo(
    (props) => {
        const dispatch = useDispatch()
        const history = useHistory();

        /**
         * reset the component
         * */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            setTimeout(()=>{
                history.push('/logout')
            },30000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <Grid id="permission-denied-Grid " item xs={12} className="fx-404">
                <FxCard id="fx-404-card" >
                    <FxCardBody id="fx-404-card-body"  >
                        <Grid item container justifyContent="center" xs={12}>
                            <Typography variant="h3" id="fx-404-first-message" >Permission</Typography>
                            <Typography variant="h3" id="fx-404-third-message" >&nbsp; denied</Typography>
                            <Grid item xs={12}>
                                <Grid item container justifyContent="center" xs={12}>
                                    <Typography id={"sub-title"} variant='h4'> You do not have the access to the application you are trying to access.</Typography>
                                    <Typography id={"sub-title"} variant='h4'> The admin may have set restrictions that prevent you from accessing the application.</Typography>
                                </Grid></Grid>
                                <Grid item xs={12}>&nbsp;</Grid>
                            <Grid item xs={12}>
                                <Grid item container justifyContent="center" xs={12}>
                                    <Grid item>
                                        <Typography id={"sub-title"} variant='h5'> {'For any kind of support, please reach out to us at'} &nbsp;</Typography>
                                    </Grid>
                                    <Grid item>
                                         <a href="mailto:support@prioritypassport.com"><Typography id={"sub-title-mail"} variant='h5' className='fx-permission-denied-link-color'>support@prioritypassport.com</Typography></a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid>
        )
    })

