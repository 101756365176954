/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getKey } from '../../../libs/utils/storageManager';
import { ManageAlertList } from './ManageAlertList';

/**
 * Component: Alert Preference List
 * Usage: To display list of Alert Preference
 
*/
export const AlertPreference: React.FC<any> = React.memo((props) => {
  const dispatch = useDispatch();

  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => {    
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Alert Preference' } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 // Get alert perferences url.
  const src = {
    url: `authorizedUser/id/${getKey('authUserId')}/preference`,
    method: "GET",
}

  return (
    <Grid id="alert-grid" className='fx-alert-perferences' container rowSpacing={2}>
      <Grid item xs={12} >
        <ManageAlertList id="manage-alert-list" source={src}></ManageAlertList>
      </Grid>
    </Grid>
  )
})


